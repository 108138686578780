import BuildingDashboardView from './building-dashboard.vue'
import { resolve } from './building-dashboard.resolve'

export const Routes = [
  // Building view
  {
    name: 'building-dashboard',
    path: '/organization/:organizationId/building/:id/dashboard',
    component: BuildingDashboardView,

    async beforeEnter (to, from, next) {
      const { redirectTo } = await resolve({ from, to }) || {}
      next(redirectTo)
    }
  }
]
