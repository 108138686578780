<script>
import { mapState, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { EntityType, EntityDescription, getPlaceIcon, getPlaceDescription } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Indicates whether navigation links etc.
    // are enabled
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      // Search condition
      condition: '',
      EntityType,
      EntityDescription
    }
  },

  computed: {
    ...mapState({
      // Recent status of devices
      deviceStatus: state => state.deviceStatus.devices,
      // Indicates that search is ongoing
      isSearching: state => state.search.isSearching,
      // Search results
      results: state => state.search.groups
    }),

    // Current device status
    status () {
      return (device) => this.deviceStatus[device.serialNumber]
    },

    statusColor () {
      return (device) => {
        const status = this.status(device)
        return status?.connection?.color || '#a0a0a0'
      }
    },

    // Search results per entity type
    groups () {
      return Object
        .entries(this.results)
        .map(([type, items]) => ({ type, items }))
        .filter(group => group.items?.length > 0)
    },

    // Returns true if there are any search results to be shown
    hasResults () {
      return this.groups.length > 0
    },

    // Indicates whether devices can be configured by the current user
    canConfigureDevice () {
      return device => device.serialNumber &&
        device.isConnectedDevice &&
        this.canUseChildrenOf('device-configuration') &&
        this.guardian.canDeviceUse('live-status', device.serialNumber)
    }
  },

  methods: {
    ...mapActions([
      'startEntitySearch',
      'clearEntitySearch',
      'filterInventory',
      'gotoRoute',
      'showDialog'
    ]),

    countString,
    getPlaceIcon,
    getPlaceDescription,

    // Starts searching
    async search (condition = '') {
      condition = condition.trim()
      this.condition = condition
      this.clearEntitySearch()
      if (condition) {
        await this.startEntitySearch({ condition })
      }
    },

    // Hides results
    hideResults () {
      this.condition = ''
      this.clearEntitySearch()
    },

    // Launches device configuration dialog
    configureDevice (device) {
      const { serialNumber } = device
      this.showDialog({ dialog: 'device-configuration', data: { serialNumber } })
    },

    // Shows history of the specified device
    showHistory (device) {
      this.showDialog({ dialog: 'device-history', data: { device } })
    },

    // Opens the specified device in the inventory
    async showInInventory (device) {
      const { serialNumber } = device
      await this.filterInventory({ column: { name: 'serialNumber' }, value: serialNumber })
      await this.gotoRoute({ name: 'inventory', query: { selection: serialNumber } })
    }
  }
}
</script>

<template>
  <div class="search">
    <q-input class="input-filter" dark standout square dense icon="search" debounce="1000" max-length="50"
      :model-value="condition" @update:model-value="condition => search(condition)"
      :disabled="isSearching">
    <template v-slot:prepend>
      <q-icon name="search"></q-icon>
    </template>
  </q-input>

    <div class="searching row items-center" v-if="isSearching">
      <q-icon name="change_circle" class="rotate-reverse q-mr-md" color="blue-7"
        size="22px"></q-icon>
      <span>Searching for <b><i>{{ condition }}</i></b> ...</span>
    </div>

    <div class="search-results" v-if="hasResults">
      <q-icon class="icon-searching rotate-reverse" name="change_circle" color="blue-7" size="22px"
        v-if="isSearching"></q-icon>
      <q-icon class="button-close" name="close" size="24px" color="grey-7" @click="hideResults()"
        v-if="!isSearching"></q-icon>

      <div v-for="group in groups" class="group">
        <div class="group-title">
          {{ countString(group.items, EntityDescription[group.type]) }}
        </div>

        <ul class="group-items" v-if="group.type === EntityType.Device">
          <li v-for="item in group.items" class="group-item">
            <div class="content row justify-between">
              <div class="row items-center">
                <q-icon size="16px" name="fiber_manual_record"
                  :style="{ color: statusColor(item) }"></q-icon>
                <router-link class="item-link q-ml-sm"
                  :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
                  {{ item.acronym }} {{ item.serialNumber }}
                  <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
                </router-link>

                <q-icon size="20px" v-if="item.owner && canUse('device-dashboard')"
                  name="keyboard_arrow_right" color="grey-9"></q-icon>

                <router-link class="item-link" v-if="item.owner && canUse('device-dashboard')"
                  :to="{ name: 'organization-dashboard', params: { id: item.ownerId } }">
                  {{ item.owner.name }}
                  <sc-tooltip :text="`Go to ${item.owner.name} dashboard`"></sc-tooltip>
                </router-link>
              </div>

              <div class="buttons row items-center" :class="{'one-item': group.items.length === 1 }">
                <q-btn v-if="canUse('device-dashboard') && item.place" unelevated round dense
                  :icon="getPlaceIcon(item.place.placeType)" size="sm" class="q-ml-sm"
                  :to="{ name: 'place-dashboard', params: { id: item.placeId } }">
                  <sc-tooltip :text="`Go to ${item.place.name} dashboard`"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canUse('inventory') && item.serialNumber" unelevated round dense
                  icon="list_alt" size="sm" class="q-ml-sm" @click="showInInventory(item)">
                  <sc-tooltip text="Show in the inventory"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canUse('history-graph') && item.serialNumber" unelevated round dense
                  icon="timeline" size="sm" class="q-ml-sm" @click="showHistory(item)">
                  <sc-tooltip text="Show device history"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canConfigureDevice(item)" unelevated round dense icon="settings"
                  size="sm" class="q-ml-sm" @click="configureDevice(item)">
                  <sc-tooltip text="Configure the device"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canUse('alerts-configure') && item.serialNumber" unelevated round dense
                  icon="notifications" size="sm" class="q-ml-sm"
                  :to="{ name: 'alerts', query: { tab: 'configuration', device: item.serialNumber } }">
                  <sc-tooltip text="Go to alert configuration"></sc-tooltip>
                </q-btn>
              </div>
            </div>
          </li>
        </ul>

        <ul class="group-items" v-if="canUse('device-dashboard') && group.type === EntityType.Place">
          <li v-for="item in group.items" class="group-item">
            <div class="content row justify-between">
              <div class="row items-center">
                <q-icon :name="getPlaceIcon(item.placeType)" size="sm" class="q-ml-sm"
                  color="blue-6"></q-icon>
                <router-link class="item-link q-ml-sm"
                  :to="{ name: 'place-dashboard', params: { id: item.id }, query: { organization: item.organizationId } }">
                  {{ item.name }}
                  <sc-tooltip :text="`Go to ${item.name} dashboard`"></sc-tooltip>
                </router-link>
                <q-icon size="20px" name="keyboard_arrow_right" color="grey-9"></q-icon>
                <router-link class="item-link"
                  :to="{ name: 'organization-dashboard', params: { id: item.organizationId } }">
                  {{ item.organization.name }}
                  <sc-tooltip :text="`Go to ${item.organization.name} dashboard`"></sc-tooltip>
                </router-link>
              </div>

              <div class="buttons row items-center">
                <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                  class="q-ml-sm"
                  :to="{ name: 'organization-place', params: { organizationId: item.organizationId, id: item.id } }">
                  <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canUse('inventory')" unelevated round dense icon="list_alt" size="sm"
                  class="q-ml-sm" :to="{ name: 'inventory', query: { place: item.id } }">
                  <sc-tooltip :text="`Go to ${getPlaceDescription(item)} inventory`"></sc-tooltip>
                </q-btn>
              </div>
            </div>
          </li>
        </ul>

        <ul class="group-items" v-if="isAdministrator && group.type === EntityType.Organization">
          <li v-for="item in group.items" class="group-item">
            <div class="content row justify-between">
              <div class="row items-center">
                <router-link class="item-link"
                  :to="{ name: 'organization-dashboard', params: { id: item.id } }">
                  {{ item.name }} {{ item.profile ? `(${item.profile.fullName})` : '' }}
                  <sc-tooltip :text="`Go to ${item.name} dashboard`"></sc-tooltip>
                </router-link>
              </div>

              <div class="buttons row items-center">
                <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                  class="q-ml-sm" :to="{ name: 'organization', params: { id: item.id } }">
                  <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                </q-btn>

                <q-btn v-if="isAdministrator && item.profile" unelevated round dense icon="home_work" size="sm"
                  class="q-ml-sm"
                  :to="{ name: 'organization-profile', params: { id: item.profileId } }">
                  <sc-tooltip
                    :text="`Go to ${item.profile.fullName} profile settings`"></sc-tooltip>
                </q-btn>

                <q-btn v-if="canUse('inventory')" unelevated round dense icon="list_alt" size="sm"
                  class="q-ml-sm" :to="{ name: 'inventory', query: { organization: item.id } }">
                  <sc-tooltip :text="`Go to ${item.name} inventory`"></sc-tooltip>
                </q-btn>
              </div>
            </div>
          </li>
        </ul>

        <ul class="group-items" v-if="isAdministrator && group.type === EntityType.User">
          <li v-for="item in group.items" class="group-item">
            <div class="content row justify-between">
              <div class="row items-center">
                <span>
                  {{ item.fullName }}{{ item.fullName !== item.name ? `, ${item.name}` : '' }}
                </span>
                <q-icon size="20px" name="keyboard_arrow_right" color="grey-9"></q-icon>
                <span>
                  {{ item.organization.name }}
                </span>
              </div>

              <div class="buttons row items-center">
                <q-btn v-if="isAdministrator" unelevated round dense icon="edit" size="sm"
                  class="q-ml-sm"
                  :to="{ name: 'user', params: { id: item.id }, query: { organizationId: item.organizationId } }">
                  <sc-tooltip :text="`Go to ${item.name} settings`"></sc-tooltip>
                </q-btn>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.search {
  position: relative;

  .searching,
  .search-results {
    position: fixed;
    z-index: 100;
    top: 65px;
    left: 48px;
    min-width: 430px;
    max-height: 590px;
    overflow: auto;
    background-color: #f0f4fd;
    box-shadow: 0 0 4px #444;
    padding: 12px;

    .icon-searching {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .button-close {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }

    .group {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .group-title {
        font-weight: bold;
      }

      .group-items {
        list-style: none;
        margin: 0;
        padding: 4px 8px 0 8px;

        .group-item {
          &:hover {
            background-color: #e0e7f6;
          }

          .content {
            height: 30px;

            .buttons {
              visibility: hidden;
            }
            .buttons.one-item {
              visibility: visible;
            }

            &:hover {
              .buttons {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

}
</style>