import Konva from 'konva'
import { Log, Point, Rectangle, isInsideRectangle, rectanglesOverlap } from '@stellacontrol/utilities'
import { PlanItemState, PlanLineStyle, PlanBackgroundStyle, PlanPortType, PlanLayers, PlanScale, PlanLineType } from '@stellacontrol/planner'
import { moveToTop } from '../utilities/shapes'
import { PlanEvent } from '../events/plan-event'
import { PlanEventEmitter } from '../events/plan-event-emitter'

/**
 * Plan shape
 */
export class Shape extends PlanEventEmitter {
  /**
   * Creates a plan shape
   * @param {PlanItem} item Plan item represented by the shape
   * @param {Object} dataCallback Callback for fetching additional data for the plan item
   */
  constructor (item, dataCallback) {
    super()
    if (!item) throw new Error('Plan item is required')
    // Fetch additional data and assign to the item
    const data = dataCallback ? dataCallback(item) : undefined
    item.setData(data)

    // Store the item
    this.item = item
    this.dataCallback = dataCallback
    this.ports = []

    // Create the content element
    this.content = new Konva.Group()
  }

  __stage = null
  __eventsBound = false
  __destroyed = false
  __blurRadius

  /**
   * Item type represented by the shape.
   * Implement in descendants.
   * @type {PlanItemType}
   */
  static get type () {
    throw new Error('Not implemented')
  }

  /**
   * Shape defaults
   * @type {Object}
   */
  get defaults () {
    return {
      port: {
        width: 12,
        height: 12
      },
      ports: {
        width: 12,
        height: 12,
        delay: {
          expand: 250,
          collapse: 500
        },
        hover: {
          backgroundStyle: new PlanBackgroundStyle({ color: '#c7f98d' }),
        },
        taken: {
          backgroundStyle: new PlanBackgroundStyle({ color: '#9ad25a' }),
        },
        expanded: {
          margin: 15,
          radius: 12,
          width: 24,
          height: 24
        },
      }
    }
  }

  /**
   * Shape layout.
   * Must define in shapes which have connectors.
   * @type {ShapeLayout}
   */
  get layout () {
    return null
  }

  /**
   * Returns `true` when shape has a defined layout,
   * specifying ports etc. interactive elements.
   * Primitive shapes such as boxes and lines do not need this.
   */
  get hasLayout () {
    return this.layout != null
  }

  /**
   * Various timers used by the shape
   * @type {Dictionary<String, Number>}
   */
  __timers = {}

  /**
   * Starts a new timer
   * @param {String} name Timer name
   * @param {Number} interval Interval after which to call the callback
   * @param {Function} callback Callback to call
   */
  startTimer (name, interval, callback) {
    if (name) {
      const { __timers: timers } = this
      clearTimeout(timers[name])
      if (callback && interval >= 0) {
        timers[name] = setTimeout(() => callback(), interval)
      }
    }
  }

  /**
   * Checks if the specified timer is running
   * @param {String} name Timer name
   */
  isTimerActive (name) {
    return name && this.__timers[name] != null
  }

  /**
   * Stops a timer
   * @param {String} name Timer name
   */
  stopTimer (name) {
    if (name) {
      const { __timers: timers } = this
      clearTimeout(timers[name])
      delete timers[name]
    }
  }

  /**
   * Clears the {@link __timers}
   */
  clearTimers () {
    for (const timer of Object.values(this.__timers)) {
      clearTimeout(timer)
      this.__timers = {}
    }

  }

  /**
   * Creates all child shapes making up the shape
   */
  createShapes () {
    const { item, labelMargin, defaults } = this

    // Add label and label border
    this.label = new Konva.Group({ id: 'label' })

    const labelText = new Konva.Text({
      id: 'text',
      x: labelMargin,
      y: labelMargin
    })

    const labelBorder = new Konva.Rect({
      id: 'border',
      x: 0,
      y: 0,
      fill: 'white',
      stroke: '#2196f3',
      strokeWidth: 2,
      cornerRadius: labelMargin,
      opacity: 1
    })

    this.label.add(labelBorder, labelText)
    this.content.add(this.label)

    // Add boundaries box
    if (this.showShapeBoundaries) {
      this.shapeBoundaries = new Konva.Rect({
        stroke: '#2196f3',
        strokeWidth: 1,
        dash: PlanLineStyle.getLineDash(PlanLineType.Dotted)
      })
      this.content.add(this.shapeBoundaries)
    }

    // Add tag to equipment items
    if (item.canHaveTag) {
      this.tag = new Konva.Group({ id: 'tag' })

      const tagBorder = new Konva.Rect({
        id: 'border',
        x: 0,
        y: 0,
        fill: 'green',
        stroke: 'white',
        strokeWidth: 1,
        cornerRadius: 10,
        opacity: 1
      })
      const tagText = new Konva.Text({
        id: 'text',
        x: 6,
        y: 5,
        fill: 'white',
        fontSize: 12,
        fontStyle: 'bold'
      })

      this.tag.add(tagBorder, tagText)
      this.content.add(this.tag)
      this.tag.moveToTop()
    }

    // Add port slider if item has connector ports
    if (item.hasPorts) {
      const { lineStyle, backgroundStyle } = this.getPortStyle()

      this.portDrawer = new Konva.Group({
        id: 'port-drawer',
        visible: false
      })
      this.content.add(this.portDrawer)

      const border = new Konva.Rect({
        id: 'port-drawer-border',
        stroke: lineStyle.color,
        strokeWidth: 0,
        listening: false
      })
      this.portDrawer.add(border)

      const ports = (item.ports || []).map(port => {
        const portShape = defaults.ports.expanded.radius
          ? new Konva.Circle()
          : new Konva.Rect()

        portShape.name('port')
        portShape.id(port.id)
        portShape.stroke(lineStyle.color)
        portShape.strokeWidth(lineStyle.width)
        portShape.fill(backgroundStyle.color)

        return portShape
      })

      this.portDrawer.add(...ports)
    }
  }

  /**
   * Refreshes all child shapes making up the shape,
   * destroying the previous shapes first
   * @param {PlanRenderer} renderer Plan renderer
   */
  refreshShapes (renderer) {
    const { content, label, tag } = this
    if (content) {
      content.destroyChildren()
      this.createShapes()
      this.bindShapeEvents(renderer)
      // Label and tag must be on top of everything
      if (label) {
        moveToTop(label)
      }
      if (tag) {
        moveToTop(tag)
      }
    }
  }

  /**
   * Indicates that label should be rendered with a little delay.some shapes
   * Some shapes require the content to be present, to correctly render the label.
   * @type {Boolean}
   */
  get deferLabel () {
    return false
  }

  /**
   * Call to remove the shape from the layer
   */
  destroy () {
    super.destroy()
    this.clearTimers()
    if (!this.__destroyed) {
      this.content?.destroy()
      this.__destroyed = true
    }
  }

  /**
   * Logs a message in context of the shape
   * @param {String} message Message to log
   * @param {any} data Additional data to log
   */
  log (message, data) {
    Log.debug(`[${this.item.type}/${this.id}] ${message}`, data)
  }

  /**
   * Logs a trace message in context of the shape
   * @param {String} message Message to log
   * @param {any} data Additional data to log
   */
  trace (message, data) {
    Log.trace(`[${this.item.type}/${this.id}] ${message}`, data)
  }

  /**
   * Returns shape stage
   * @returns {Konva.Stage}
   */
  get stage () {
    if (this.__stage) {
      return this.__stage
    }
    let element = this.content
    while (element) {
      if (element.nodeType === 'Stage') {
        this.__stage = element
        return element
      } else {
        element = element.parent
      }
    }
    return undefined
  }

  /**
   * Returns HTML container for the {@link stage}
   * @type {HTMLElement}
   */
  get container () {
    return this.stage?.container()
  }

  /**
   * Plan item represented by the shape
   * @type {PlanItem}
   */
  item

  /**
   * Shape identifier, same as that of item's
   * @type {String}
   */
  get id () {
    return this.item?.id
  }

  /**
   * Callback for fetching additional data for the plan item
   * @type {Function<PlanItem, any>}
   */
  dataCallback

  /**
   * Additional item data returned by the {@link dataCallback}
   * @type {any}
   */
  data

  /**
   * Group shape containing all item-specific shapes.
   * @type {Konva.Shape}
   */
  content

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.content
  }

  /**
   * Shape label
   * @type {Konva.Group}
   */
  label

  /**
   * Shape tag
   * @type {Konva.Group}
   */
  tag

  /**
   * Shape boundaries
   * @type {Konva.Rect}
   */
  shapeBoundaries

  /**
   * Margin between the label and its border.
   * If zero, the label will not have a border.
   * @type {Number}
   */
  get labelMargin () {
    return 0
  }

  /**
   * Shape representing label border
   * @type {Konva.Rect}
   */
  get labelBorder () {
    return this.label.getChildren()[0]
  }

  /**
   * Shape representing label text
   * @type {Konva.Text}
   */
  get labelText () {
    return this.label.getChildren()[1]
  }

  /**
   * Indicates that the shape is locked for any changes.
   * This can happen due to the item marked as locked,
   * or the entire layer being locked, like for example the background layer
   * @type {Boolean}
   */
  isLocked

  /**
   * Locks the shape, disabling any interactions with it
   */
  lock () {
    this.isLocked = true
  }

  /**
   * Unlock the shape
   */
  unlock () {
    this.isLocked = false
  }


  /**
   * Indicates whether the shape can be selected, transformed etc.
   * @type {Boolean}
   */
  get canInteractWith () {
    return this.item.canSelect && !this.item.isLocked
  }

  /**
   * Current X coordinate of the shape
   * @type {Number}
   */
  get x () {
    return this.content?.x()
  }

  /**
   * Current Y coordinate of the shape
   * @type {Number}
   */
  get y () {
    return this.content?.y()
  }

  /**
   * Returns shape boundaries
   * @param {PlanRenderer} renderer Plan renderer
   * @type {Rectangle}
   */
  getBounds ({ renderer }) {
    return this.item.getBounds(renderer.isCrossSection)
  }

  /**
   * Returns shape center
   * @param {PlanRenderer} renderer Plan renderer
   * @type {Point}
   */
  getCenter ({ renderer }) {
    if (renderer) {
      const bounds = this.main.getClientRect()
      const center = Rectangle.from(bounds).center
      return center
    }
  }

  /**
   * Indicates whether the item is currently being pointed at
   * @type {Boolean}
   */
  isPointedAt

  /**
   * Indicates that the shape can be resized,
   * @type {Boolean}
   */
  get canResize () {
    return this.item?.canResize
  }

  /**
   * Current position the main shape
   * @type {Point}
   */
  get position () {
    const { x, y } = this
    if (x != null && y != null) {
      return new Point({ x, y })
    } else {
      return null
    }
  }

  /**
   * Determines the current coordinates of the item, depending whether
   * we're looking at the cross-section view or the floor plan
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Plan item
   * @returns {Point}
   */
  getCoordinates (renderer, item) {
    const coordinates = renderer.isCrossSection
      ? item.crossSection.coordinates
      : item.coordinates
    return coordinates.copy()
  }

  /**
   * Checks whether the shape has no visual representation
   * @type {Boolean}
   */
  get isEmpty () {
    return this.content
      ? this.content.getChildren().length === 0
      : true
  }

  /**
   * Indicates that shape represents a dynamic connector between two items
   * @type {Boolean}
   */
  get isConnector () {
    return Boolean(this.item?.isConnector)
  }

  /**
   * Returns the details of a shape
   * @param {Shape} shape Shape details
   * @returns {Object}
   */
  getShapeDetails (shape) {
    if (shape) {
      const id = shape.id()
      const { x, y } = shape.getAbsolutePosition()
      const { width, height } = shape.size()
      return { id, x, y, width, height, shape }
    } else {
      return {}
    }
  }

  /**
   * Shapes representing connection ports.
   * Populate in ancestors which have any connection ports, such as device or antenna!
   * @type {Array[Konva.Shape]}
   */
  ports

  /**
   * Container for item ports
   * @type {Konva.Group}
   */
  portDrawer

  /**
   * Port width after applying scaling to the shape
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} expanded If `false`, we need size of the port on the device shape.
   * If `true`, we need size of the port in the expanded drawer.
   * @type {Number}
   */
  getPortWidth ({ renderer, expanded } = {}) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, defaults } = this
    const scale = renderer.getEquipmentScale()

    const sizeRatio = item.isCircular
      ? item.radiusX / item.defaults.radius
      : item.width / item.defaults.width

    // Expanded ports must be increased inversely to the item scale,
    // so they still remain well visible
    const width = expanded
      ? Math.round(defaults.ports.expanded.width / scale.value)
      : Math.max(5, Math.ceil(defaults.ports.width * sizeRatio))

    return width
  }

  /**
   * Port height after applying scaling to the shape
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} expanded If `false`, we need size of the port on the device shape.
   * If `true`, we need size of the port in the expanded drawer.
   * @type {Number}
   */
  getPortHeight ({ renderer, expanded } = {}) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, defaults } = this
    const scale = renderer.getEquipmentScale()
    const sizeRatio = item.isCircular
      ? item.radiusY / item.defaults.radius
      : item.width / item.defaults.width

    const height = expanded
      ? defaults.ports.expanded.height
      : Math.max(5, Math.ceil(defaults.ports.height * sizeRatio))

    // Expanded ports must be increased inversely to the item scale,
    // so they still remain well visible
    return expanded
      ? Math.round(height / scale.value)
      : height
  }

  /**
   * Port radius after applying scaling to the shape
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} expanded If `false`, we need radius of the port on the device shape.
   * If `true`, we need radius of the port in the expanded drawer.
   * @type {Number}
   */
  getPortRadius ({ renderer, expanded } = {}) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, defaults } = this
    const scale = renderer.getEquipmentScale()
    const sizeRatio = item.isCircular
      ? item.radiusY / item.defaults.radius
      : item.width / item.defaults.width

    const radius = expanded
      ? defaults.ports.expanded.radius
      : Math.max(5, Math.ceil(defaults.ports.radius * sizeRatio))

    // Expanded ports must be increased inversely to the item scale,
    // so they still remain well visible
    return expanded
      ? Math.round(radius / scale.value)
      : radius
  }

  /**
   * Identifier of the currently selected port
   * @param {String}
   */
  selectedPort

  /**
   * Returns details of the specified port
   * @param {String} id Port identifier. If not specified, returns the single port available on the shape.
   * @returns {PlanPort}
   */
  getPort (id) {
    return id
      ? this.item.ports.find(p => p.id === id)
      : this.item.ports[0]
  }

  /**
   * Returns the details of a shape representing the specified port
   * @param {PlanPort} port Plan port
   * @param {Boolean} expanded If true, we're looking for port shape in the expanded drawer
   * @returns {Object}
   */
  getPortShape ({ port, expanded }) {
    if (!port) throw new Error('Port is required')

    const shape = expanded
      ? this.portDrawer.findOne(`#${port.id}`)
      : this.ports.find(s => s.id() === port.id)
    const { x, y } = shape.getAbsolutePosition()
    const { width, height } = shape.size()
    return { x, y, width, height, shape }
  }

  /**
   * Returns the style for rendering the the specified port
   * @param {Boolean} active If true, we're looking for style of an active port
   * @returns {Object}
   */
  getPortStyle ({ active } = {}) {
    const { item, defaults } = this

    let lineStyle = item.lineStyle
    let backgroundStyle = item.backgroundStyle
    let takenStyle = item.backgroundStyle

    if (active) {
      lineStyle = defaults.ports.hover.lineStyle || defaults.ports.lineStyle || lineStyle
      backgroundStyle = defaults.ports.hover.backgroundStyle || defaults.ports.backgroundStyle || backgroundStyle
      takenStyle = defaults.ports.taken.backgroundStyle || defaults.ports.backgroundStyle || backgroundStyle

    } else {
      lineStyle = defaults.ports.lineStyle || lineStyle
      backgroundStyle = defaults.ports.backgroundStyle || backgroundStyle
      takenStyle = defaults.ports.taken.backgroundStyle || defaults.ports.backgroundStyle || backgroundStyle
    }

    return { lineStyle, backgroundStyle, takenStyle }
  }

  /**
   * Checks whether the entire shape is a receiving port.
   * This is the case with shapes such as Dome antenna.
   * @type {Boolean}
   */
  get shapeIsPort () {
    return false
  }

  /**
   * Indicates that ports are currently expanded
   */
  get portsExpanded () {
    return this.portDrawer?.visible() || false
  }

  /**
   * Expands the port drawer if user stays inside the shape for long enough
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} instant Set to `true` to force instant opening of the port drawer.
   * Normally there's a little delay, to prevent flickering of port drawers as user
   * moves the mouse across the plan
  */
  expandPorts ({ renderer, instant }) {
    if (!renderer) throw new Error('Renderer is required')
    // Don't expand if there aren't any free ports on the shape
    if (!this.hasFreePorts({ renderer })) return

    // Expand when user not busy with things other than adding new connector
    if (renderer.isUserActive && renderer.newConnector == null) return
    const { item, portDrawer, defaults } = this

    // When drawing connectors, there should be no delay in opening ports on target shapes
    instant = instant || renderer.newConnector != null
    const delay = instant ? 0 : defaults.ports.delay.expand

    if (item.hasActivePorts) {
      this.stopTimer('port-slider-close')
      this.startTimer('port-slider-open', delay, () => {
        if (!this.portsExpanded) {
          // Show port drawer with expanded ports
          portDrawer.visible(true)

          // Hide normal shape ports
          for (const port of this.ports) {
            port.visible(false)
          }

          // Redraw the ports
          this.renderPortDrawer({ renderer })
          this.renderPorts({ renderer })

          // Redraw all related connectors
          renderer.updateLinkedItems(item)

          // Remove selection box so it does not interfere with dragging connectors
          renderer.deselect()
        }
      })
    }
  }

  /**
   * Collapses the expanded the port drawer if user stays outside the shape for long enough
   * @param {Boolean} instant Set to `true` to force instant closing of the port drawer.
   * Normally there's a little delay, to prevent flickering of port drawers as user
   * moves the mouse across the plan
   */
  collapsePorts ({ renderer, instant }) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, portDrawer, defaults } = this
    const delay = instant ? 0 : defaults.ports.delay.collapse

    if (item.hasActivePorts) {
      this.stopTimer('port-slider-open')
      this.startTimer('port-slider-close', delay, () => {
        // Collapse the port, unless the user is now drawing a connector
        // from the shape - in this case expanded ports must remain visible
        // until the user finishes drawing the connectors!
        const collapse = this.portsExpanded && !renderer.isDrawingConnectorFrom(item)
        if (collapse) {
          // Hide port drawer with expanded ports
          portDrawer.visible(false)

          // Show normal shape ports
          this.renderPorts({ renderer })

          // Redraw all related connectors
          renderer.updateLinkedItems(item)
        }
      })
    }
  }

  /**
   * Returns the bounds of an expanded port drawer
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Rectangle}
   */
  // eslint-disable-next-line no-unused-vars
  getPortDrawerBounds ({ renderer }) {
    const { layout, item, portsExpanded } = this
    if (!layout) throw new Error(`[${item.type}] Shape layout is required to determine port bounds`)

    const scale = portsExpanded
      ? renderer.getEquipmentScale(item)
      : null

    return layout.getPortDrawerBounds({ scale })
  }

  /**
   * Returns coordinates and size of a shape representing the specified port.
   * @param {PlanPort} port Port
   * @param {Boolean} expanded If `false`, we need bounds of the port on the device shape.
   * If `true`, we need coordinates of the port in the expanded port drawer
   * @returns {Rectangle}
   * @description At this stage we don't take into considerations any rotation or scaling.
   */
  getPortBounds ({ renderer, port, expanded }) {
    if (!renderer) throw new Error('Renderer is required')
    if (!port) throw new Error('Port is required')

    const { layout, item, portsExpanded } = this
    if (!layout) throw new Error(`[${item.type}] Shape layout is required to determine port bounds`)

    const scale = portsExpanded
      ? renderer.getEquipmentScale(item)
      : null

    const bounds = layout.getPortBounds({
      id: port.id,
      scale,
      onDrawer: expanded
    })

    return bounds
  }

  /**
   * Returns absolute coordinates of the point on the specified port where connector can begin/end
   * after applying of rotation and scale
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanPort} port Port
   * @param {Boolean} expanded If `false`, we need connection point on the port on the device shape.
   * If `true`, we need connection point on the port in the expanded drawer.
   * @returns {Point}
   */
  getConnectionPoint ({ renderer, port, expanded }) {
    if (!renderer) throw new Error('Renderer is required')
    if (!port) throw new Error('Port is required')

    const { item, layout } = this
    if (!layout) throw new Error(`[${item.type}] Shape layout is required to determine port bounds`)

    const { isCrossSection } = renderer
    const coordinates = item.getCoordinates(isCrossSection)
    const scale = renderer.getEquipmentScale(item)
    const rotation = item.getRotation(isCrossSection) || null
    const point = layout.getConnectionPoint({
      coordinates,
      scale,
      rotation,
      id: port.id,
      onDrawer: expanded
    })


    return point
  }

  /**
   * Checks whether any port on the shape is still unused
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Boolean}
   */
  hasFreePorts ({ renderer }) {
    const { item } = this
    return renderer &&
      item.hasActivePorts &&
      item.ports.some(port => !renderer.layout.isPortTaken(port))
  }

  /**
   * Indicates whether user can drag connectors from the port.
   * Sometimes connectors are created automatically and user must not be able to fiddle with them.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanPort} port Port to connect from
   * @param {PlanItem} item Item to connect to
   * @returns {Boolean}
   */
  canDragConnectorsFrom (renderer, port, item) {
    return renderer != null &&
      item != null &&
      port != null &&
      port.isActive &&
      !renderer.layout.isPortTaken(port)
  }

  /**
   * Indicates whether user can drag connectors into the port.
   * Sometimes connectors are created automatically and user must not be able to fiddle with them.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanPort} port Port to connect to
   * @param {PlanItem} item Item to connect to
   * @returns {Boolean}
   */
  canDragConnectorsInto (renderer, port, item) {
    // Check whether the port isn't already taken
    let can = port != null &&
      !renderer.layout.isPortTaken(port)

    // When creating new connector, cannot connect to itself
    if (can && renderer.newConnector) {
      can = renderer.newConnector.start.itemId !== item.id
    }
    return can

  }

  /**
   * Returns coordinates of the label.
   * By default the label is rendered in the center of the shape.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} autoFit If true and label is too big for the shape, it will be automatically rescaled
   * @returns {Point}
   */
  getLabelPosition (renderer, autoFit) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, label, labelText, labelMargin } = this
    const bounds = this.getBounds({ renderer })

    if (labelText) {
      // Check whether label is too big for the shape and requires further scaling.
      const width = labelText.width()
      if (autoFit) {
        const ratio = width / Math.max(10, (bounds.width - 8))
        const scale = ratio > 1 ? (1 / (0.1 + ratio)) : 1
        labelText.scale({ x: scale, y: scale })
      }

      // If label can be moved freely, return the user-assigned position if any
      const { isLabelFixed, labelPosition } = item
      if (!isLabelFixed && labelPosition) {
        return labelPosition
      }

      // If cable, scale the label proportionally to other equipment.
      const itemScale = item.isCable ? renderer.getEquipmentScale() : PlanScale.Normal
      const scale = PlanScale.max(itemScale, PlanScale.from({ x: 0.7, y: 0.7 }))
      label.scale(scale)

      // Place the label in the center of the shape
      const position = item.isPointBased
        ? bounds.center
        : bounds.relative().center

      if (position) {
        const textWidth = labelText.width() * labelText.scaleX() * label.scaleX()
        const textHeight = labelText.height() * labelText.scaleY() * label.scaleY() || 15
        position.moveBy({
          x: -textWidth / 2 - labelMargin * label.scaleX(),
          y: -textHeight / 2 - labelMargin * label.scaleX()
        })

        // For circular shapes we need to correct by radius
        if (item.isCircular) {
          position.moveBy({
            x: -item.radiusX,
            y: -item.radiusY
          })
        }
      }

      return position
    }
  }

  /**
   * Returns coordinates of the item tag.
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point}
   */
  getTagPosition ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    const { item, tag } = this
    const bounds = this.getBounds({ renderer })
    const itemScale = renderer.getEquipmentScale()
    if (item.tag && bounds) {
      // Place the label in the left-top-corner of the shape.
      // If items are scaled, compensate to not to cover the shapes
      const [tagBorder] = tag.getChildren()
      const position = Point.from({
        x: -tagBorder.width() + 8,
        y: -tagBorder.height() + 8
      })

      if (item.isCircular) {
        position.moveBy({
          x: -item.radiusX * itemScale * Math.sin(45),
          y: -item.radiusY * itemScale * Math.sin(45)
        })
      }

      return position.scale(1 / itemScale)
    }
  }

  /**
   * Determines the text of the shape label
   * @param {PlanRenderer} renderer Renderer instance
   * @returns {String}
   */
  getLabelText ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    return this.item.label
  }

  /**
   * If true, the shape boundaries are show. Useful for debugging.
   * @type {Boolean}
   */
  get showShapeBoundaries () {
    return false
  }

  /**
   * Returns the rectangle containing the shape
   * @type {Rectangle}
   */
  get clientRectangle () {
    const r = this.content?.getClientRect()
    if (r) {
      return new Rectangle(r)
    } else {
      return null
    }
  }

  /**
   * Checks whether the specified point is inside the shape
   * @param {Point} point Point coordinates
   * @returns {Boolean}
   */
  isPointInside (point) {
    if (point) {
      return isInsideRectangle(point, this.clientRectangle)
    }
  }

  /**
   * Checks whether the shape overlaps with the specified rectangle
   * @param {Rectangle} rectangle Rectangle to check
   * @returns {Boolean}
   */
  overlapsWith (rectangle) {
    if (rectangle) {
      const r = this.clientRectangle
      return rectanglesOverlap(rectangle, r)
    }
  }

  /**
   * Checks whether the shape is inside the specified rectangle
   * @param {Rectangle} rectangle Rectangle to check
   * @returns {Boolean}
   */
  isInside (rectangle) {
    if (rectangle) {
      const r = this.clientRectangle
      return isInsideRectangle(r, rectangle)
    }
  }

  /**
   * Shapes representing line turns on lines, polygons and connectors
   * @type {Array[Konva.Shape]}
   */
  joints

  /**
   * Returns an array of shape points
   * Implemented in descendants which are point-based such as lines, polygons and connectors.
   * @param {PlanRenderer} renderer Plan renderer if points are related
   * to other shapes on the plan, like it is with {@link Connector} shape, a descendant of this class
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getShapePoints ({ renderer }) {
    return []
  }

  /**
   * Returns an array of coordinates of shape points
   * which are joint points which can be moved freely.
   * For some shapes such as lines, this will be all points.
   * For some other shapes such as connectors, start and end will be excluded.
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  getShapeJoints (renderer) {
    return this.getShapePoints({ renderer })
  }

  /**
   * Converts the specified points into a flat list of coordinates [x1, y1, x2, y2, ..., xn, yn]
   * @param {Array[Point]} points Points
   * @returns {Array[Number]}
   */
  toCoordinates (points) {
    if (points) return points.flatMap(p => ([p.x, p.y]))
  }

  /**
   * Triggered when shape is about to be moved
   * @param {PlanRenderer} renderer Plan renderer
   */
  startMoving ({ renderer }) {
    this.collapsePorts({ renderer, instant: true })
  }

  /**
   * Triggered when moving item to new position
   * @param {Point} position Position to which the item was moved
   * @param {Point} delta Movement delta
   */
  // eslint-disable-next-line no-unused-vars
  moveTo (position, delta) {
    const { item, content } = this
    // Update shape position.
    // Do this only for items not based on absolute points!
    // Those have to handle it themselves.
    if (item && content && position && item.canMove && !item.isPointBased) {
      content.position(position.round())
    }
  }

  /**
   * Sets/returns z-index of the shape
   * @returns {Number}
   */
  zIndex (value) {
    if (value) {
      this.content.zIndex(value)
    }
    return this.content.zIndex()
  }

  /**
   * Moves the shape on z axis to the top
   * @returns {Number} Ordinal index of the item on z axis
   */
  moveToTop () {
    const { content } = this
    moveToTop(content)
    return content.zIndex()
  }

  /**
   * Moves the item on z axis to the bottom
   * @param {PlanItem} item
   * @returns {Number} Ordinal index of the item on z axis
   */
  moveToBottom () {
    const { content } = this
    content.moveToBottom()
    return content.zIndex()
  }

  /**
   * Returns point of origin for applying transformations
   * such as rotation, skew or scaling
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point} Point of origin
   */
  getOrigin (renderer) {
    return this.item.getCoordinates(renderer.isCrossSection)
  }

  /**
   * Applies shape transformations to the specified point,
   * such as rotation, skew etc.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} point Point to transform
   * @returns {Point} Transformed point
   */
  transformPoint (renderer, point) {
    const { isCrossSection } = renderer
    const { item, item: { canRotate, canFlip } } = this
    const rotation = item.getRotation(isCrossSection)
    const origin = this.getOrigin(renderer)
    if ((canRotate || canFlip) && rotation !== 0) {
      point = point.rotate(rotation, origin)
    }
    return point
  }

  /**
   * Returns shape line style, appropriate for the specified state.
   * @param {PlanItem} item Item for which to determine the line style
   * @param {PlanLineStyle} style Optional style to tap into. If not specified, we're using `lineStyle` of the item
   * @param {PlanItemState} state Optional shape state
   * @param {PlanRenderer} renderer Plan renderer
   */
  getLineStyle (item, style, state, renderer) {
    if (item && renderer) {
      style = style || item.lineStyle
      if (state === PlanItemState.Hover) {
        return new PlanLineStyle({
          ...style,
          width: Math.min(8, Math.max(4, Math.ceil(style.width * 1.5)))
        })
      } else {
        return new PlanLineStyle(style)
      }
    }
  }

  /**
   * Indicates whether shape needs to be (partially) recreated.
   * For example, changing the antenna type requires re-creation of all shapes.
   * @type {Boolean}
   */
  get requiresRefresh () {
    return !this.content
  }

  /**
   * Checks whether the specified shape has the specified property,
   * and optionally whether its value meets the specified condition
   * @param {Konva.Shape} shape Shape to check
   * @param {String} property Property to check
   * @param {Function|any} condition Condition or value to check
   * @returns {Boolean} `true` if shape has the specified property,
   * and that it meets the specified condition or has the specified value
   */
  shapeHasProperty (shape, property, condition) {
    if (shape && typeof shape[property] === 'function') {
      const value = shape[property]()
      if (condition) {
        if (typeof condition === 'function') {
          return Boolean(condition(value))
        } else {
          return value === condition
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  /**
   * Checks whether the specified shape has any dimensions
   * @param {Konva.Shape} shape Shape to check
   * @returns {Boolean} `true` if shape is visible and has non-zero `width` and `height`,
   * non-zero `radius` or non-empty list of `points`
   */
  shapeHasDimensions (shape) {
    return shape &&
      shape.visible() &&
      ((this.shapeHasProperty(shape, 'width', v => v > 0) && this.shapeHasProperty(shape, 'height', v => v > 0)) ||
        (this.shapeHasProperty(shape, 'radius', v => v > 0)) ||
        this.shapeHasProperty(shape, 'points', v => v.length > 1)
      )
  }

  /**
   * Returns the actual scale of the specified item,
   * being a superposition of global shape scale if shape
   * belongs to the items layer and the item's individual scale
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item
   * @param {Boolean} includeZoom If true, also zoom scale is included in the result.
   * Useful when positioning HTM elements in relation to stage shapes.
   * @returns {PlanScale}
   */
  getItemScale (renderer, item, includeZoom) {
    const { zoom } = renderer
    const onItemLayer = item.layer === PlanLayers.Items
    const itemScale = renderer.getEquipmentScale()
    const scale = {
      x: (includeZoom ? zoom : 1) * (onItemLayer ? itemScale.value : 1) * item.scale.x,
      y: (includeZoom ? zoom : 1) * (onItemLayer ? itemScale.value : 1) * item.scale.y
    }
    return scale
  }

  /**
   * Shows the shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  show (renderer) {
    if (!this.item.isVisible) {
      this.item.isVisible = true
      if (renderer) {
        this.render(renderer)
      } else {
        this.content.show()
      }
    }
  }

  /**
   * Hides the shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  hide (renderer) {
    if (this.item.isVisible) {
      this.item.isVisible = false
      if (renderer) {
        this.render(renderer)
      } else {
        this.content.hide()
      }
    }
  }

  /**
   * Renders the {@link shapes} using the parameters
   * specified in {@link item} and additional data returned by the {@link dataCallback}
   * Override in descendants to set other, item-specific properties!
   * @param {PlanRenderer} renderer Plan renderer
   */
  render (renderer) {
    if (!renderer) throw new Error('Renderer is required')
    if (!this.content) throw new Error('Shape parts are not yet created')

    if (this.requiresRefresh) {
      this.refreshShapes(renderer)
    }

    const { content, item, ports, __destroyed, shapeBoundaries, label, tag, dataCallback } = this
    const { isCrossSection } = renderer

    // Fetch additional data and assign to the item
    const data = dataCallback ? dataCallback(item) : undefined
    item.setData(data)

    if (!content) return
    if (__destroyed) return

    // Store item ID in shape, useful for lookups
    content.itemId = item.id

    // Update shape visibility
    if (content.visible() !== item.isVisible)
      content.visible(item.isVisible)

    // Make the shape draggable
    if (content.draggable() !== item.canMove) {
      content.draggable(item.canMove)
    }

    // Set position, but only if item has a single position,
    // rather than when it's made of a sequence of points
    if (content.x != null && content.y != null && !item.isPointBased) {
      // Cross-section view uses its own set of coordinates
      const { x, y } = isCrossSection ? item.crossSection.coordinates : item.coordinates
      content.x(x)
      content.y(y)
    }

    // Set opacity
    const opacity = item.backgroundStyle?.opacity || item.lineStyle?.opacity
    if (opacity <= 1) {
      content.opacity(opacity)
    }

    // Render line joints
    if (item.hasEditablePoints) {
      const points = this.getShapeJoints(renderer)
      this.renderJoints(renderer, points)
    }

    // Render ports
    if (ports) {
      this.renderPortDrawer({ renderer })
      this.renderPorts({ renderer })
    }

    // Hide/show item label
    if (label) {
      this.renderLabel(renderer)
      moveToTop(label)
    }

    // Hide/show item tag
    if (tag) {
      this.renderTag(renderer)
      moveToTop(tag)
    }

    // Set scale.
    // Take into consideration the global plan scale and the individual scale of the item.
    // Some elements cannot be scaled, for example connectors and cables,
    // as they operate on absolute coordinates
    if (renderer.isLayerScaled(item.layer) && item.canScale) {
      const scale = renderer.getEquipmentScale(item)
      content.scale(scale)
    }

    // Set rotation
    const rotation = item.getRotation(isCrossSection)
    content.rotation(rotation || 0)

    // Update the boundaries box
    if (this.showShapeBoundaries) {
      const bounds = this.getBounds({ renderer })
      shapeBoundaries.position(bounds)
      shapeBoundaries.size(bounds)
    }

    // Bind events, once
    this.bindEvents(renderer)
  }

  /**
   * Renders the shape label
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderLabel (renderer) {
    const { item, label, labelText, labelBorder, labelMargin, deferLabel } = this
    const { isCrossSection } = renderer

    const text = this.getLabelText({ renderer })
    if (!(item.hasLabel && text)) {
      label.hide()
      return
    }

    // Show border only if margin is defined
    labelBorder.visible(labelMargin > 0)

    const { textStyle, isLabelHorizontal } = item
    const rotation = item.getRotation(isCrossSection)
    labelText.text(text)
    if (textStyle) {
      labelText.fontSize(Math.min(24, textStyle.size))
      labelText.fill(textStyle.color)
    }

    // Position the label with certain delay, as some shapes
    // require the content to be present, to correctly render the label inside of it.
    const placeLabel = () => {
      // Position the label, either automatically or wherever the user dragged it
      const position = this.getLabelPosition(renderer)
      if (position) {
        label.position(position)

        // Adjust the border to the size of the label
        if (labelMargin > 0) {
          labelBorder.width(labelText.width() + labelMargin * 2)
          labelBorder.height(labelText.height() + labelMargin * 2)
        }

        // If label must remain horizontal, rotate it against the current rotation of the shape
        if (isLabelHorizontal) {
          label.rotation(-rotation)
        }

        label.show()
      } else {
        label.hide()
      }
    }

    if (deferLabel) {
      window.setTimeout(() => placeLabel(), 10)
    } else {
      placeLabel()
    }
  }

  /**
   * Renders the shape tag
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderTag (renderer) {
    const { item, tag } = this
    const { layout, isCrossSection } = renderer

    if (tag) {
      if (item.canHaveTag && item.tag && layout.showTags) {
        const [tagBorder, tagText] = tag.getChildren()

        tagText.text(`${item.tag}${item.tagIndex || ''}`)
        tagBorder.width(Math.max(20, tagText.width() + 12))
        tagBorder.height(tagText.height() + 8)
        tagBorder.fill(layout.getTagColor(item.tag))

        // Determine tag position
        const position = this.getTagPosition({ renderer })

        // Counter-rotate the tag
        const rotation = item.getRotation(isCrossSection)
        if (rotation) {
          tag.rotation(-rotation)
          position.rotate(-rotation)
        } else {
          tag.rotation(0)
        }

        // If equipment is scaled below 0.8, rescale the tag
        // to make sure it's still reasonably big
        const itemScale = renderer.getEquipmentScale()
        if (itemScale.value < 1) {
          const ratio = 0.9 / itemScale.value
          tag.scale(PlanScale.Normal.add(ratio))
        }

        tag.position(position)
        tag.visible(true)

      } else {
        tag.visible(false)
      }
    }
  }

  /**
   * Renders the ports on the shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderPorts ({ renderer, active }) {
    const { item, ports } = this
    for (const portShape of ports) {
      const port = item.getPort(portShape.id())
      this.renderPort({ renderer, port, active })
    }
  }

  /**
   * Renders the expanded port drawer
   * @param {PlanRenderer} renderer Plan renderer
   */
  renderPortDrawer ({ renderer }) {
    const { portDrawer, item } = this

    if (portDrawer) {
      const border = portDrawer.findOne('#port-drawer-border')
      // REMOVE THE BORDER AFTER DEBUGGING
      // border.strokeWidth(2)
      const scale = renderer.getEquipmentScale(item)
      const bounds = this.getPortDrawerBounds({ renderer, scale })
      border.size(bounds)
      portDrawer.position(bounds)
    }
  }

  /**
   * Renders the specified port
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanPort} port Port to render
   * @param {Boolean} active If true, the port must be rendered in `active` state
   * @returns {Boolean} True if port has been rendered
   */
  renderPort ({ renderer, port, active }) {
    const { item, portsExpanded: expanded } = this
    const { shape } = this.getPortShape({ port, expanded })

    if (port && shape) {
      // Position the port.
      // Hide if port is not visible in the current mode
      const bounds = this.getPortBounds({ renderer, port, expanded })
      shape.visible(bounds != null)
      if (!bounds) return

      if (bounds.radius) {
        shape.radius(bounds.radius)
      } else {
        shape.size(bounds)
      }

      shape.position(bounds)

      // Check whether the port can be interacted with, indicate with different background.
      // Port could be disabled or already occupied by another connector.
      const isTaken = renderer.layout.isPortTaken(port)
      let canInteractWith = renderer.newConnector
        ? port && this.canDragConnectorsInto(renderer, port, item)
        : port && this.canDragConnectorsFrom(renderer, port, item)


      // Get port styles appropriate for the current state
      const { lineStyle, backgroundStyle, takenStyle } = this.getPortStyle({
        active: active && expanded && canInteractWith
      })

      if (active && canInteractWith) {
        this.selectedPort = port.id
      } else if (!active) {
        this.selectedPort = null
      }

      // Set port style
      // If expanded port and can't interact with, grey it out
      shape.stroke(lineStyle.color)
      if (expanded && isTaken) {
        shape.fill(takenStyle.color)
      } else {
        shape.fill(backgroundStyle.color)
      }

      if (!shape.visible()) {
        shape.visible(true)
      }

      return canInteractWith
    }
  }

  /**
   * Moves the label from its current position to the new custom one
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} delta Movement delta
   * @returns {Point} New label position
   */
  moveLabelBy (renderer, delta) {
    if (delta) {
      const { item } = this
      const { isCrossSection } = renderer
      // Get default position if no custom position set yet
      if (!item.labelPosition) {
        item.labelPosition = this.getLabelPosition(renderer)
      }
      // Counter-rotate the movement if item is rotated!
      const rotation = item.getRotation(isCrossSection)
      if (rotation) {
        delta.rotate(-rotation)
      }
      // Assign new custom position and redraw the label
      item.labelPosition.moveBy(delta)
      this.renderLabel(renderer)
    }
  }

  /**
   * Indicates whether the shape is cached
   * @type {Boolean}
   */
  get hasFilters () {
    return this
      .content
      .getChildren()
      .some(c => (c.filters() || []).length > 0)
  }

  /**
   * Adds the specified filter to the shape
   * @param {Konva.Filter} filter Filter to add
   * @param {Object} properties Properties to set on the shape, applicable to the filter
   * @returns {Boolean} `true` if filter was applied to the shape
   */
  addFilter (filter, properties = {}) {
    let result = false
    if (filter) {
      this.removeFilter(filter)
      const { item, content } = this
      const shapes = content instanceof Konva.Group ? content.getChildren() : [content]
      for (const shape of shapes) {
        if (this.shapeHasDimensions(shape)) {
          const filters = shape.filters() || []
          if (!filters.includes(filter)) {
            for (const [key, value] of Object.entries(properties)) {
              shape[key](value)
            }
            shape.cache({ offset: item.blurRadius * 2 })
            shape.filters([...filters, filter])
            result = true
          }
        }
      }
    }
    return result
  }

  /**
   * Removes the specified filter from the shape
   * @param {Konva.Filter} filter Filter to remove
   */
  removeFilter (filter) {
    if (filter) {
      const { content } = this
      const shapes = content instanceof Konva.Group ? content.getChildren() : [content]
      for (const shape of shapes) {
        const filters = (shape.filters() || []).filter(f => f !== filter)
        shape.filters(filters.length > 0 ? filters : null)
      }
    }
  }

  /**
   * Removes all filters from the shape
   */
  removeFilters () {
    this.content.filters(null)
  }

  /**
   * Applies filters to the shape
   */
  applyFilters () {
    const { item: { blurRadius }, __blurRadius } = this
    // Set blur
    if (blurRadius > 0 && blurRadius !== __blurRadius) {
      this.addFilter(Konva.Filters.Blur, { blurRadius, globalCompositeOperation: 'multiply' })
      this.__blurRadius = blurRadius
    }

    if (!(blurRadius > 0) && (__blurRadius > 0)) {
      this.removeFilter(Konva.Filters.Blur)
      this.__blurRadius = 0
    }
  }

  /**
   * Returns true element scale,
   * after adjusting for the global item scale
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Plan item
   * @param {Shape} shape Item shape whose scale to determine
   * @returns {PlanScale}
   */
  getShapeScale (renderer, item, shape) {
    if (renderer && renderer.isLayerScaled(item.layer)) {
      const scale = new PlanScale(shape.scale() || { x: 1, y: 1 })
      if (item.canScale) {
        const itemScale = renderer.getEquipmentScale()
        scale.subtract(itemScale)
      }
      return scale
    }

    return PlanScale.Normal
  }

  /**
   * Returns true element rotation,
   * after adjusting for the global plan rotation
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Plan item
   * @param {Shape} shape Item shape whose rotation to determine
   * @returns {Number}
   */
  getShapeRotation (renderer, item, shape) {
    if (renderer) {
      const { canRotate, canFlip } = item
      return (canRotate || canFlip) ? (shape.rotation() || 0) : 0
    } else {
      return 0
    }
  }

  /**
   * Triggered when shape has been transformed.
   * Implement in descendants to collect any new
   * properties from the shape and store it in the item.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  // eslint-disable-next-line no-unused-vars
  transformed ({ renderer, scale, rotation }) {
    // Store rotation
    this.item.rotate(Math.round(rotation), renderer.isCrossSection)

    // Remove cached content, so the item is rendered instantly during the transformation
    if (this.hasFilters) {
      for (const shape of this.content.getChildren()) {
        shape.clearCache()
      }
      this.__blurRadius = null
    }
  }

  /**
   * Renders joint shapes
   * @param {PlanRenderer} renderer Renderer
   * @param {Array[Point]} points Points at which the joints are found
   * @param {Boolean} force If true, joints are forcefully recreated, not just updated
   */
  renderJoints (renderer, points, force) {
    let { joints, item, item: { jointStyle } } = this
    if (!jointStyle) return

    // Recreate joints if none yet, or number of joints has changed
    const mustRecreate = force || !joints || joints.length !== points.length
    if (mustRecreate) {
      for (const joint of joints || []) {
        joint.destroy()
      }
      joints = points
        .map((point, index) => this.createJoint(renderer, point, index))
      this.content.add(...joints)
      this.joints = joints
    }
    if (!(joints?.length > 0)) return

    // If shape line is currently selected or pointed at,
    // draw the joints for all joints, otherwise hide the joints
    const isJointVisible = renderer.isItemSelected(item) || renderer.isItemPointedAt(item)
    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i]
      if (isJointVisible) {
        const { x, y } = points[i]
        joint.x(x - jointStyle.radius)
        joint.y(y - jointStyle.radius)
        joint.show()
      } else {
        joint.hide()
      }
    }
  }

  /**
   * Recreates all joints
   * @param {PlanRenderer} renderer
   */
  refreshJoints (renderer) {
    const points = this.getShapeJoints(renderer)
    this.renderJoints(renderer, points, true)
  }

  /**
   * Creates a joint on the shape
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} at Point at which to create the joint
   * @param {Number} index Joint index
   * @returns {Konva.Shape}
   */
  createJoint (renderer, at, index) {
    if (at && index != null) {
      const { item } = this
      // If connector, the point index must be shifted as
      // connector start isn't managed by us
      index = item.isConnector ? index + 1 : index
      const { radius, color, borderColor, borderWidth } = item.jointStyle
      const x = at.x - radius
      const y = at.y - radius
      const joint = new Konva.Rect({
        x,
        y,
        point: at,
        isJoint: true,
        index,
        width: radius * 2,
        height: radius * 2,
        fill: color,
        stroke: borderColor,
        strokeWidth: borderWidth,
        draggable: false
      })

      // Make joint clickable, so it can be dragged
      if (this.canInteractWith) {
        joint.on('mousedown', (e) => {
          if (!renderer.isEditable) return
          if (this.isLocked) return

          renderer.isUserActive = true
          if (PlanEvent.isLeftButton(e) && !PlanEvent.isEventHandled(e)) {
            this.notifyEvent('select-point', { item, index })
            PlanEvent.cancelEvent(e)
          }
        })

        // If unclicked, notify
        joint.on('mouseup', (e) => {
          if (!renderer.isEditable) return
          if (this.isLocked) return

          renderer.isUserActive = false
          if (!PlanEvent.isEventHandled(e)) {
            this.notifyEvent('deselect-point', { item, joint, index })
            PlanEvent.cancelEvent(e)
          }
        })

        // Remove the joint on doubleclick
        joint.on('dblclick', (e) => {
          if (!renderer.isEditable) return
          if (this.isLocked) return

          this.notifyEvent('remove-point', { item, joint, index })
          PlanEvent.cancelEvent(e)
        })

        joint.on('mouseenter', (e) => {
          if (!renderer.isEditable) return
          if (this.isLocked) return
          if (PlanEvent.isRightButton(e)) return

          joint.fill(item.jointStyle.hoverColor)
        })

        joint.on('mouseleave', (e) => {
          if (!renderer.isEditable) return
          if (this.isLocked) return
          if (!PlanEvent.isLeftButton(e)) return

          joint.fill(item.jointStyle.color)
        })
      }

      return joint
    }
  }

  /**
   * Wires up event handlers to {@link shapes}
   * @param {PlanRenderer} renderer Plan renderer
   */
  bindEvents (renderer) {
    if (this.__eventsBound) return true
    const { content, item, shapeIsPort } = this
    if (!(content && item)) return

    this.__eventsBound = true

    // If item is not locked, show pointer cursor on hover
    content.on('mouseenter', (e) => {
      if (!this.canInteractWith) return
      if (renderer.isAddingItem) return
      if (PlanEvent.isRightButton(e)) return

      if (PlanEvent.noControlKeys(e)) {
        this.isPointedAt = !renderer.isUserActive
        this.notifyEvent('enter', { item })
        this.expandPorts({ renderer })
        PlanEvent.cancelEvent(e)
      }
    })

    content.on('mouseleave', (e) => {
      if (!this.canInteractWith) return
      if (renderer.isAddingItem) return

      if (PlanEvent.noControlKeys(e)) {
        this.isPointedAt = false
        this.notifyEvent('exit', { item })
        this.collapsePorts({ renderer })
        PlanEvent.cancelEvent(e)
      }
    })

    // If clicked, select the item
    content.on('mousedown', (e) => {
      if (!this.canInteractWith) return

      renderer.isUserActive = true
      if (renderer.isAddingItem) return
      if (PlanEvent.isLeftButton(e)) {
        this.collapsePorts({ renderer, instant: true })
        const multiSelect = PlanEvent.isCtrlKey(e)
        this.notifyEvent('select', { item, multiSelect })
        PlanEvent.cancelEvent(e)
      }
    })

    // If entire shape is a port, mouseup should end any connector dragged onto it
    // Notify when mouse released on a port, maybe a new connector leading to it needs to be created
    content.on('mouseup', (e) => {
      if (!this.canInteractWith) return

      renderer.isUserActive = false
      if (!PlanEvent.isEventHandled(e)) {
        if (shapeIsPort && renderer.newConnector) {
          const port = this.getPort(PlanPortType.Main)
          if (port) {
            this.notifyEvent('connect-to-port', { item, port })
            PlanEvent.cancelEvent(e)
          }
        }
      }
    })

    // If dbl-clicked, select the item
    // This provides a way out of a multi-selection,
    // when selecting items inside is prevented, to allow moving the entire group
    content.on('dblclick', (e) => {
      if (!this.canInteractWith) return
      if (renderer.isAddingItem) return

      if (PlanEvent.isLeftButton(e)) {
        this.notifyEvent('deselect')
        this.notifyEvent('select', { item })
      }
    })

    // Send transform notifications - scaling, rotation etc.
    content.on('transformstart', (e) => {
      if (!this.canInteractWith) return

      this.notifyEvent('transform-start', { item })
      PlanEvent.cancelEvent(e)
    })

    content.on('transform', async (e) => {
      if (!this.canInteractWith) return

      // Take the element scale and rotation,
      // adjust for the global layout scale and rotation!
      const scale = this.getShapeScale(renderer, item, content)
      const rotation = this.getShapeRotation(renderer, item, content)
      await this.transformed({ renderer, scale, rotation })
      // Revert to scale from before the transformation
      content.scale(this.getItemScale(renderer, item))
      // Notify
      this.notifyEvent('transform', { item, scale, rotation })
      PlanEvent.cancelEvent(e)
    })

    content.on('transformend', (e) => {
      if (!this.canInteractWith) return

      this.notifyEvent('transform-end', { item })
      PlanEvent.cancelEvent(e)
    })

    this.bindShapeEvents(renderer)
  }

  /**
   * Wires up event handlers to internal shapes in the content group
   * @param {PlanRenderer} renderer Plan renderer
   */
  bindShapeEvents (renderer) {
    const { content, item, ports = [], label, labelBorder, portDrawer } = this
    if (!(renderer && content && item)) return

    // Shape label events
    if (label) {
      // Make sure that entering label also activates ports
      label.on('mouseenter', () => {
        this.expandPorts({ renderer })
      })

      label.on('mouseleave', () => {
        this.collapsePorts({ renderer })
      })

      // Make label draggable, to allow custom positioning
      if (!item.isLabelFixed) {
        let labelBackground = labelBorder.fill()
        label.draggable(true)

        label.on('mousedown', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return

          PlanEvent.cancelEvent(e)
          this.notifyEvent('select', { item, allowNewPoint: false })
        })

        label.on('mouseenter', () => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return

          // Highlight the label if it can be moved
          if (!item.isLabelFixed) {
            labelBorder.fill('#c9deff')
          }
        })

        label.on('mouseleave', () => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return

          // Remove label highlight
          if (!item.isLabelFixed) {
            labelBorder.fill(labelBackground)
          }
        })

        label.on('dragstart', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          PlanEvent.cancelEvent(e)
        })

        label.on('dragmove', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return

          PlanEvent.cancelEvent(e)
          this.moveLabelBy(renderer, Point.from({
            x: e.evt.movementX,
            y: e.evt.movementY
          }))
        })

        label.on('dragend', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          this.renderLabel(renderer)
          renderer.changed()
        })
      }
    }

    // Shape port events
    if (item.hasPorts) {
      // Ports on the shape
      for (const portShape of ports) {
        // Add hover effect to port
        portShape.on('mouseenter', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          this.expandPorts({ renderer })
          const port = this.getPort(portShape.id())
          if (this.renderPort({ renderer, port, active: true })) {
            this.notifyEvent('enter', { item })
            PlanEvent.cancelEvent(e)
          }
        })

        portShape.on('mouseleave', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          this.collapsePorts({ renderer })
          const port = this.getPort(portShape.id())
          if (this.renderPort({ renderer, port, active: false })) {
            PlanEvent.cancelEvent(e)
          }
        })
      }

      // Ports in the expanded drawer
      // Make sure that entering label also activates ports
      label.on('mouseenter', () => {
        this.expandPorts({ renderer })
      })

      label.on('mouseleave', () => {
        this.collapsePorts({ renderer })
      })

      portDrawer.on('mouseenter', () => {
        this.expandPorts({ renderer })
      })

      portDrawer.on('mouseleave', () => {
        this.collapsePorts({ renderer })
      })

      const drawerPorts = portDrawer.find('.port')
      for (const portShape of drawerPorts) {
        // Slide out the ports drawer when port is entered
        portShape.on('mouseenter', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          this.expandPorts({ renderer })
          const port = this.getPort(portShape.id())
          if (this.renderPort({ renderer, port, active: true })) {
            this.notifyEvent('enter', { item })
            PlanEvent.cancelEvent(e)
          }
        })

        // Hide the ports drawer when port is left
        portShape.on('mouseleave', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          this.collapsePorts({ renderer })
          const port = this.getPort(portShape.id())
          if (this.renderPort({ renderer, port, active: false, expanded: true })) {
            PlanEvent.cancelEvent(e)
          }
        })

        // Make port clickable, so we can drag connectors from it
        portShape.on('mousedown', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          renderer.isUserActive = true
          if (PlanEvent.isLeftButton(e) && !PlanEvent.isEventHandled(e)) {
            const port = this.getPort(portShape.id())
            if (port && this.canDragConnectorsFrom(renderer, port, item)) {
              this.notifyEvent('select-port', { item, port })
              PlanEvent.cancelEvent(e)
            }
          }
        })

        // Notify when mouse released on a port, maybe a new connector leading to it needs to be created
        portShape.on('mouseup', (e) => {
          if (!this.canInteractWith) return
          if (!renderer.isEditable) return
          if (PlanEvent.isRightButton(e)) return

          renderer.isUserActive = false
          if (!PlanEvent.isEventHandled(e)) {
            // Finish the started connector
            if (renderer.newConnector) {
              const port = this.getPort(portShape.id())
              if (port && this.canDragConnectorsInto(renderer, port, item)) {
                this.notifyEvent('connect-to-port', { item, port })
                PlanEvent.cancelEvent(e)
              }
              this.renderPorts({ renderer })
            }
          }
        })

      }
    }
  }
}

