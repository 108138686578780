<script>
import { mapActions, mapGetters } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { getFullDurationString } from '@stellacontrol/utilities'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  data () {
    return {
      // Default values for preferences
      preferences: {
        'ui-start-page': 'home',
        'privacy-email-technical-opt-out': false,
        'privacy-email-commercial-opt-out': false,
        'premium-services-notify-expiration': false,
        'notifications-receive': false,
        'notifications-receive-customers': false,
        'notifications-recipients': '',
        'notifications-frequency': 24 * 3600
      },

      // Start pages to choose from
      startPages: [
        { route: 'home', label: 'Home Page' },
        { route: 'buildings', label: 'Buildings', permissions: ['buildings'] },
        { route: 'inventory', label: 'Inventory', permissions: ['inventory'] },
        { route: 'administration', label: 'Administration' },
        { route: 'help', label: 'Help and Videos' },
      ]
    }
  },

  computed: {
    ...mapGetters([
      'isDevelopmentEnvironment'
    ]),

    // Start pages allowed to the current user
    allowedStartPages () {
      return this.startPages.filter(page => this.canUseAll(page.permissions))
    },

    // Indicates whether current user can receive emails such as update announcements
    canReceiveEmails () {
      return this.isAdministrator
    },

    // Returns true if current organization can configure anything related to premium service notifications
    canConfigurePremiumServiceNotifications () {
      const { canUse, mustUse, canReceiveEmails } = this
      return canReceiveEmails &&
        canUse('premium-services-notify') &&
        (mustUse('premium-services-buy') || canUse('premium-services-sell'))
    },

    // Returns true if current organization can configure anything related to alerts notifications
    canConfigureAlertNotifications () {
      const { canUse, canReceiveEmails } = this
      return canReceiveEmails &&
        canUse('alerts-subscribe')
    },

    // Available frequencies for alert notifications.
    // Super-administrators have more choices.
    // Development environment allows once per minute as well.
    alertNotificationFrequencies () {
      const { isSuperAdministrator, isDevelopmentEnvironment } = this
      const frequencies = (isSuperAdministrator || isDevelopmentEnvironment)
        ? [
          600,            // 10m
          1800,           // 30m
          6 * 3600,       // 6h
          12 * 3600,      // 12h
          24 * 3600,      // 1d
        ]
        : [
          1800,           // 30m
          6 * 3600,       // 6h
          12 * 3600,      // 12h
          24 * 3600,      // 1d
        ]
      return frequencies
        .filter(frequency => frequency > 0)
        .map(frequency => ({
          value: frequency,
          label: getFullDurationString(frequency).replace('about', '')
        }))
    },

    // Examples of alert notification frequencies
    alertNotificationFrequencyExamples () {
      const value = parseInt(this.preferences['notifications-frequency'])
      if (value > 0) {
        if (value === 600) return '00:00, 00:10, 00:20, 00:30 etc.'
        if (value === 1800) return '00:00, 00:30, 01:00, 01:30 etc.'
        if (value === 3600) return '00:00, 01:00, 02:00 etc.'
        if (value === 6 * 3600) return '00:00, 06:00, 12:00 an 18:00'
        if (value === 12 * 3600) return 'midnight and noon'
        if (value === 24 * 3600) return 'midnight'
      }
    },

    // Indicates whether user wants to receive any alert notifications at all
    wantsToReceiveAlertNotifications () {
      const { currentOrganization, preferences } = this
      return preferences['notifications-receive'] ||
        (currentOrganization.canHaveChildOrganizations && preferences['notifications-receive-customers'])
    }
  },

  methods: {
    ...mapActions([
      'getUserPreferences',
      'storeUserPreference'
    ]),

    async populate () {
      // Fetch current preferences, assign defaults for missing preferences
      const currentPreferences = await this.getUserPreferences()
      const defaultPreferences = {
        ...this.preferences
      }

      for (const key of Object.keys(this.preferences)) {
        const currentValue = currentPreferences[key]
        this.preferences[key] = currentValue == null
          ? defaultPreferences[key]
          : currentValue
      }

      // Revert to default home page if currently selected is no longer allowed
      const startPage = this.preferences['ui-start-page']
      if (!(startPage && this.allowedStartPages.find(p => p.route === startPage))) {
        this.preferences['ui-start-page'] = 'home'
      }

      // Alert notifications should be sent once a day by default
      if (!this.preferences['notifications-frequency']) {
        this.preferences['notifications-frequency'] = 24 * 3600
      }
      // Revert to 30 minutes if one hour was chosen,
      // this is no longer supported
      if (this.preferences['notifications-frequency'] === 3600) {
        this.preferences['notifications-frequency'] = 1800
      }
    },

    // Saves the specified user preference
    async save (name, value) {
      // Normalize email separator to ;
      if (name === 'notifications-recipients' && value.includes(',')) {
        value = value.replace(',', ';')
      }
      this.preferences[name] = value
      await this.storeUserPreference({ name, value })
    }
  },

  async created () {
    await this.populate()
  }
}

</script>

<template>
  <main class="preferences">
    <section>
      <q-banner class="bg-indigo-1">
        <q-icon name="web" color="indigo-9" size="28px" class="q-mr-sm" />
        <span class="text-subtitle1">
          User Interface
        </span>
      </q-banner>

      <div class="q-pa-md row">
        <div class="col-6">
          <!-- START PAGE AFTER LOGIN -->
          <q-select square outlined :model-value="preferences['ui-start-page']"
            :options="allowedStartPages" emit-value lazy-rules map-options option-value="route"
            option-label="label" label="Page to open when user logs in"
            @update:model-value="route => save('ui-start-page', route)">
          </q-select>
        </div>
      </div>
    </section>

    <section v-if="canReceiveEmails">
      <q-banner class="bg-indigo-1">
        <q-icon name="email" color="indigo-9" size="28px" class="q-mr-sm" />
        <span class="text-subtitle1">
          System Notifications
        </span>
      </q-banner>
      <div class="q-pa-md">
        <!-- EMAIL PREFERENCES -->
        <div>
          <q-checkbox size="sm" color="indigo-5" label="Software updates and platform maintenance"
            :model-value="!preferences['privacy-email-technical-opt-out']"
            @update:model-value="value => save('privacy-email-technical-opt-out', !value)" />
        </div>

        <div>
          <q-checkbox size="sm" color="indigo-5" label="Products and services information"
            :model-value="!preferences['privacy-email-commercial-opt-out']"
            @update:model-value="value => save('privacy-email-commercial-opt-out', !value)" />
        </div>

        <div v-if="canConfigurePremiumServiceNotifications">
          <q-checkbox size="sm" color="indigo-5"
            :model-value="preferences['premium-services-notify-expiration']"
            @update:model-value="value => save('premium-services-notify-expiration', value)"
            label="Expiration of premium services and warranties">
          </q-checkbox>
        </div>
      </div>
    </section>

    <section v-if="canReceiveEmails && canConfigureAlertNotifications">
      <q-banner class="bg-indigo-1">
        <q-icon name="notifications" color="indigo-9" size="28px" class="q-mr-sm" />
        <span class="text-subtitle1">
          Alert Notifications
        </span>
      </q-banner>
      <div class="q-pa-md">
        <div>
          <q-checkbox size="sm" color="indigo-5" :model-value="preferences['notifications-receive']"
            @update:model-value="value => save('notifications-receive', value)"
            :label="currentOrganization.canHaveChildOrganizations ? 'Alerts from my own devices' : 'Send me alerts by e-mail'">
          </q-checkbox>
        </div>

        <div v-if="currentOrganization.canHaveChildOrganizations">
          <q-checkbox size="sm" color="indigo-5"
            :model-value="preferences['notifications-receive-customers']"
            @update:model-value="value => save('notifications-receive-customers', value)"
            label="Alerts from devices of my customers">
          </q-checkbox>
        </div>

        <div class="alert-notify-emails q-mt-sm" v-if="wantsToReceiveAlertNotifications">
          <div>
            Alerts will be sent to your address <b>{{ currentUser.email }} </b>
          </div>
          <q-input square outlined :model-value="preferences['notifications-recipients']"
            class="q-mt-sm" debounce="1000"
            label="Enter additional recipients (multiple addresses must be separated with semicolon)"
            @update:model-value="emails => save('notifications-recipients', emails)">
          </q-input>
        </div>

        <div class="alert-notify-frequency q-mt-md" v-if="wantsToReceiveAlertNotifications">
          <div class="q-mb-xs">
            Frequency of sending alert e-mails:
          </div>
          <q-field square outlined>
            <q-option-group dense inline color="indigo-6" class="q-mt-md"
              :options="alertNotificationFrequencies"
              :model-value="preferences['notifications-frequency']"
              @update:model-value="frequency => save('notifications-frequency', frequency)">
            </q-option-group>
          </q-field>
          <div class="q-mt-sm" v-if="alertNotificationFrequencyExamples">
            Alert notifications will be sent at {{ alertNotificationFrequencyExamples }}
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style lang='scss'>
.preferences {
  max-width: 1200px;

  >section {
    margin-bottom: 24px;
  }
}

.alert-notify-emails {
  padding-left: 10px;
}

.alert-notify-frequency {
  padding-left: 10px;
}
</style>
