import { state, getters } from './dashboard-view.device.state'
import { mutations } from './dashboard-view.device.mutations'
import { actions } from './dashboard-view.device.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
