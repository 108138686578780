<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { Log } from '@stellacontrol/utilities'
import { PlanFloors, PlannerMode, PlanState } from '@stellacontrol/planner'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { resolvePlan, resolvePlanFloor } from './planner.resolve'

const name = 'plan'

// Plan editor
export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
  },

  data () {
    return {
      name,
      // Indicates that changes to the plan should be saved automatically
      autoSave: true,
      // Indicates whether to show the SAVE button
      showSaveButton: false,
      // Plan state
      state: null,
      stateLabel: null,
      // Indicates whether we're setting up live status watcher
      startingStatusWatch: false,
      // Devices on the plan
      planDevices: [],
      // Indicates whether we're currently in debug mode
      isDebugging: false,
      // Mouse pointer position over the plan, used in debug mode
      pointerPosition: null
    }
  },

  computed: {
    ...mapState({
      // Edited plan
      plan: state => state.planner.plan,
      // Edited plan floor
      floorId: state => state.planner.floorId,
      // Planner editing mode
      mode: state => state.planner.mode,
      // Normal/maximized status of the view
      isMaximized: state => state.planner.isMaximized,
      // All devices available to the current organization
      devices: state => state.devices.devices || [],
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    ...mapGetters([
      'devices',
      'organizations',
      'allPlaces'
    ]),

    // View title
    title () {
      const { plan } = this
      return plan.name
    },

    // Organization to which the plan belongs
    organization () {
      const { plan, organizations } = this
      return plan ? organizations.find(o => o.id === plan.organizationId) : null
    },

    // Place to which the plan belongs
    place () {
      const { plan, allPlaces } = this
      return plan ? allPlaces.find(p => p.id === plan.placeId) : null
    },

    // Indicates that the plan is initialized
    isInitialized () {
      return this.plan?.layout != null
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization, place, plan, getViewTitle, fromRoute = {} } = this
      const cameFromBuildings = this.canUse('buildings')
        ? fromRoute.name === 'buildings' || fromRoute.name === 'building-dashboard' || fromRoute.name === 'plan' || !fromRoute.name
        : false

      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        }
      ]

      if (!place) return breadcrumbs

      if (cameFromBuildings) {
        breadcrumbs.push({
          name: 'buildings',
          title: getViewTitle('buildings')
        })
        breadcrumbs.push({
          name: 'building-dashboard',
          route: 'building-dashboard',
          title: place.name,
          params: { id: place.id, organizationId: place.organizationId }
        })

      } else {
        // TODO: needed for legacy dashboard, remove!
        breadcrumbs.push({
          name: 'dashboard',
          title: organization.name,
          route: 'organization-dashboard',
          params: { id: place.organizationId }
        })

        breadcrumbs.push({
          name: 'place-dashboard',
          title: place.name,
          route: 'place-dashboard',
          params: { id: place.id },
          query: { organization: place.organizationId }
        })
      }

      if (plan) {
        breadcrumbs.push({
          title: plan.name === place.name ? 'Plan' : plan.name
        })
      }

      return breadcrumbs.filter(b => b)
    },

    // Indicates that we're now saving the plan
    isSaving () {
      return this.plan?.layout.isSaving
    },

    // Indicates that we're now saving the plan
    saveFailed () {
      return this.plan?.layout.saveError != null
    },

    // Color of SAVE icon, indicating the status - idle, saving, failed
    saveIconColor () {
      if (this.isSaving) return 'green-6'
      if (this.saveFailed) return 'orange-6'
      return 'green-4'
    },

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.mode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.mode === PlannerMode.ReadOnly
    },

    // Checks whether advanced mode is available
    canUseAdvancedMode () {
      return !this.isReadOnly && this.canUse('planner-advanced')
    },

    // Checks whether debugging is available
    canDebug () {
      return this.isSuperAdministrator
    },

    // Returns a list of devices which are permitted to be monitored for live status.
    // If customer is on premium plan, this requires live status subscription.
    liveDevices () {
      const { organizationGuardian, planDevices, currentOrganizationGuardian } = this
      let liveDevices
      if (currentOrganizationGuardian) {
        liveDevices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
          ? planDevices.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
          : (currentOrganizationGuardian.canUse('live-status') ? planDevices : [])
      } else {
        liveDevices = []
      }

      // Return only actual communicating boards
      return liveDevices.filter(d => d.isConnectedDevice && !d.isMultiDevice)
    },

    // Indicates whether any devices can be monitored live
    hasLiveDevices () {
      return this.liveDevices.length > 0
    },
  },

  methods: {
    ...mapActions([
      'goBack',
      'toggleSidebar',
      'editPlan',
      'editPlanFloor',
      'savePlan',
      'saveFloorImage',
      'updateRoute',
      'setPlannerMode',
      'setPlannerView',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
    ]),

    // Triggered when plan has been loaded
    planInitialized ({ renderer, layout, hierarchy }) {
      this.isDebugging = renderer.isDebugging

      // Initialize the device list
      this.populateDevices(layout, hierarchy)

      renderer.events.addEventListener('hierarchy-changed', ({ detail: { layout, hierarchy } }) => {
        this.populateDevices(layout, hierarchy)
      })

      // Show current position in debug mode
      renderer.events.addEventListener('position', ({ detail: { position } }) => {
        this.pointerPosition = position
      })

      // Save the plan immediately if any changes,
      // upgrades etc. have been applied while loading the plan
      this.save()
    },

    // Additional data requested when rendering a plan item
    dataRequested ({ item }) {
      if (item.isDevice && item.serialNumber) {
        const { planDevices, deviceStatus } = this
        const device = planDevices.find(d => d.serialNumber === item.serialNumber)
        const status = deviceStatus[item.serialNumber]
        return { device, status }
      }
    },

    // Triggered when plan has been changed
    planChanged ({ renderer }) {
      if (this.autoSave) {
        return this.save({ renderer })
      }
    },

    // Notifies about changes to the equipment hierarchy
    hierarchyChanged ({ layout, hierarchy }) {
      this.populateDevices(layout, hierarchy)
    },

    // Determines which actual devices are currently being viewed on the plan
    async populateDevices (layout, hierarchy) {
      if (layout) {
        const serials = hierarchy
          .devices
          .map(d => d.serialNumber)
          .reduce((all, s) => ({ ...all, [s]: true }), {})
        this.planDevices = this.devices.filter(d => serials[d.serialNumber])
        await this.watchStatus()
      }
    },

    // Triggered when user selects another floor
    async selectFloor ({ floor }) {
      await this.save()
      const floorId = floor?.id || PlanFloors.Main
      this.updateRoute({ params: { floorId } })
    },

    // Triggered when user selects cross-section
    async selectCrossSection () {
      await this.save()
      const floorId = PlanFloors.CrossSection
      this.updateRoute({ params: { floorId } })
    },

    // Saves the changed layout
    async save ({ renderer, force } = {}) {
      const { plan } = this
      if (plan.layout.isSaving) return
      if (!(plan.layout.isDirty || force)) return

      // Get the renderer
      if (!renderer) {
        renderer = this.$refs.plan.renderer
      }

      // Bump layout version
      plan.layout.saving()
      plan.layout.changed(true)

      try {
        // Save with a brief delay so the user notices
        await this.savePlan({ plan })
        Log.debug(`Plan [${plan.name}] saved`)
        plan.layout.saved()
      } catch (e) {
        Log.error(`Plan [${plan.name}] could not be saved`)
        Log.exception(e)
        plan.layout.saved(e)
      }
    },

    // Saves the floor image
    async saveImage ({ floor, file } = {}) {
      const { plan } = this
      if (plan.layout.isSaving) return

      // Bump layout version
      plan.layout.saving()
      plan.layout.changed(true)

      try {
        // Save with a brief delay so the user notices
        await this.saveFloorImage({ plan, floor, file })
        await this.savePlan({ plan })
        Log.debug(file ? `${floor.label}: Image saved` : `${floor.label}: Image removed`)

      } finally {
        plan.layout.saved()
      }
    },

    // Cancels changes and goes back to the previous view
    async cancel () {
      await this.goBack()
    },

    // Displays help
    showHelp () {
      this.$refs.plan.showHelp()
    },

    // Displays the current plan state
    showState ({ state, label }) {
      this.state = state
      // Suppress SAVE label
      this.stateLabel = state === PlanState.Saving ? null : label
    },

    // Toggles between regular and advanced mode
    toggleAdvancedMode () {
      if (this.canUseAdvancedMode) {
        const mode = this.isAdvancedMode ? PlannerMode.Regular : PlannerMode.Advanced
        this.setPlannerMode({ mode })
      }
    },

    // Toggles renderer debug mode
    toggleDebugging () {
      const plan = this.$refs.plan
      if (plan) {
        this.isDebugging = !this.isDebugging
        plan.debug(this.isDebugging)
      }
    },

    // Toggles maximal/normal view
    async toggleMaximize () {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen()
        await this.setPlannerView({ isMaximized: false })
      } else if (!document.fullscreenElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
        await this.setPlannerView({ isMaximized: true })
      }
      await this.toggleSidebar({ isCollapsed: this.isMaximized })
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasLiveDevices) {
            const devices = this.liveDevices
            await this.watchDeviceStatus({ name, devices, onStatus: (device, status) => this.onDeviceStatus(device, status) })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.nonLiveDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
    },

    // Triggered when device status arrives
    onDeviceStatus (device, status) {
      if (device && status) {
        const renderer = this.$refs.plan?.renderer
        if (renderer) {
          const item = renderer.layout.getDevice(device.serialNumber)
          renderer.updateData(item, { device, status })
        }
      }
    }
  },

  created () {
    // For super-admin turn on the advanced mode by default
    const mode = this.isSuperAdministrator
      ? PlannerMode.Advanced
      : (this.isAdministrator ? PlannerMode.Regular : PlannerMode.ReadOnly)
    this.setPlannerMode({ mode })
  },

  // Reload data on navigation to another plan or floor
  async beforeRouteUpdate (to, from, next) {
    const isPlanChanged = this.plan.id !== to.params.id
    const isFloorChanged = this.plan.id === to.params.id && this.floorId !== to.params.floorId

    if (isPlanChanged) {
      // If plan changed, do a full reload
      if (await resolvePlan({ from, to })) {
        return next()
      }
    } else if (isFloorChanged) {
      // If the same plan but the floor changed, just select that floor
      if (await resolvePlanFloor({ from, to })) {
        return next()
      }
    }

    next({ name: 'home' })
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs" :noHeader="isMaximized"
    bgColor="#fafafa">
    <template #toolbar>
      <div class="q-gutter-xs row items-center" v-if="!isMaximized">
        <div class="plan-state q-mr-lg bg-green-6 text-white" v-if="stateLabel">
          {{ stateLabel }}
        </div>
        <q-icon v-if="isInitialized" unelevated name="cloud_upload" size="md" class="q-mr-sm"
          :color="saveIconColor">
          <sc-tooltip>
            {{ saveFailed
    ? 'The last save has failed. It will be repeated again'
    : 'The plan is saved automatically'
            }}
          </sc-tooltip>
        </q-icon>
        <q-btn v-if="isInitialized && isDebugging && pointerPosition" :label="pointerPosition.toString() || ''" unelevated
          :class="isDebugging ? 'bg-orange-7 text-white' : undefined">
        </q-btn>
        <q-btn v-if="isInitialized && canDebug" label="Debug" unelevated
          :class="isDebugging ? 'bg-orange-7 text-white' : undefined" @click="toggleDebugging()">
        </q-btn>
        <q-btn v-if="isInitialized && canUseAdvancedMode"
          :label="isAdvancedMode ? 'Advanced Mode' : 'Normal Mode'" icon="tune"
          :class="isAdvancedMode ? 'bg-orange-7 text-white' : undefined" unelevated
          @click="toggleAdvancedMode()"></q-btn>
        <q-btn v-if="isInitialized" label="Maximize" unelevated @click="toggleMaximize()"></q-btn>
        <q-btn v-if="isInitialized" label="Help" unelevated @click="showHelp()"></q-btn>
        <q-btn v-if="isInitialized && showSaveButton" label="Save" unelevated
          @click="save({ force: true })"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-plan v-if="plan" ref="plan" :is-debugging="isDebugging" :devices="devices"
      :layout="plan.layout" :place="place" :floorId="floorId" :isMaximized="isMaximized"
      :initialized="(args) => planInitialized(args)" :data-requested="(args) => dataRequested(args)"
      :changed="(args) => planChanged(args)" :saved="(args) => save(args)"
      :image-saved="(args) => saveImage(args)" :floor-selected="(args) => selectFloor(args)"
      :cross-section-selected="(args) => selectCrossSection(args)"
      :state-changed="(args) => showState(args)">
    </sc-plan>

    <div class="toolbar-maximized row items-center" v-if="isMaximized">
      <div class="plan-state q-mr-lg bg-green-6 text-white" v-if="stateLabel">
        {{ stateLabel }}
      </div>
      <q-btn v-if="isInitialized && !isReadOnly"
        :label="isAdvancedMode ? 'Advanced Mode' : 'Normal Mode'" icon="tune" class="q-mr-xs"
        :class="isAdvancedMode ? 'bg-orange-7 text-white' : undefined" unelevated
        @click="toggleAdvancedMode()">
      </q-btn>
      <q-btn unelevated flat v-if="isInitialized" class="button-minimize bg-orange-7 text-white"
        label="Minimize" @click="toggleMaximize()">
      </q-btn>
    </div>
  </sc-view>
</template>

<style lang="scss" scoped>
.toolbar-maximized {
  position: absolute;
  right: 280px;
  top: 6px;
}

:deep(.q-tab-panel) {
  padding: 0 !important;
}

.plan-state {
  padding: 4px 8px 5px 8px;
  border-radius: 4px;
  font-size: 13px;
}
</style>