import { getDelta, sortProperties, getId } from '@stellacontrol/utilities'
import { GeoCoordinates, DeviceConnectionStatus, DeviceConnectionStatusName, MessageContent } from '@stellacontrol/model'
import { DeviceStatusIdentity } from './device-status-identity'
import { DeviceStatusConnection } from './device-status-connection'
import { DeviceStatusHealth } from './device-status-health'
import { DeviceStatusBands } from './device-status-bands'
import { DeviceStatusTimings } from './device-status-timings'
import { DeviceStatusFeatures } from './device-status-features'

/**
 * Device Status items
 */
export const DeviceStatusItem = {
  Identity: 'identity',
  Connection: 'connection',
  Health: 'health',
  Bands: 'bands',
  Timings: 'timings',
  Features: 'features',
  Counters: 'counters',
  Mega: 'mega',
  Custom: 'custom'
}

/**
 * Device status
 */
export class DeviceStatus {
  /**
   * Initializes the instance
   * @param {String} serialNumber Device serial number
   * @param {Object} reported Reported device parameters
   * @param {Object} data Initial values of device status properties
   */
  constructor (serialNumber, reported, data = {}) {
    Object.assign(this, data)
    this.mega = this.parse(reported)
    this.serialNumber = serialNumber
    this.identity = new DeviceStatusIdentity(serialNumber, this.reported)
    this.connection = new DeviceStatusConnection(serialNumber, this.reported)
    this.health = new DeviceStatusHealth(serialNumber, this.reported)
    this.bands = new DeviceStatusBands(serialNumber, this.reported)
    this.timings = new DeviceStatusTimings(serialNumber, this.reported)
    this.features = new DeviceStatusFeatures(serialNumber, this.reported)
    this.counters = data?.counters
    this.custom = data?.custom
    this.parts = {}
    this.id = this.id || getId('msg')
  }

  /**
   * Creates a {@link DeviceStatus} instance from deserialized data
   * @param {Object} data Deserialized device status
   * @returns {DeviceStatus}
   */
  static from (data = {}) {
    let status
    const { serialNumber, mega } = data
    if (serialNumber && mega) {
      status = new DeviceStatus(serialNumber, mega, data)
    } else {
      status = new DeviceStatus()
      Object.assign(status, {
        ...data,
        identity: DeviceStatusIdentity.from(data.identity),
        connection: DeviceStatusConnection.from(data.connection),
        health: DeviceStatusHealth.from(data.health),
        bands: DeviceStatusBands.from(data.bands),
        timings: DeviceStatusTimings.from(data.timings),
        features: DeviceStatusFeatures.from(data.features),
        custom: data.custom,
        counters: data.counters
      })
    }
    return status
  }

  /**
   * Creates a {@link DeviceStatus} instance representing status unavailable
   * @param {String} serialNumber Device serial number
   * @param {String} message Error message to report
   * @returns {DeviceStatus}
   */
  static notAvailable (serialNumber, message) {
    const status = new DeviceStatus(serialNumber, {})
    status.connection.unknown(message || 'Device status is not available')
    return status
  }

  /**
   * Unique identifier
   * @type {String}
   */
  id

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Raw MEGA message
   * @type {Dictionary<String, any>}
   */
  mega

  /**
   * Values reported by the platform
   * @type {Dictionary<String, any>}
   */
  get reported () {
    return this.mega
  }

  /**
   * Values customized on the platform
   * @type {Dictionary<String, any>}
   */
  custom

  /**
   * Date and time when message has been received
   * @type {Date}
   */
  get receivedAt () {
    return this.timings?.receivedAt
  }
  // Overruled, now it's a computed property
  // eslint-disable-next-line no-unused-vars
  set receivedAt (value) {
  }

  /**
   * Device communication protocol
   * @type {MessageProtocol}
   */
  protocol

  /**
   * MEGA message version
   * @type {String}
   */
  version

  /**
   * Indicates that status message is partial update,
   * such as delta since the previous status.
   * Notice that for older devices this will return `undefined`
   * as flag indicating the report format is simply missing.
   * @type {Boolean|undefined}
   */
  get isPartial () {
    const format = this.reported?.report_format
    return format == null
      ? undefined
      : (format === MessageContent.Delta || format === MessageContent.FastSamplingDelta)
  }

  /**
   * Indicates that status message is full update.
   * Notice that for older devices this will return `undefined`
   * as flag indicating the report format is simply missing.
   * @type {Boolean|undefined}
   */
  get isFull () {
    const format = this.reported?.report_format
    return format == null
      ? undefined
      : format === MessageContent.Full
  }

  /**
   * Indicates that status message is sent in fast-sampling mode.
   * Notice that for older devices this will return `undefined`
   * as flag indicating the report format is simply missing.
   * @type {Boolean|undefined}
   */
  get isFastSampling () {
    const format = this.reported?.report_format
    return format == null
      ? undefined
      : format === MessageContent.FastSamplingDelta
  }

  /**
   * Error captured while retrieving device status
   * @type {Error}
   */
  error

  /**
   * Device identification
   * @type {DeviceStatusIdentity}
   */
  identity

  /**
   * Device connection status
   * @type {DeviceStatusConnection}
   */
  connection

  /**
   * User-friendly label representing the current status of device connection
   * @type {String}
   */
  get label () {
    return this.timings?.label || DeviceConnectionStatusName[DeviceConnectionStatus.Unknown]
  }

  /**
   * Determines whether the device can be considered as connected,
   * being either in `online` or `heartbeat` status
   * @type {Boolean}
   */
  get isConnected () {
    return this.timings?.isRealTime || this.timings?.isHeartbeat
  }

  /**
   * Device health status
   * @type {DeviceStatusHealth}
   */
  health

  /**
   * Detailed status of device bands
   * @type {DeviceStatusBands}
   */
  bands

  /**
   * Device status timings
   * @type {DeviceStatusTimings}
   */
  timings

  /**
   * Device features
   * @type {DeviceStatusFeatures}
   */
  features

  /**
   * Device message counters
   * @type {Dictionary<String, Number}
   */
  counters

  /**
   * Status of device parts,
   * available when device is a multi-board unit
   * @type {Dictionary<String, DeviceStatus>}
   */
  parts

  /**
   * Parses raw MEGA record received from the device
   * @param {<Dictionary<String, any>} mega MEGA record
   * @returns {<Dictionary<String, any>} Augmented mega
   */
  parse (mega = {}) {
    mega = sortProperties(mega)
    this.version = mega['@version']

    // ADD CALCULATED FIELDS, before passing it to other parsers
    // 1. Long version string
    const longVersion = mega['@version_git_long'] || mega['@version_git']
    if (longVersion) {
      mega['@version_git_long'] = longVersion
    }

    // 2. GPS coordinates
    const coordinates = GeoCoordinates.fromString(mega['gps'])
    if (coordinates) {
      mega['latitude'] = coordinates.latitude
      mega['longitude'] = coordinates.longitude
    }

    return mega
  }

  /**
   * Shortcut for checking latest device connection status
   * @param status Status to check
   * @returns {Boolean}
   */
  isConnectionStatus (status) {
    return this.connection && this.connection.status === status
  }

  /**
   * Shortcut for checking whether the device has ever connected
   * @type {Boolean}
   */
  get hasDeviceConnected () {
    const { connection } = this
    return connection?.status && connection?.status !== DeviceConnectionStatus.NeverConnected
  }

  /**
   * Shortcut for checking whether the device or any of its parts has ever connected
   * @type {Boolean}
   */
  get hasAnyPartConnected () {
    const { connection, parts = {} } = this
    return connection?.status &&
      (connection.status !== DeviceConnectionStatus.NeverConnected ||
        Object.values(parts).some(part => part.connection?.status !== DeviceConnectionStatus.NeverConnected))
  }

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   */
  toJSON () {
    const result = {
      ...this,
      identity: { ...(this.identity || {}) },
      connection: { ...(this.connection || {}) },
      health: { ...(this.health || {}) },
      bands: { ...(this.bands || {}) },
      timings: { ...(this.timings || {}) },
      features: {
        ...(this.features || {})
      },
      counters: this.counters,
      mega: this.reported,
      custom: this.custom
    }

    // Get rid of redundant values
    for (const [name, item] of Object.entries(result)) {
      if (item) {
        delete item.serialNumber
        if (name !== 'identity') {
          delete item.megaVersion
        }
      } else if (item == null) {
        delete result[name]
      }
    }

    return result
  }

  /**
   * Returns a delta of the previous device status
   * @param {DeviceStatus} previous Previous device status
   * @returns {Dictionary<String, any>} Dictionary containing all new and changed MEGA values
   */
  getMEGADelta (previous) {
    const delta = getDelta(this.reported, previous?.reported)
    if (delta) {
      delete delta.extra
    }
    return delta
  }

  /**
   * Checks whether status contains the specified parameter
   * and optionally whether it has the specified value
   * @param {String} parameter Parameter name
   * @param {any} value Parameter value
   * @returns {Boolean} True, when the parameter exists and optionally also has the specified value
   */
  has (parameter, value) {
    const { reported = {} } = this
    if (parameter) {
      if (reported[parameter] == null) {
        return false
      } else {
        return value == null ? true : reported[parameter] == value
      }
    }
  }

  /**
   * Returns the reported value of the specified parameter
   * @param {String} parameter Parameter name
   * @returns {any}
   */
  getReported (parameter) {
    const { reported = {} } = this
    return reported[parameter]
  }

  /**
   * Returns the customized value of the specified parameter, if any
   * @param {String} parameter Parameter name
   * @returns {any}
   */
  getCustom (parameter) {
    const { custom = {} } = this
    return custom[parameter]
  }

  /**
   * Checks whether device parameters have been reported by the device
   * @param {String} parameter Parameter name, optional.
   * @returns {Boolean} True, if specified parameter is listed in {@link reported} parameters.
   * If parameter is not specified, we return true if any parameter has been reported.
   */
  isReported (parameter) {
    const { reported = {} } = this
    if (parameter) {
      return reported[parameter] != null
    } else {
      return Object.values(reported).length > 0
    }
  }

  /**
   * Returns the current value of the specified parameter.
   * If parameter is customized but not reconciled, custom value takes precedence.
   * @param {String} parameter Parameter name
   * @returns {any}
   */
  getCurrent (parameter) {
    const { custom = {}, reported = {} } = this
    return custom[parameter] == null ? reported[parameter] : custom[parameter]
  }

  /**
   * Checks whether device parameters have been customized by the user
   * @param {String} parameter Parameter name, optional.
   * @returns {Boolean} True, if specified parameter is listed in {@link custom} parameters.
   * If parameter is not specified, we return true if any parameter has been customized.
   */
  isCustomized (parameter) {
    const { custom = {}, reported = {} } = this
    if (parameter) {
      if (reported[parameter] != null) {
        return custom[parameter] != null
      }
    } else {
      return Object.values(custom).length > 0
    }
  }

  /**
   * Checks whether device parameters have been customized by the user
   * and device acknowledged their custom values in recent status
   * @param {String} parameter Parameter name, optional
   * @returns {Boolean} True, if parameter is customized and reconciled with the device,
   * or when parameter is not customized.
   * If parameter is not specified, we return true if all customized parameters are reconciled.
   */
  isReconciled (parameter) {
    const { custom = {}, reported = {} } = this
    if (parameter) {
      if (reported[parameter] != null) {
        if (custom[parameter] == null) {
          return true
        } else {
          return custom[parameter] == reported[parameter]
        }
      }
    } else {
      return Object.entries(custom).every(([parameter, value]) => value === reported[parameter])
    }
  }

  /**
   * Returns a dictionary of unreconciled parameters
   * and their desired values
   * @returns {Dictionary<String, any>}
   */
  getUnreconciledParameters () {
    if (this.isReconciled() === false) {
      const { custom = {}, reported = {} } = this
      // Some fields are used to carry information one way, from the platform to the device
      // and should be ignored
      const ignored = [
        '_fw_update_pending',
        '_is_licenced'
      ]
      const unreconciled = Object
        .entries(custom)
        .filter(([parameter]) => !ignored.includes(parameter))
        .filter(([parameter, value]) => value !== reported[parameter])
        .reduce((all, [parameter, value]) => ({ ...all, [parameter]: value }), {})
      return unreconciled
    }
  }

  /**
   * Returns status of the specified band
   * @param {BandIdentifier} identifier Band identifier
   * @returns {BandStatus}
   */
  getBandStatus (identifier) {
    return this.bands?.status[identifier]
  }

  /**
   * Returns details of the status of the specified band
   * @param {BandIdentifier} identifier Band identifier
   * @returns {BandDetails}
   */
  getBandsDetails (identifier) {
    return this.bands?.details[identifier]
  }

  /**
   * Updates the specified device with the parsed status
   * @param {Device} device Device to update with this status
   */
  updateDevice (device) {
    if (device && this.hasDeviceConnected) {
      for (const key of Object.values(DeviceStatusItem)) {
        const item = this[key]
        if (item?.update) {
          item?.update(device)
        }
      }
    }
  }
}