import { PlanCircle } from './plan-circle'
import { PlanItemType } from './plan-item'
import { PlanTextStyle, PlanBackgroundStyle } from '../styles'

/**
 * Cable Plug - a connection point where cross-floor cable enters/exits a {@link PlanRiser}
 */
export class PlanPlug extends PlanCircle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Plug
  }

  /**
   * Object defaults
   */
  get defaults () {

    const defaults = {
      ...super.defaults,
      radius: 12,
      backgroundStyle: new PlanBackgroundStyle({ color: '#feffb1' }),
      textStyle: PlanTextStyle.Small
    }

    return defaults
  }

  normalize () {
    super.normalize()

    this.clearPorts()
    this.addPort()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime properties
    delete result.ports

    // Remove fixed styles
    delete result.radius
    delete result.innerRadius
    delete result.backgroundStyle
    delete result.lineStyle
    delete result.textStyle

    return result
  }

  /**
   * Identifier of a riser to which the plug belongs
   * @type {Array[String]}
   */
  riser
}
