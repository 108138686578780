<script>
import { mapActions } from 'vuex'
import { AttachmentType, AttachmentTypeDescription, DocumentType, MessageType, MessageTypeDescription } from '@stellacontrol/model'
import { DialogMixin, Notification, Clipboard, Download } from '@stellacontrol/client-utilities'
import { DeviceAPI, CommonAPI } from '@stellacontrol/client-api'
import { scanToText } from '@stellacontrol/devices'
import TTScanPreview from './ttscan/ttscan.vue'

const dialog = 'file-preview'

export default {
  mixins: [
    DialogMixin
  ],

  components: {
    'sc-ttscan-preview': TTScanPreview
  },

  data () {
    return {
      dialog,
      // Indicates that we're loading
      isLoading: true,
      // Viewed attachment
      attachment: null,
      // Attachment download URL
      url: null
    }
  },

  computed: {
    // Form class
    formClass () {
      const { attachment, isScanResults } = this
      if (attachment) {
        const { type } = attachment
        return {
          'ttscan': isScanResults,
          [type]: true
        }
      }
    },

    // Dialog title
    title () {
      const { attachment, isScanResults } = this
      if (attachment) {
        const { type, name, entity, folder } = attachment
        const typeLabel = isScanResults
          ? MessageTypeDescription[MessageType.ScanResults]
          : AttachmentTypeDescription[type]
        const entityLabel = entity?.label || entity?.name
        return [
          typeLabel,
          folder,
          name,
          entityLabel
        ].filter(p => p).join(' / ')
      }
    },

    // Document type
    documentType () {
      const { attachment } = this
      const types = {
        [AttachmentType.Markdown]: DocumentType.Markdown,
        [AttachmentType.HTML]: DocumentType.HTML,
        [AttachmentType.JSON]: DocumentType.JSON
      }
      return types[attachment?.type] || DocumentType.Text
    },

    // Indicates that the document contains TT Scan Results
    isScanResults () {
      return this.attachment?.isScanResults
    },

    // Document content
    documentContent () {
      const { attachment } = this

      if (attachment) {
        return attachment.content
      }
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getAttachment',
      'getAttachmentUrl',
      'openUrl'
    ]),

    // Called when dialog is shown
    async dialogShown () {
      this.attachment = this.data.attachment
      const { id } = this.attachment || {}
      if (id) {
        const attachment = await this.getAttachment({ id, withContent: true })
        if (attachment) {
          this.url = await this.getAttachmentUrl({ attachment })
          this.attachment = attachment
        }
      }
      this.isLoading = false
      if (!this.attachment) {
        this.close()
      }
    },

    // Called when dialog is shown
    async dialogHidden () {
      this.attachment = null
      this.url = null
      this.isLoading = true
    },

    // Closes the dialog
    close () {
      this.dialogOk({ dialog })
    },

    // Copies the content to the clipboard (text documents only!)
    async copy () {
      const { attachment } = this
      if (attachment?.canCopy) {
        let text

        text = attachment.isScanResults
          ? scanToText(attachment)
          : attachment.toText()

        await Clipboard.write(text)
        Notification.success({ message: 'The document has been copied to clipboard' })
      }
    },

    // Downloads the file
    async downloadData () {
      const { attachment, url } = this
      if (attachment?.canDownload && url) {
        this.openUrl({ url })
        Notification.success({ message: 'The data has been downloaded' })
      }
    },

    // Downloads printed file
    async downloadPrintout () {
      const { attachment } = this
      if (attachment?.canPrint) {
        // Print scan results
        if (attachment.isScanResults) {
          const filename = 'scans.zip'
          const result = await DeviceAPI.printScanReports({
            identifiers: [attachment.id],
            format: AttachmentType.HTML,
            bundle: filename
          })

          // Download the file
          if (result) {
            const url = CommonAPI.getAttachmentUrl({ attachment: result, remove: true })
            Download.saveUrlToFile(url, filename)
            Notification.success({ message: 'The report has been printed and downloaded' })
          }
        }
      }
    }
  }
}

</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()" @dialogHidden="dialogHidden()"
    :cssClass="{ 'file-preview': true, 'frame-preview': attachment?.canPreviewInFrame }">
    <q-form class="form" :class="formClass" ref="form">
      <header>
        <q-banner class="bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              {{ title }}
            </span>
            <q-space>
            </q-space>
            <q-btn flat dense label="Copy" @click="copy()" class="primary q-mr-md"
              v-if="attachment?.canCopy">
              <sc-tooltip>
                Copies the file data to clipboard
              </sc-tooltip>
            </q-btn>
            <q-btn flat dense label="Download" @click="downloadData()" class="primary q-mr-md"
              v-if="attachment?.canDownload">
              <sc-tooltip>
                Downloads the file data
              </sc-tooltip>
            </q-btn>
            <q-btn flat dense label="Print" @click="downloadPrintout()" class="primary q-mr-md"
              v-if="attachment?.canPrint">
              <sc-tooltip>
                Prints the report and downloads as ZIP archive
              </sc-tooltip>
            </q-btn>
            <q-btn flat dense label="Close" @click="close()" class="primary">
            </q-btn>
          </div>
        </q-banner>
      </header>

      <main>
        <!-- Loading indicator -->
        <div v-if="isLoading">
          <q-inner-loading :showing="true">
            <span class="text-gray-9 q-mb-md">
              Loading file content, please wait ...
            </span>
            <q-spinner-gears size="48px" color="grey-8">
            </q-spinner-gears>
          </q-inner-loading>
        </div>

        <!-- TT Scan Results Preview -->
        <sc-ttscan-preview v-else-if="isScanResults" :file="attachment">
        </sc-ttscan-preview>

        <!-- File content viewed inline -->
        <sc-document-viewer v-else-if="attachment?.canPreview && documentContent"
          :type="documentType" :content="documentContent">
        </sc-document-viewer>

        <!-- File content viewed in frame -->
        <iframe v-else-if="attachment?.canPreviewInFrame && url" :src="url">
        </iframe>

        <!-- File is empty -->
        <div v-else>
          The file is empty
        </div>
      </main>
    </q-form>
  </sc-dialog>
</template>

<style lang="scss" scoped>
.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 800px;
  min-width: 1000px;
  max-width: 1600px;
  overflow: hidden;

  &.ttscan {
    width: 80vw;
    max-width: 1400px;
    height: 90vh;
    max-height: 90vh;
  }

  >header {
    .title {
      font-size: 16px;
    }
  }

  >main {
    padding: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    iframe {
      border: none;
      min-width: 1200px;
      min-height: 800px;
    }
  }

  &.frame-preview {
    >main {
      padding: 0;
    }
  }
}

/* Layout adjustments for mobile phones */
@media screen and (max-width: 640px) {
  .form {
    min-height: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;

    &.ttscan {
      width: 100vw;
      height: 100vh;
      min-height: 100vw;
      max-height: 100vh;
      min-width: 100vw;
      max-width: 100vw;
    }
  }
}
</style>
