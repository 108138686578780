import { runBatch } from '@stellacontrol/utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { getPlaceDescription } from '@stellacontrol/model'
import { ListViewMode } from '@stellacontrol/client-utilities'

export const actions = {
  /**
   * Stores devices, places and organization for the ORGANIZATION DASHBOARD view
   * @param organization Organization to view
   * @param place Place within the organization to view
   */
  async populateOrganizationDashboard ({ commit, dispatch, state }, { organization, place }) {
    let ownDevices
    let sharedDevices
    let places

    await runBatch([
      async () => {
        ownDevices = await dispatch('getOwnDevices', { organization, withDetails: true })
      },
      async () => {
        sharedDevices = await dispatch('getSharedDevices', { organization, withDetails: true })
      },
      async () => {
        places = await dispatch('getPlaces', { organization, withChildren: false })
      },
    ])

    // Restore the status of collapsed/expanded places and groups from settings
    const expandedPlaces = await dispatch('getUserPreference', { name: `expanded-places-${organization.id}` })

    const collapsedPlaceGroups = []
    for (const place of [...places, state.noPlace]) {
      const name = `collapsed-place-groups-${organization.id}-${place.id}`
      const groups = await dispatch('getUserPreference', { name })
      if (groups) {
        const items = (groups || '')
          .split(',')
          .map(group => ({ placeId: place.id, group }))
        collapsedPlaceGroups.push(...items)
      }
    }

    // Restore last view mode
    const viewMode = await dispatch('getUserPreference', { name: `place-devices-view-mode-${organization.id}`, defaultValue: ListViewMode.MiniCards })
    commit('setPlaceDevicesViewMode', { viewMode })

    // Populate the dashboard with data
    commit('populateOrganizationDashboard', {
      organization,
      place,
      ownDevices,
      sharedDevices,
      places,
      expandedPlaces,
      collapsedPlaceGroups
    })
  },

  /**
   * Assigns device to a place
   * @param {Device} device Device to assign to the place
   * @param {Place} place Place to assign to
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Place>} Place to which the device was assigned
   */
  async setDevicePlace ({ commit, dispatch }, { place, device, silent } = {}) {
    if (!place) throw new Error('Place is required')
    if (!device) throw new Error('Device is required')
    if (device.placeId !== place.id) {
      await dispatch('busy', { message: `Assigning device ${device.serialNumber} to ${getPlaceDescription(place)}, please wait ...`, silent })
      place = await DeviceAPI.setDevicePlace({ place, device })
      commit('storePlace', { place })
      commit('setDevicePlace', { device, place })
      await dispatch('done', { silent })
      return place
    }
  },

  /**
   * Removes device from a place
   * @param {Device} device Device to remove from the place
   * @param {Place} place Place to remove the device from
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Place>} Place from which the device was renoved
   */
  async removeDeviceFromPlace ({ commit, dispatch }, { place, device, silent } = {}) {
    if (!place) throw new Error('Place is required')
    if (!device) throw new Error('Device is required')
    if (device.placeId === place.id) {
      await dispatch('busy', { message: `Removing device ${device.serialNumber} from ${getPlaceDescription(place)}, please wait ...`, silent })
      place = await DeviceAPI.removeDeviceFromPlace({ place, device })
      commit('setDevicePlace', { device })
      await dispatch('done', { silent })
      return place
    }
  },

  /**
   * Assigns a batch of devices to a place
   * @param {Array[Device]} devices Devices to assign to the place
   * @param {Place} place Place to assign to
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async setDevicesPlace ({ dispatch }, { place, devices, silent } = {}) {
    if (!place) throw new Error('Place is required')
    if (!devices) throw new Error('Devices are required')
    if (devices.length === 1) {
      await dispatch('setDevicePlace', { place, device: devices[0] })
    } else {
      await dispatch('busy', { message: `Assigning devices to ${getPlaceDescription(place)}, please wait ...`, silent })
      for (const device of devices) {
        await dispatch('setDevicePlace', { place, device, silent: true })
      }
      await dispatch('done', { silent })
    }
  },

  /**
   * Removes a batch of devices from a place
   * @param {Array[Device]} devices Devices to remove from the place
   * @param {Place} place Place to remove the device from
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async removeDevicesFromPlace ({ dispatch }, { place, devices, silent } = {}) {
    if (!place) throw new Error('Place is required')
    if (!devices) throw new Error('Devices are required')
    if (devices.length === 1) {
      await dispatch('removeDeviceFromPlace', { place, device: devices[0] })
    } else {
      await dispatch('busy', { message: `Removing devices from ${getPlaceDescription(place)}, please wait ...`, silent })
      for (const device of devices) {
        await dispatch('removeDeviceFromPlace', { place, device })
      }
      await dispatch('done', { silent })
    }
  },

  /**
   * Moves a device to a specified place.
   * Place can be a no-place, in which case the device
   * will be unassigned from its current place.
   * @param {String} deviceId Identifier of a device to move
   * @param {String} placeId Identifier of a place to move to
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async moveDeviceToPlace ({ dispatch, state, getters }, { placeId, deviceId, silent }) {
    const { noPlace } = state
    const { allOrganizationPlaces } = getters
    const device = state.devices.find(d => d.id === deviceId)
    const place = allOrganizationPlaces.find(p => p.id === placeId)
    const currentPlace = allOrganizationPlaces.find(p => p.id === device.placeId)
    if (place.id === noPlace.id) {
      // Remove the device from current place
      await dispatch('removeDeviceFromPlace', { place: currentPlace, device, silent })
    } else {
      // Move device to a new place
      await dispatch('setDevicePlace', { place, device, silent })
    }
    return place
  },

  /**
   * Moves the specified place before the given one
   * @param place Place to move
   * @param before Place before which the specified place is to be moved
   */
  async movePlaceBefore ({ commit, state }, { place, before } = {}) {
    if (!(place && before)) return

    commit('movePlaceBefore', { place, before })
    const { places } = state
    await DeviceAPI.setPlacesOrder({ places })
  },

  /**
   * Moves the specified device at the given one
   * @param device Device to move
   * @param atDevice Device at which the specified device is to be moved
   */
  async moveDeviceAt ({ commit, getters }, { device, atDevice } = {}) {
    if (!(device && atDevice)) return

    // Reorder devices
    commit('moveDeviceAt', { device, atDevice })

    // Store devices order and grouping in place
    const devices = getters.getPlaceDevices({ id: device.placeId })
    await DeviceAPI.setDevicesOrder({ devices })
    await DeviceAPI.setDeviceGroups({ devices })
  },

  /**
   * Toggles place as expanded/collapsed
   * @param organization Owner organization of the place
   * @param place Place to toggle
   * @param isExpanded Optional status of the place. If not specified, place status is toggled.
   * @param dontSave If true, the state will not be persisted. Useful when temporarily expanding
   * places during search operations etc.
   */
  async togglePlace ({ commit, state, dispatch }, { organization, place, isExpanded, dontSave } = {}) {
    commit('togglePlace', { place, isExpanded })
    if (!dontSave) {
      // Store the state of the place in user preferences.
      // We store expanded places separately for each organization.
      const name = `expanded-places-${organization.id}`
      const value = state.expandedPlaces
      await dispatch('storeUserPreference', { name, value })
    }
  },

  /**
   * Toggles all places in organization as expanded/collapsed
   * @param organization Owner organization of the place
   * @param isExpanded Optional status of the place. If not specified, place status is toggled.
   * @param dontSave If true, the state will not be persisted. Useful when temporarily expanding
   * places during search operations etc.
   */
  async togglePlaces ({ state, dispatch, getters }, { organization, isExpanded, dontSave } = {}) {
    for (const place of getters.allOrganizationPlaces) {
      dispatch('togglePlace', { organization, place, isExpanded, dontSave: true })
    }
    if (!dontSave) {
      const name = `expanded-places-${organization.id}`
      const value = state.expandedPlaces
      await dispatch('storeUserPreference', { name, value })
    }
  },

  /**
   * Toggles place group as expanded/collapsed
   * @param organization Owner organization of the place
   * @param place Place
   * @param placeGroup Place group to toggle
   * @param isExpanded Optional status of the place group. If not specified, place group status is toggled.
   * @param dontSave If true, the state will not be persisted. Useful when temporarily expanding
   * places during search operations etc.
   */
  async togglePlaceGroup ({ commit, state, dispatch }, { organization, place, placeGroup, isExpanded, dontSave } = {}) {
    commit('togglePlaceGroup', { place, placeGroup, isExpanded })

    if (!dontSave) {
      // Store the state of the place groups in user preferences
      const name = `collapsed-place-groups-${organization.id}-${place.id}`
      const value = state
        .collapsedPlaceGroups
        .filter(item => item.placeId === place.id)
        .map(item => item.group).join(',')
      await dispatch('storeUserPreference', { name, value })
    }
  },

  /**
   * Toggles all groups in a place as expanded/collapsed
   * @param organization Owner organization of the place
   * @param place Place
   * @param isExpanded Optional status of the place group. If not specified, place group status is toggled.
   * @param dontSave If true, the state will not be persisted. Useful when temporarily expanding
   * places during search operations etc.
   */
  async togglePlaceGroups ({ commit, state, dispatch, getters }, { organization, place, isExpanded, dontSave } = {}) {
    const placeDevices = getters.getPlaceDevices(place)
    const placeGroups = getters.getPlaceGroups(placeDevices, place)
    for (const placeGroup of placeGroups) {
      commit('togglePlaceGroup', { place, placeGroup, isExpanded })
    }

    if (!dontSave) {
      // Store the state of the place groups in user preferences
      const name = `collapsed-place-groups-${organization.id}-${place.id}`
      const value = state
        .collapsedPlaceGroups
        .filter(item => item.placeId === place.id)
        .map(item => item.group).join(',')
      await dispatch('storeUserPreference', { name, value })
    }
  },

  /**
   * Sets place group at the specified device
   * @param device Device
   * @param placeGroup Name of a place group to start at the specified device
   */
  async setPlaceGroup ({ commit }, { device, placeGroup }) {
    commit('setPlaceGroup', { device, placeGroup })

    await DeviceAPI.setDeviceGroup({
      device,
      placeGroup: device.placeGroup,
      placeSeparator: false
    })
  },

  /**
   * Removes place group at the specified device
   * @param device Device
   */
  async removePlaceGroup ({ commit }, { device }) {
    commit('removePlaceGroup', { device })

    await DeviceAPI.setDeviceGroup({
      device,
      placeGroup: null,
      placeSeparator: false
    })
  },

  /**
   * Adds separator before the specified device
   * @param device Device
   */
  async addPlaceSeparator ({ commit }, { device }) {
    commit('addPlaceSeparator', { device })

    await DeviceAPI.setDeviceGroup({
      device,
      placeGroup: device.placeGroup,
      placeSeparator: true
    })
  },

  /**
   * Removes separator before the specified device
   */
  async removePlaceSeparator ({ commit }, { device }) {
    commit('removePlaceSeparator', { device })

    await DeviceAPI.setDeviceGroup({
      device,
      placeGroup: device.placeGroup,
      placeSeparator: false
    })
  },

  /**
   * Sets view mode for devices in places
   * @param viewMode Devices list view mode
   * @param organization Organization being viewed
   */
  async setPlaceDevicesViewMode ({ commit, dispatch }, { viewMode, organization } = {}) {
    commit('setPlaceDevicesViewMode', { viewMode })
    // Store the state of the view mode in user preferences.
    // We store it separately for each organization.
    const name = `place-devices-view-mode-${organization.id}`
    const value = viewMode
    await dispatch('storeUserPreference', { name, value })
  }
}
