import { stringCompare, numberCompare, groupItems, notNull } from '@stellacontrol/utilities'
import { GSMServiceName, bandLabelToIdentifier, getBandFrequency } from '@stellacontrol/model'
import { getSignalLevel } from './utilities'

/**
 * Extracts the neighbour cell scan results from the specified scan
 * @param {Attachment} attachment
 * @returns {Object}
 */
export function getScanNeighbourCells (attachment) {
  if (!attachment?.isScanResults) return
  const { neighbourResults: items, operatorNames: operators = {} } = attachment.data.results
  if (!(items?.length > 0)) return

  // Extract scan results of individual cells, avoid duplicates and empties
  const isDuplicate = (item, items) =>
    items.some(i =>
      item.plmn === i.plmn &&
      item.physicalCellId === i.physicalCellId &&
      item.bandNumber === i.bandNumber &&
      item.isServing === i.isServing &&
      item.isNeighbour === i.isNeighbour)

  // Get individual cell results,
  // Resolve operator, get timing metrics,
  // Check whether they're for the scanning cell or neighboring cell,
  const cells = items
    .map(({ cellInfos, timeOffsetMs, timeTakenMs, plmn, requestedBandNumber }) => {
      const operator = (operators || {})[plmn] || plmn
      const isRawScan = requestedBandNumber === 0
      return cellInfos.map(cellInfo => {
        const { physicalCellId, appearances = [], bandNumber, serviceType } = cellInfo
        const signalPower = notNull(cellInfo.signalPower, cellInfo.peakSignalPower)
        const signalQuality = notNull(cellInfo.signalQuality, cellInfo.peakQuality)
        const bandLabel = `B${bandNumber}`
        const band = bandLabelToIdentifier(bandLabel)
        const passes = appearances.length
        const isServing = appearances[passes - 1] === 1
        const isNeighbour = !isServing
        const key = `${plmn}-${physicalCellId}-${bandNumber}-${isServing ? 's' : 'n'}`
        const service = GSMServiceName[serviceType] || 'Unknown'

        // Calculate power/quality indicators
        const { level: signalPowerLevel, range: signalPowerRange } = getSignalLevel(signalPower, -120, -105, -100, -60)
        const { level: signalQualityLevel, range: signalQualityRange } = getSignalLevel(signalQuality, -20, -14, -12, -5)

        return {
          band,
          bandLabel,
          key,
          plmn,
          operator,
          service,
          isServing,
          isNeighbour,
          isRawScan,
          timeOffsetMs,
          timeTakenMs,
          ...cellInfo,
          signalPower,
          signalQuality,
          signalPowerLevel,
          signalPowerRange,
          signalQualityLevel,
          signalQualityRange,
          appearances: undefined
        }
      })
    })
    .flatMap(item => item)
    .filter(item => item.band && (item.isServing || item.isNeighbour))
    .reduce((all, item) => isDuplicate(item, all) ? [...all] : [item, ...all], [])

  // Sort by operator, then band number
  cells.sort((a, b) => {
    let result = stringCompare(a.operator, b.operator)
    if (result === 0) result = -numberCompare(
      getBandFrequency(a.band),
      getBandFrequency(b.band)
    )
    if (result === 0) result = numberCompare(a.physicalCellId, b.physicalCellId)
    return result
  })

  // Get serving cells. grouped by operator
  const servingCells = groupItems(cells.filter(c => c.isServing), 'operator')

  // Get neighbouring cells. grouped by operator
  const neighbourCells = groupItems(cells.filter(c => c.isNeighbour), 'operator')

  // Get results
  const groups = []
  if (Object.keys(servingCells).length > 0) {
    const isRawScan = cells.filter(c => c.isServing && c.isRawScan)
    groups.push({ name: 'Serving Cells', cells: servingCells, isRawScan })
  }
  if (Object.keys(neighbourCells).length > 0) {
    groups.push({ name: 'Neighbour Cells', cells: neighbourCells })
  }
  return groups
}
