import { state, getters } from './dashboard-view.organization.state'
import { mutations } from './dashboard-view.organization.mutations'
import { actions } from './dashboard-view.organization.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
