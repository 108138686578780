<script>
import { mapActions, mapState } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  data () {
    return {
      devices: [],
      isLoading: true
    }
  },

  computed: {
    ...mapState({
      organizationDevicesColumns: state => state.organizations.organizationDevicesColumns
    }),

    hasDevices () {
      return this.devices.length > 0
    }
  },

  methods: {
    ...mapActions([
      'getOrganizationDevices'
    ]),

    async loadDevices () {
      this.isLoading = true
      this.devices = await this.getOrganizationDevices(this.data)
      this.isLoading = false
    }
  },

  async created () {
    await this.loadDevices()
  }
}

</script>

<template>
  <div class="column">
    <div class="header q-mb-sm" v-if="!hasDevices && !isLoading">
      <div class="q-mb-md">
        This organization does not own any devices yet.
      </div>
    </div>

    <sc-list
      v-if="hasDevices"
      name="organization-devices"
      row-key="id"
      :columns="organizationDevicesColumns"
      :items="devices">

      <template v-slot:body-cell-serialNumber="props">
        <q-td :props="props">
          <router-link class="item-link" v-if="canUse('device-dashboard')" :to="{ name: 'dashboard', query: { entityType: 'device', device: props.row.id } }">
            {{ props.row.serialNumber }}
            <sc-tooltip :text="`Go to ${props.row.serialNumber} dashboard`" />
          </router-link>
          <span v-else>
            {{ props.row.serialNumber }}
          </span>
        </q-td>
      </template>

    </sc-list>
  </div>
</template>

<style lang='scss' scoped>
</style>
