import { state, getters } from './buildings.state'
import { mutations } from './buildings.mutations'
import { actions } from './buildings.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
