<script>
import { mapState, mapActions } from 'vuex'
import { getDeviceLabel, EntityType } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { DashboardWidgets } from '../widgets'

export default {
  mixins: [
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  data () {
    return {
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Loading message
      loadingMessage: ''
    }
  },

  computed: {
    ...mapState({
      // Current user
      user: state => state.security.currentUser,
      // Viewed organization
      organization: state => state.dashboardView.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.dashboardView.organizationGuardian,
      // Viewed place
      place: state => state.dashboardView.place,
      // Currently viewed device
      device: state => state.dashboardView.device,
      // Pending premium service associated with device
      pendingPremiumService: state => state.dashboardView.pendingPremiumService
    }),

    // Physical components of the displayed device
    deviceParts () {
      return this.device?.parts || []
    },

    // Indicates that device is editable for the current user, commands can be sent to it etc.
    // Device is not editable when:
    // - it's only shared with user's organization
    // - organization is a guest organization
    // - user is a guest user
    isEditable () {
      const { device, currentOrganization, currentUser } = this
      return !(device?.isShared || currentOrganization.isGuestOrganization || currentUser.isGuestUser)
    },

    // Indicates whether the current user can edit device comments
    canEditComments () {
      const { isEditable, device, canUse } = this
      return isEditable && !device.isShared && canUse('device-management')
    }
  },

  methods: {
    ...mapActions([
      'gotoDevicePart',
      'setDeviceComments'
    ]),

    getDeviceLabel,

    // Breaks lines of text with HTML break
    breakLines (text) {
      return (text || '').replace(/\n/g, '<br>')
    },

    // Returns link to part dashboard
    getPartLink (part) {
      return {
        name: 'dashboard',
        query: {
          entityType: EntityType.Device,
          device: part.partOf,
          part: part.serialNumber
        }
      }
    }
  }
}
</script>

<template>
  <main class="container" v-if="device">
    <div class="multi-device q-gutter-md">

      <div class="box">

        <div class="header">
          <sc-widget-device-info :device="device" :isEditable="isEditable" :hasActions="false">
          </sc-widget-device-info>
        </div>

        <div class="boards">
          <sc-widget-device-card v-for="part in device.parts" dense :masterDevice="device"
            :device="part" :place="place" :organization="organization" :isEditable="isEditable"
            :isSelectable="false" :canComment="false" :cardLink="getPartLink(part)">
          </sc-widget-device-card>
        </div>

        <q-icon v-if="canEditComments || device.comments" class="device-comments" size="22px"
          :name="device.comments ? 'comment' : 'mode_comment'"
          :color="device.comments ? 'indigo-6' : 'grey-5'" @click.stop>

          <sc-tooltip :text="breakLines(device.comments) || 'Click to add notes ...'"></sc-tooltip>

          <q-popup-edit v-if="canEditComments" buttons self="bottom left" label-set="Save"
            :model-value="device.comments" v-slot="scope"
            :title="`Enter comments for ${device.acronym} ${device.serialNumber}`"
            @save="comments => setDeviceComments({ device, comments })">
            <q-input type="textarea" autofocus max-length="1000" v-model="scope.value"
              @keydown.enter.stop @keypress.enter.stop @keyup.enter.stop>
            </q-input>
          </q-popup-edit>
        </q-icon>

      </div>

      <sc-widget-device-subscriptions :device="device"
        :pendingPremiumService="pendingPremiumService" :organization="organization"
        :organizationGuardian="organizationGuardian" :isEditable="isEditable"
        v-if="organizationGuardian.mustUse('premium-services-buy')">
      </sc-widget-device-subscriptions>

    </div>
  </main>
</template>

<style scoped lang="scss">
.container {
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .multi-device {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >.box {
      background-color: white;
      padding: 8px 0 0 16px;
      position: relative;

      >.header {}

      >.device-comments {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
      }

      >.boards {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        >.widget,
        >.device-card {
          margin-right: 16px;
          margin-bottom: 16px;
        }
      }

    }
  }
}
</style>