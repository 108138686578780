<script>
import { mapState, mapGetters } from 'vuex'
import { getDurationString, stringCompare, capitalizeWords, sortItems } from '@stellacontrol/utilities'
import { getMegaValue, isMegaParameterApplicable, getMegaParameterLabel, getMegaParameterPermissions } from '@stellacontrol/mega'
import { DeviceStatusFeatures } from '@stellacontrol/devices'
import { Clipboard, Notification } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    Secure,
    DeviceWidget
  ],

  data () {
    return {
      // Indicates that we're showing raw MEGA values, not just the user-friendly extract
      showRawMega: false
    }
  },

  computed: {
    ...mapState({
      // Status watcher clock ticks, used to ensure refreshing of labels on each status arrival
      ticks: state => state.deviceStatus.ticks
    }),

    ...mapGetters([
      'guardian',
      'isMobilePhone'
    ]),

    // Available actions
    actions () {
      const { isMobilePhone } = this
      return [
        {
          name: 'toggle-raw-parameters',
          label: this.showRawMega ? 'Show Parameters' : 'Show raw MEGA',
          icon: 'visibility',
          color: 'indigo-6',
          permissions: ['device-parameters-mega']
        },
        isMobilePhone
          ? null
          : {
            name: 'copy',
            label: 'Copy to clipboard',
            icon: 'content_copy',
            color: 'indigo-6',
            permissions: []
          }
      ]
    },

    // MEGA
    mega () {
      return this.status?.mega
    },

    // Produce features to display
    productFeatureItems () {
      const { device, status } = this
      if (device && status?.identity) {
        const hasDeviceFeatures = isMegaParameterApplicable('product_features', device) && !status.features.isEmpty
        const items = hasDeviceFeatures
          ? Object
            .keys(DeviceStatusFeatures.Fields)
            .map(feature => ({
              label: feature,
              value: status.features[feature]
            }))
          : []
        return sortItems(items, 'label')
      } else {
        return []
      }
    },

    // Determines whether product features should be shown
    showProductFeatures () {
      return this.isSuperAdministrator && this.productFeatureItems.length > 0
    },

    // MEGA values to display
    items () {
      const { isSuperAdministrator, device, device: { protocol }, status, mega = {}, ticks } = this
      if (device && status && mega && ticks >= 0) {
        const items = [
          // TODO: old-style definitions => refactor as per below new-style to use MEGA definitions from @stellacontrol/mega package
          { label: 'ID', value: device.id, permissions: ['super-administrator'] },
          { label: 'Serial', value: device.serialNumber },
          { parameter: 'sw_version', label: 'FW', value: status.identity.firmwareVersionLong },
          { parameter: 'hw_version', label: 'HW', value: status.identity.hardwareVersion },
          isSuperAdministrator
            ? { label: 'Protocol', value: `${status.identity.protocol || protocol || ''} ${status.isPartial ? ' | delta' : ''}`, permissions: ['super-administrator'] }
            : { label: 'Protocol', value: status.identity.protocol || protocol, permissions: ['super-administrator'] },
          { parameter: 'product_type' },
          { parameter: 'product_model' },
          { parameter: 'product_bands' },
          { parameter: 'ip_server' },
          { parameter: 'ip_local' },
          { parameter: 'mac' },
          { parameter: 'sim_iccid' },
          { parameter: 'bypass' },
          { parameter: '_default_sampling_speed' },
          { label: 'Rebalance frequency', value: getDurationString(mega['_timer_long_mins'] * 60) },
          { parameter: 'rebalance_count' },
          { parameter: 'since_rebalance_secs', format: value => getDurationString(value, { suffix: ' ago' }) },
          { parameter: 'reboot_count_sw' },
          { parameter: 'reboot_count_hw' },
          { parameter: 'error_count' },
          { parameter: 'eeprom_save_cycle' },
          { parameter: 'watchdog_timer' },
          { parameter: 'temperature' },
          { parameter: 'battery' },
          { parameter: 'lcd_broken' },
          { parameter: '_rf_region' },
          { parameter: 'rf_disabled' },
          { parameter: 'gps' },
          { parameter: '_tft_hostname' },
          { parameter: 'uptime', label: 'Uptime', value: status.timings.uptimeString }
        ]

        return items
          // Fix labels, determine values, filter out empty values
          .filter(item => {
            if (!item) return false
            // Take parameter label from MEGA definitions, if not specified explicitly
            if (!item.label) {
              item.label = getMegaParameterLabel(item.parameter, device)
            }
            item.label = capitalizeWords(item.label, true)
            // Determine current value of the item
            if (item.parameter && !item.value) {
              const value = getMegaValue(item.parameter, mega, device, status)?.label
              item.value = item.format ? item.format(value, item.unit) : value
            }
            return item?.value != null && item.value != ''
          })
      } else {
        return []
      }
    },

    // Visible MEGA values
    availableItems () {
      const { device, status } = this
      return this.items
        // Check whether user has permission to see the MEGA parameter
        .filter(item => {
          const permissions = (item.parameter && !item.permissions)
            ? getMegaParameterPermissions(item.parameter, device)
            : item.permissions || []
          return this.guardian.canUseAll(permissions)
        })
        // Check if MEGA parameter is applicable to the currently displayed device
        .filter(item => item.parameter ? isMegaParameterApplicable(item.parameter, device, status) : true)
    },

    // raw MEGA values
    raw () {
      const { status, mega } = this
      if (status && mega) {
        const ignore = ['extra', 'received', 'parsed']
        const values = Object
          .entries(mega)
          .filter(([name]) => !ignore.includes(name))
          .map(([name, value]) => {
            const isCustomized = status.isCustomized(name)
            const isReconciled = status.isReconciled(name)
            const desiredValue = isReconciled === false ? status.custom[name] : undefined
            return {
              name,
              value,
              isCustomized,
              isReconciled,
              desiredValue
            }
          })
        values.sort((a, b) => stringCompare(a.name, b.name))
        return values
      }
    }
  },

  methods: {
    // Executes the action
    async executeAction (action) {
      switch (action.name) {
        case 'copy':
          await this.copyToClipboard()
          break

        case 'toggle-raw-parameters':
          this.showRawMega = !this.showRawMega
          break
      }
    },

    // Copies the table content to clipboard
    async copyToClipboard () {
      const { items, raw, device, showRawMega } = this
      const id = `Device:\t${device.acronym} ${device.serialNumber}`
      let lines

      if (showRawMega) {
        lines = [
          id,
          ...raw.map(item => `${item.name}:\t${item.value}`)
        ].join('\n')
      } else {
        lines = [
          id,
          ...items.map(item => `${item.label}:\t${item.value}${item.unit ? ' ' + item.unit : ''}`)
        ].join('\n')
      }

      await Clipboard.write(lines + '\n')
      Notification.success({ message: 'Device parameters have been copied to clipboard' })
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" :actions="actions" @action="executeAction"
    v-if="hasStatus" width="230px">
    <div class="parameters">
      <div class="parameters-header">
        Parameters
      </div>

      <div class="content">
        <div class="raw-parameters" v-if="showRawMega">
          <div class="item row items-center" v-for="(item, index) in raw" :key="index"
            :class="{ 'customized': item.isCustomized === true, 'not-reconciled': item.isReconciled === false }">
            <div class="name" :title="item.name">
              {{ item.name }}
            </div>
            <div v-if="item.isReconciled === false" class="value row items-center">
              <span>
                {{ item.desiredValue }}
              </span>
              <q-icon name="arrow_right" color="grey-7" size="sm" class="q-ml-xs q-mr-xs pulse">
              </q-icon>
              <span>
                {{ item.value }}
              </span>
            </div>
            <div v-else class="value">
              {{ item.value }}
            </div>
          </div>
        </div>

        <div class="parsed-parameters" v-else>
          <div class="item" v-for="(item, index) in availableItems" :key="index">
            <span class="name" :title="item.label">
              {{ item.label }}
            </span>
            <span class="value">
              {{ item.value }} {{ item.unit }}
            </span>
          </div>

          <div class="q-mt-md product-features" v-if="showProductFeatures">
            <div class="parameters-header">
              Product Features
            </div>

            <div class="item q-ml-sm" v-for="(item, index) in productFeatureItems" :key="index"
              :class="{ present: item.value, absent: !item.value }">
              <span class="name" :title="item.label">
                {{ item.label }}
              </span>
              <span class="value">
                {{ item.value ? 'Yes' : 'No' }}
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.parameters {
  padding: 8px 0px 8px 12px;
  background-color: white;
  overflow: hidden;

  .parameters-header {
    font-size: 13px;
    font-weight: bold;
  }

  .content {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
  }

  .parsed-parameters,
  .raw-parameters,
  .product-features {
    .item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 6px 0 6px 0;
      border-bottom: solid #dddddd 1px;

      &:hover {
        background-color: #fafafa;
      }

      &.customized {
        background-color: #fff9c4;

        &:hover {
          background-color: #f7efaa;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .name {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #4a4a4a;
        padding-left: 2px;
      }

      .value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #242424;
        padding-right: 2px;
      }

      &.present {

        .name,
        .value {
          color: #304ffe;
        }
      }

      &.absent {

        .name,
        .value {
          color: #7a7a7a;
        }
      }
    }
  }
}

@keyframes pulse-horizontal {
  from {
    transform: translateX(-2px);
  }

  to {
    transform: translateX(2px);
  }
}

.pulse {
  animation: pulse-horizontal 0.75s alternate-reverse infinite ease-in-out;
}

/* Layout adjustments for mobile phones */
@media screen and (max-width: 640px) {
  .parameters {
    padding: 4px;

    .content {
      height: auto;
    }
  }
}
</style>
