<script>
import { DeviceBandColor } from '@stellacontrol/model'
import { range } from '@stellacontrol/utilities'
import { getBandLabel, getBandFrequencyLabel } from '@stellacontrol/model'
import { getMegaParameter } from '@stellacontrol/mega'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  props: {
    // Compact view
    dense: {
      type: Boolean,
      default: false
    },

    // Mini view for the card
    micro: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // LEDs present on the device
    LEDs () {
      const parameter = getMegaParameter('signal_leds', this.device, this.status)
      const LEDs = Array.from(range(0, parameter.max + 1))
      return LEDs
    },

    /**
     * Bands status parsed from MEGA
     */
    bandStatus () {
      return this.status?.bands
    },

    /**
     * Band identifiers
     */
    bandIdentifiers () {
      return this.bandStatus?.identifiers || []
    },

    /**
     * Returns true if any bands are available in the status
     */
    hasBands () {
      return this.bandIdentifiers.length > 0
    },

    /**
     * Status of device bands
     */
    bands () {
      return this.bandStatus?.status || {}
    },

    /**
     * Other details about states of device bands
     */
    details () {
      return this.bandStatus?.details || {}
    },

    /**
     * User-friendly and device-family-specific label of a band
     * @param identifier Band identifier
     */
    bandLabel () {
      const family = this.device?.family
      return identifier => getBandLabel(identifier, family)
    },

    /**
     * User-friendly and device-family-specific label of band's frequency
     * @param identifier Band identifier
     */
    bandFrequencyLabel () {
      const family = this.device?.family
      return identifier => getBandFrequencyLabel(identifier, family)
    },

    /**
     * Short label representing the general state of the band
     */
    bandStatusLabel () {
      return identifier => {
        const band = this.details[identifier]
        return (band ? band.label : 'N/A') || 'Status unknown'
      }
    },

    /**
     * Details of the state of the band
     */
    bandStatusDetails () {
      return identifier => {
        const band = this.details[identifier]
        return (band ? band.details || band.description : '') || ''
      }
    },

    /**
     * CSS style of band status indicator
     */
    bandIndicatorStyle () {
      return identifier => {
        const band = this.details[identifier]
        return band
          ? {
            'background-color': band.color,
            'border': band.borderColor ? `solid ${band.borderColor} 2px` : 'none'
          }
          : {
          }
      }
    },

    /**
     * CSS style of dot status indicator (`micro` mode)
     */
    dotIndicatorStyle () {
      return identifier => {
        const band = this.details[identifier]
        return band?.isError
          ? {
            'background-color': DeviceBandColor.Error,
            'border': 'none'
          }
          : {}
      }
    },

    /**
     * Indicates if the specified band is in uplink status
     */
    isUplink () {
      return identifier => this.bands[identifier].isUplink
    },

    /**
     * Checks if specified led on the band is on.
     * Led index starts from 1.
     */
    isLedOn () {
      return (identifier, index) => {
        const band = this.bands[identifier]
        return band.ledCount >= index + 1
      }
    },

    /**
     * Style of a band tooltip with status details
     */
    bandStatusTooltipStyle () {
      return identifier => ({
        identifier: true,
        'transform': 'translate(67px, -13px)',
        'font-size': '13px',
        'background-color': this.details[identifier].color,
        'color': 'white',
        'border-radius': 0
      })
    },

    // Returns a value of a parameter for the specified band with unit and a descriptive text
    describedParameter () {
      return (text, identifier, name, unit) => {
        const { bands, device, status } = this
        if (bands) {
          const values = bands[identifier].values
          let value = values[name]
          if (value == null || value === 0)
            return text

          // Add paired values
          /*for (const [param, pairedParam] of this.pairedParameters) {
            if (name === param && isMegaParameterApplicable(pairedParam, device, status)) {
              value = `${value} <span class="text-orange-9">${values[pairedParam]}</span>`
            }
          }*/
          return `${text} ${value} ${unit}`;
        }
      }
    }
  }
}
</script>

<template>
  <sc-widget padding="0px" v-bind="{ ...$props, ...$attrs }" v-if="hasBands" :width="micro ? '172px' : '360px'">
    <div class="signal-levels column" :class="{ dense, micro }">
      <div>
        <q-markup-table flat :bordered="false" square separator="none" class="dots">
          <tbody>
            <tr v-for="identifier in bandIdentifiers" :key="identifier">
              <td>
                <div class="row items-center">
                  <span v-if="!micro">
                    <sc-hint size="16px" :text="bandFrequencyLabel(identifier)">
                    </sc-hint>
                  </span>
                  <span>
                    {{ bandLabel(identifier) }}
                  </span>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="dot" v-for="index in LEDs" :key="index"
                    :class="{ on: isLedOn(identifier, index) }">
                    <sc-tooltip
                      :text="describedParameter('Strength of the signal as reported by the device and matching device LEDs.', identifier, 'peak_dw', 'dBm')" />
                  </div>

                  <div class="dot square" :class="{ active: isUplink(identifier) }">
                    <sc-tooltip v-if="isUplink(identifier)"
                      :text="describedParameter('Indicates usage of the band by the nearby phones and communication devices.', identifier, 'peak_up', 'dBm')" />
                  </div>

                  <div v-if="micro" class="dot square" :style="dotIndicatorStyle(identifier)">
                    <sc-tooltip
                      :text="describedParameter(bandStatusDetails(identifier), identifier, 'extra_perm', 'dB')" />
                  </div>
                </div>
              </td>

              <td v-if="!micro">
                <div class="indicator" :style="bandIndicatorStyle(identifier)">
                  {{ bandStatusLabel(identifier) }}
                  <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
                    self="top middle" />
                </div>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.signal-levels {
  padding: 8px;

  .dots {
    td {
      padding: 4px 0 4px 4px;
    }

    .dot {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      margin-right: 4px;
      border: solid #bebebe 1px;
      background-color: white;

      &.on {
        border: solid #2ECD6F 1px;
        background-color: #2ECD6F;
      }

      &.active {
        background-color: #5991fa;
        border: solid #5991fa 1px;
      }
    }

    .indicator {
      height: 22px;
      width: 110px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 11px;
      border-radius: 4px;
    }
  }

  &.micro {
    padding: 2px;

    .dots {
      background-color: transparent;

      td {
        height: 24px;
        padding: 4px;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 2px;
        &.square {
          border-radius: 0px;
        }
      }
    }
  }

  &.dense {
    padding: 8px;

    .dots {
      td {
        height: 36px;
        padding: 4px;
      }

      .dot {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        margin-right: 6px;
      }

      .indicator {
        height: 22px;
        width: 110px;
        font-size: 11px;
      }
    }
  }
}
</style>
