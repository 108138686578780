import { stringCompare, numberCompare } from '@stellacontrol/utilities'
import { Place, sortPlaces, PlaceSortOrder } from '@stellacontrol/model'
import { ListViewMode } from '@stellacontrol/client-utilities'

/**
 * Places table columns
 */
const placeColumns = [
  { name: 'type', label: 'Type', field: 'placeType', sortable: true },
  { name: 'name', label: 'Description', field: 'fullText', sortable: true },
  { name: 'deviceCount', label: '# of Devices', field: 'deviceCount', sortable: true },
  { name: 'actions', label: '', sortable: false }
]

export function createState () {
  return {
    /**
     * Organization being viewed
     */
    organization: null,
    /**
     * Place within the organization being viewed
     */
    place: null,
    /**
     * Empty place, for displaying devices not assigned to any place
     */
    noPlace: new Place(Place.NoPlace),
    /**
     * Virtual place, for displaying devices shared with organization
     */
    sharedPlace: new Place(Place.SharedPlace),
    /**
     * Places of the organization
     */
    places: [],
    /**
     * Places table columns
     */
    placeColumns,
    /**
     * Places and devices filter
     */
    filter: null,
    /**
     * Devices visible to organization - own and shared ones
     */
    devices: [],
    /**
     * Devices owned by organization
     */
    ownDevices: [],
    /**
     * Devices shared with organization
     */
    sharedDevices: [],
    /**
     * Selected devices
     */
    selectedDevices: [],
    allDevicesSelected: false,
    /**
     * List of expanded places
     */
    expandedPlaces: [],
    /**
     * List of collapsed place groups -
     * tuples of { placeId, group } with identifier of a place and name of a group
     */
    collapsedPlaceGroups: [],
    /**
     * View mode for devices in places
     */
    devicesListViewMode: ListViewMode.MiniCards
  }
}

export const state = createState()

export const getters = {
  /**
   * All places, including no-place container, sorted by order
   */
  allOrganizationPlaces: state => {
    if (state.place) {
      // Viewing a selected place of the organization
      return [state.place]
    } else {
      // Viewing all places of the organization
      const places = sortPlaces(state.places, PlaceSortOrder.Custom)
      return [
        state.noPlace,
        state.sharedDevices.length > 0 ? state.sharedPlace : undefined,
        ...places
      ].filter(p => p)
    }
  },

  /**
   * Returns devices in the specified place.
   * Optionally only those which are full fledged products,
   * not just parts of a multi-device unit.
   * If place is not specified or has ID equal to NO_PLACE,
   * returns devices not assigned to any place
   */
  getPlaceDevices: state =>
    (place, onlyProducts) => getPlaceDevices(state.devices, place, onlyProducts),

  /**
   * Returns groups in the specified place.
   */
  getPlaceGroups: state =>
    place => getPlaceGroups(state.devices, place),

  /**
   * Returns true if place has any devices
   */
  placeHasDevices: (state, getters) =>
    place => getters.getPlaceDevices(place).length > 0,

  /**
   * Returns true if place has any devices
   */
  placeHasDevice: (state, getters) =>
    (place, serialNumber) => getters
      .getPlaceDevices(place)
      .some(d => d.serialNumber.toLowerCase().includes(serialNumber) ||
        (d.name || '').toLowerCase().includes(serialNumber)),

  /**
   * Returns true if the specified place is expanded
   */
  isPlaceExpanded: (state, getters) =>
    place => {
      return state.expandedPlaces.includes(place.id) &&
        getters.placeHasDevices(place)
    }
}

/**
 * Returns devices belonging to the specified place,
 * with respect to virtual places such as NO PLACE and SHARED DEVICES
 * @param devices Devices to filter
 * @param place Place to filter with
 * @param onlyProducts If true, only those devices which are full fledged products are returned, not just parts of a multi-device unit.
 */
export function getPlaceDevices (devices, place, onlyProducts) {
  const noPlace = (!place || !place.id || place.id === 'none')
  const sharedPlace = (place && place.id === 'shared')
  let result
  if (sharedPlace) {
    // Devices shared with organization
    result = devices.filter(device => device.isShared)
  } else if (noPlace) {
    // Own devices, not assigned to any place
    result = devices.filter(device => !device.placeId && !device.isShared)
  } else {
    // Own devices, assigned to specified place
    result = devices.filter(device => device.placeId === place.id && !device.isShared)
  }
  result.sort((a, b) =>
    numberCompare(a.sortOrder, b.sortOrder) || stringCompare(a.serialNumber, b.serialNumber, false))

  return onlyProducts
    ? result?.filter(d => !d.partOf)
    : result
}

/**
 * Returns all place groups of the specified devices
 * @param devices Devices
 */
export function getPlaceGroups (devices) {
  const placeGroups = devices
    .map(d => d.placeGroup)
    .filter(g => (g || '').trim())
    .map(g => g.trim())
  return Array.from(new Set(placeGroups))
}
