import { Log, padLeft, capitalize } from '@stellacontrol/utilities'
import { ListViewMode, Notification } from '@stellacontrol/client-utilities'
import { DeviceAPI, CommonAPI, LegacyAPI, ServiceManagementAPI, DeviceTransmissionAPI } from '@stellacontrol/client-api'
import { Tag, TagCategory, Tags, Device, DeviceType, DeviceLink, DeviceLinkType, StandardDeviceBands, Note, NoteCategory, Place, getDevicesDescription, UploadType } from '@stellacontrol/model'
import { DeviceAudit } from '@stellacontrol/devices'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the devices applet
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'places', sortBy: 'name', viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * When session ends, stop any ongoing device status and settings retrieval
   */
  async endSession ({ dispatch }) {
    await dispatch('unsubscribeDeviceStatus')
  },

  /**
   * Navigates to device inventory page
   * @param {Array[String]} selection Initially selected devices, specified as list of serial numbers
   */
  async gotoInventory ({ dispatch }, { selection } = {}) {
    dispatch('gotoRoute', {
      name: AppletRoute.Inventory,
      query: {
        selection: selection ? selection.join(',') : undefined
      }
    })
  },

  /**
   * Returns a specified device
   * @param {String} id Device identifier
   * @param {Boolean} withDetails If true, device details are retrieved such as place where it belongs etc.
   * @param {Boolean} withParents If true, device owner, creator, organization and all other such details are retrieved.
   * @returns {Promise<Device>}
   */
  async getDevice (_, { id, withDetails = false, withParents = false } = {}) {
    const device = await DeviceAPI.getDevice({ id, withDetails, withParents })
    return device
  },

  /**
   * Returns a specified device by serial number
   * @param {String} serialNumber Device serial number
   * @param {Boolean} withDetails If true, device details are retrieved such as place where it belongs etc.
   * @param {Boolean} withParents If true, device owner, creator, organization and all other such details are retrieved.
   * @returns {Promise<Device>}
   */
  async getDeviceBySerialNumber (_, { serialNumber, withDetails = false, withParents = false } = {}) {
    const device = await DeviceAPI.getDeviceBySerialNumber({ serialNumber, withDetails, withParents })
    return device
  },

  /**
   * Returns all devices accessible to the specified organization,
   * including those of child organizations
   */
  async getDevices ({ commit, getters, state }, { organization = {} } = {}) {
    const { currentOrganization } = getters
    const { id } = organization
    const { refresh } = state
    const devices = await DeviceAPI.getAvailableDevices({ id, refresh })
    const { organizationsDictionary, allUsersDictionary, allPlacesDictionary, premiumServicesDictionary } = getters

    // Populate related data
    for (const device of devices) {
      device.place = allPlacesDictionary[device.placeId]
      device.premiumService = premiumServicesDictionary[device.premiumServiceId]
      for (const link of device.links) {
        link.principal = organizationsDictionary[link.principalId]
      }
      for (const note of device.notes) {
        note.creator = allUsersDictionary[note.createdBy]
        note.updater = allUsersDictionary[note.updatedBy]
      }
    }

    // Store the devices
    if (!id || id === currentOrganization.id) {
      commit('storeDevices', { devices, currentOrganization })
    }

    return devices
  },

  /**
   * Retrieves all devices accessible to the current organization
   * if not retrieved yet
   */
  async requireDevices ({ state, dispatch }) {
    return state.hasDevices && !state.refresh
      ? state.devices
      : await dispatch('getDevices')
  },

  /**
   * Returns all devices owned by the current or specified organization
   */
  async getOwnDevices ({ commit, getters, state }, { organization = {} } = {}) {
    const { currentOrganization } = getters
    const { refresh } = state
    const { id } = organization
    const devices = await DeviceAPI.getOwnDevices({ id, refresh })
    if (!id || organization.id === currentOrganization.id) {
      commit('storeOwnDevices', { devices, currentOrganization })
    }
    return devices
  },

  /**
   * Retrieves all devices owned by the current organization
   * if not retrieved yet
   */
  async requireOwnDevices ({ state, dispatch }) {
    return state.hasOwnDevices ? state.ownDevices : await dispatch('getOwnDevices')
  },

  /**
   * Returns all devices shared with the current or specified organization
   */
  async getSharedDevices ({ commit, getters }, { organization = {} } = {}) {
    const { currentOrganization } = getters
    const { id } = organization
    const devices = await DeviceAPI.getSharedDevices({ id })
    if (!id || organization.id === currentOrganization.id) {
      commit('storeSharedDevices', { devices, currentOrganization })
    }
    return devices
  },

  /**
   * Retrieves all devices shared with the current organization
   * if not retrieved yet
   */
  async requireSharedDevices ({ state, dispatch }) {
    return state.hasSharedDevices ? state.sharedDevices : await dispatch('getSharedDevices')
  },

  /**
   * Retrieves all known device types, firmwares, hardwares, models etc.
   * @param {String} minFirmwareVersion Minimal required firmware version for the interrogated devices, optional
   * @param {String} maxFirmwareVersion Maximal required firmware version for the interrogated devices, optional
   * @returns Dictionary of all known device types, firmwares, hardwares, models etc.
   */
  async getDeviceTypes ({ commit }, { minFirmwareVersion, maxFirmwareVersion } = {}) {
    const { types, models, firmwares, hardwares } = await DeviceAPI.getDeviceTypes({ minFirmwareVersion, maxFirmwareVersion })
    if (!(minFirmwareVersion || maxFirmwareVersion)) {
      commit('storeDeviceTypes', { types, models, firmwares, hardwares })
    }
    return { types, models, firmwares, hardwares }
  },

  /**
   * Updates a batch of devices in the state
   * @param {Array[Device]} devices Devices to update
   */
  async updateDevices ({ commit }, { devices = [] }) {
    commit('updateDevices', { devices })
  },

  /**
   * Refreshes a device in the state
   * @param {String} id Identifier of a device to refresh
   * @param {String} serialNumber Alternatively, serial number of a device to refresh
   */
  async refreshDevice ({ commit }, { id, serialNumber }) {
    const device = id
      ? await DeviceAPI.getDevice({ id, withDetails: true })
      : await DeviceAPI.getDeviceBySerialNumber({ serialNumber, withDetails: true })
    if (device) {
      commit('updateDevices', { devices: [device] })
      commit('deviceRefreshed', { device })
    }
  },

  /**
   * Stores a device, updates the state
   * @param {Device} device Device to save
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Device>} Updated device
   */
  async saveDevice ({ commit, dispatch }, { device, silent } = {}) {
    if (device) {
      await dispatch('busy', { message: `Saving device ${device.serialNumber}, please wait ...`, silent })
      const isNew = device.isNew
      const savedDevice = await DeviceAPI.saveDevice({ device })
      if (savedDevice) {
        const { parts = [] } = savedDevice
        await dispatch('updateDevices', { devices: [savedDevice, ...parts] })
        if (isNew) {
          commit('deviceAddedToInventory', { device: savedDevice })
        }
      }
      await dispatch('done', { silent })
      return savedDevice
    }
  },

  /**
   * Stores a batch of devices, updates the state
   * @param {Array[Device]} devices Devices to save
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async saveDevices ({ commit, dispatch }, { devices = [], silent } = {}) {
    const savedDevices = []
    const failedDevices = []
    const existingDevices = []

    if (devices.length > 0) {
      let message = `Saving ${devices.length} device${devices.length === 1 ? '' : 's'}, please wait ...`
      await dispatch('busy', { message, silent })

      for (let device of devices || []) {
        const isNew = device.isNew
        try {
          if (isNew) {
            // Save new device - but check whether the serial number already exists!
            const { serialNumber } = device
            const { id, exists } = await DeviceAPI.deviceExists({ serialNumber })
            if (exists) {
              device.id = id
              existingDevices.push(device)
            } else {
              const savedDevice = await DeviceAPI.saveDevice({ device })
              savedDevices.push(savedDevice)
              commit('deviceAddedToInventory', { device: savedDevice })
            }
          } else {
            // Save existing device
            const savedDevice = await DeviceAPI.saveDevice({ device })
            savedDevices.push(savedDevice)
          }
        } catch (error) {
          Log.error(error)
          device.error = error
          failedDevices.push(device)
        }
      }

      message = `${savedDevices.length} device${savedDevices.length === 1 ? '' : 's'} added to inventory`
      await dispatch('done', { message, silent })
    }

    await dispatch('updateDevices', { devices: savedDevices })

    return {
      savedDevices,
      failedDevices,
      existingDevices
    }
  },

  /**
   * Checks whether the specified devices exist,
   * returns a list of those which do
   * @param {Array[Device]} devices Devices to check
   * @returns {Array[Device]} Devices which are exist in the inventory
   */
  async devicesExist (_, { devices = [] } = {}) {
    return DeviceAPI.devicesExist({ devices })
  },

  /**
   * Splits a multi-device
   * @param {Device} device Device to split
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Device>} Updated device
   */
  async splitMultiDevice ({ dispatch }, { device, silent }) {
    if (device && device.parts) {
      const { parts } = device
      await dispatch('busy', { message: `Saving device ${device.serialNumber}, please wait ...`, silent })
      const savedDevice = await DeviceAPI.splitMultiDevice({ device })
      if (savedDevice) {
        for (const part of parts) {
          part.partOf = undefined
        }
        await dispatch('updateDevices', { devices: [savedDevice, ...parts] })
        await dispatch('done', { silent })
        return savedDevice
      }
    }
  },

  /**
   * Adds a part to a multi-device
   * @param {Device} device Multi-device
   * @param {Device} part Part to add to the device
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Device>} Updated device
   */
  async addPartToMultiDevice ({ dispatch }, { device, part, silent }) {
    if (device && part) {
      await dispatch('busy', { message: `Saving device ${device.serialNumber}, please wait ...`, silent })
      const savedDevice = await DeviceAPI.addPartToMultiDevice({ device, part })
      if (savedDevice) {
        part.partOf = device.id
        await dispatch('updateDevices', { devices: [savedDevice, part] })
        await dispatch('done', { silent })
        return savedDevice
      }
    }
  },

  /**
   * Removes a part from a multi-device
   * @param {Device} device Multi-device
   * @param {Device} part Part to remove from the device
   * @param {Boolean} silent If true, notifications will not be shown to the user
   * @returns {Promise<Device>} Updated device
   */
  async removePartFromMultiDevice ({ dispatch }, { device, part, silent }) {
    if (device && part) {
      await dispatch('busy', { message: `Saving device ${device.serialNumber}, please wait ...`, silent })
      const savedDevice = await DeviceAPI.removePartFromMultiDevice({ device, part })
      if (savedDevice) {
        part.partOf = null
        await dispatch('updateDevices', { devices: [savedDevice, part] })
        await dispatch('done', { silent })
        return savedDevice
      }
    }
  },

  /**
   * Imports a legacy device, updates the state
   * @param {Device} device Device to import
   * @param {Organization} organization Organization owning the device
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async importLegacyDevice ({ commit, dispatch }, { device, organization, silent } = {}) {
    const { serialNumber } = device
    let message = `Importing device ${serialNumber}, please wait ...`
    await dispatch('busy', { message, silent })
    let importError

    const isNew = !device.id
    let savedDevice

    try {
      if (isNew) {
        // Check whether the serial number already exists!
        const { exists, id } = await DeviceAPI.deviceExists({ serialNumber })
        if (exists) {
          device.id = id
        } else {
          const result = await LegacyAPI.importLegacyDevice({ device, organization }) || { error: 'Unknown error' }
          if (result.device) {
            savedDevice = result.device
            commit('deviceAddedToInventory', { device: savedDevice })
          } else {
            Log.error(`Error while importing ${serialNumber}: ${result.error || 'unknown error'}`)
            importError = new Error(`Device API error: ${result.error || 'Unknown error'}`)
          }
        }
      }
    } catch (error) {
      Log.error(error)
      importError = error
    }

    message = savedDevice
      ? `Device ${serialNumber} added to inventory`
      : `Device ${serialNumber} could not be added to inventory`

    await dispatch('done', {
      message: importError ? undefined : message,
      error: importError ? message : undefined,
      silent
    })

    await dispatch('updateDevices', { devices: [savedDevice] })

    return { device: savedDevice, error: importError }
  },

  /**
   * Deletes a device
   * @param {Device} device Device to delete
   * @param {Boolean} silent If true, notifications will not be shown to the user
   */
  async deleteDevice ({ commit, dispatch }, { device, silent }) {
    if (device) {
      if (!device.canBeDeleted) {
        Notification.error({
          message: `Device ${device.serialNumber} cannot be deleted. It must be decommissioned by administrator.`,
          silent
        })
        return
      }
      await dispatch('busy', { message: `Deleting ${device.acronym} ${device.serialNumber} ...`, silent })
      await DeviceAPI.deleteDevice({ device })
      await dispatch('done', { message: `${device.acronym} ${device.serialNumber} has been deleted.`, silent })
      commit('deviceDeleted', { device })
    }
  },

  /**
   * Adds tag on a device
   * @param {Device} device Device to add the tag on
   * @param {User} user User who adds the tag
   */
  async addDeviceTag ({ commit }, { device, tag } = {}) {
    tag.entityId = device.id
    tag.category = TagCategory.Device
    const savedTag = await CommonAPI.saveTag({ tag })
    commit('addDeviceTag', { device, tag: savedTag })
  },

  /**
   * Removes tag from a device
   * @param {Device} device Device to remove the tag from
   * @param {Tag} tag Tag to remove
   */
  async removeDeviceTag ({ commit }, { device, tag } = {}) {
    const tagToDelete = device.getTag(tag)
    if (tagToDelete) {
      await CommonAPI.deleteTag({ tag: tagToDelete })
      commit('removeDeviceTag', { device, tag: tagToDelete })
    }
  },

  /**
   * Toggles device as favorite
   * @param device Device to toggle as favorite
   * @param user User who (un)favorites the device
   */
  async toggleFavoriteDevice ({ dispatch }, { device, user } = {}) {
    const tag = new Tag({
      category: TagCategory.device,
      name: Tags.Favorite,
      userId: user.id
    })
    if (device.hasTag(tag)) {
      await dispatch('removeDeviceTag', { device, tag })
    } else {
      await dispatch('addDeviceTag', { device, tag })
    }
  },

  /**
   * Sells devices to a new owner
   * @param {Array[Device]} devices Devices to sell
   * @param {Organization} organization New owner of the devices
   * @param {Date} soldAt Date and time of the sale
   * @param {String} notes Additional notes
   * @param {String} premiumServiceId Premium service to grant to the device, free of charge
   */
  async sellDevices ({ commit, dispatch, getters }, { devices, organization, soldAt = new Date(), notes, premiumServiceId } = {}) {
    if (devices && devices.length > 0) {
      if (!organization) throw new Error('Organization is not specified')

      const message = `Changing owner of ${getDevicesDescription(devices)}...`
      const successMessage = `${organization.name} is a new owner of ${getDevicesDescription(devices)}`
      await dispatch('busy', { message })

      // Sell devices
      for (let device of devices) {
        device = await DeviceAPI.sellDevice({ device, organization, soldAt, notes, premiumServiceId })
        commit('sellDevice', { device, user: getters.currentUser })
        commit('updateDevice', { device })
      }

      // Make sure that any premium subscriptions associated with the sold devices
      // are migrated to their new owner, so the device continues enjoying access to premium services
      await ServiceManagementAPI.migrateDeviceSubscriptions({ devices })

      await dispatch('done', { message: successMessage })
    }
  },

  /**
   * Links devices with organization
   * @param devices Devices to link
   * @param organization New delegate of the devices
   * @param type Link type, assumed to be delegate unless specified otherwise
   * @param notes Additional notes
   */
  async linkDevices ({ commit, dispatch }, { devices, organization, type = DeviceLinkType.Delegate, notes } = {}) {
    if (devices && devices.length > 0) {
      if (!organization) throw new Error('Organization is not specified')

      const message = `Sharing ${getDevicesDescription(devices)} devices with ${organization.name} ...`
      const successMessage = `${capitalize(getDevicesDescription(devices))} been shared with ${organization.name}`
      await dispatch('busy', { message })
      for (let device of devices) {
        device = await DeviceAPI.linkDevice({ device, organization, type, notes })
        commit('updateDevice', { device })
      }

      await dispatch('done', { message: successMessage })
    }
  },

  /**
   * Unlinks devices from organization
   * @param devices Devices to unlink
   * @param organization Delegate of the devices
   * @param type Link type, assumed to be delegate unless specified otherwise
   * @param notes Additional notes
   */
  async unlinkDevices ({ commit, dispatch }, { devices, organization, type = DeviceLinkType.Delegate, notes } = {}) {
    if (devices && devices.length > 0) {
      if (!organization) throw new Error('Organization is not specified')

      const message = `Revoking access to ${getDevicesDescription(devices)} from ${organization.name} ...`
      const successMessage = `${organization.name} can no longer access ${getDevicesDescription(devices)}`
      await dispatch('busy', { message })
      for (let device of devices) {
        device = await DeviceAPI.unlinkDevice({ device, organization, type, notes })
        commit('updateDevice', { device })
        commit('unlinkDevice', { device, organization, type, notes })
      }

      await dispatch('done', { message: successMessage })
    }
  },

  /**
   * Decommissions device from organization
   * @param devices Device to decommission
   * @param notes Additional notes
   */
  async decommissionDevices ({ commit, dispatch }, { devices, decommissionedOn = new Date(), notes } = {}) {
    if (devices && devices.length > 0) {
      const message = `Removing ${devices.length === 1 ? 'device ' + devices[0].serialNumber : devices.length.toString() + ' devices'} from inventory ...`
      const successMessage = `${devices.length === 1 ? 'Device ' + devices[0].serialNumber + ' has been' : 'The devices have been'} removed from inventory`
      await dispatch('busy', { message })
      for (let device of devices) {
        device = await DeviceAPI.decommissionDevice({ device, decommissionedOn, notes })
        commit('updateDevice', { device })
      }

      await dispatch('done', { message: successMessage })
    }
  },

  /**
   * Resets the device, by clearing customer-bound and user-bound properties,
   * links, notes, tags etc.
   * @param devices Device to reset
   */
  async resetDevices ({ commit, dispatch }, { devices } = {}) {
    if (devices && devices.length > 0) {
      const message = `Resetting ${devices.length === 1 ? 'device ' + devices[0].serialNumber : devices.length.toString() + ' devices'}  ...`
      const successMessage = `${devices.length === 1 ? 'Device ' + devices[0].serialNumber + ' has been' : 'The devices have been'} reset`
      await dispatch('busy', { message })
      for (let device of devices) {
        device = await DeviceAPI.resetDevice({ device })
        commit('updateDevice', { device })
      }

      await dispatch('done', { message: successMessage })
    }
  },

  /**
   * Swaps a device with a replacement device which will take over all settings and associated data.
   * @param {Device} device Device to replace
   * @param {Device} replaceWith Replacement device
   * @param {Boolean} copyDeviceSettings If true, the relevant technical parameters of the old device are applied to the replacement device
   * @param {Boolean} copyPlace If true, the replacement device is assigned to the same place as the old device
   * @param {Boolean} copyAlerts If true, alert configuration of the old device is applied to the replacement device
   * @param {Boolean} copyPremiumServices If true, premium services of the old device are transferred to the replacement device
   * @param {Boolean} copyComments If true, notes associated with the old device will be moved to the replacement device
   * @param {String} notes Notes to register in the audit log
   */
  async swapDevice ({ commit, dispatch }, { device, replaceWith, copyDeviceSettings, copyPlace, copyAlerts, copyPremiumServices, copyComments, notes }) {
    if (device && replaceWith && device.serialNumber !== replaceWith.serialNumber) {
      if (copyDeviceSettings) {
        await dispatch('loading', { message: `Copying device settings to  ${replaceWith.label} ...` })
        await dispatch('copyDeviceSettings', { source: device, target: replaceWith, silent: true })
        await dispatch('done')
      }

      const message = `Swapping ${device.label} with ${replaceWith.label} ...`
      await dispatch('loading', { message })
      const result = await DeviceAPI.swapDevice({ device, replaceWith, copyPlace, copyAlerts, copyPremiumServices, copyComments, notes })

      if (result?.device && result?.replaceWith) {
        commit('swapDevice', { device: result.device, replaceWith: result.replaceWith })
        await dispatch('done')
        const message = `${device.label} has been swapped with ${replaceWith.label}`
        Notification.success({ message })

        // Refresh devices list
        await dispatch('getDevices')

      } else {
        await dispatch('done')
        const message = `${device.label} could not be swapped with ${replaceWith.label}`
        Notification.error({ message })
      }
    }
  },

  /**
   * Saves a device note
   * @param {Device} device Device
   * @param {Note} note Note to save
   * @returns {Promise<Note>} Saved note
   * @description If note text is empty, the note will be removed
   */
  async saveDeviceNote ({ commit, getters }, { device, note } = {}) {
    if (device && note) {
      if (note.isNew) {
        note.id = undefined
      }
      note.entityId = device.id
      note.category = NoteCategory.Device
      const { currentUser: user } = getters
      const savedNote = await CommonAPI.saveNote({ note })
      if (savedNote) {
        commit('saveDeviceNote', { device, note: savedNote, user })
        note.id = savedNote.id
      } else {
        commit('removeDeviceNote', { device, note, user })
      }

      return savedNote
    }
  },

  /**
   * Removes a device note
   * @param device Device
   * @param note Note to remove
   */
  async removeDeviceNote ({ commit, getters }, { device, note } = {}) {
    if (device && note) {
      const { currentUser: user } = getters
      if (note.id) {
        await CommonAPI.deleteNote({ note })
      }
      commit('removeDeviceNote', { device, note, user })
    }
  },

  /**
   * Retrieves a chronological audit of the specified device
   * @param {Device} device Device details, including links, versions, creator, updater etc.
   * @param {User} user User viewing the audit
   * @param {Organization} organization Organization where the user belongs
   * @param {Boolean} descending If true, the audit is sorted in descending order
   * @returns {Array[DeviceAudit]} Device audit
   */
  async getDeviceAudit (_, { device: { id } = {}, user, organization, descending } = {}) {
    if (!id) throw new Error('Device is required')
    const device = await DeviceAPI.getDevice({ id, withDetails: true, withParents: true })
    if (device) {
      const audit = await CommonAPI.getAuditTrail({ subjectId: device.id })
      const firmwareUpdates = await DeviceTransmissionAPI.getUploadJobs({ type: UploadType.Firmware, device })
      return DeviceAudit.create({ device, user, organization, audit, firmwareUpdates, descending })
    }
  },

  /**
   * Retrieves device status entries recorded during the specified period, optionally with values
   * @param {String} id Identifier number of a device to query
   * @param {String} serialNumber Alternatively, serial number of a device to query
   * @param {Date} from Period start
   * @param {Date} to Period end (exclusive)device, query, history* @param {Boolean} withStatus If specified, device status reported by the device will be returned
   * @param {Number} decimate If present, excessive data will be decimated to retain the specified number of status data points
   * @param {Boolean|Array[String]} withParameters If specified, also the values recorded with the status will be returned.
   * If `true` passed, all the values will be returned, otherwise the parameters with the specified names or keys.
   * Keys are particularly useful for retrieving band variables. Instead of specifying them one by one, i.e.
   * 'mgn_dw_08', 'mgn_dw_18' one can just pass the key 'mgn_dw' and obtain the entire group in the result.
   * @param {Boolean} withSettings If specified, also the settings changes performed on the device will be returned
   * @param {Boolean} withCommands If specified, also the commands sent to the device will be returned
   * @param {Boolean} withAlerts If specified, alerts triggered by the device will be returned
   * @param {Boolean} withUpdates If specified, firmware updates performed on the device will be returned
   * @returns {Promise} Combined history of the device
   */
  async getDeviceHistory ({ getters }, { id, serialNumber, from, to, decimate, withStatus, withParameters, withSettings, withCommands, withAlerts, withUpdates } = {}) {
    if (!id) throw new Error('Device is required')
    const { isDevelopmentEnvironment, toLocalDateTime } = getters

    const { device, query, history } = await DeviceAPI.getDeviceHistory({
      id,
      serialNumber,
      from,
      to,
      decimate,
      withStatus,
      withParameters,
      withSettings,
      withCommands,
      withAlerts,
      withUpdates,
      debug: isDevelopmentEnvironment
    })

    // Recalculate event times to the viewer's local time
    for (const item of history) {
      item.time = new Date(toLocalDateTime(item.time))
    }

    return { device, query, history }
  },

  /**
   * Adds a new device, such as non-connected device or simulated device.
   * @param device Device to add
   * @param organization Organization where device is to be added
   * @param place Place where device is to be added
   * @param notes Notes to attach to the device
   */
  async addDevice ({ commit, dispatch, getters }, { device, organization, place, notes, silent } = {}) {
    if (!device) throw new Error('Device is required')
    organization = organization || getters.currentOrganization

    // Link to the place
    device.placeId = place ? place.id : undefined

    // Create a link with owner
    device.links = [
      new DeviceLink({
        type: DeviceLinkType.Owner,
        principalId: organization.id,
        principal: organization
      })
    ]

    // Pass on notes
    if (notes && notes.trim()) {
      device.notes = [new Note({
        category: NoteCategory.Device,
        entityId: device.id,
        text: notes
      })]
    }

    // Save device
    await dispatch('busy', { message: `Adding device ${device.acronym} ${device.serialNumber} ...`, data: device, silent })
    const { savedDevices, failedDevices } = await dispatch('saveDevices', { devices: [device], silent: true })

    // Add to the place
    if (savedDevices && savedDevices.length === 1) {
      device = await dispatch('getDevice', { id: savedDevices[0].id })
      commit('addNewDeviceToPlace', { device, place })
      await dispatch('done', { message: `Device ${device.acronym} ${device.serialNumber} has been added`, silent })
      return device

    } else if (failedDevices && failedDevices.length === 1) {
      await dispatch('done', { error: `Device ${device.acronym} ${device.serialNumber} was not added`, silent })
    }
  },

  /**
   * Opens a dialog for adding a non-connected device to the specified organization and place.
   * Used to add simple passive devices such as line amps, directly by customers or resellers.
   * @param device Device to add
   * @param organization Organization where device is to be added
   * @param place Place where device is to be added
   */
  async addNonConnectedDevice ({ dispatch, getters }, { device: initialData = {}, organization, place, silent } = {}) {
    organization = organization || getters.currentOrganization
    place = place && place.id !== 'none' ? place : undefined
    const data = {
      device: new Device({
        type: DeviceType.LineAmpNC,
        bands: StandardDeviceBands.FiveBands,
        ...initialData
      }),
      place,
      organization,
      notes: ''
    }

    const result = await dispatch('showDialog', { dialog: 'non-connected-device', data })
    if (result.isOk && result.data) {
      let { device, notes } = result.data
      const { serialNumber, type } = device
      device = new Device({ serialNumber, type })
      dispatch('addDevice', { device, organization, place, notes, silent })
    }
  },

  /**
   * Opens a dialog for adding a simulated device to the specified organization and place.
   * Used to add devices which will automatically generate fake data, without being present in the IoT gateway.
   * @param device Device to add
   * @param organization Organization where device is to be added
   * @param place Place where device is to be added
   */
  async addSimulatedDevice ({ dispatch, getters }, { device: initialData = {}, organization, place, silent } = {}) {
    organization = organization || getters.currentOrganization
    place = place && place.id !== 'none' ? place : undefined

    const count = await dispatch('getGlobalPreference', { name: 'simulated-device-counter', defaultValue: 1 })
    const data = {
      device: new Device({
        ...initialData,
        serialNumber: `sim${padLeft(count.toString(), 4, '0')}`,
        simulatedDeviceProfile: initialData.simulatedDeviceProfile || 'heartbeat'
      }),
      place,
      organization,
      notes: ''
    }

    const result = await dispatch('showDialog', { dialog: 'simulated-device', data })
    if (result.isOk && result.data) {
      let { device, notes } = result.data
      const { serialNumber, simulatedDeviceProfile } = device
      device = new Device({ serialNumber, simulatedDeviceProfile })
      await dispatch('addDevice', { device, organization, place, notes, silent })
      await dispatch('storeGlobalPreference', { name: 'simulated-device-counter', value: count + 1 })
    }
  },

  /**
   * Creates sample data for the specified organization.
   * @param {Organization} organization Organization where to create sample data
   * @param {Object} data Details of the sample data to create
   */
  async createSampleOrganizationData ({ dispatch }, { organization, data } = {}) {
    if (organization && data) {
      let place
      const { createPlace, createDevices, placeName, deviceCount } = data
      if (!(createPlace || createDevices)) {
        return
      }

      const dismiss = Notification.progress({ message: `Creating sample data in ${organization.name} ...` })

      if (createPlace && placeName && placeName.trim()) {
        place = await dispatch('savePlace', {
          place: new Place({ name: placeName.trim(), organizationId: organization.id }),
          silent: true
        })
      }

      if (createDevices) {
        for (let i = 0; i < deviceCount; i++) {
          const count = await dispatch('getGlobalPreference', { name: 'simulated-device-counter', defaultValue: 1 })
          await dispatch('addDevice', {
            device: new Device({ serialNumber: `sim${padLeft(count.toString(), 4, '0')}`, simulatedDeviceProfile: 'online' }),
            organization,
            place,
            silent: true
          })
          await dispatch('storeGlobalPreference', { name: 'simulated-device-counter', value: count + 1 })
        }
      }

      dismiss()
      Notification.success({ message: `Sample data has been created in ${organization.name}` })
    }
  },

  /**
   * Sets device flags
   * @param {Device} device Device to start logging
   * @param {DeviceFlags} flags Device flags to set
   * @returns {DeviceFlags} Modified device flags
   */
  async setDeviceFlags ({ getters }, { device, flags } = {}) {
    const { currentUser: user } = getters
    const updatedFlags = await DeviceAPI.setFlags({ user, device, flags })
    return updatedFlags
  },

  /**
   * Initiates debug logging for the specified device.
   * Previously collected logs are cleared.
   * @param {Device} device Device to start logging
   * @param {Number} duration Duration of logging, in seconds
   * @param {Boolean} profile If true, also profiling is enabled
   * @param {Boolean} clear If true, any previously collected logs will be cleared first
   * @returns {DeviceFlags} Device flags
   */
  async startDeviceLogging ({ getters }, { device, duration = 3600, profile, clear = true } = {}) {
    const { currentUser: user } = getters
    const flags = await DeviceAPI.startLogging({ user, device, duration, profile, clear })
    return flags
  },

  /**
   * Finishes debug logging for the specified device,
   * captures the collected logs in a bundle and clears the logs.
   * @param {Device} device Device to stop logging
   * @param {Boolean} capture If true, the data recorded so far should be captured and made ready for download
   * @returns {LogBundle} Details of the captured logs bundle
   */
  async stopDeviceLogging ({ getters }, { device, capture = true } = {}) {
    const { currentUser: user } = getters
    const bundle = await DeviceAPI.stopLogging({ user, device, capture })
    return bundle
  },

  /**
   * Returns log bundle
   * @param {Device} device Device to retrieve the bundle
   * @param {String} id Log bundle identifier. Specify `recent` to retrive the most recent log bundle associated with the device'
   * @returns {LogBundle} Log bundle
   */
  async getDeviceLogBundle ({ getters }, { device, id = 'recent' } = {}) {
    const { currentUser: user } = getters
    const bundle = await DeviceAPI.getLogBundle({ user, device, id })
    return bundle
  }
}
