import { EntityType } from '@stellacontrol/model'
import { DeviceAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the DASHBOARD view
   * @param {String} title Dashboard title
   * @param {DeviceHierarchy} hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param {EntityType} entityType Type of the entity to view: organization, place or device
   * @param {Organization} organization Organization to view or organization associated with the entity
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Place} place Place to view or place associated with device
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {Boolean} isTreeMinimized Indicates whether to collapse the assets tree initially
   */
  async initializeDashboardView ({ commit, dispatch, getters }, { title, hierarchy, entityType, organization, organizationGuardian, place, device, devicePart, isTreeMinimized } = {}) {
    const { currentOrganization, cannotUse } = getters

    // If organization does not have permission to see child organizations,
    // remove child organizations from hierarchy
    if (cannotUse('child-organizations')) {
      hierarchy.organizations = []
    }

    // Retrieve previously stored tree settings
    const viewMode = await dispatch('getUserPreference', { name: 'dashboard-tree-view-mode' })
    const isMinimized = isTreeMinimized || await dispatch('getUserPreference', { name: 'dashboard-tree-minimized' })
    const filter = await dispatch('getUserPreference', { name: 'dashboard-tree-filter' })
    let expanded = await dispatch('getUserPreference', { name: 'dashboard-expanded-items' })
    // If nothing has been marked as expanded yet, expand the top level only
    if (!expanded) {
      expanded = [hierarchy.id]
    }

    // Retrieve pending premium service associated with device, if any
    const pendingPremiumService = device && device.isPremiumServiceNotStarted
      ? await dispatch('getPremiumService', { id: device.premiumServiceId })
      : undefined

    commit('initializeDashboardView', {
      currentOrganization,
      title,
      hierarchy,
      entityType,
      organization,
      organizationGuardian,
      place,
      device,
      devicePart,
      pendingPremiumService,
      tree: {
        viewMode,
        isMinimized,
        filter,
        expanded,
        selected: null
      }
    })
  },

  /**
   * Forces data updates on the dashboard, after administrative activity
   */
  resetDashboardView ({ commit }) {
    commit('resetDashboardView')
  },

  /**
   * Retrieves organization to be displayed in the dashboard view.
   * @param {String} id Organization identifier
   * @returns {Promise<Organization>}
   */
  async getDashboardViewOrganization ({ commit, getters }, { id }) {
    if (id) {
      const organization = getters.getOrganization(id)
      commit('updateDashboardView', { organization })
      return organization
    }
  },

  /**
   * Stores currently expanded tree items
   * @param expanded List of identifiers of items currently expanded in the asset tree
   */
  async storeExpandedDashboardItems ({ commit, dispatch }, { expanded = [] } = {}) {
    const value = Array.from(new Set(expanded))
    await dispatch('storeUserPreference', { name: 'dashboard-expanded-items', value })
    commit('storeExpandedDashboardItems', { expanded: value })
  },

  /**
   * Stores currently selected tree item
   * @param selected Identifier of item currently selected in the asset tree
   */
  async storeSelectedDashboardItem ({ commit, dispatch }, { selected } = {}) {
    await dispatch('storeUserPreference', { name: 'dashboard-selected-item', value: selected })
    commit('storeSelectedDashboardItem', { selected })
  },

  /**
   * Stores currently visibility of the tree
   * @param isMinimized Visibility of the tree
   * @param filter Filter applied to the tree
   * @param viewMode Tree view mode
   */
  async storeDashboardTreeState ({ commit, dispatch }, { isMinimized, filter, viewMode } = {}) {
    if (isMinimized !== undefined) {
      await dispatch('storeUserPreference', { name: 'dashboard-tree-minimized', value: Boolean(isMinimized) })
    }
    if (filter !== undefined) {
      await dispatch('storeUserPreference', { name: 'dashboard-tree-filter', value: filter })
    }
    if (viewMode !== undefined) {
      await dispatch('storeUserPreference', { name: 'dashboard-tree-view-mode', value: viewMode })
    }
    commit('storeDashboardTreeState', { isMinimized, filter, viewMode })
  },

  /**
   * Navigates to organization dashboard
   * @param organization Organization to show
   */
  async gotoOrganizationDashboard ({ dispatch }, { organization: { id } = {} } = {}) {
    if (!id) throw new Error('Organization identifier is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.Dashboard, query: {
        entityType: EntityType.Organization,
        organization: id
      }
    })
  },

  /**
   * Navigates to place dashboard
   * @param place Place to show
   */
  async gotoPlaceDashboard ({ dispatch }, { place: { id, organizationId } = {} } = {}) {
    if (!id) throw new Error('Place identifier is required')
    if (!organizationId) throw new Error('Organization identifier is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.Dashboard, query: {
        entityType: EntityType.Place,
        place: id,
        organization: organizationId
      }
    })
  },

  /**
   * Navigates to device dashboard
   * @param {Device} device Device to show
   */
  async gotoDeviceDashboard ({ dispatch }, { device: { id, serialNumber } = {} } = {}) {
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.Dashboard,
      query: {
        entityType: EntityType.Device,
        device: id || serialNumber
      }
    })
  },

  /**
   * Navigates to multi-device board on the current dashboard
   * @param {Device} device Device to show
   * @param {String} board Serial number of the device board to show
   */
  async gotoDevicePart ({ dispatch }, { device: { id, serialNumber } = {}, part } = {}) {
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')
    if (!part) throw new Error('Device part serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.Dashboard,
      query: {
        entityType: EntityType.Device,
        device: id || serialNumber,
        part
      }
    })
  },

  /**
 * Sets device's custom location.
 * @param device Device whose custom location is to be set
 * @param location Custom location
 * @description
 * If the same value is entered as device's auto-reported location,
 * custom location will be cleared instead, as we don't want duplicates.
 */
  async setDeviceCustomLocation ({ commit }, { device, location } = {}) {
    if (device) {
      // Clear custom location if the same as device's reported location
      if (location === device.location) {
        location = null
      }

      await DeviceAPI.setDeviceLocation({ device, customLocation: location })
      commit('setDeviceLocation', { device, customLocation: location })
    }
  },

  /**
   * Sets custom location of multiple devices
   * @param devices Devices whose custom location is to be set
   * @param location Custom location
   */
  async setDevicesCustomLocation ({ dispatch }, { devices, location } = {}) {
    if (devices) {
      for (const device of devices) {
        await dispatch('setDeviceCustomLocation', { device, location })
      }
    }
  },

  /**
   * Sets device comments
   * @param device Device whose comments are to be set
   * @param comments Comments text
   */
  async setDeviceComments ({ commit }, { device, comments } = {}) {
    if (device) {
      await DeviceAPI.setDeviceComments({ device, comments })
      commit('setDeviceComments', { device, comments })
    }
  },

  /**
   * Sets device name
   * @param device Device whose custom name is to be set
   * @param name Device name to assign
   */
  async setDeviceName ({ commit }, { device: { id }, name } = {}) {
    if (id) {
      const device = await DeviceAPI.getDevice({ id })
      if (device) {
        device.name = name
        await DeviceAPI.saveDevice({ device })
        commit('setDeviceName', { device, name })
      }
    }
  }
}
