<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { pluralize, distinctItems, sameArrays } from '@stellacontrol/utilities'
import { ListAction, Notification } from '@stellacontrol/client-utilities'
import { EntityType, getOrganizationDescription, getPlaceLabel, getPlaceIcon, getPlaceDescription, DeviceRegions, DeviceRegion, DeviceRegionDescription } from '@stellacontrol/model'
import { DeviceCommands } from '@stellacontrol/devices'
import { Secure } from '@stellacontrol/security-ui'
import PlaceComponent from './components/place.vue'
import DevicesInPlaceComponent from './components/devices-in-place.vue'
import PlaceAttachmentsComponent from './components/place-attachments.vue'
import TogglePlacesComponent from './components/toggle-places.vue'
import DashboardViewModeComponent from './components/view-mode.vue'
import SendCommandsComponent from './components/send-commands.vue'

const name = 'dashboard'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-place': PlaceComponent,
    'sc-devices-in-place': DevicesInPlaceComponent,
    'sc-place-attachments': PlaceAttachmentsComponent,
    'sc-toggle-places': TogglePlacesComponent,
    'sc-dashboard-view-mode': DashboardViewModeComponent,
    'sc-send-commands': SendCommandsComponent
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      startingStatusWatch: false,
      // Loading message
      loadingMessage: '',
      // Information about dragging places and devices
      dragging: {
        deviceId: null,
        placeId: null,
        targetPlaceId: null
      },
      // Place filter
      placeFilterInput: null,
      // Indicates that the user is now rearranging the places and devices
      isRearranging: false,
      // Devices currently being fast-sampled
      fastMonitoredDevices: [],
      // Other datasets for data bindings
      DeviceRegion,
      DeviceRegions,
      DeviceRegionDescription
    }
  },

  computed: {
    ...mapState({
      // Current user
      user: state => state.security.currentUser,
      // Viewed organization
      organization: state => state.dashboardView.organization,
      // Viewed place
      place: state => state.dashboardView.place,
      // Viewed organization's guardian
      organizationGuardian: state => state.dashboardView.organizationGuardian,
      // Indicates whether we're watching the current organization
      isMyOrganization: state => state.dashboardView.isMyOrganization,
      // Indicates whether we're watching a dashboard of an organization
      isShowingOrganization: state => state.dashboardView.entityType === EntityType.Organization,
      // Indicates whether we're watching a dashboard of a place within an organization
      isShowingPlace: state => state.dashboardView.entityType === EntityType.Place,
      // Dashboard title
      title: state => state.dashboardView.title,
      // Places table columns
      columns: state => state.dashboardView.organizationDashboard.placeColumns,
      // Places where devices are installed
      places: state => state.dashboardView.organizationDashboard.places || [],
      // No-place, for grouping devices which aren't assigned anywhere
      noPlace: state => state.dashboardView.organizationDashboard.noPlace,
      // Devices of/available-to the organization
      devices: state => state.dashboardView.organizationDashboard.devices || [],
      connectedDevices: state => (state.dashboardView.organizationDashboard.devices || []).filter(d => d.isConnectedDevice),
      // Devices currently selected in the dashboard
      selectedDevices: state => state.dashboardView.organizationDashboard.selectedDevices || [],
      selectedConnectedDevices: state => (state.dashboardView.organizationDashboard.selectedDevices || []).filter(d => d.isConnectedDevice),
      selectedConnectedBoards: state => (state.dashboardView.organizationDashboard.selectedDevices || []).filter(d => d.isConnectedDevice && !d.isMultiDevice),
      // Device groups which are currently collapsed
      collapsedPlaceGroups: state => state.dashboardView.organizationDashboard.collapsedPlaceGroups,
      // Places and devices filter
      placeFilter: state => state.dashboardView.organizationDashboard.filter,
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
    }),

    ...mapGetters([
      'allOrganizationPlaces',
      'placeHasDevice',
      'getPlaceDevices',
      'isPlaceExpanded',
      'getStatusWatchSettings',
      'isMobilePhone'
    ]),

    // Identifier of the organization
    organizationId () {
      return (this.organization || {}).id
    },

    // Places matching the filter
    matchingPlaces () {
      const filter = (this.placeFilter || '').trim().toLowerCase()
      const places = filter
        ? this.allOrganizationPlaces.filter(place => {
          if (place.fullText.toLowerCase().includes(filter)) {
            return true
          }
          if (this.placeHasDevice(place, filter)) {
            return true
          }
          return false
        })
        : this.allOrganizationPlaces
      return places
    },

    // Returns a group within the place, to which a device belongs
    getDeviceGroup () {
      return device => {
        let group = device.placeGroup
        if (!group) {
          const placeDevices = this.getPlaceDevices(device.place)
          const predecessors = [...placeDevices.filter(d => d.sortOrder < device.sortOrder)].reverse()
          group = (predecessors.find(p => p.placeGroup) || {}).placeGroup
        }
        return group
      }
    },

    // Checks whether the toolbar should be visible at all
    isToolbarVisible () {
      return !this.isMobilePhone
    },

    // Checks whether a group of devices in a place is currently expanded
    isDeviceGroupExpanded () {
      return device => {
        const deviceGroup = this.getDeviceGroup(device)
        if (!deviceGroup) return true
        const { noPlace, collapsedPlaceGroups } = this
        const placeGroups = collapsedPlaceGroups.filter(group => device.placeId ? group.placeId === device.placeId : group.placeId === noPlace.id)
        const isInCollapsedGroup = placeGroups.some(group => group.group === deviceGroup)
        return !isInCollapsedGroup
      }
    },

    // Returns a list of all devices on the current dashboard
    allDevices () {
      return this
        .matchingPlaces
        .map(place => this.getPlaceDevices(place))
        .reduce((all, devices) => ([...all, ...devices]), [])
    },

    // Indicates that there are no devices in this place
    isEmpty () {
      return this.allDevices.length === 0
    },

    // Returns a list of serial numbers of all devices on the current dashboard
    allSerialNumbers () {
      return this.allDevices.map(d => d.serialNumber).filter(s => s)
    },

    // Returns a list of currently visible devices - only those in places and groups that are currently expanded
    visibleDevices () {
      const devices = this
        .matchingPlaces
        .filter(place => this.isPlaceExpanded(place))
        .map(place => this.getPlaceDevices(place))
        .reduce((all, devices) => ([...all, ...devices]), [])
        .filter(device => this.isDeviceGroupExpanded(device))
      return distinctItems(devices, 'id')
    },

    // Returns a list of currently visible devices
    // which are permitted to be monitored for live status.
    // If customer is on premium plan, this requires live status subscription.
    liveDevices () {
      const { organizationGuardian, visibleDevices, currentOrganizationGuardian } = this
      let devices
      if (currentOrganizationGuardian) {
        devices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
          ? visibleDevices.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
          : (currentOrganizationGuardian.canUse('live-status') ? visibleDevices : [])
      } else {
        devices = []
      }

      // Return only actual communicating boards
      return devices.filter(d => d.isConnectedDevice && !d.isMultiDevice)
    },

    // Indicates whether any devices can be monitored live
    hasLiveDevices () {
      return this.liveDevices.length > 0
    },

    // Returns parts of a specified multi-device
    deviceParts () {
      return device => device.isMultiDevice
        ? this.devices.filter(d => d.partOf === device.id)
        : []
    },

    // Devices which aren't monitored live but can be still asked for status
    nonLiveDevices () {
      return this.visibleDevices
        .filter(d => !this.liveDevices.some(ld => ld.id === d.id))
        .flatMap(d => d.isMultiDevice ? [...this.deviceParts(d)] : [d])
        .filter(d => d.isConnectedDevice)
    },

    // Indicates whether any devices are selected
    hasSelectedDevices () {
      return (this.selectedDevices || []).length > 0
    },

    // Serial numbers of all devices in place
    selectedSerialNumbers () {
      return this.selectedDevices.map(d => d.serialNumber)
    },

    // Indicates whether the user can rearrange the dashboard.
    // Must have proper permissions, and place cannot be SHARED DEVICES
    canRearrange () {
      const { isShowingPlace, place, canUse } = this
      if (canUse('rearrange-dashboard')) {
        if (!(isShowingPlace && place.isSharedPlace)) {
          return true
        }
      }
    },

    // Indicates whether the user can send commands to devices
    canSendCommands () {
      return this.canUse('device-management') && !this.isRearranging
    },

    // Indicates whether the viewer can edit the organization
    // currently viewed in the dashboard
    canEditOrganization () {
      return !this.isMyOrganization
    },

    // Indicates whether user is allowed to navigate to inventory
    canGoToInventory () {
      return this.canUse('inventory') && !this.isEmpty && !this.isRearranging
    },

    // Indicates that user is allowed to add a new place to the organization
    canAddPlace () {
      return !this.isMobilePhone &&
        this.isShowingOrganization &&
        this.canUse('set-device-place') &&
        this.isAdministrator
    },

    // Indicates that user is allowed to edit the place
    canEditPlace () {
      return !this.isMobilePhone &&
        this.isShowingPlace &&
        this.place &&
        !this.place.isNoPlace &&
        !this.place.isSharedPlace &&
        this.canUse('set-device-place') &&
        this.isAdministrator
    },

    // Indicates that user is allowed to edit a floor plan of the place
    canEditPlan () {
      const { canEditPlace, isMyOrganization } = this
      if (canEditPlace && this.canUse('planner')) {
        // Editing other organization's plans requires separate permission
        if (isMyOrganization) {
          return true
        } else {
          return this.canUse('child-floor-plans')
        }
      } else {
        return false
      }
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    // Indicates whether user can change region of the selected devices.
    // Conditions:
    // * Permission device-management-region-change has been granted
    // * Place is selected
    // * Place is flagged with 'hasRegion'
    // * There are devices in place
    // * ... all place devices are selected
    canChangeRegion () {
      const { canUse, place, visibleDevices } = this
      const devices = visibleDevices.filter(d => d.isConnectedDevice && !d.isMultiDevice && d.isMultiRegionDevice)
      return canUse('device-management-region-change') &&
        place &&
        place.hasRegion &&
        devices.length > 0
    },

    // Actions
    actions () {
      const { organization, canEditOrganization, canUse } = this
      const actions = [
        {
          name: 'add-place',
          label: `Add building in ${organization.name}`,
          icon: 'add',
          color: 'indigo-5',
          isVisible: canUse('set-device-place')
        },
        {
          ...ListAction.Edit,
          label: `Edit ${organization.name}`,
          isVisible: canEditOrganization
        },
        {
          separator: true,
          name: 'rearrange',
          icon: 'swap_vert',
          label: 'Rearrange dashboard',
          isVisible: () => this.canRearrange && !this.isRearranging
        }
      ]
      return actions
    }
  },

  methods: {
    ...mapMutations([
      'selectDevicesInAllPlaces',
      'filterPlaces'
    ]),

    ...mapActions([
      'populateOrganizationDashboard',
      'getDevice',
      'editPlace',
      'createPlace',
      'moveDeviceToPlace',
      'togglePlace',
      'movePlaceBefore',
      'addNonConnectedDevice',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'suspendWatchingDeviceStatus',
      'resumeWatchingDeviceStatus',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'togglePlaces',
      'editOrganization',
      'gotoRoute',
      'gotoInventory',
      'startDeviceSubscriptions',
      'changeRegionOfDevices',
      'reload'
    ]),

    getOrganizationDescription,
    getPlaceLabel,
    getPlaceDescription,
    getPlaceIcon,

    // Populates the dashboard
    async populate () {
      this.isInitialized = false
      const { organization, place } = this
      if (organization) {
        await this.populateOrganizationDashboard({ organization, place })
        this.isInitialized = true
        this.loadingMessage = ''
        this.watchStatus()
      }
    },

    // Invalidates the dashboard
    async invalidate (message) {
      this.isInitialized = false
      this.loadingMessage = message
    },

    // Starts rearranging mode. When in rearranging mode,
    // user can permanently change order of places and devices
    startRearranging () {
      this.isRearranging = true
      this.suspendWatchStatus()
    },

    // Finish rearranging
    stopRearranging () {
      this.isRearranging = false
      this.resumeWatchStatus()
    },

    // Indicates whether live status is monitored and allowed for the specified device
    isLiveStatusAllowed (device) {
      return this.liveDevices?.some(d => d.id === device?.id || d.partOf === device?.id)
    },

    // Filters the places to show the specified place only
    async findPlace (placeId) {
      if (placeId) {
        const { organization, allOrganizationPlaces } = this
        const place = allOrganizationPlaces.find(p => p.id === placeId)
        if (place) {
          this.placeFilter = place.fullText
          await this.togglePlace({ organization, place, isExpanded: true, dontSave: true })
        }
      }
    },

    // Opens up a dialog for adding a simple device such as lineamp
    // to the place
    addNonConnectedDeviceToPlace ({ place } = {}) {
      const { organization } = this
      this.addNonConnectedDevice({ place, organization })
    },

    // Triggered when place has been removed
    placeRemoved (place) {
      this.$emit('placeRemoved', place)
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasLiveDevices) {
            const devices = this.liveDevices
            const fastSampling = this.fastMonitoredDevices
            await this.watchDeviceStatus({ name, devices, fastSampling })
            await this.watchUploadStatus({ interval: 30 })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.nonLiveDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
      await this.unwatchUploadStatus()
    },

    // Suspends watching the device status
    async suspendWatchStatus () {
      if (!this.hasLiveDevices) return
      this.suspendWatchingDeviceStatus({ name })
      this.unwatchUploadStatus({ name })
    },

    // Resumes watching the device status
    async resumeWatchStatus () {
      if (!this.hasLiveDevices) return
      this.resumeWatchingDeviceStatus({ name })
      this.watchUploadStatus({ name, interval: 30 })
    },

    // Opens organization devices (or current selection) in inventory
    showDevicesInInventory () {
      const { hasSelectedDevices, selectedSerialNumbers, allSerialNumbers } = this
      const selection = hasSelectedDevices ? selectedSerialNumbers : allSerialNumbers
      this.gotoInventory({ selection })
    },

    // Opens the plan editor for the current place
    editPlan () {
      const { canEditPlan, place } = this
      if (canEditPlan) {
        this.gotoRoute({
          name: 'building-plan',
          params: {
            id: place.id
          }
        })
      }
    },

    // DRAG-N-DROP -------------------------------------------------------------------------
    // Triggered when device has been dragged over a place.
    // Remember which one, so it's reflected in the UI.
    deviceDraggedOverPlace ({ targetPlaceId, deviceId }) {
      this.dragging.deviceId = deviceId
      this.dragging.targetPlaceId = targetPlaceId
    },

    // Triggered when place has been dragged over another place.
    // Remember which one, so it's reflected in the UI.
    placeDraggedOverPlace ({ targetPlaceId, placeId }) {
      this.dragging.placeId = placeId
      this.dragging.targetPlaceId = targetPlaceId
    },

    // Triggered when device has been dropped onto a place.
    // Assigns the device to that place or
    // remove the device from current place, if dropped on unassigned devices.
    async deviceDroppedOnPlace ({ targetPlaceId, deviceId }) {
      const { organization } = this

      const place = await this.moveDeviceToPlace({
        placeId: targetPlaceId,
        deviceId,
        silent: true
      })

      // If device has not-activated premium services which should be activated
      // on sale or place assignment, activate them now
      if (place.isRealPlace && this.mustUse('premium-services-buy')) {
        const device = await this.getDevice({ id: deviceId, withDetails: true })

        if (device && device.isPremiumServiceNotStarted) {
          const subscriptions = await this.startDeviceSubscriptions({
            devices: [device],
            startsAt: new Date(),
            details: `Premium service has been activated, because the device has been assigned to ${getPlaceDescription(place)}`,
            silent: true
          })
          if (subscriptions && subscriptions[0]) {
            await Notification.success({ message: `Premium service has been activated on ${device.acronym}` })
            this.reload({ instant: true })
          }
        }
      }


      this.endDragging()
      this.togglePlace({
        organization,
        place,
        isExpanded: true
      })
    },

    // Triggered when place has been dropped onto another place.
    // Stores the order of places, so that they're sorted in the same order on reload.
    async placeDroppedOnPlace ({ targetPlaceId, placeId }) {
      const place = this.places.find(p => p.id === placeId)
      const before = this.places.find(p => p.id === targetPlaceId)
      await this.movePlaceBefore({ place, before })
      this.endDragging()
    },

    // Triggered when dragging a device has ended
    endDragging () {
      this.dragging.deviceId = null
      this.dragging.placeId = null
      this.dragging.targetPlaceId = null
    },

    // Executes an action
    async executeAction (action) {
      const { organization } = this
      switch (action.name) {
        case 'edit':
          await this.editOrganization({ organization })
          break

        case 'add-place':
          await this.createPlace({ organization })
          break

        case 'rearrange':
          this.startRearranging()
          break
      }
    },

    // Triggered when command has been executed
    async commandExecuted ({ command, devices } = {}) {
      // Switch selected devices to fast sampling mode
      if (command?.name === DeviceCommands.StartFastSampling && devices?.length > 0) {
        const { fastSamplingMaxCount } = this.statusWatchSettings.liveStatus
        this.fastMonitoredDevices = [...devices].slice(0, fastSamplingMaxCount)
        if (devices.length === this.fastMonitoredDevices.length) {
          const message = `The selected ${pluralize(devices, 'device is', 'devices are')} monitored live.`
          Notification.success({ message })

        } else {
          const message = `Only <b>${fastSamplingMaxCount}</b> can be monitored in fast mode at the same time.`
          const details = 'The remaining devices will be ignored.'
          Notification.warning({ message, details, timeout: 5000 })
        }
        this.watchStatus()
      }

      await this.selectDevicesInAllPlaces({ isSelected: false })
    },

    // Changes the region
    async setDeviceRegion (region) {
      const { visibleDevices: devices, place } = this
      if (place && place.hasRegion && devices.length > 0) {
        await this.changeRegionOfDevices({ devices, region, confirm: true })
      }
    }
  },

  watch: {
    // Restart device status subscription on change of monitored devices
    liveDevices (current, previous) {
      if (!sameArrays(current, previous, item => item.serialNumber)) {
        this.$emit('monitoredDevicesChanged')
        this.watchStatus()
      }
    },

    // Triggered when places modified, tree needs a refresh
    places () {
      this.$emit('placesChanged')
    },

    // When finding places by search, auto-expand if only one is left
    async matchingPlaces () {
      const { organization, matchingPlaces } = this
      if (matchingPlaces.length === 1) {
        const place = matchingPlaces[0]
        await this.togglePlace({
          organization,
          place,
          isExpanded: true,
          dontSave: true
        })
      }
    }
  },

  // Stop watching the device status and deselect devices on navigation
  async beforeRouteUpdate () {
    await this.stopRearranging()
    await this.unwatchStatus()
    await this.selectDevicesInAllPlaces({ isSelected: false })
  },

  async beforeUnmount () {
    await this.stopRearranging()
    await this.unwatchStatus()
    await this.selectDevicesInAllPlaces({ isSelected: false })
  }
}

</script>

<template>
  <main class="organization-dashboard">
    <div v-if="isInitialized && isToolbarVisible"
      class="toolbar row items-center q-pt-lg q-pr-lg q-gutter-sm">

      <q-btn dense unelevated icon="swap_vert" v-if="isRearranging"
        label="Click to finish rearranging dashboard" color="orange-9" @click="stopRearranging()">
      </q-btn>

      <q-btn-dropdown v-if="canChangeRegion" :disabled="isChangingRegion" dense unelevated
        label="Change region" :ripple="false">
        <q-list>
          <q-item v-for="r in DeviceRegions" clickable v-close-popup @click="setDeviceRegion(r)">
            <q-item-section side>
              <sc-icon-flag-eu v-if="r === DeviceRegion.EMEA" :width="24" :height="20">
              </sc-icon-flag-eu>
              <sc-icon-flag-usa v-if="r === DeviceRegion.USA" :width="24" :height="20">
              </sc-icon-flag-usa>
              <q-icon v-if="r === DeviceRegion.Off" name="stop_circle" color="grey-6" size="24px">
              </q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ DeviceRegionDescription[r] }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <sc-send-commands v-if="canSendCommands" @command="commandExecuted"></sc-send-commands>

      <q-btn v-if="canAddPlace" dense unelevated label="Add Place" icon="add_location" @click="createPlace({ organization })">
      </q-btn>

      <q-btn v-if="canEditPlace" dense unelevated label="Edit Place" :icon="getPlaceIcon(place.placeType)" @click="editPlace({ place })">
      </q-btn>

      <q-btn v-if="canEditPlan" dense unelevated label="Edit Plan" icon="category" @click="editPlan()">
      </q-btn>

      <q-btn dense unelevated icon="list_alt" label="Go to inventory" v-if="canGoToInventory"
        @click="showDevicesInInventory()">
        <sc-tooltip
          :text="hasSelectedDevices ? 'Open selected devices in inventory' : 'Open all devices in inventory'" />
      </q-btn>
    </div>

    <header v-if="isInitialized && isToolbarVisible" class="header">
      <section class="table-header row">
        <q-markup-table class="places-header">
          <thead>
            <tr>
              <th colspan="2" class="title">
                <div class="row items-center">
                  <div class="q-pr-sm">
                    <sc-toggle-places :places="places"></sc-toggle-places>
                  </div>

                  <router-link
                    :to="{ name: 'dashboard', query: { entityType: 'organization', organization: organization.id } }"
                    class="item-link">
                    {{ getOrganizationDescription(organization) }}
                  </router-link>

                  <span v-if="place" class="q-ml-sm q-mr-sm">
                    /
                  </span>

                  <router-link v-if="place" class="item-link"
                    :to="{ name: 'dashboard', query: { entityType: 'place', organization: organization.id, place: place.id } }">
                    {{ getPlaceDescription(place) }}
                  </router-link>
                </div>
              </th>

              <th class="actions">
                <div class="row no-wrap justify-end items-center">

                  <div class="q-mr-md">
                    <sc-dashboard-view-mode></sc-dashboard-view-mode>
                  </div>

                  <q-input class="place-filter col-auto" dense outlined clearable clear-icon="close"
                    v-model="placeFilterInput" label="Find place or device" icon="search"
                    bg-color="white" debounce="500"
                    @update:model-value="filter => filterPlaces({ filter })">
                    <template v-slot:prepend>
                      <q-icon name="search"></q-icon>
                    </template>
                  </q-input>

                  <sc-action-dropdown dense v-if="!isRearranging" class="q-ml-sm" :actions="actions"
                    @action="action => executeAction(action)">
                  </sc-action-dropdown>

                </div>
              </th>
            </tr>
          </thead>
        </q-markup-table>
      </section>
    </header>

    <main v-if="isInitialized" class="content">
      <section class="scroll-area">
        <table class="place" v-for="place in matchingPlaces" :key="`place-${place.id}`">
          <sc-place :place="place" :isRearranging="isRearranging"
            :targetPlaceId="dragging.targetPlaceId" @deviceDraggedOverPlace="deviceDraggedOverPlace"
            @placeDraggedOverPlace="placeDraggedOverPlace" @dropDeviceOnPlace="deviceDroppedOnPlace"
            @dropPlaceOnPlace="placeDroppedOnPlace" @placeDragEnded="endDragging"
            @placeRemoved="placeRemoved">
          </sc-place>

          <sc-devices-in-place :place="place" :isRearranging="isRearranging"
            :isLiveStatusAllowed="isLiveStatusAllowed" :targetPlaceId="dragging.targetPlaceId"
            @deviceDraggedOverPlace="deviceDraggedOverPlace"
            @placeDraggedOverPlace="placeDraggedOverPlace" @dropDeviceOnPlace="deviceDroppedOnPlace"
            @dropPlaceOnPlace="placeDroppedOnPlace" @placeDragEnded="endDragging"
            @addNonConnectedDevice="addNonConnectedDeviceToPlace">
          </sc-devices-in-place>

          <sc-place-attachments v-if="isShowingPlace" :place="place" :show-own="false" :show-linked="true" :is-rearranging="isRearranging">
          </sc-place-attachments>
        </table>
      </section>
    </main>

    <main v-if="!isInitialized">
      <sc-busy :title="loadingMessage"></sc-busy>
    </main>

    <sc-non-connected-device-dialog></sc-non-connected-device-dialog>
    <sc-simulated-device-dialog></sc-simulated-device-dialog>
    <sc-band-selector-dialog></sc-band-selector-dialog>
    <sc-document-upload-dialog></sc-document-upload-dialog>
    <sc-file-preview></sc-file-preview>
    <sc-file-notes></sc-file-notes>
  </main>
</template>

<style scoped lang="scss">
.organization-dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  >.toolbar {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  >.header {
    flex: 0;
    display: flex;
    flex-direction: column;

    .table-header {
      .places-header {
        box-shadow: none;
        width: 100%;

        tr {
          th {
            text-align: left;
            border-bottom: solid #0000001f 1px;
            background-color: #f1f1ff;
            height: 57px;

            &.title {
              padding-right: 0;
              font-size: 15px;
            }

            &.actions {
              text-align: right;
            }
          }
        }
      }
    }
  }

  >.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >.scroll-area {
      overflow: auto;
      width: 100%;
      height: 100%;

      >.place {
        width: 100%;
        border-spacing: 0;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .organization-dashboard {
    >.toolbar {
      top: 32px;
    }
  }
}
</style>