<script>
import { ListViewMode } from '@stellacontrol/client-utilities'
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      ListViewMode
    }
  },
  computed: {
    ...mapState({
      // Devices view mode
      viewMode: state => state.dashboardView.organizationDashboard.devicesListViewMode,
      // Selected organization
      organization: state => state.dashboardView.organization
    }),
    // Three viewing modes for devices:
    // List
    showDeviceList () {
      return this.viewMode === ListViewMode.Normal
    },
    // Small cards
    showDeviceCards () {
      return this.viewMode === ListViewMode.MiniCards
    },
    // Large cards
    showDeviceDetails () {
      return this.viewMode === ListViewMode.Cards
    }
  },
  methods: {
    ...mapActions([
      'setPlaceDevicesViewMode'
    ])
  }
}
</script>

<template>
  <div class="row items-center no-wrap">
    <q-btn flat dense round icon="reorder" class="compact" :color="showDeviceList ? 'primary' : 'grey-6'" @click="setPlaceDevicesViewMode({ viewMode: ListViewMode.Normal, organization })">
      <sc-tooltip text="Show devices as list" />
    </q-btn>
    <q-btn flat dense round icon="view_comfy" class="compact" :color="showDeviceCards ? 'primary' : 'grey-6'" @click="setPlaceDevicesViewMode({ viewMode: ListViewMode.MiniCards, organization })">
      <sc-tooltip text="Show devices as cards" />
    </q-btn>
  </div>
</template>