<script>
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { DashboardWidgets } from '../dashboard/widgets'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { resolve } from './building-dashboard.resolve'
import { getPlaceIcon, DeviceType } from '@stellacontrol/model'

const name = 'building-dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Indicates device status initialization in progress
      startingStatusWatch: false,
      // Available device commands
      DeviceCommands,
      DefaultDeviceCommands,
    }
  },

  computed: {
    ...mapGetters([
      'availableFastSamplingSlots',
      'isFastSampling',
      'getStatusWatchSettings',
      'isMobilePhone'
    ]),

    ...mapState({
      // Organization to which the building belongs
      organization: state => state.buildingDashboard.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.buildingDashboard.organizationGuardian,
      // Building displayed on the dashboard
      place: state => state.buildingDashboard.place,
      // Devices which belong to the building
      devices: state => state.buildingDashboard.devices,
      // Devices which belong to the building
      showParts: state => state.buildingDashboard.showParts,
      // Selected device ids
      selectedDeviceIds: state => state.buildingDashboard.selectedDevices,
      fastSamplingCountdown: state => state.buildingDashboard.fastSamplingCountdown
    }),

    // Devices to show on the dashboard.
    // By default do not show individual device boards which are parts of multi-board devices.
    visibleDevices () {
      return this.devices
        .filter(device => this.showParts
          ? !device.isMultiDevice
          : !device.partOf)
    },

    // Returns a list of serial numbers of all devices on the current dashboard
    allSerialNumbers () {
      return this.visibleDevices.map(d => d.serialNumber).filter(s => s)
    },
    // Returns a list of currently visible devices
    // which are permitted to be monitored for live status.
    // If customer is on a premium plan, this requires the `live-status` subscription.
    liveDevices () {
      const { organizationGuardian, visibleDevices, currentOrganizationGuardian } = this
      let devices
      if (currentOrganizationGuardian) {
        devices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
          ? visibleDevices.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
          : (currentOrganizationGuardian.canUse('live-status') ? visibleDevices : [])
      } else {
        devices = []
      }

      // Return only actual communicating boards
      return devices.filter(d => d.isConnectedDevice && !d.isMultiDevice)
    },

    sortedDevices () {
      return [
        ...this.visibleDevices.filter(d => d.type === DeviceType.Repeater),
        ...this.visibleDevices.filter(d => d.type !== DeviceType.Repeater)
      ]
    },

    // Indicates whether any devices can be monitored live
    hasLiveDevices () {
      return this.liveDevices.length > 0
    },

    // Devices which aren't monitored live but can be still asked for status
    nonLiveDevices () {
      return this.visibleDevices
        .filter(d => !this.liveDevices.some(ld => ld.id === d.id))
        .filter(d => d.isConnectedDevice)
    },
    canGoToInventory () {
      return this.canUse('inventory') && this.liveDevices.length
    },

    // Indicates whether the user can send commands to devices
    canSendCommands () {
      return this.canUse('device-management')
    },

    // Indicates whether live status can be monitored for the specified device
    liveStatusAbsenceDescription () {
      return device => {
        const { currentOrganizationGuardian, organizationGuardian } = this
        if (currentOrganizationGuardian && device) {
          if (device.isMultiDevice && !device.hasParts) {
            return 'Status not available'
          }
          if (currentOrganizationGuardian?.requiresPremiumSubscription('live-status')) {
            return !organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian) &&
              'No premium services active'
          } else {
            return !currentOrganizationGuardian?.canUse('live-status') &&
              'Not authorized to see live status'
          }
        }
      }
    },

    // Indicates whether live status can be monitored for the specified device
    canSeeLiveStatus () {
      return device => {
        return !this.liveStatusAbsenceDescription(device)
      }
    },

    // Indicates whether the device should be selectable
    isSelectable () {
      return device => this.canSeeLiveStatus(device) && this.canSendCommands
    },

    // Devices currently selected in the place
    selectedDevices () {
      return this.liveDevices.filter(d =>
        this.selectedDeviceIds.some(s => s.id === d.id))
    },

    // Indicates whether all devices are selected in the place
    allDevicesSelected () {
      return this.liveDevices.length &&
        this.liveDevices.every(d => this.isDeviceSelected(d))
    },

    // Indicates whether more than one device has been selected
    isBatchSelected () {
      return this.selectedDevices.length > 0
    },

    // Checks whether the specified device is currently selected
    isDeviceSelected () {
      return device => this.selectedDevices.some(d => d.id === device.id)
    },

    notesIcon () {
      return this.place.attachmentCount
        ? 'attachment'
        : this.place.notes.length ? 'comment' : 'mode_comment'
    },

    // Maximum number of bands for any live device
    maxBands () {
      return Math.max(...this.visibleDevices.map(d => d.bandCount), 1)
    },

    // Whether the selection (no selection = first devices) can enter fast-sampling
    canFastSample () {
      return this.devicesEligibleForFastSampling.length > 0 && this.statusWatchSettings.fastSamplingSpeed !== 'off'
    },

    // Devices that can enter fast-sampling
    fastSamplingDevices () {
      return this.selectedDevices.length > 0 ? this.selectedDevices : this.sortedDevices
    },

    // Devices eligible for fast-sampling
    devicesEligibleForFastSampling () {
      if (!this.canSendCommands) return []
      const devices = this.fastSamplingDevices.filter(device => this.canSeeLiveStatus(device) && !this.isFastSampling(device))
      if (devices.length >= this.availableFastSamplingSlots) {
        return this.selectedDevices.length > 0 ? [] : devices.slice(0, this.availableFastSamplingSlots)
      }
      return devices
    },

    fastSamplingCounter () {
      return this.canFastSample
        ? this.statusWatchSettings.fastSamplingDuration
        : this.fastSamplingCountdown || '-'
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    isStock () {
      return this.place.id === 'none'
    },

    canAddToBuilding () {
      return this.isStock && this.selectedDevices.length > 0 &&(
        this.isAdministrator || this.canUse('set-device-place')
      )
    }
  },

  methods: {
    ...mapMutations([
      'selectDevice',
      'initCountDown'
    ]),
    ...mapActions([
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'suspendWatchingDeviceStatus',
      'resumeWatchingDeviceStatus',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'gotoInventory',
      'showPlaceNotes',
      'goBack',
      'gotoRoute',
      'openInventoryAction',
      'startFastSampling'
    ]),

    getPlaceIcon,

    // Populates the dashboard
    async populate () {
      this.isInitialized = false
      const { organization, place } = this
      if (organization && place) {
        this.watchStatus()
      }
      this.isInitialized = true
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasLiveDevices) {
            await this.watchDeviceStatus({ name, devices: this.liveDevices })
            await this.watchUploadStatus({ interval: this.statusWatchSettings.fastSamplingDuration })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.nonLiveDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
      await this.unwatchUploadStatus()
    },

    // Suspends watching the device status
    async suspendWatchStatus () {
      if (!this.hasLiveDevices) return
      this.suspendWatchingDeviceStatus({ name })
      this.unwatchUploadStatus({ name })
    },

    // Resumes watching the device status
    async resumeWatchStatus () {
      if (!this.hasLiveDevices) return
      this.resumeWatchingDeviceStatus({ name })
      this.watchUploadStatus({ name, interval: this.statusWatchSettings.fastSamplingDuration })
    },

    // Opens organization devices (or current selection) in inventory
    showDevicesInInventory () {
      this.gotoInventory({ selection: this.allSerialNumbers })
    },

    // Toggle device selection
    toggleDevice (device, isSelected) {
      if (this.isSelectable(device)) {
        this.selectDevice({ device, isSelected })
      }
    },

    popupPlaceNotes () {
      this.showPlaceNotes({ place: this.place })
    },

    async startDeviceFastSampling () {
      const devices = this.devicesEligibleForFastSampling
      if (devices.length > 0) {
        const fastSamplingDuration = this.statusWatchSettings.fastSamplingDuration
        this.startFastSampling({ devices, fastSamplingDuration })
        this.initCountDown({ fastSamplingDuration })
      }
    },

    close () {
      this.goBack()
    },

    addToBuilding (devices) {
      this.openInventoryAction({ devices, action: { permissions: ['set-device-place'], name: 'place' } })
    }
  },

  async created () {
    await this.populate()
  },

  // Reload the dashboard on navigation to another organization and place
  async beforeRouteUpdate (to, from, next) {
    // Stop any running status subscriptions
    await this.unwatchDeviceStatus()

    // Load the data of the new organization and place
    const { redirectTo } = await resolve({ from, to }) || {}

    // Re-initialize the dashboard
    await next(redirectTo)
    if (!redirectTo) {
      await this.populate()
    }
  }
}
</script>

<template>
  <sc-view :name="name">

    <template #toolbar>

      <q-btn dense unelevated :label="isMobilePhone ? '' : 'Notes'" :icon="notesIcon" textColor="indigo-5"
        v-if="!isStock" @click.stop="popupPlaceNotes()">
      </q-btn>


      <q-btn unelevated :label="isMobilePhone ? fastSamplingCounter : `Live ${fastSamplingCounter}`" icon="play_arrow" :ripple="false"
        @click="startDeviceFastSampling()" :disable="!canFastSample">
      </q-btn>

      <q-btn-dropdown label="Commands" unelevated icon="wifi_tethering" :ripple="false"
        :disable="selectedDevices.length === 0" v-if="canSendCommands && !isMobilePhone">
        <sc-device-commands :show-header="false" :commands="DefaultDeviceCommands"
          :devices="selectedDevices">
        </sc-device-commands>
      </q-btn-dropdown>
      <q-btn dense unelevated label="Edit Plan" icon="category" v-if="!isMobilePhone && !isStock"
        :to="{ name: 'building-plan', params: { id: place.id } }">
      </q-btn>

      <q-btn flat class="primary" icon="place" label="Add to building" color="indigo-6"
        v-if="canAddToBuilding" @click="addToBuilding(selectedDevices)"></q-btn>

      <q-btn flat class="primary" icon="close" :label="isMobilePhone ? '' : 'Close'" color="indigo-6"
        @click="close()"></q-btn>
    </template>
    <template #header v-if="isMobilePhone">
      <h1 class="place-name">
        <q-icon :name="getPlaceIcon(place.placeType)" size="md" color="indigo-5"></q-icon>
        {{ place.name }}
      </h1>
    </template>

    <q-banner v-if="isStock" class="bg-orange-6 row items-center">
      <q-icon color="white" name="new_releases" class="q-mr-md" size="md" />
      <span class="text-subtitle2">
        Place the devices into a building to unlock all functionalities
      </span>
    </q-banner>
    <div class="devices">
      <template v-for="device in sortedDevices">
        <sc-widget-device-card-bands dense :device="device" :place="place"
          :organization="organization" :maxBands="maxBands" :isSelectable="isSelectable(device)"
          :isEditable="isSelectable(device)" :isSelected="isDeviceSelected(device)"
          :isLiveStatusAllowed="canSeeLiveStatus(device)"
          :liveStatusAbsenceDescription="liveStatusAbsenceDescription(device)"
          @select="({ device, isSelected }) => toggleDevice(device, isSelected)">
        </sc-widget-device-card-bands>
      </template>
    </div>

    <template v-if="isMobilePhone" #footer>
      <div class="contextual-menu" v-if="selectedDevices.length > 0">
        <!--q-btn flat v-if="selectedDevices.length === 1" class="primary" icon="link" color="indigo-6" label="Info"
          :to="{ name: 'device-dashboard', params: { serialNumber: selectedDevices[0].serialNumber }}"></q-btn-->
        <sc-device-commands :show-header="false" :commands="DefaultDeviceCommands"
          :devices="selectedDevices" horizontal>
          <template #prefix-commands>
            <q-item v-if="selectedDevices.length === 1" clickable v-close-popup
              :to="{ name: 'device-dashboard', params: { serialNumber: selectedDevices[0].serialNumber }}">
              <q-item-section side>
                <q-icon name="link" color="indigo-6" size="24px"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label class="command-label no-wrap">
                  Info
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </sc-device-commands>
      </div>
    </template>

    <sc-band-selector-dialog></sc-band-selector-dialog>
    <sc-document-upload-dialog></sc-document-upload-dialog>
  </sc-view>

</template>

<style lang="scss" scoped>
.devices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  gap: 6px;
  height: 100%;
  overflow-y: auto;
  align-content: flex-start;
}
.contextual-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.place-name {
  margin: 0;
  padding: 0 0 0 2px;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
  color: #272727;
  margin-bottom: 10px;
}
</style>
