<script>
import { mapState } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  computed: {
    ...mapState({
      placeDevicesColumns: state => state.organizationPlaces.placeDevicesColumns
    }),

    devices () {
      return this.data.devices || []
    },

    hasDevices () {
      return this.devices.length > 0
    }
  }
}

</script>

<template>
  <div class="column">
    <div class="header q-mb-sm" v-if="!hasDevices">
      <div class="q-mb-md">
        There are no devices assigned to this place.
      </div>
    </div>

    <sc-list
      v-if="hasDevices"
      name="place-devices"
      row-key="id"
      :columns="placeDevicesColumns"
      :items="devices">

      <template v-slot:body-cell-serialNumber="props">
        <q-td :props="props">
          <router-link class="item-link" v-if="canUse('device-dashboard')" :to="{ name: 'dashboard', query: { entityType: 'device', device: props.row.id } }">
            {{ props.row.serialNumber }}
            <sc-tooltip :text="`Go to ${props.row.serialNumber} dashboard`" />
          </router-link>
          <span v-else>
            {{ props.row.serialNumber }}
          </span>
        </q-td>
      </template>

    </sc-list>
  </div>
</template>

<style lang='scss' scoped>
</style>
