import { parseEnum, parseBoolean, Point } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'
import { PlanLocation } from '../styles'

/**
 * Connection port types
 */
export const PlanPortType = {
  External: 'external',
  Internal: 'internal',
  Main: 'main',
  In: 'in',
  Out: 'out'
}

/**
 * Connection point on a plan item, such as device port,
 * connector line start or end etc.
 */
export class PlanPort extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      isActive: true
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.point = this.cast(this.point, Point)
    this.location = parseEnum(PlanLocation, this.location)
    this.isActive = parseBoolean(this.isActive, defaults.isActive)
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }

    // Delete default values
    this.clearDefaults(result)

    // Delete runtime data
    delete result.isStart
    delete result.isEnd
    delete result.item
    delete result.location
    delete result.point
    return result
  }

  /**
   * Identifier, must be unique within the plan item
   * @type {String}
   */
  id

  /**
   * Indicates that the connector is active.
   * If not, new connections cannot be started from the port
   * (but they can still end at it)
   * @type {Boolean}
   */
  isActive

  /**
   * Checks whether port represents an external port on a device
   * @type {Boolean}
   */
  get isExternal () {
    return this.type === PlanPortType.External || this.id === PlanPortType.External
  }

  /**
   * Checks whether port represents an internal port on a device
   * @type {Boolean}
   */
  get isInternal () {
    return this.type === PlanPortType.Internal || this.id === PlanPortType.Internal
  }

  /**
   * Checks whether port represents an main port on a device
   * @type {Boolean}
   */
  get isMain () {
    return this.type === PlanPortType.Main || this.id === PlanPortType.Main
  }

  /**
   * Checks whether port represents an input port on a device
   * @type {Boolean}
   */
  get isIn () {
    return this.type === PlanPortType.In
  }

  /**
   * Checks whether port represents an output port on a device
   * @type {Boolean}
   */
  get isOut () {
    return this.type === PlanPortType.Out
  }

  /**
   * Port type
   * @type {PlanPortType}
   */
  type

  /**
   * Identifier of the item to which the port belongs
   * @type {String}
   */
  itemId

  /**
   * Item to which the port belongs, resolved at runtime
   * @type {PlanItem}
   */
  item

  /**
   * Indicates that port is the starting point of a connection, resolved at runtime
   * @type {Boolean}
   */
  isStart

  /**
   * Indicates that connection point is the ending point of a connection, resolved at runtime
   * @type {Boolean}
   */
  isEnd

  /**
   * User-friendly label
   * @type {String}
   */
  label

  /**
   * More details
   * @type {String}
   */
  description

  /**
   * Absolute position of the connector on the item,
   * used during runtime to keep the calculated position of the port.
   * @type {Point}
   */
  point

  /**
   * Port location related to the item on which the port exists
   * @type {PlanLocation}
   */
  location

  /**
   * Returns a position representing the connection point
   * @param {PlanItem} item Item with which the point is associated, optional.
   * If specified, the point will have coordinates of the item.
   * @returns {Point}
   */
  toPoint () {
    const { id, item } = this
    if (item) {
      const port = item.getPort(id)
      if (port) {
        const { x, y } = item
        return new Point({ x, y })
      }
    }
    return new Point(this.point)
  }

  /**
   * Checks whether the port is on top of the item
   * @type {Boolean}
   */
  get isTop () {
    return this.location === PlanLocation.Top
  }

  /**
   * Checks whether the port is on the bottom of the item
   * @type {Boolean}
   */
  get isBottom () {
    return this.location === PlanLocation.Bottom
  }

  /**
   * Checks whether the port is on the left of the item
   * @type {Boolean}
   */
  get isLeft () {
    return this.location === PlanLocation.Left
  }

  /**
   * Checks whether the port is on the right of the item
   * @type {Boolean}
   */
  get isRight () {
    return this.location === PlanLocation.Right
  }
}
