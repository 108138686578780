<script>
import { getStore } from '../store/get-store'
import { mapState, mapGetters, mapActions } from 'vuex'

const defaultErrorMessage = `
  An unexpected error has happened.<br>
  We're sorry for the inconvenience.<br>
  Please try again later.
`

/**
 * Unhandled error view
 */
export default {
  data () {
    return {
      showDetails: false,
      isSendingBugReport: false
    }
  },

  computed: {
    ...mapState({
      application: state => state.client.application,
      error: state => state.client.error,
      isInitialized: state => state.client.isInitialized
    }),

    ...mapGetters([
      'isLoggedIn',
      'guardian'
    ]),

    message () {
      return (this.error ? this.error.message : '') || defaultErrorMessage
    },

    exception () {
      return this.error ? this.error.exception : undefined
    },

    details () {
      let result = 'No other details are available'
      const { exception } = this

      if (exception) {
        const { details, message } = exception
        if (details) {
          result = `${message} in ${details.source.fileName} at ${details.source.line}:${details.source.column}`
        } else {
          result = message
        }

        return result
      }

      return result
    },

    // Bug report identifier
    bugReportId () {
      const { exception } = this
      return exception ? exception.bugReportId : undefined
    },

    // Can go to login page ONLY if the application has been initialized at all.
    // If even that has failed, i.e. when API is dead, we just stay here.
    canGoToLogin () {
      return this.isInitialized
    },

    // Checks whether current user can submit a bug report
    canSendBugReport () {
      const { isLoggedIn, guardian } = this
      return isLoggedIn && guardian && guardian.canUse('bug-reports')
    },

    // Checks whether current user can view the bug report
    canViewBugReport () {
      const { isLoggedIn, guardian } = this
      return isLoggedIn && guardian && guardian.isSuperAdministrator
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'submitBugReport',
      'gotoHome',
    ]),

    toggleDetails () {
      if (this.details) {
        this.showDetails = !this.showDetails
      }
    },

    async submit () {
      const { canSendBugReport, exception } = this
      if (canSendBugReport) {
        this.isSendingBugReport = true
        this.busy({ message: 'Submitting bug report ...' })
        await this.submitBugReport({ error: exception })
        this.done({ message: 'Bug report has been submitted', details: 'Thank you!' })
        await this.gotoHome()
      }
    }
  },

  async beforeUnmount () {
    // Clear the error when leaving the error view
    const store = getStore()
    await store.dispatch('recover')
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <div class="content q-pl-xl">
          <div class="q-pb-lg text-h5">
            {{ application.name }}
          </div>

          <div class="message" @click="toggleDetails()">

            <transition name="slide" mode="out-in">
              <div>
                <div key="details" v-if="showDetails">
                  <div v-html="details" class="text-h6 text-weight-light">
                  </div>
                  <div v-if="bugReportId" class="q-mt-sm text-h6 text-weight-light">
                    Reference:
                    <router-link v-if="canViewBugReport"
                      :to="{ name: 'bug-report', params: { id: bugReportId } }">
                      {{ bugReportId }}
                    </router-link>
                    <span v-else>
                      {{ bugReportId }}
                    </span>
                  </div>
                </div>

                <div key="message" v-if="!showDetails">
                  <span v-html="message" class="text-h6 text-weight-light">
                  </span>
                </div>
              </div>
            </transition>

          </div>

          <div class="bug-report row q-pt-lg" v-if="canSendBugReport">
            <q-btn unelevated
              icon="forward_to_inbox"
              :label="isSendingBugReport ? 'Please wait ...' : 'Submit bug report'"
              :disabled="isSendingBugReport"
              @click="submit()">
            </q-btn>
          </div>

          <div class="back row row q-pt-lg" v-if="canGoToLogin">
            <sc-back-to-login if-logged-out label="Go back to login page">
            </sc-back-to-login>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>
  .content {
    width: 450px;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity .2s
  }

  .slide-enter,
  .slide-leave-active {
    opacity: 0
  }
</style>
