import { EntityType, PlaceType, Place } from '@stellacontrol/model'
import { createState } from './dashboard-view.state'

export const mutations = {
  /**
   * Initializes the DASHBOARD view
   * @param {Organization} currentOrganization Currently logged in organization
   * @param {String} title Dashboard title
   * @param {DeviceHierarchy} hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param {Organization} organization Organization to view or organization associated with the entity
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {EntityType} entityType Type of the entity to view: organization, place or device
   * @param {Place} place Place to view or place associated with device
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {PremiumService} pendingPremiumService Pending premium service associated with device
   * @param {Object} tree Recent state of the items tree
   */
  initializeDashboardView (state, { currentOrganization, title, hierarchy, entityType, organization, organizationGuardian, place, device, devicePart, tree, pendingPremiumService } = {}) {
    if (!currentOrganization) return

    state.title = title
    state.hierarchy = hierarchy
    state.entityType = entityType
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.isMyOrganization = organization.id === currentOrganization.id
    state.place = place
    state.device = device
    state.devicePart = devicePart
    state.pendingPremiumService = pendingPremiumService
    state.tree.viewMode = tree.viewMode
    state.tree.expanded = tree.expanded
    state.tree.selected = tree.selected
    state.tree.isMinimized = tree.isMinimized
    state.tree.filter = tree.filter

    state.organizationCache[organization.id] = organization

    // Get flat list of organizations for easier lookups
    state.organizations = []
    hierarchy.traverse(o => {
      state.organizations.push(o)
    })

    switch (entityType) {
      case EntityType.Organization:
        state.entity = organization
        break
      case EntityType.Place:
        state.entity = place
        break
      case EntityType.Device:
        state.entity = device
        break
      default:
        state.entity = null
        state.entityType = null
    }
  },

  /**
   * Stores updated data in the DASHBOARD view
   * @param hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param organization Organization to view or organization associated with the entity
   */
  updateDashboardView (state, { hierarchy, organization } = {}) {
    if (hierarchy !== undefined) {
      state.hierarchy = hierarchy
    }
    if (organization !== undefined) {
      state.organization = organization
      state.organizationCache[organization.id] = organization
    }
  },

  /**
   * Stores currently visibility of the tree
   * @param isMinimized Visibility of the tree
   * @param filter Filter applied to the tree
   * @param viewMode Tree view mode
   */
  storeDashboardTreeState (state, { isMinimized, filter, viewMode } = {}) {
    if (isMinimized !== undefined) {
      state.tree.isMinimized = isMinimized
    }
    if (filter !== undefined) {
      state.tree.filter = filter
    }
    if (viewMode !== undefined) {
      state.tree.viewMode = viewMode
    }
  },

  /**
   * Stores currently expanded tree items
   * @param expanded List of identifiers of items currently expanded in the asset tree
   */
  storeExpandedDashboardItems (state, { expanded = [] } = {}) {
    state.tree.expanded = expanded
  },

  /**
   * Stores currently selected tree item
   * @param selected Identifier of item currently selected in the asset tree
   */
  storeSelectedDashboardItem (state, { selected } = {}) {
    state.tree.selected = selected
  },

  /**
   * When place is removed, also remove the place from inventory tree
   * and move its devices to unassigned one
   * @param place Removed place
   */
  removePlace (state, { place: { id } = {} } = {}) {
    // Recursively removes the place in specified organization
    // and reshuffles the devices to unassigned
    function removeFromOrganization (o) {
      const index = (o.places || []).findIndex(p => p.id === id)
      if (index > -1) {
        const place = o.places[index]
        if (!place.devices) place.devices = []

        // Delete the place from organization
        o.places.splice(index, 1)

        // Move devices to unassigned
        const devices = place.devices.map(d => {
          d.placeId = undefined
          return d
        })
        if (devices.length > 0) {

          let noPlace = o.places.find(p => p.placeType === PlaceType.NoPlace)
          if (!noPlace) {
            noPlace = Place.createNoPlace()
            o.places.push(noPlace)
          }
          noPlace.devices = (noPlace.devices || []).concat(...devices)
          place.devices = []
        }
        return true

      } else {
        for (const child of o.organizations || []) {
          if (removeFromOrganization(child)) {
            break
          }
        }
      }
    }

    removeFromOrganization(state.hierarchy)

  },

  reset (state) {
    Object.assign(state, createState())
  },

  resetDashboardView (state) {
    Object.assign(state, createState())
  }
}
