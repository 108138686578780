<script>
import '@stellacontrol/common-ui'
import { Secure } from '@stellacontrol/security-ui'
import { mapActions, mapGetters } from 'vuex'
import { sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget,
    Secure
  ],

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // All organizations to which notes can be addressed.
    // We include:
    // - child organizations
    // We ignore:
    // - guest organizations
    recipients () {
      const { organizations, currentOrganization } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
        .filter(o => o).filter(o => o.id !== currentOrganization.id && !o.isGuestOrganization)
    },

    // Returns true if editing notes is possible
    canEditNotes () {
      return this.canUse('edit-device-notes')
    }
  },

  methods: {
    ...mapActions([
      'saveDeviceNote',
      'removeDeviceNote'
    ]),

    async updateNote ({ note }) {
      const { device } = this
      await this.saveDeviceNote({ device, note })
    },

    async removeNote ({ note }) {
      const { device } = this
      await this.removeDeviceNote({ device, note })
    },
  }
}
</script>

<template>
  <sc-widget v-bind="$props" width="320px">
    <sc-notes
      :notes="device.notes"
      :entity="device"
      :recipients="recipients"
      :creator="currentUser"
      :edit-mode="canEditNotes ? (isSuperAdministrator ? 'all' : 'own') : 'none'"
      category="device"
      @update="updateNote"
      @remove="removeNote">
    </sc-notes>
  </sc-widget>
</template>

<style lang="scss" scoped>
</style>
