import Konva from 'konva'
import { DeviceType } from '@stellacontrol/model'
import { PlanItemType, LegendType, PlanLineStyle, PlanTextStyle, PlanBackgroundStyle, AntennaType, SplitterType, CableType } from '@stellacontrol/planner'
import { Shape } from './shape'

import svgDeviceRepeater from './icons/device/repeater.svg'
import svgDeviceAmplifier from './icons/device/lineamp.svg'
import svgSplitterTwoWay from './icons/splitter/splitter-two-way.svg'
import svgSplitterThreeWay from './icons/splitter/splitter-three-way.svg'
import svgSplitterFourWay from './icons/splitter/splitter-four-way.svg'
import svgTapperTwoWay from './icons/splitter/tapper-two-way.svg'
import svgAntennaIndoorOmni from './icons/antenna/omni-indoor.svg'
import svgAntennaOutdoorOmni from './icons/antenna/omni-outdoor.svg'
import svgAntennaYagi from './icons/antenna/yagi.svg'
import svgAntennaLaser from './icons/antenna/laser.svg'
import svgAntennaPanelWall from './icons/antenna/panel.svg'
import svgAntennaPanelCeiling from './icons/antenna/panel-ceiling.svg'
import svgCableSD200 from './icons/cable/sd200.svg'
import svgCableSD400 from './icons/cable/sd400.svg'
import svgCableSD900 from './icons/cable/sd900.svg'
import svgCableCustom from './icons/cable/custom.svg'

const equipmentIcons = {
  [DeviceType.Repeater]: svgDeviceRepeater,
  [DeviceType.LineAmp]: svgDeviceAmplifier,
  [SplitterType.SplitterTwoWay]: svgSplitterTwoWay,
  [SplitterType.SplitterThreeWay]: svgSplitterThreeWay,
  [SplitterType.SplitterFourWay]: svgSplitterFourWay,
  [SplitterType.TapperTwoWay]: svgTapperTwoWay,
  [AntennaType.IndoorOmni]: svgAntennaIndoorOmni,
  [AntennaType.OutdoorOmni]: svgAntennaOutdoorOmni,
  [AntennaType.Laser]: svgAntennaLaser,
  [AntennaType.WallPanel]: svgAntennaPanelWall,
  [AntennaType.CeilingPanel]: svgAntennaPanelCeiling,
  [AntennaType.Yagi]: svgAntennaYagi,
  [CableType.SD200]: svgCableSD200,
  [CableType.SD400]: svgCableSD400,
  [CableType.SD900]: svgCableSD900,
  [CableType.Custom]: svgCableCustom,
}

/**
 * Plan legend
 * @param {PlanLegend} item Plan item details
 */
export class LegendShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Legend
  }

  // Used to detect a change in legend type,
  // when shapes need to be re-created
  __legendType

  /**
   * Sub-shapes making part of the shape
   * @type {Dictionary<string, Konva.Shape}
   */
  shapes = {}

  get defaults () {
    const legendDefaults = {
      [LegendType.Power]: {
        width: 160,
        height: 170
      },
      [LegendType.Equipment]: {
        width: 250,
        height: 200
      }
    }
    return {
      ...super.defaults,
      ...legendDefaults[this.item.legendType],
      backgroundStyle: new PlanBackgroundStyle({
        color: 'white'
      }),
      lineStyle: new PlanLineStyle({
        color: '#a0a0a0',
        width: 1
      }),
      textStyle: new PlanTextStyle({
        size: 14
      }),
      dbRanges: [
        { color: 'red', from: -50, to: -60 },
        { color: 'yellow', from: -60, to: -70 },
        { color: 'green', from: -70, to: -100 },
        { color: 'lightblue', from: -100, to: -120 },
      ]
    }
  }

  /**
   * Maximal number of distinct equipment items to count.
   * This must match the maximal amount of items returned by {@link PlanLayout} `getEquipment` method!
   * @type {Number}
   */
  get maxEquipmentItems () {
    return 15
  }

  /**
   * Creates all shapes making up the legend
   */
  createShapes () {
    super.createShapes()

    const { item, defaults, shapes } = this
    const { width, height, backgroundStyle, lineStyle, textStyle, dbRanges } = defaults
    this.__legendType = item.legendType

    shapes.box = new Konva.Rect({
      width,
      height,
      fill: backgroundStyle.color,
      stroke: lineStyle.color,
      strokeWidth: lineStyle.width,
      dash: lineStyle.dash,
      cornerRadius: lineStyle.radius
    })

    // Power legend is static
    if (item.legendType === LegendType.Power) {
      shapes.title = new Konva.Text({
        x: 10,
        y: 10,
        text: 'LTE B20 (dBm)',
        fill: textStyle.color,
        fontFamily: textStyle.fontFamily,
        fontSize: 16,
        fontStyle: 'bold'
      })

      dbRanges.map(({ color, from, to }, index) => {
        shapes[`icon-${index}`] = new Konva.Rect({
          x: 12,
          y: 40 + index * 30,
          stroke: 'silver',
          strokeWidth: 1,
          fill: color,
          width: 20,
          height: 20
        })
        shapes[`label-${index}`] = new Konva.Text({
          x: 45,
          y: 44 + index * 30,
          text: `${from} to ${to}`,
          fill: textStyle.color,
          fontFamily: textStyle.font,
          fontSize: textStyle.size
        })
      })
    }

    // Equipment legend is based on the current layout
    if (item.legendType === LegendType.Equipment) {
      shapes.title = new Konva.Text({
        x: 10,
        y: 10,
        text: 'Equipment',
        fill: textStyle.color,
        fontFamily: textStyle.fontFamily,
        fontSize: 16,
        fontStyle: 'bold'
      })

      for (let i = 0; i < this.maxEquipmentItems; i++) {
        const item = new Konva.Group({
          x: 12,
          y: 40 + i * 40,
          visible: false
        })
        shapes[`item-${i}`] = item
      }
    }

    this.content.add(...Object.values(shapes))
  }

  /**
   * Call to remove the shape from the layer
   */
  destroy () {
    super.destroy()
    this.shapes = {}
  }

  get box () {
    return this.content.getChildren
  }

  /**
   * Indicates whether shape needs to be recreated,
   * i.e. after changing the legend type
   * @type {Boolean}
   */
  get requiresRefresh () {
    return super.requiresRefresh ||
      this.__legendType !== this.item.legendType
  }

  /**
   * Renders the legend shape
   */
  render (renderer) {
    super.render(renderer)

    const { item, maxEquipmentItems, shapes, defaults } = this
    const { textStyle } = defaults

    if (item.legendType === LegendType.Equipment) {
      const equipment = renderer.layout.getEquipment(renderer.items)
      const hasEquipment = equipment.length > 0
      let itemCount = 0

      if (hasEquipment) {
        for (let i = 0; i < maxEquipmentItems; i++) {
          const { label, count, unit, type, subtype } = equipment[i] || {}
          const key = `item-${i}`
          const item = shapes[key]
          if (!item) continue

          const hasCount = count != null && count > 0
          item.visible(hasCount)
          if (hasCount) {
            itemCount++

            item.destroyChildren()

            const countShape = new Konva.Text({
              x: 55,
              y: 14,
              width: 70,
              fill: textStyle.color,
              fontFamily: textStyle.font,
              fontSize: textStyle.size,
              text: `${count.toString()}${unit || ''}`
            })

            const labelShape = new Konva.Text({
              x: 112,
              y: 14,
              fill: textStyle.color,
              fontFamily: textStyle.font,
              fontSize: textStyle.size,
              text: label
            })

            const iconData = this.getEquipmentIcon(type, subtype, true)
            Konva.Image.fromURL(iconData, (image) => {
              item.add(image)
            })

            item.add(countShape, labelShape)
          }
        }
      }

      if (itemCount > 0) {
        shapes.box.height(45 + itemCount * 40)
        shapes.title.text('Equipment')
      } else {
        shapes.box.height(35)
        shapes.title.text('No equipment found')
      }
    }
  }

  /**
   * Returns an icon representing the specified type of equipment
   * @param {PlanItemType} type Equipment type, such as `device`, `antenna`, `splitter` etc.
   * @param {String} subtype Equipment subtype, such as `repeater`, `omni`, `splitter-two-way` etc.
   * @param {Boolean} asDataUrl If true, the image is returned as data URL
   * @returns {String}
   */
  getEquipmentIcon (type, subtype, asDataUrl) {
    if (type) {
      const svg = equipmentIcons[subtype]
      return asDataUrl
        ? `data:image/svg+xml;${svg}`
        : svg
    }
  }
}
