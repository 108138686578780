<script>
import { mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { sortItemsWith, dateCompareReverse } from '@stellacontrol/utilities'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Announcements to show
    announcements: {
      default: () => []
    },

    // If true, acknowledging is allowed
    allowAcknowledge: {
      default: false
    },

    // If true, show announcement dates
    showDate: {
      default: false
    },

    // If true, list items are separated
    separator: {
      default: false
    },

    // If true, shown in dense mode
    dense: {
      default: false
    },

    // If true, shown in dark mode
    dark: {
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    // Announcements sorted by priority and date,
    // eventually only unacknowledged
    visibleAnnouncements () {
      return sortItemsWith(this.announcements, (a, b) =>
        dateCompareReverse(a.validFrom || a.publishedAt, b.validFrom || b.publishedAt))
    }
  },

  methods: {
    ...mapActions([
      'acknowledgeAnnouncement'
    ])
  }
}
</script>

<template>
  <main :class="{ dense, dark, separator }">
    <ul>
      <li v-for="announcement in visibleAnnouncements">
        <div class="icon q-mr-md">
          <q-icon :name="announcement.icon" :color="announcement.color" size="md">
            <sc-tooltip :text="announcement.categoryDescription"></sc-tooltip>
          </q-icon>
        </div>
        <div class="text q-mr-md">
          <div class="title text-body1">
            {{ announcement.title }}
          </div>
          <div v-if="showDate" class="date text-body2 q-mt-sm">
            {{ date(announcement.validFrom || announcement.createdAt) }}
          </div>
          <div v-if="announcement.hasText" class="details text-body2 q-mt-sm"
            v-html="announcement.text">
          </div>
        </div>
        <div class="break" v-if="dense">
        </div>
        <div class="button" v-if="allowAcknowledge">
          <q-btn v-if="!announcement.isAcknowledged" dense unelevated label="Dismiss" class="clear"
            :class="{ 'text-orange-9': dark }" no-caps
            @click="acknowledgeAnnouncement({ announcement })">
          </q-btn>
          <q-icon v-else>
            <q-icon name="check" color="green-6" size="md"></q-icon>
          </q-icon>
        </div>
        <div class="button" v-else>
          <q-icon>
            <q-icon name="check" color="green-6" size="md"></q-icon>
          </q-icon>
        </div>
      </li>
    </ul>
  </main>
</template>

<style lang='scss' scoped>
main {
  flex: 1;
  flex-direction: column;
  overflow: auto;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition: all 0.2s ease-in-out;
      padding: 10px 12px 8px 12px;

      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  :deep(.details ul),
  :deep(.details ol), {
    padding: 10px 0 0 16px;
  }

  :deep(.details li) {
    margin-bottom: 4px;
  }

  :deep(.details a) {
    color: #1565c0;
    text-decoration: none;
    border-bottom: transparent dotted 1px;
  }

  :deep(.details a:hover) {
    color: #e65100;
    border-bottom: #e65100 dotted 1px;
  }

  .icon {
    flex: 0;
  }

  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      color: black;
    }

    .details {
      color: #606060;
    }
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .button {
    flex: 0;
  }
}

main.dense {
  li {
    padding: 0 4px 0 4px;
  }

  .button {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 8px;
  }
}

main.separator {
  ul {
    li {
      border-bottom: solid #0000001f 1px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

main.dark {
  :deep(.details a) {
    color: #ffa726;
    text-decoration: none;
    border-bottom: transparent dotted 1px;
  }

  :deep(.details a:hover) {
    color: #e65100;
    border-bottom: #ffb74d dotted 1px;
  }

  ul {
    li {
      &:hover {
        background-color: #303030;
      }
    }
  }

  .text {
    .title {
      color: white;
    }

    .details {
      color: #b0b0b0;
    }
  }

  .button {
    color: orange;
  }
}
</style>
