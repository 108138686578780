import { startOfDay, endOfDay, differenceInSeconds } from 'date-fns'
import { isEnum, parseDate, findBiggest, stringCompare } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { EntityType } from '../common/entity-type'
import { Tag } from '../common/tag'
import { Note } from '../common/note'
import { Attachment } from '../attachment/attachment'
import { DeviceLink } from './device-link'
import { DeviceLinkType } from './device-link-type'
import { DeviceLinkLimit } from './device-link-limit'
import { DeviceRegion } from './device-region'
import { DeviceType, ConnectedDeviceTypes, MultiDeviceTypes, DeviceAcronym, isMultiRegionDevice } from './device-type'
import { DeviceFamily, getDeviceFamily } from './device-family'
import { DeviceSnapshot } from './device-snapshot'
import { MessageProtocol } from '../messaging'
import { useResellerModel } from './device-model'
import { DeviceVersion } from './device-version'
import { Product, getProduct } from './product'
import { PlaceType } from './place-type'
import { Organization, User } from '../organization'
import { Principal, PrincipalType } from '../security'
import { sortPlaces } from './sort-places'
import { PremiumServiceStatus } from '../service-management/premium-service-status'
import { PremiumService } from '../service-management/premium-service'
import { isValidVersion } from '../version/version'
import { getDeviceLabel } from './get-devices-description'
import { hasLegacyFirmware } from '../device-transmission/device-firmware'
import { DeviceFlags } from './device-flags'
import { DeviceUpdateStatus } from './device-update-status'
import { UploadStatus } from '../device-transmission/upload-status'

/**
 * Device
 */
export class Device extends Entity {
  constructor (data = {}) {
    super()
    this.assign(data, {
      premiumServiceStartedAt: parseDate,
      premiumServiceExpiredAt: parseDate,
      manufacturedAt: parseDate,
      identityUpdatedAt: parseDate,
      resetAt: parseDate
    })
    this.type = this.type || DeviceType.Repeater
    this.isEnabled = this.isEnabled == null ? true : this.isEnabled
    this.links = this.links || []
    this.tags = this.tags || []
    this.notes = this.notes || []
    this.sortOrder = this.sortOrder || 0
    this.family = this.family || getDeviceFamily(this) || DeviceFamily.EU
    this.protocol = this.protocol || MessageProtocol.ProtoBuf

    if (!isEnum(DeviceType, this.type)) throw new Error(`Invalid device type [${this.type}]`)

    this.firmwareVersionLong = this.firmwareVersionLong || this.firmwareVersion

    if (data.ownerId && !data.links?.length > 0) {
      const link = this.linkWith({ id: data.ownerId }, DeviceLinkType.Owner)
      link.createdAt = parseDate(data.soldAt)
    }
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.parts = this.castArray(this.parts, Device)
    this.links = this.castArray(this.links, DeviceLink)
    this.tags = this.castArray(this.tags, Tag)
    for (const tag of this.tags || []) {
      tag.creator = this.cast(tag.creator, User)
      tag.updater = this.cast(tag.creator, User)
    }
    this.notes = this.castArray(this.notes, Note)
    for (const note of this.notes || []) {
      note.creator = this.cast(note.creator, User)
      note.updater = this.cast(note.creator, User)
    }
    this.creator = this.cast(this.creator, User)
    this.updater = this.cast(this.updater, User)
    this.deleter = this.cast(this.deleter, User)
    this.place = this.cast(this.place, Place)
    this.versions = this.castArray(this.versions, DeviceVersion)
    this.premiumService = this.cast(this.premiumService, PremiumService)
    this.snapshot = this.cast(this.snapshot, DeviceSnapshot)
    this.updateStatus = this.cast(this.updateStatus, DeviceUpdateStatus)
    this.flags = this.cast(this.flags, DeviceFlags)
  }

  /**
   * Brief device label
   * @type {String}
   */
  get label () {
    return getDeviceLabel(this)
  }

  /**
   * Indicates that this device is an internal board,
   * a part of a multi-device identified by this property
   * @type {String}
   */
  partOf

  /**
   * List of devices making up the multi-device
   * @type {Array[Device]}
   */
  parts

  /**
   * Returns the specified part of a multi-device
   * @param {String} serialNumber Part serial number
   * @param {Boolean} includeMe If true, device itself is also treated as part and included in search
   * @returns {Device}
   */
  getPart (serialNumber, includeMe) {
    if (serialNumber) {
      let part = this.isMultiDevice
        ? (this.parts || []).find(part => part.serialNumber === serialNumber)
        : null
      if (!part && includeMe && serialNumber === this.serialNumber) {
        part = this
      }
      return part
    }
  }

  /**
   * Returns the specified part of a multi-device
   * @param {String} id Part identifier
   * @param {Boolean} includeMe If true, device itself is also treated as part and included in search
   * @returns {Device}
   */
  getPartById (id, includeMe) {
    if (id) {
      let part = this.isMultiDevice
        ? (this.parts || []).find(part => part.id === id)
        : null
      if (!part && includeMe && id === this.id) {
        part = this
      }
      return part
    }
  }

  /**
   * Checks whether the specified serial number belongs
   * to the device or one of its parts
   * @param {String} serialNumber Part serial number
   * @returns {Boolean}
   */
  hasPart (serialNumber) {
    return this.serialNumber === serialNumber ||
      (this.parts || []).some(part => part.serialNumber === serialNumber)
  }

  /**
   * Returns active part, that's the one which has region set with _rf_region setting
   * and it matches part's model region
   * @returns {Device}
   */
  getActivePart () {
    return (this.parts || []).find(part => part.region && part.region === part.modelRegion)
  }

  /**
   * Date when device identity has been updated with
   * the recently received device MEGA
   * @type {Date}
   */
  identityUpdatedAt

  /**
   * Date when device has been manufactured
   * @type {Date}
   */
  manufacturedAt

  /**
   * Indicates that device has been decommissioned at specified date and time
   * @type {Date}
   */
  decommissionedAt

  /**
   * Identifier of a user who registered decommissioning of the device
   * @type {String}
   */
  decommissionedBy

  /**
   * User who registered decommissioning of the device
   * @type {User}
   */
  decommissioner

  /**
   * Indicates that the device has been decommissioned
   * @type {Boolean}
   */
  get isDecommissioned () {
    return Boolean(this.decommissionedAt)
  }

  /**
   * Indicates that device has been reset at specified date and time
   * @type {Date}
   */
  resetAt

  /**
   * Identifier of a user who reset the device
   * @type {String}
   */
  resetBy

  /**
   * User who reset the device
   * @type {User}
   */
  resetter

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Custom device name, assigned by user
   * @type {String}
   */
  name

  /**
   * Device type
   * @type {DeviceType}
   */
  type

  /**
   * Returns true if device is a multi-device type
   * @returns {Boolean}
   */
  get isMultiDevice () {
    return MultiDeviceTypes.includes(this.type)
  }

  /**
   * Returns true if device is a test tool
   * @returns {Boolean}
   */
  get isTestTool () {
    return this.type === DeviceType.TestTool
  }

  /**
   * Device model
   * @type {String}
   */
  model

  /**
   * Device model as used by reseller.
   * Resellers can introduce alternative naming for device models.
   * @type {String}
   */
  resellerModel

  /**
   * Returns device model as seen by the specified user.
   * Some devices have alternative model names introduced by their resellers.
   * Super organization users should still see the generic model name though!
   * @param user User displaying the device
   * @type {String}
   */
  getModel (user) {
    const { model, resellerModel } = this
    if (user && (user.isSuperAdministrator || (user.organization && user.organization.isSuperOrganization))) {
      return model
    } else {
      return useResellerModel(this) ? resellerModel : model
    }
  }

  /**
   * Checks whether the device matches the specified model
   * @param {String} model Model to check
   * @type {Boolean}
   */
  isModel (model) {
    return stringCompare(
      (this.model || '').toString().trim(),
      (model || '').toString().trim(),
      false
    ) === 0
  }

  /**
   * Product details
   */
  get product () {
    const product = getProduct(this.type, this.model, this.resellerModel)
    if (product && product.isSimple && !product.id) {
      product.id = this.id
    }
    return product
  }

  /**
   * Device mega version
   * @type {String}
   */
  megaVersion

  /**
   * Device firmware version
   * @type {String}
   */
  firmwareVersion

  /**
   * Full device firmware version, only accessible to super organization
   * @type {String}
   */
  firmwareVersionLong

  /**
   * Returns firmware version
   */
  getFirmwareVersion () {
    return this.firmwareVersionLong || this.firmwareVersion
  }

  /**
   * Indicates a legacy firmware < 7.2.0.0
   * @type {Boolean}
   */
  get isLegacyFirmware () {
    return hasLegacyFirmware(this)
  }

  /**
   * Device hardware version
   * @type {String}
   */
  hardwareVersion

  /**
   * Device EEPROM version
   * @type {String}
   */
  eepromVersion

  /**
   * Returns true if versions are the same as specified
   * @returns {Boolean}
   */
  hasVersion ({ hardwareVersion, firmwareVersion, firmwareVersionLong, eepromVersion, megaVersion } = {}) {
    return (hardwareVersion == null || this.hardwareVersion === hardwareVersion) &&
      (firmwareVersion == null || this.firmwareVersion === firmwareVersion) &&
      (firmwareVersionLong == null || this.firmwareVersionLong === firmwareVersionLong) &&
      (eepromVersion == null || this.eepromVersion === eepromVersion) &&
      (megaVersion == null || this.megaVersion === megaVersion)
  }

  /**
   * Device region assigned using device settings
   * @type {DeviceRegion}
   */
  region

  /**
   * Device region deducted from device model
   * @type {DeviceRegion}
   */
  get modelRegion () {
    const { family } = this
    if (family === DeviceFamily.EU) return DeviceRegion.EMEA
    if (family === DeviceFamily.US) return DeviceRegion.USA
  }

  /**
   * Returns true if device is a multi-region device
   * @type {Boolean}
   */
  isMultiRegionDevice () {
    return isMultiRegionDevice(this.type)
  }

  /**
   * Indicates whether device region is controlled manually by device owner / installer
   * @type {Boolean}
   */
  isManualRegionControl

  /**
   * Complete history of device versions
   * @type {Array[DeviceVersion]}
   */
  versions

  /**
   * Port count
   * @type {Number}
   */
  portCount

  /**
   * Device family
   * @type {DeviceFamily}
   */
  family

  /**
   * Device bands
   * @type {Array[String]}
   */
  bands

  /**
   * Returns the number of bands enabled on the device
   */
  get bandCount () {
    return (this.bands || '').length || 6
  }

  /**
   * Device communication protocol
   * @type {MessageProtocol}
   */
  protocol

  /**
   * Checks whether device uses binary protocol for communicating with the platform
   * @returns {Boolean}
   */
  get usesBinaryProtocol () {
    return this.protocol === MessageProtocol.ProtoBuf
  }

  /**
   * Checks whether device uses JSON protocol for communicating with the platform
   * @returns {Boolean}
   */
  get usesJSONProtocol () {
    return this.protocol === MessageProtocol.JSON
  }

  /**
   * Indicates whether device is enabled
   * @type {Boolean}
   */
  isEnabled

  /**
   * Device is in SHIP mode
   * @type {Boolean}
   */
  isShip

  /**
   * Device links
   * @type {Array[DeviceLink]}
   */
  links

  /**
   * Comments, usually associated with place where device is installed
   * @type {String}
   */
  comments

  /**
   * Identifier of a place where the device is assigned to
   * @type {String}
   */
  placeId

  /**
   * Place where the device is assigned to
   * @type {Place}
   */
  place

  /**
   * Detailed location within the place
   * A technician can enter device location using device control panel.
   * This can be overridden in the application with `customLocation`
   * field below. We don't simply override this field as we still
   * want to be able to see location as reported by the device
   * @type {String}
   */
  location

  /**
   * Custom device location
   * @type {String}
   */
  customLocation

  /**
   * Sorting order on the dashboard
   * @type {Number}
   */
  sortOrder

  /**
   * Name of the group in the place where the device belongs
   * @type {String}
   */
  placeGroup

  /**
   * Indicates that device should be preceded with a separator in the place
   * @type {Boolean}
   */
  placeSeparator

  /**
   * Recently obtained live device events
   * @type {Array[DeviceEvent]}
   */
  events

  /**
   * Most recent alerts associated with the device
   * @type {Array[AlertOccurrence]}
   */
  lastAlerts

  /**
   * Returns true if any alerts have been triggered recently
   * @type {Boolean}
   */
  get recentlyHadAlerts () {
    return this.lastAlerts?.length > 0
  }

  /**
   * Device acronym, showing its type and bands
   * @type {String}
   */
  get acronym () {
    const type = DeviceAcronym[this.type] || '?'
    if (this.isMultiDevice || this.isTestTool) {
      return type
    } else {
      const bands = (this.bands || '').length || ''
      return type + bands
    }
  }

  /**
   * Indicates a connected device, such as iRepeater
   * @type {Boolean}
   */
  get isConnectedDevice () {
    return ConnectedDeviceTypes.includes(this.type)
  }

  /**
   * Indicates a non-connected device
   * @type {Boolean}
   */
  get isNonConnectedDevice () {
    return !ConnectedDeviceTypes.includes(this.type)
  }

  /**
   * Indicates that it's a simulated device
   * for which fake status data will be generated and returned.
   * @type {Boolean}
   */
  get isSimulatedDevice () {
    return Boolean(this.simulatedDeviceProfile)
  }

  /**
   * Indicates that it's a real device
   * @type {Boolean}
   */
  get isRealDevice () {
    return !this.simulatedDeviceProfile
  }

  /**
   * Indicates that it's a real not simulated connected device
   * @type {Boolean}
   */
  get isRealConnectedDevice () {
    return this.isConnectedDevice && !this.simulatedDeviceProfile
  }

  /**
   * Indicates that it's a connected device board,
   * which excludes connected multi-unit devices
   * @type {Boolean}
   */
  get isConnectedBoard () {
    return this.isConnectedDevice && !this.isMultiDevice
  }

  /**
   * Indicates that it's a real not simulated connected device board,
   * which excludes connected multi-unit devices
   * @type {Boolean}
   */
  get isRealConnectedBoard () {
    return this.isConnectedDevice && !this.simulatedDeviceProfile && !this.isMultiDevice
  }

  /**
   * Profile of a simulated device,
   * There are various profiles possible, each one defining
   * various behaviour of a device, different errors etc.
   * @type {String}
   */
  simulatedDeviceProfile

  /**
   * Returns true if device is able to receive commands
   * @type {Boolean}
   */
  get canReceiveCommands () {
    return this.isConnectedDevice && !this.isMultiDevice
  }

  /**
   * Returns true if device is able to send status
   * @type {Boolean}
   */
  get canSendStatus () {
    return this.isConnectedDevice && !this.isMultiDevice
  }

  /**
   * Returns true if device is able to trigger alerts.
   * It must be a connected device, not a multi-device,
   * and also certain device types such as Test Tool are excluded.
   * @type {Boolean}
   */
  get canTriggerAlerts () {
    return this.isConnectedDevice &&
      !this.isMultiDevice &&
      ![DeviceType.TestTool].includes(this.type)
  }

  /**
   * Users can only delete non-connected or simulated devices
   * which they've created themselves.
   * Regular devices, provisioned by StellaDoradus, have to be
   * decommissioned by them
   * @type {Boolean}
   */
  get canBeDeleted () {
    return this.isNonConnectedDevice || this.isSimulatedDevice
  }

  /**
   * Indicates whether device has any links
   * @type {Boolean}
   */
  get hasLinks () {
    return this.links && this.links.length > 0
  }

  /**
   * Indicates whether device has a place assigned
   * @type {Boolean}
   */
  get hasPlace () {
    return Boolean(this.place)
  }

  /**
   * Returns current device links.
   * When device is unlinked from an entity, we don't delete the link
   * but mark as no longer current. This allows us viewing device history.
   * @type {Array[DeviceLink]}
   */
  get currentLinks () {
    return (this.links || []).filter(link => link.isCurrent)
  }

  /**
   * Returns a device link of a specified type
   * @param type Link type
   * @param isCurrent If true, only the currently valid links are searched
   * @returns {DeviceLink}
   */
  getLink (type, isCurrent = true) {
    const links = (isCurrent ? this.currentLinks : this.links) || []
    return links.find(link => link.type === type)
  }

  /**
   * Returns device links of a specified type
   * @param type Link type
   * @param isCurrent If true, only current links are searched
   * @returns {Array[DeviceLink]}
   */
  getLinks (type, isCurrent = true) {
    const links = (isCurrent ? this.currentLinks : this.links) || []
    return links.filter(link => link.type === type)
  }

  /**
   * Returns a (current) link with device owner
   * @returns {DeviceLink}
   */
  getOwnerLink (isCurrent = true) {
    return this.getLink(DeviceLinkType.Owner, isCurrent)
  }

  /**
   * Returns device ownership path, from owner all the way up to the super-organization
   * @param hierarchy Organization hierarchy
   * @returns {Array[Organization]}
   */
  getOwnershipPath (hierarchy) {
    let path = []

    if (hierarchy) {
      const device = this
      if (device && device.owner && hierarchy) {
        let parent = hierarchy.find(device.owner.id, EntityType.Organization)
        while (parent) {
          const { id, name, level, profileId, parentOrganizationId } = parent
          path = [{ id, name, level, profileId }, ...path]
          parent = hierarchy.find(parentOrganizationId, EntityType.Organization)
          // Ignore the top dog, unless he's the owner himself
          if (parent && parent.level === 'super-organization' && parent.id !== device.owner.id) {
            parent = undefined
          }
        }
      }
    }

    return path
  }

  /**
   * Returns device link with specified organization
   * @param organization Organization
   * @param type Link type
   * @param isCurrent If true, only current links are searched
   * @returns {DeviceLink}
   */
  getLinkWith (organization, type = DeviceLinkType.Delegate, isCurrent = true) {
    const links = (isCurrent ? this.currentLinks : this.links) || []
    return links.find(link => link.type === type && link.principalId === organization.id)
  }

  /**
   * Links the device with the specified organization
   * @param organization Organization to link with
   * @param type Link type
   * @param user User linking the device
   * @param notes Additional notes
   * @returns {DeviceLink} Created link
   */
  linkWith (organization, type = DeviceLinkType.Delegate, user, notes) {
    if (!organization) throw new Error('Organization is required')
    if (!isEnum(DeviceLinkType, type)) throw new Error('Invalid link type')

    // Make sure to disable current link
    // if only one link of such type is allowed!
    if (DeviceLinkLimit[type] === 1) {
      const link = this.getLink(type)
      if (link) {
        link.isCurrent = false
      }
    }

    // Add new link
    const link = new DeviceLink({
      createdBy: user?.id,
      updatedBy: user?.id,
      creator: user,
      updater: user,
      type,
      deviceId: this.id,
      principal: organization,
      principalId: organization.id,
      isCurrent: true,
      notes
    })

    this.links.push(link)

    return link
  }

  /**
   * Indicates that this device is only shared with my organization
   * but not owned by me
   * @type {Boolean}
   */
  isShared

  /**
   * Checks whether this device is shared with the specified organization
   * @param organization Organization to check
   * @returns {Boolean}
   */
  isSharedWith (organization) {
    return Boolean(this.getLinkWith(organization, DeviceLinkType.Delegate, true))
  }

  /**
   * Current owner organization of the device, deducted
   * from a link marked with `Owner` type
   * @type {Organization}
   */
  get owner () {
    const link = this.getLink(DeviceLinkType.Owner)
    return link ? link.principal : undefined
  }

  /**
   * Identifier of the owner
   * @type {String}
   */
  get ownerId () {
    return (this.owner || {}).id
  }

  /**
   * Checks whether this device is owned by the specified organization
   * @param organization Organization to check
   * @returns {Boolean}
   */
  isOwnedBy (organization) {
    return Boolean(this.getLinkWith(organization, DeviceLinkType.Owner, true))
  }

  /**
   * Date and time when device has been sold to the current owner
   * @type {Date}
   */
  get soldAt () {
    const link = this.getLink(DeviceLinkType.Owner)
    return link ? link.createdAt : undefined
  }

  /**
   * Date and time when device has been last sold
   * by the specified organization
   * @param {Organization} id Identifier of organization which might have
   * sold the device. If not present in the links, nothing is returned.
   * @returns {Date}
   */
  soldByAt (id) {
    const sales = this.links.filter(l =>
      l.type === DeviceLinkType.Owner &&
      l.creatorOrganizationId === id &&
      l.principalId !== id)
    const link = findBiggest(sales, l => l.createdAt)
    return link ? link.createdAt : undefined
  }

  /**
   * All other delegate organizations of the device -
   * those who are neither reseller nor owner
   * @type {Array[Organization]}
   */
  get delegates () {
    return this.links
      .filter(link => link.type === DeviceLinkType.Delegate)
      .map(link => link.principal)
  }

  /**
   * Adds a link
   * @param type Link type
   * @param principal Principal linked to device
   * @param notes Additional notes to store with the link
   * @returns {DeviceLink} Added link
   */
  addLink (type, principal, notes) {
    if (!principal) throw new Error('Link principal is required')
    type = type || DeviceLinkType.Delegate
    const maxCount = DeviceLinkLimit[type]
    const currentLinks = this.getLinks(type)
    // If count exceeded, unmark the current links as current
    if (currentLinks.length >= maxCount) {
      currentLinks.every(link => { link.isCurrent = false })
    }

    const link = new DeviceLink({
      type,
      deviceId: this.id,
      principalId: principal.id,
      isCurrent: true,
      notes
    })

    this.links = [...this.links || [], link]

    return link
  }

  /**
   * Removes the current links with the specified principal
   * @param type Link type
   * @param principal Principal linked to device
   */
  removeLink (type, principal) {
    if (!principal) throw new Error('Link principal is required')
    type = type || DeviceLinkType.Delegate
    this.links = (this.links || [])
      .filter(link => link.type === type && link.principalId === principal.id && link.isCurrent)
  }

  /**
   * Identifier of premium service assigned to device
   * @type {String}
   */
  premiumServiceId

  /**
   * Identifier of most recent premium subscription
   * @type {String}
   */
  premiumSubscriptionId

  /**
   * Premium service assigned to device
   * @type {PremiumService}
   */
  premiumService

  /**
   * Features available with the currently active premium service
   * @type {Array[String]}
   */
  premiumFeatures

  // Label representing the premium service associated with device
  get premiumServiceLabel () {
    const { premiumService } = this
    return premiumService
      ? (premiumService.code || premiumService.name || '-')
      : '-'
  }

  /**
   * Indicates whether there's a premium service associated with this device
   * @type {Boolean}
   */
  get hasPremiumService () {
    return Boolean(this.premiumServiceId)
  }

  /**
   * Indicates whether the specified premium feature is available for the device
   * @param {String} feature Feature to check
   * @type {Boolean}
   */
  canUse (feature) {
    return this.premiumFeatures?.includes(feature)
  }

  /**
   * Date and time when premium service has been started
   * @type {Date}
   */
  premiumServiceStartedAt

  /**
   * Date and time when last active premium service subscription associated with device has expired
   * @type {Date}
   */
  premiumServiceExpiredAt

  /**
 * Status of premium service associated with the device
 * @type {PremiumServiceStatus}
 */
  get premiumServiceStatus () {
    const { premiumServiceId, premiumServiceStartedAt, premiumServiceExpiredAt } = this
    const today = startOfDay(new Date())
    const endOfToday = endOfDay(new Date())
    const expiredAgo = differenceInSeconds(endOfToday, endOfDay(premiumServiceExpiredAt))

    if (!premiumServiceId) return PremiumServiceStatus.None
    if (!premiumServiceStartedAt) return PremiumServiceStatus.NotStarted
    if (premiumServiceStartedAt > today) return PremiumServiceStatus.Inactive
    if (premiumServiceExpiredAt && expiredAgo > 0) return PremiumServiceStatus.Expired

    return PremiumServiceStatus.Active
  }

  /**
   * Returns true if status of premium service associated with the device is not yet started.
   * @type {Boolean}
   */
  get isPremiumServiceNotStarted () {
    const { premiumServiceId, premiumServiceStartedAt } = this
    return premiumServiceId && !premiumServiceStartedAt
  }

  /**
   * Returns true if status of premium service associated with the device is started.
   * It might still be inactive, if start date is in the future
   * @type {Boolean}
   */
  get isPremiumServiceStarted () {
    const { premiumServiceId, premiumServiceStartedAt } = this
    return premiumServiceId && premiumServiceStartedAt
  }

  /**
   * Returns true if status of premium service associated with the device is
   * not yet active, because start date is in future
   * @type {Boolean}
   */
  get isPremiumServiceInactive () {
    const now = new Date()
    const { premiumServiceId, premiumServiceStartedAt } = this
    return premiumServiceId && premiumServiceStartedAt > now
  }

  /**
   * Returns true if status of premium service associated with the device is
   * started and currently active
   * @type {Boolean}
   */
  get isPremiumServiceActive () {
    const now = startOfDay(new Date())
    const { premiumServiceId, premiumServiceStartedAt, premiumServiceExpiredAt } = this
    return premiumServiceId &&
      premiumServiceStartedAt &&
      premiumServiceStartedAt <= now &&
      (!premiumServiceExpiredAt || premiumServiceExpiredAt >= now)
  }

  /**
   * Returns true if status of premium service associated with the device has expired
   * @type {Boolean}
   */
  get hasPremiumServiceExpired () {
    const now = startOfDay(new Date())
    const { premiumServiceId, premiumServiceExpiredAt } = this
    return premiumServiceId &&
      premiumServiceExpiredAt &&
      premiumServiceExpiredAt >= now
  }

  /**
   * Clears premium service from the device
   */
  clearPremiumService () {
    this.premiumService = undefined
    this.premiumServiceId = undefined
    this.premiumSubscriptionId = undefined
    this.premiumServiceStartedAt = undefined
    this.premiumServiceExpiredAt = undefined
  }

  /**
   * Device snapshot, current whereabouts of status, place, ownership etc.
   * @type {DeviceSnapshot}
   */
  snapshot

  /**
   * Updates the device snapshot with specified data
   * @param {Object} data Snapshot data
   * @returns {DeviceSnapshot}
   */
  updateSnapshot (data = {}) {
    if (this.snapshot) {
      this.snapshot.assign(data)
    } else {
      this.snapshot = new DeviceSnapshot(data)
    }
    return this.snapshot
  }

  /**
   * Device update status
   * @type {DeviceUpdateStatus}
   */
  updateStatus

  /**
   * Device flags
   * @type {DeviceFlags}
   */
  flags

  /**
   * Updates firmware upload status on device snapshot.
   * @param {UploadJobStatus} status Upload job status
   */
  updateUploadStatus (status = {}) {
    const device = this

    if (status.isFirmwareUpload) {
      // If upload job is completed ...
      if (status.isCompleted) {
        // Update the information about current firmware version
        const { firmwareUpdateVersion } = device.snapshot || {}
        const version = isValidVersion(firmwareUpdateVersion)
        if (version) {
          device.firmwareVersion = version.toCustomString(3)
          device.firmwareVersionLong = version.toFullString()
        }

        delete device.updateStatus
      } else {
        device.updateStatus = new DeviceUpdateStatus({
          ...(device.updateStatus || {}),
          deviceId: status.deviceId,
          jobId: status.id,
          updatedAt: status.updatedAt || new Date(),
          progress: status.progress,
        })
      }
    }
  }

  /**
   * Clear information about upload in progress from device snapshot
   */
  cancelUpload () {
    delete this.updateStatus
  }

  /**
   * Indicates whether there's a firmware update in progress,
   * associated with the device
   */
  get firmwareUpdateInProgress () {
    return this.updateStatus?.status === UploadStatus.Sending
  }

  /**
   * Resets the device
   * @param user User resetting the device
   * @param organization Organization resetting the device
   */
  reset (user, organization) {
    if (user && organization) {
      this.resetAt = new Date()
      this.resetBy = user.id
      this.decommissionedAt = null
      this.decommissionedBy = null
      this.updatedBy = this.createdBy
      this.updatedAt = this.createdAt
      this.placeId = null
      this.place = null
      this.location = null
      this.customLocation = null
      this.updateStatus = null
      this.tags = []
      // Unlink from all but leave the reseller link intact
      const resellerLink = this.getResellerLink()
      this.links = this.links
        .filter(l => !resellerLink || l.id !== resellerLink.id || l.principalId !== organization.id)
    }

    return this
  }

  /**
   * Remove runtime properties before serialization
   */
  toJSON () {
    const result = { ...this }
    return result
  }
}

/**
 * Compound device, bundling together a couple of simple device devices
 */
export class MultiDevice extends Entity {
  constructor (data = {}) {
    super()
    this.assign(data, {})
    if (!isEnum(DeviceType, this.type)) throw new Error(`Invalid multi-device type [${this.type}]`)
    this.devices = this.devices || []
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.product = this.cast(this.product, Product)
    this.devices = this.castArray(this.devices, Device)
  }

  /**
   * Product definition
   * @type {Product}
   */
  product

  /**
   * Boards making up the multi-device
   * @type {Array[Device]}
   */
  devices
}

/**
 * Logical group of devices, such as office building where devices are kept etc.
 */
export class Place extends Principal {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
      type: PrincipalType.Place,
      placeType: data.placeType || PlaceType.Building,
      notes: data.notes || [],
      attachments: data.attachments || [],
      devices: data.devices || [],
      deviceCount: data.deviceCount || (data.devices || []).length,
      sortOrder: data.sortOrder || 0,
      isEnabled: data.isEnabled == null ? true : data.isEnabled,
      hasRegion: data.hasRegion == null ? false : data.hasRegion
    }, {
      hasRegion: Boolean
    })

    if (!isEnum(PlaceType, this.placeType)) throw new Error(`Invalid place type ${this.placeType}`)
  }

  __attachments

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.notes) {
      this.notes = this.castArray(this.notes, Note)
      this.organization = this.cast(this.organization, Organization)
      this.devices = this.castArray(this.devices, Device)
      this.attachments = this.castArray(this.attachments, Attachment)
      if (this.attachments?.length > 0) {
        this.attachmentCount = this.attachments.length
      }
    }
  }

  /**
   * Returns core information about the place
   * @returns {Place}
   */
  core () {
    const place = new Place(this)
    delete place.creator
    delete place.updater
    delete place.deleter
    delete place.notes
    delete place.tags
    delete place.devices
    delete place.attachments
    delete place.__attachments
    delete place.floorPlans
    delete place.plans
    return place
  }

  /**
   * Place type
   * @type {PlaceType}
   */
  placeType

  /**
   * Indicates that place can be assigned to a geographic region,
   * which is then applied to devices in it
   * @type {Boolean}
   */
  hasRegion

  /**
   * Returns true if place represents a real place, not a virtual container
   * such as unassigned devices or shared devices
   * @type {Boolean}
   */
  get isRealPlace () {
    return this.placeType && this.placeType !== PlaceType.NoPlace && this.placeType !== PlaceType.SharedPlace
  }

  /**
   * Returns true if place represents a container for devices
   * not assigned to any place
   * @type {Boolean}
   */
  get isNoPlace () {
    return this.placeType === PlaceType.NoPlace
  }

  /**
   * Returns true if place represents a container for devices
   * shared with organization
   * @type {Boolean}
   */
  get isSharedPlace () {
    return this.placeType === PlaceType.SharedPlace
  }

  /**
   * Returns true if place represents a ship
   * @type {Boolean}
   */
  get isShip () {
    return this.placeType === PlaceType.Ship
  }

  /**
   * Returns true if place is currently locked for the specified user,
   * eg. when it's region-bound
   * @param {User} user User viewing the place
   * @returns {Boolean}
   */
  isLockedFor (user) {
    return this.isShip && this.hasRegion && !user?.isSuperAdministrator
  }

  /**
   * Organization to which the place belongs
   * @type {Organization}
   */
  organization

  /**
   * Identifier of an organization to which the place belongs
   * @type {String}
   */
  organizationId

  /**
   * Place city
   * @type {String}
   */
  city

  /**
   * Place address
   * @type {String}
   */
  address

  /**
   * Notes about the place
   * @type {String}
   */
  notes

  /**
   * Place attachments, such as images or other documents
   * @type {Array[Attachment]}
   */
  get attachments () {
    return this.__attachments
  }
  set attachments (value) {
    this.__attachments = value
    this.attachmentCount = value ? value.length : 0
  }

  /**
   * Number of attachments associated with the place
   * @type {Number}
   */
  attachmentCount

  /**
   * Indicates whether the place has attachments, such as images or other documents
   * @type {Boolean}
   */
  get hasAttachments () {
    return this.attachmentCount || this.attachments?.length > 0
  }

  /**
   * Place's own attachments
   * @type {Boolean}
   */
  get ownAttachments () {
    return (this.attachments || []).filter(a => !a.isLinked)
  }

  /**
   * Indicates whether the place has own attachments
   * @type {Boolean}
   */
  get hasOwnAttachments () {
    return this.ownAttachments.length > 0
  }

  /**
   * Place's linked attachments
   * @type {Boolean}
   */
  get linkedAttachments () {
    return (this.attachments || []).filter(a => a.isLinked)
  }

  /**
   * Indicates whether the place has linked attachments
   * @type {Boolean}
   */
  get hasLinkedAttachments () {
    return this.linkedAttachments.length > 0
  }

  /**
   * Adds attachments to the place
   * @param {Array[Attachment]} attachments Attachments to add
   * @returns {Array[Attachment]} All place attachments
   */
  addAttachments (attachments) {
    if (attachments) {
      if (!this.attachments) {
        this.attachments = []
      }
      this.attachments = [
        ...this.attachments,
        ...attachments
      ]
    }
    return this.attachments
  }

  /**
   * Removes the attachment from the place
   * @param {Attachment} attachment Attachments to remove
   * @returns {Array[Attachment]} Remaining place attachments
   */
  removeAttachment (attachment) {
    if (attachment) {
      this.attachments = (this.attachments || []).filter(a => a.id !== attachment.id)
    }
    return this.attachments
  }

  /**
   * Sorting order on the dashboards
   * @type {Number}
   */
  sortOrder

  /**
   * Number of devices under the place
   * @type {Number}
   */
  deviceCount

  /**
   * Latitude
   * @type {Number}
   */
  latitude

  /**
   * Longitude
   * @type {Number}
   */
  longitude

  /**
   * Devices assigned to the place
   * @type {Array[Device]}
   */
  devices

  /**
   * Returns true if place has any {@link devices}
   * @type {Boolean}
   */
  get hasDevices () {
    return this.devices && this.devices.length > 0
  }

  /**
   * Floor plans assigned to the place
   * @type {Array[FloorPlan]}
   */
  floorPlans

  /**
   * Returns true if place has any {@link floorPlans}
   * @type {Boolean}
   */
  get hasFloorPlans () {
    return this.floorPlans && this.floorPlans.length > 0
  }

  /**
   * Indicates whether the edited place can have floor plans
   * @type {Boolean}
   */
  get canHaveFloorPlans () {
    return this.placeType === PlaceType.Building ||
      this.placeType === PlaceType.Ship ||
      this.placeType === PlaceType.Other
  }

  /**
   * Identifier of the building plan associated with the place
   * @type {String}
   */
  planId

  /**
   * Indicates that there is a building plan associated with the place
   * @type {Boolean}
   */
  get hasPlan () {
    return this.planId != null
  }

  /**
   * Full text describing the place
   * @type {String}
   */
  get fullText () {
    switch (this.placeType) {
      case PlaceType.Building:
        return [this.name, this.city, this.address]
          .filter(a => (a || '').trim())
          .join(', ')
      default:
        return this.name
    }
  }

  /**
   * Address text
   * @type {String}
   */
  get fullAddress () {
    switch (this.placeType) {
      case PlaceType.Building:
        return [this.city, this.address]
          .filter(a => (a || '').trim())
          .join(', ')
      default:
        return ''
    }
  }

  /**
   * Remove runtime properties before serialization
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this,
      attachments: this.__attachments
    }
    delete result.__attachments
    return result
  }
}

/**
 * Groups the provided list of devices by places where they belong,
 * returns the list of places with devices each under their place
 * @param {Array[Device]} devices Devices to group
 * @param {Array[Place]} places Places to which devices belong, required unless devices already have their `place` property set
 * @param {Boolean} noEmptyPlaces If true, empty places aren't returned in the result
 * @returns {Array[Place]} Places with devices belonging to them
 * or {@link places} list is provided
 */
export function groupDevicesByPlace (devices, places = [], noEmptyPlaces = true) {
  if (devices) {
    // Placeholder for stock devices
    let noPlace = places.find(place => place.isNoPlace)
    if (!noPlace) {
      noPlace = Place.createNoPlace()
      places.push(noPlace)
    }

    // Work on cloned items, leave originals intact
    for (const device of devices.map(d => new Device(d))) {
      if (device.placeId) {
        // If device belongs to a place,
        // pick the matching place ...
        let place = places.find(place => place.id === device.placeId)
        // ... or use the one that comes with device
        if (!place && device.place) {
          place = device.place
          places.push(place)
        }
        // Add device to the place and clear its place, to avoid recursion.
        // Mind duplicates!
        device.place = undefined
        if (!place.devices) {
          place.devices = []
        }
        if (!place.devices.find(d => d.id === device.id)) {
          place.devices.push(device)
        }

      } else {
        // If device not assigned to place, store in special place
        noPlace.devices.push(device)
      }
    }
  }

  // Filter out empty places, sort by name
  return sortPlaces(places
    .filter(place => place.hasDevices || !noEmptyPlaces))
}

// Special record representing new place
Place.ID_NEWPLACE = 'new'

// Special record representing empty place, for displaying devices not assigned to any place
Place.ID_NOPLACE = 'none'
Place.createNoPlace = function (data = {}) {
  return new Place({
    id: Place.ID_NOPLACE,
    name: 'Stock',
    placeType: PlaceType.NoPlace,
    order: Number.MIN_VALUE,
    ...data
  })
}

// Special record representing virtual place, for displaying devices shared with organization
Place.ID_SHAREDPLACE = 'shared'
Place.createSharedPlace = function (data = {}) {
  return new Place({
    id: Place.ID_SHAREDPLACE,
    name: 'Devices shared with my organization',
    placeType: PlaceType.SharedPlace,
    order: Number.MIN_VALUE + 1,
    ...data
  })
}

Place.NoPlace = Place.createNoPlace()
Place.SharedPlace = Place.createSharedPlace()

/**
 * Returns true if place is currently locked for the specified user,
 * eg. when it's region-bound
 * @param {Place} place Place to check
 * @param {User} user User viewing the place
 * @returns {Boolean}
 */
export function isPlaceLockedFor (place, user) {
  if (place) {
    return new Place(place).isLockedFor(user)
  }
}

