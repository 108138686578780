import { EntityType } from '@stellacontrol/model'
import { createState } from './dashboard-view.device.state'

export const mutations = {
  /**
   * Stores device and other data required in the DEVICES DASHBOARD view
   * @param {Organization} organization Organization to view
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   */
  populateDeviceDashboard (state, { organization, device, devicePart } = {}) {
    state.organization = organization
    state.device = device
    state.devicePart = devicePart
  },


  /**
   * Triggered when most recent alert associated with a device has been retrieved
   * @param {Device} device Owner device - if specified, only alert associated with this device will be returned
   * @param {Array[AlertOccurrence]} alerts Received alerts
   * @param {Number} age Alerts age, in seconds
   */
  deviceAlertsReceived (state, { device: { id }, alerts, age }) {
    const { device } = state
    state.lastAlertsAge = age
    if (alerts?.length > 0 && device && device.id === id) {
      device.lastAlerts = alerts
    }
  },

  /**
   * Triggered when update of device data has been received.
   * Implement this action on other stores to consume the received update.
   * @param {Device} device Device whose data has been received
   * @param {Device} masterDevice Master device, if {@link device} is a part of multi-board unit
   * @param {Device} update Received data of the device
   * @param {DeviceEntity} part Specified if update contains only partial data of the device.
   */
  deviceUpdateReceived (state, { update, part } = {}) {
    const { device, devicePart } = state
    if (device) {
      // Check if it's the device or one of its parts being updated
      const board = devicePart?.id === update.id
        ? devicePart
        : (device.id === update.id ? device : undefined)
      if (board) {
        switch (part) {
          case EntityType.DeviceFlags:
            board.flags = update.flags
            break
        }
      }
    }
  },

  /**
   * Triggered when live status of a device has been received.
   * If it's the displayed device, update its metadata so it's reflected in the view.
   * @param {DeviceStatus} status Live status of a single device
   * @param {Device} device Device whose status has been received
   * @param {Device} masterDevice Master device, if {@link device} is a part of multi-board unit
   */
  deviceStatusReceived (state, { device: { id }, status } = {}) {
    if (status && id) {
      for (const device of [state.device, state.devicePart]) {
        if (device?.id === id) {
          // status.updateDevice(device)
        }
      }
    }
  },

  /**
   * Triggered when devices are removed from a premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  unsubscribeDevices (state, { subscription = {}, devices = [] } = {}) {
    const { device } = state
    if (device && subscription && devices && devices.length > 0) {
      if (devices.find(d => d.id === device.id)) {
        if (device.premiumSubscriptionId === subscription.id) {
          device.clearPremiumService()
        }
      }
    }
  },

  /**
   * Clears free-of-charge premium services currently assigned to specified devices
   * @param {Array[Device]} devices Devices to clear the service from
   */
  clearPremiumServicesOnDevices (state, { devices } = {}) {
    const { device } = state
    if (device && devices && devices.find(d => d.id === device.id)) {
      device.clearPremiumService()
    }
  },

  /**
   * Triggered when upload job status is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJobStatus} status Upload job status
   */
  storeUploadStatus (state, { status = {} } = {}) {
    const { device } = state
    if (device && device.id === status.deviceId) {
      device.updateUploadStatus(status)
    }
  },

  /**
   * Triggered when upload job update is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJob} job Updated job
   */
  storeUploadJob (state, { job = {} } = {}) {
    const { device } = state
    if (device && device.id === job.deviceId) {
      device.updateUploadStatus(job)
    }
  },

  /**
   * Sets device comments
   * @param device Device whose custom comments are to be set
   * @param comments Comments text
   */
  setDeviceComments (state, { device: { id }, comments } = {}) {
    const { device } = state
    if (device) {
      const part = device.getPartById(id, true)
      if (part) {
        part.comments = comments
      }
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
