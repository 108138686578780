import { dispatch } from '@stellacontrol/client-utilities'
import PlacesView from './places.vue'
import PlaceView from './place.vue'

export const Routes = [
  /**
   * All places of current organization
   */
  {
    name: 'places',
    path: '/places',
    component: PlacesView,

    async beforeEnter (to, from, next) {
      await dispatch('requireOrganizations')
      await dispatch('requirePlaces')
      next()
    }
  },

  /**
   * Properties of a specified place within the current organization
   */
  {
    name: 'place',
    path: '/place/:id',
    component: PlaceView,
    // The same view place.vue is accessible from various locations in the view hierarchy.
    // To reflect that in the breadcrumbs, we've defined the view under distinct names.
    // Consequently, depending on how we arrive at place, we need to set this view name accordingly.
    viewAlias: {
      'default': 'place',
      'dashboard': 'devices-place',
      'organization': 'organization-place'
    },

    async beforeEnter (to, from, next) {
      await dispatch('requireOrganizations')

      const { params: { id }, query: { organization: organizationId } = {} } = to

      const data = id === 'new'
        ? await dispatch('newPlace', { organizationId })
        : await dispatch('getPlace', { id, withDetails: true })

      if (data) {
        const title = id === 'new' ? 'New Building' : data.name
        await dispatch('setRouteData', { from, to, data, title })
        next()
      }
    }
  }
]
