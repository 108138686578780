import { Log, pluralize, createClock } from '@stellacontrol/utilities'
import { EntityType, NoteCategory } from '@stellacontrol/model'
import { Confirmation, isRouteDataChanged } from '@stellacontrol/client-utilities'
import { CommonAPI } from '@stellacontrol/client-api'

export const actions = {

  /**
   * Called after the initial application data has been loaded.
   * Initializes the FILES view
   */
  async dataInitialized ({ state, getters, commit, dispatch }) {
    const organizationId = await dispatch('getUserPreference', { name: 'files-view-organization' })
    const organization = organizationId ? getters.organizations.find(o => o.id === organizationId) : undefined
    const filter = await dispatch('getUserPreference', { name: 'files-view-filter' })
    const age = await dispatch('getUserPreference', { name: 'files-view-age', defaultValue: state.filesView.age })
    const sortOrder = await dispatch('getUserPreference', { name: 'files-view-sort-order', defaultValue: state.filesView.sortOrder })

    commit('sortAttachments', { sortOrder })
    commit('filterAttachments', { organization, filter, age })
  },

  /**
   * On route changes stop all the watching for device status
   */
  async navigationStarted ({ dispatch }, { from, to } = {}) {
    if (isRouteDataChanged(from, to)) {
      await dispatch('unwatchAttachments')
    }
  },

  /**
   * Retrieves the specified attachment
   * @param {String} id Identifier of attachment to retrieve
   * @param {Boolean} withContent If true, attachment content is also retrieved
   * @returns {Promise<Attachment>} Saved attachment
   */
  async getAttachment ({ getters }, { id, withContent } = {}) {
    const attachment = await CommonAPI.getAttachment({ id, withContent })
    const { devices, allPlaces } = getters
    fillAttachmentEntity(attachment, devices, allPlaces)
    return attachment
  },

  /**
   * Returns URL for downloading attachment content.
   * @param {Attachment} attachment Attachment to download
   * @returns {String} URL for downloading attachment content
   */
  getAttachmentUrl (_, { attachment } = {}) {
    return CommonAPI.getAttachmentUrl({ attachment })
  },

  /**
   * Gets all attachments owned by the specified owner, associated with the specified entity
   * or linked with the specified principal
   * @param {String} ownerId Owner identifier
   * @param {String} entityId Entity identifier
   * @param {String} principalId Linked principal identifier
   * @param {Boolean} withContent If true, attachment content is also retrieved
   * @returns {Promise<Array[Attachment]>}
   */
  async getAttachments (_, { ownerId, entityId, principalId, withContent } = {}) {
    const { attachments } = await CommonAPI.getAttachments({ ownerId, entityId, principalId, withContent })
    return attachments
  },

  /**
   * Saves the specified attachment
   * @param {Entity} entity Entity with which the attachment is associated
   * @param {Attachment} attachment Attachment to save
   * @param {Organization} owner Attachment owner
   * @returns {Promise<Attachment>} Saved attachment
   */
  async saveAttachment ({ getters }, { owner, entity, attachment } = {}) {
    if (entity) {
      attachment.entityId = entity.id
      attachment.entityType = entity.getType()
      attachment.ownerId = owner?.id || getters.currentOrganization.id
    }

    try {
      const savedAttachment = await CommonAPI.saveAttachment({ attachment })
      return savedAttachment

    } catch (error) {
      if (CommonAPI.isEntityTooLargeError(error) || CommonAPI.isNetworkError(error)) {
        const limits = getters.configuration.upload.limits
        const details = `The uploaded file is too large. Maximal allowed size is ${Math.round(limits.fileSize / (1024 * 1024))} MB`
        throw new Error(details)
      } else {
        throw error
      }
    }
  },

  /**
   * Saves the specified attachments
   * @param {Entity} entity Entity with which the attachments are associated
   * @param {Array[Attachment]} attachments Attachments to save
   * @param {Organization} owner Attachment owner
   * @returns {Promise<Array[Attachment]>} Saved attachments
   */
  async saveAttachments ({ getters }, { owner, entity, attachments } = {}) {
    if (entity) {
      for (const attachment of attachments) {
        attachment.entityId = entity.id
        attachment.entityType = entity.getType()
        attachment.ownerId = owner?.id || getters.currentOrganization.id
      }
    }

    const results = []
    for (const attachment of attachments) {
      try {
        const savedAttachment = await CommonAPI.saveAttachment({ attachment })
        if (savedAttachment) {
          results.push(savedAttachment)
        }
      } catch (error) {
        if (CommonAPI.isEntityTooLargeError(error) || CommonAPI.isNetworkError(error)) {
          const limits = getters.configuration.upload.limits
          const details = `The uploaded file is too large. Maximal allowed size is ${Math.round(limits.fileSize / (1024 * 1024))} MB`
          throw new Error(details)
        } else {
          throw error
        }
      }
    }

    return results
  },

  /**
   * Deletes the specified attachment
   * @param {Attachment} attachment Attachment to delete
   * @returns {Promise<Attachment>} Deleted attachment
   */
  async deleteAttachment ({ commit }, { attachment } = {}) {
    const deletedAttachment = await CommonAPI.deleteAttachment({ id: attachment.id })
    if (deletedAttachment) {
      commit('deleteAttachment', { attachment })
    }
    return deletedAttachment
  },

  /**
   * Deletes the specified attachments
   * @param {Array[Attachment]} attachments Attachments to delete
   * @param {Boolean} confirm If true, the user must confirm deleting the attachments
   * @returns {Promise<Array[Attachment]>} Deleted attachments
   */
  async deleteAttachments ({ commit }, { attachments, confirm } = {}) {
    if (!(attachments?.length > 0)) return

    const yes = await Confirmation.ask({
      title: 'Delete?',
      message: `Do you want to delete the selected ${pluralize(attachments, 'file')}?`,
      confirm
    })

    if (yes) {
      const results = []
      for (const attachment of attachments) {
        const deletedAttachment = await CommonAPI.deleteAttachment({ id: attachment.id })
        if (deletedAttachment) {
          results.push(deletedAttachment)
        }
      }
      for (const attachment of results) {
        commit('deleteAttachment', { attachment })
      }
      return results
    }
  },

  /**
   * Sets the filter for attachments
   * @param {Organization} organization Organization whose files to load
   * @param {String} filter Text filter for the files
   * @param {Number} age File age, in days
   */
  async filterAttachments ({ commit, dispatch, state }, { organization, filter, age } = {}) {
    commit('filterAttachments', { organization, filter, age })
    dispatch('storeUserPreferences', {
      items: [
        { name: 'files-view-organization', value: state.filesView.organization?.id },
        { name: 'files-view-filter', value: state.filesView.filter },
        { name: 'files-view-age', value: state.filesView.age }
      ]
    })
  },

  /**
   * Sorts the attachments by the specified order
   * @param {String} sortOrder Sort order
   */
  async sortAttachments ({ commit, dispatch }, { sortOrder = 'date-desc' } = {}) {
    commit('sortAttachments', { sortOrder })
    dispatch('storeUserPreference', { name: 'files-view-sort-order', value: sortOrder })
  },

  /**
   * Stores the attachments in file view state
   * @param {Array[Attachment]>} attachments Attachments to store
   * @param {Array[Organization]} owners All owners of attachments
   * @param {Organization} organization Organization to which the attachments belong
   */
  async storeAttachments ({ getters, commit }, { attachments = [], organization, owners } = {}) {
    const { devices, allPlaces } = getters
    for (const attachment of attachments) {
      fillAttachmentEntity(attachment, devices, allPlaces)
    }

    // Sanitize owners, only showing those actually available to the current organization,
    // always show the current organization
    if (owners) {
      owners = owners.filter(o => getters.organizations.some(oo => oo.id === o.id))
    }
    if (!(owners?.length > 0)) {
      owners = getters.organizations || []
    }
    if (!owners.some(o => o.id === getters.currentOrganization.id)) {
      owners = [getters.currentOrganization, ...owners]
    }

    commit('storeAttachments', { organization, attachments, owners })
  },

  /**
   * Gets all attachments owned by the specified organization
   * @param {Organization} organization Organization whose files to load
   * @returns {Promise<Array[Attachment]>}
   */
  async loadAttachments ({ getters, dispatch }, { organization } = {}) {
    try {
      await dispatch('busy', { action: 'load-files' })
      organization = organization || getters.currentOrganization
      const { attachments, owners } = await CommonAPI.getAttachments({ ownerId: organization.id })
      await dispatch('storeAttachments', { attachments, organization, owners })
      return attachments
    } finally {
      await dispatch('done')
    }
  },

  /**
   * Selects/deselects the specified attachment
   * @param {Attachment} attachment  Attachment to select/deselect
   * @param {Boolean} isSelected Selection status
   */
  async selectAttachment ({ commit }, { attachment, isSelected = true } = {}) {
    commit('selectAttachment', { attachment, isSelected })
  },

  /**
   * Selects/deselects multiple attachments
   * @param {Array[Attachment]} attachments  Attachments to select/deselect
   * @param {Boolean} isSelected Selection status
   */
  async selectAttachments ({ commit }, { attachments, isSelected = true } = {}) {
    for (const attachment of attachments) {
      commit('selectAttachment', { attachment, isSelected })
    }
  },

  /**
   * Expands/collapses the specified folder or a group of files inside the folder
   * @param {AttachmentFolder} folder Folder
   * @param {String} group Group to expand, collapse
   * @param {Boolean} allGroups If true, toggle all groups in the folder
   * @param {Boolean} expanded Required state. If not specified, the current state is toggled to opposite state
   */
  async toggleAttachmentFolder ({ commit }, { folder, group, allGroups, expanded } = {}) {
    commit('toggleAttachmentFolder', { folder, group, allGroups, expanded })
  },

  /**
   * Previews the specified attachment
   * @param {Attachment} attachment Attachment to preview
   */
  async previewAttachment ({ dispatch }, { attachment }) {
    await dispatch('showDialog', {
      dialog: 'file-preview',
      data: {
        attachment
      }
    })
  },

  /**
   * Saves a device note
   * @param {Attachment} attachment Attachment
   * @param {Note} note Note to save
   * @returns {Promise<Attachment>} Modified attachment
   * @description If note text is empty, the note will be removed
   */
  async saveAttachmentNote ({ commit, getters }, { attachment, note } = {}) {
    if (attachment && note) {
      if (note.isNew) note.id = undefined
      note.entityId = attachment.id
      note.category = NoteCategory.Attachment
      const { currentUser: user } = getters
      const savedNote = await CommonAPI.saveNote({ note })
      if (savedNote) {
        commit('saveAttachmentNote', { attachment, note: savedNote, user })
        note.id = savedNote.id
      } else {
        commit('removeAttachmentNote', { attachment, note, user })
      }
      return attachment
    }
  },

  /**
   * Removes a note from the attachment
   * @param {Attachment} attachment Attachment
   * @param {Note} note Note to remove
   * @returns {Promise<Attachment>} Modified attachment
   */
  async removeAttachmentNote ({ commit, getters }, { attachment, note } = {}) {
    if (attachment && note) {
      const { currentUser: user } = getters
      if (note.id) {
        await CommonAPI.deleteNote({ note })
        commit('removeAttachmentNote', { attachment, note, user })
      }
      return attachment
    }
  },

  /**
     * Starts polling attachment changes
     * @param {String} name Name of a view or process which has initiated the polling
     * @param {Number} interval Polling interval in seconds
     */
  async watchAttachments ({ state, commit, dispatch }, { name = 'attachments', interval = 30 } = {}) {
    if (!name) throw new Error('Process initiating attachment polling is required')
    if (!interval || interval < 0) return
    // If polling already, leave
    if (state.filesView.polling[name]) return

    // Set up polling clock
    const clock = createClock({ name, frequency: 1000 })
    clock.addAlert({ name: 'attachments', interval })
    clock.addAlertListener('attachments', async () => {
      try {
        const { organization, attachments: existingAttachments } = state.filesView
        if (organization) {
          // Fetch current attachments
          const { attachments, owners } = await CommonAPI.getAttachments({ ownerId: organization.id })
          // If anything changed, update the state
          const changed = attachments.length !== existingAttachments.length ||
            attachments.some(a => {
              const existing = existingAttachments.find(ea => ea.id === a.id)
              return (!existing || existing.createdAt !== a.createdAt)
            })
          if (changed) {
            await dispatch('storeAttachments', { attachments, owners, organization })
          }
        }
      } catch (error) {
        Log.warn('Error fetching attachments')
      }
    })
    await clock.runAlert('attachments')
    await clock.start()

    commit('watchAttachments', { name, clock })
    Log.debug(`[${name}] Polling for attachments every ${interval}s`)
  },

  /**
   * Stops polling attachment changes
   * @param name Name of a view or process which has initiated the polling.
   * If not specified, all processes are stopped.
   */
  async unwatchAttachments ({ commit, state }, { name } = {}) {
    const { polling } = state.filesView
    const processes = name
      ? [polling[name]]
      : Object.values(polling)

    for (const { name, clock } of processes.filter(p => p)) {
      if (clock) {
        clock.stop()
        Log.debug(`[${name}] Stopped polling for attachments`)
        commit('unwatchAttachments', { name })
      }
    }
  },

  /**
   * Links the specified attachment to the specified principal.
   * @param {Attachment} attachment Attachment to link
   * @param {Principal} principal Principal to link the attachment to
   * @param {Boolean} allowEdit If true, the principal can not only view the attachment but also modify it
   * @param {Boolean} allowDelete If true, the principal can not only view the attachment but also delete it
   * @param {Boolean} removeExistingLinks If true, any existing links will be removed
   * @returns {Promise<Attachment>} Linked attachment
   */
  async linkAttachment (_, { attachment, principal, allowEdit = false, allowDelete = false, removeExistingLinks = false }) {
    if (attachment && principal) {
      const savedAttachment = await CommonAPI.linkAttachment({ attachment, principal, allowEdit, allowDelete, removeExistingLinks })
      return savedAttachment
    }
  },

  /**
   * Unlinks the specified attachment from the specified principal
   * or from all principals to which it is currently linked.
   * @param {Attachment} attachment Attachment to unlink
   * @param {Principal} principal Principal to unlink the attachment from.
   * If not specified, the attachment will be unlinked from all principals to which it is currently linked
   * @returns {Promise<Attachment>} Unlinked attachment
   */
  async unlinkAttachment (_, { attachment, principal }) {
    if (attachment && principal) {
      const savedAttachment = await CommonAPI.unlinkAttachment({ attachment, principal })
      return savedAttachment
    }
  }
}

/**
 * Fill in the entities related to the specified attachment
 */
function fillAttachmentEntity (attachment, devices = [], places = []) {
  const entities = {
    [EntityType.Device]: devices,
    [EntityType.Place]: places
  }
  attachment.entity = (entities[attachment.entityType] || []).find(d => d.id === attachment.entityId)
  return attachment
}