import { itemCompare, itemValue } from './compare'

/**
 * Flattens the nested array of arrays
 * @param {Array} array Array to flatten
 * @param {Number} depth Depth of flattening
 * @returns {Generator} Enumerable of the flattened elements
 */
export function* flatten (array, depth) {
  if (depth === undefined) {
    depth = 1
  }

  if (array) {
    for (const item of array) {
      if (Array.isArray(item) && depth > 0) {
        yield* flatten(item, depth - 1)
      } else {
        yield item
      }
    }
  }
}

/**
 * Returns distinct elements from the specified array
 * @param {Array} items Elements to select from
 * @param {String|Function} getter Property name or function extracting value from array elements.
 * If not specified, array elements are treated as values.
 * @returns {Array} Distinct elements from the specified array
 */
export function distinctItems (items, getter) {
  if (items) {
    const values = []
    return items.filter(item => {
      const value = itemValue(item, getter)
      if (!values.includes(value)) {
        values.push(value)
        return item
      }
    })
  }
}

/**
 * Returns the number of unique elements in the specified array
 * @param {Array} items Elements to scan
 * @param {String|Function} getter Property name or function extracting value from array elements.
 * If not specified, array elements are treated as values.
 * @returns {Number} Number of unique elements in the specified array
 */
export function distinctItemCount (items, getter) {
  return distinctItems(items, getter)?.length
}

/**
 * Returns unique values extracted from elements in the specified array
 * @param {Array} items Elements to select from
 * @param {String|Function} getter Property name or function extracting value from array elements.
 * If not specified, array elements are treated as values.
 * @returns {Array} Unique values from elements in the specified array
 */
export function distinctValues (items, getter) {
  if (items) {
    const values = []
    const valueFn = getter
      ? (typeof getter === 'function' ? item => getter(item) : item => item[getter.toString()])
      : item => item
    items.filter(item => {
      const value = valueFn(item)
      if (!values.includes(value)) {
        values.push(value)
        return value
      }
    })
    return values
  }
}

/**
 * Compares the arrays, returns true if both arrays have the same elements, not necessarily in the same order
 * @param {Array} a Array
 * @param {Array} b Array
 * @param {Function} getter Optional value extract function, getting the array item as argument and returning a value to compare
 */
export function sameArrays (a, b, getter) {
  if (a && b) {
    return (a.length === b.length) &&
      a.every(aa => getter
        ? b.find(bb => itemCompare(aa, bb, getter) === 0)
        : b.indexOf(aa) > -1)
  }
}

/**
 * Returns union of the specified array
 * @param {Array} a Array
 * @param {Array} b Array
 * @param {Function} getter Optional value extract function, getting the array item as argument and returning a value to compare
 */
export function arrayUnion (a, b, getter) {
  if (a && b) {
    return a.filter(aa => {
      const item = getter ? getter(aa) : aa
      return b.some(bb => item === (getter ? getter(bb) : bb))
    })
  }
}

/**
 * Returns values of the specified array which aren't found in the other array
 * @param {Array} a Array
 * @param {Array} b Array
 * @param {Function} getter Optional value extract function, getting the array item as argument and returning a value to compare
 */
export function arrayDifference (a, b, getter) {
  if (a && b) {
    return a.filter(aa => {
      const item = getter ? getter(aa) : aa
      return !b.some(bb => item === (getter ? getter(bb) : bb))
    })
  }
}

/**
 * Filters the array using async predicate
 * @param {Array} items Array to filter
 * @param {Function<any, Boolean>} predicate Async predicate to check
 * @returns {Array} Elements of the array matching the predicate
 */
export async function asyncFilter (items, predicate) {
  if (items && predicate) {
    return items.reduce(async (all, e) =>
      [
        ...await all,
        ...await predicate(e) ? [e] : []
      ], [])
  }
}

/**
 * Finds the last item in the array matching the predicate
 * @param {Array} items Items to scan
 * @param {Function<any, Boolean>} predicate Predicate to check on each array item.
 * If not specified, the function simply returns the last element of the array.
 * @returns {Object} Found item
 */
export function findLast (items, predicate) {
  if (items) {
    if (predicate) {
      return [...items].reverse().find(item => predicate(item))
    } else {
      return items[items.length - 1]
    }
  }
}

/**
 * Finds the index of the last item in the array matching the predicate
 * @param {Array} items Items to scan
 * @param {Function<any, Boolean>} predicate Predicate to check on each array item.
 * If not specified, the function simply returns the last element of the array.
 * @returns {Number} Index of the found item
 */
export function findLastIndex (items, predicate) {
  if (items) {
    if (predicate) {
      const index = [...items].reverse().findIndex(item => predicate(item))
      return index == -1
        ? index
        : items.length - index - 1
    } else {
      return items.length - 1
    }
  }
}

/**
 * Finds item in the array by specified value
 * @param {Array} items Items to scan
 * @param {Object} findValue Value to look for
 * @param {String|Function} getter Property to search by, or function extracting the value
 * from the searched items. If not specified, items will be treated as primitive values
 * and compared directly.
 * @returns {Object} Found item
 */
export function findByValue (items, findValue, getter) {
  if (items) {
    return items.find(item => findValue == itemValue(item, getter))
  }
}

/**
 * Finds index of item in the array by specified value
 * @param {Array} items Items to scan
 * @param {Object} findValue Value to look for
 * @param {String|Function} getter Property to search by, or function extracting the value from the items.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Number} Index of the found item
 */
export function findIndexByValue (items, findValue, getter) {
  if (items) {
    return items.findIndex(item => findValue == itemValue(item, getter))
  }
}

/**
 * Finds biggest item in the array, by specified value
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property to search by, or function extracting the value from the item.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Object} Found biggest item in the array
 */
export function findBiggest (items, getter) {
  if (items) {
    let biggest = items[0]

    for (let i = 1; i < items.length; i++) {
      let next = items[i]
      if (next != null && biggest != null && itemCompare(next, biggest, getter) === 1) {
        biggest = next
      }
    }

    return biggest
  }
}

/**
 * Finds smallest item in the array, by specified value
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property to search by, or function extracting the value from the item.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Object} Found smallest item in the array
 */
export function findSmallest (items, getter) {
  if (items) {
    let smallest = items[0]

    for (let i = 1; i < items.length; i++) {
      let next = items[i]
      if (next != null && smallest != null && itemCompare(next, smallest, getter) === -1) {
        smallest = next
      }
    }

    return smallest
  }
}

/**
 * Finds biggest value of an item in the array
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property to search by, or function extracting the value from the item.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Object} Found biggest value of the found item
 */
export function findBiggestValue (items, getter) {
  if (items) {
    let biggest = items[0]

    for (let i = 1; i < items.length; i++) {
      let next = items[i]
      if (next != null && biggest != null && itemCompare(next, biggest, getter) === 1) {
        biggest = next
      }
    }

    return itemValue(biggest, getter)
  }
}

/**
 * Finds smallest value of an item in the array
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property to search by, or function extracting the value from the item.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Object} Found smallest value of the found item
 */
export function findSmallestValue (items, getter) {
  if (items) {
    let smallest = items[0]

    for (let i = 1; i < items.length; i++) {
      let next = items[i]
      if (next != null && smallest != null && itemCompare(next, smallest, getter) === -1) {
        smallest = next
      }
    }

    return itemValue(smallest, getter)
  }
}

/**
 * Calculates a sum of values in the array
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property or function extracting the value from the item.
 * If not specified, items will be treated as numbers and summed directly.
 * @returns {Number} Sum of collected values
 */
export function sumItems (items, getter) {
  if (items?.length > 0) {
    return items.reduce((sum, item) => sum + itemValue(item, getter), 0)
  }
}

/**
 * Calculates an average of values in the array
 * @param {Array} items Items to scan
 * @param {String|Function} getter Property or function extracting the value from the item.
 * If not specified, items will be treated as numbers and averaged directly.
 * @returns {Number} Average of collected values
 */
export function averageItems (items, getter) {
  if (items?.length > 0) {
    return sumItems(items, getter) / items.length
  }
}

/**
 * Toggles the specified item in the array:
 * adds if not present, removes if present
 * @param {Array} items Items
 * @param {Object} item Item to toggle
 * @param {String|Function} getter Property to search by, or function extracting the value from the item.
 * If not specified, items will be treated as primitive values and compared directly.
 * @returns {Array} Modified array
 */
export function toggleItem (items, item, getter) {
  if (items) {
    const result = [...items]
    const foundAt = findIndexByValue(result, itemValue(item, getter), getter)
    if (foundAt === -1) {
      return [...items, item]
    } else {
      const result = [...items]
      result.splice(foundAt, 1)
      return result
    }
  }
}

/**
 * Bundles array items into buckets, using the specified bundler
 * @param {Array} items Items to bundle
 * @param {Number|Function|String} bundler Number of items per bundle, bundling function, returning bundle identifier for each item or simply item property
 * @param {String} unassignedKey Key under which the items for which the bundler returned null should be grouped
 * @returns {Dictionary<any, Array>} Dictionary of bundled items
 */
export function groupItems (items, bundler, unassignedKey = 'unassigned') {
  if (items && bundler != null) {
    if (items.length === 0) {
      return items
    }

    const fixedBatch = typeof bundler === 'number'
    if (fixedBatch) {
      const batchSize = bundler
      if (batchSize <= 0) throw new Error('Bundle size must be a positive number')
      bundler = (item, index) => Math.floor(index / batchSize)
    }

    const byProperty = typeof bundler === 'string'
    if (byProperty) {
      const property = bundler
      bundler = (item) => item[property]
    }

    const bundles = {}
    unassignedKey = 'unassigned'
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const key = bundler(item, i)?.toString() || unassignedKey
      if (!bundles[key]) {
        bundles[key] = []
      }
      bundles[key].push(item)
    }

    return bundles
  }
}

/**
 * Checks if the specified object is iterable
 * @param {any} instance Instance to check
 * @returns {Boolean} True if instance is iterable
 */
export function isIterable (instance) {
  if (instance != null) {
    return typeof instance[Symbol.iterator] === 'function'
  } else {
    return false
  }
}

/**
 * Returns a sorted array of items
 * @param {Array} items Items to sort
 * @param {String|Function} getter Optional property name or value extraction function.
 * If not specified, values are compared directly.
 * @param {Boolean} reverse If true, items will be sorted in reverse order
 * @returns {Array} Sorted items
 */
export function sortItems (items, getter, reverse) {
  if (isIterable(items)) {
    const sortedItems = [...items]

    sortedItems.sort((a, b) => {
      const result = itemCompare(a, b, getter)
      return reverse ? -result : result
    })

    return sortedItems
  }
}

/**
 * Returns a sorted array of items in reverse order
 * @param {Array} items Items to sort
 * @param {Function<any, any, Number>} getter Comparer function
 * @param {String|Function} getter Optional property name or value extraction function.
 * If not specified, values are compared directly.
 * @returns {Array} Items sorted in reverse order
 */
export function sortItemsReverse (items, getter) {
  return sortItems(items, getter, true)
}

/**
 * Returns an array of items sorted by specified property
 * @param {Array} items Items to sort
 * @param {String} property Property name
 * @param {Boolean} reverse If true, sorts in reverse order
 * @returns {Array} Sorted items
 */
export function sortItemsBy (items, property, reverse) {
  return sortItems(items, a => a[property], reverse)
}

/**
 * Returns a sorted array of items using the specified comparer function
 * @param {Array} items Items to sort
 * @param {Function} comparer Comparer function (a, b) => -1|0|1
 * @param {String|Function} getter Optional property name or value extraction function
 * for obtaining values to compare with the comparer function. If not specified, values are compared directly.
 * @param {Boolean} reverse If true, items will be sorted in reverse order
 * @returns {Array} Sorted items
 */
export function sortItemsWith (items, comparer, getter, reverse) {
  if (isIterable(items) && typeof comparer === 'function') {
    const sortedItems = [...items]

    sortedItems.sort((a, b) => {
      const va = itemValue(a, getter)
      const vb = itemValue(b, getter)
      const result = comparer(va, vb)
      return reverse ? -result : result
    })

    return sortedItems
  }
}

/**
 * Reverses an array.
 * Unline `Array.reverse`, this does not reverse the array in place
 * but returns a new reversed array
 * @param {Array} items Array to reverse
 * @returns {Array}
 */
export function reverseArray (items) {
  return items?.toReversed()
}