export const actions = {
  /**
   * Stores devices, places and organization for the DEVICE DASHBOARD view
   * @param {Organization} organization Organization to view
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   */
  async populateDeviceDashboard ({ commit }, { organization, device, devicePart }) {
    commit('populateDeviceDashboard', { organization, device, devicePart })
  }
}
