import { Log } from '@stellacontrol/utilities'
import { dispatch } from '@stellacontrol/client-utilities'
import { EntityType, DeviceHierarchy, getDeviceLabel, getPlaceDescription, getOrganizationDescription } from '@stellacontrol/model'

/**
 * Prepares the data required by dashboard view
 */
export async function resolve ({ from, to }) {
  let { entityType, organization: organizationId, place: placeId, device: deviceId, part: partNumber } = to.query || {}
  let organization, organizationGuardian, place, device, devicePart, title

  // Create the hierarchy of organizations, places and devices
  const currentOrganization = await dispatch('getCurrentOrganization')
  const guardian = await dispatch('getCurrentOrganizationGuardian')
  const organizations = await dispatch('requireOrganizations')
  const places = await dispatch('requirePlaces')
  const devices = await dispatch('requireDevices')

  const hierarchy = DeviceHierarchy.fromOrganizations({
    organization: currentOrganization,
    organizations,
    places,
    devices
  })

  if (!(currentOrganization && hierarchy)) {
    return {
      redirectTo: {
        name: 'home'
      }
    }
  }

  // If no entity specified explicitly, try find the last-selected in the tree.
  // If no success either, redirect to current organization's dashboard
  if (!(entityType && (organizationId || placeId || deviceId))) {
    const selected = await dispatch('getUserPreference', { name: 'dashboard-selected-item' })

    if (selected) {
      const selectedEntity = hierarchy.items.find(item => item.id === selected)
      if (selectedEntity) {
        entityType = selectedEntity.type

        switch (entityType) {
          case EntityType.Organization:
            organizationId = selectedEntity.id
            break
          case EntityType.Place:
            placeId = selectedEntity.id
            organizationId = selectedEntity.organizationId
            break
          case EntityType.Device:
            deviceId = selectedEntity.id
            placeId = selectedEntity.placeId
            organizationId = selectedEntity.organizationId
            break
        }

        if (organizationId || placeId || deviceId) {
          return {
            redirectTo: {
              name: to.name,
              query: {
                entityType,
                organization: organizationId,
                place: placeId,
                device: deviceId,
                part: partNumber,
                fast: true
              }
            }
          }
        }
      }
    }
  }

  // If no success in determining what to load, redirect to current organization's dashboard
  if (!(entityType && (organizationId || placeId || deviceId))) {
    return {
      redirectTo: {
        name: to.name,
        query: {
          entityType: EntityType.Organization,
          organization: currentOrganization.id,
          fast: true
        }
      }
    }
  }

  // If viewing device, fetch device details and its organization and place
  if (entityType === EntityType.Device && deviceId) {
    // VIEWING A DEVICE DASHBOARD
    device = deviceId.startsWith('dev')
      ? await dispatch('getDevice', { id: deviceId, withDetails: true })
      : await dispatch('getDeviceBySerialNumber', { serialNumber: deviceId, withDetails: true })
    if (device) {
      title = getDeviceLabel(device)
      organization = await dispatch('getDashboardViewOrganization', { id: device.ownerId }) || currentOrganization
      place = device.placeId
        ? await dispatch('getPlace', { id: device.placeId, withDetails: true })
        : await dispatch('getPlace', { id: 'none', organizationId: organization.id, withDetails: true })
      devicePart = device.isMultiDevice
        ? device.getPart(partNumber)
        : null
    }

  }
  else if (entityType === EntityType.Place && (placeId || organizationId)) {
    // VIEWING A PLACE DASHBOARD
    place = await dispatch('getPlace', {
      id: placeId || 'none',
      organizationId,
      withAttachments: true
    })
    organizationId = place.organizationId
    if (place) {
      const thisOrganization = (place.organizationId === currentOrganization.id)
      organization = await dispatch('getDashboardViewOrganization', { id: place.organizationId })
      title = getOrganizationDescription(organization, !thisOrganization)
      if (place.id === 'none') {
        title = title + ' / ' + place.name
      } else {
        title = title + ' / ' + getPlaceDescription(place)
      }
    }

  } else if (entityType === EntityType.Organization && organizationId) {
    // VIEWING AN ORGANIZATION DASHBOARD
    const thisOrganization = (organizationId === currentOrganization.id)
    organization = thisOrganization
      ? currentOrganization
      : await dispatch('getDashboardViewOrganization', { id: organizationId })
    if (organization) {
      title = getOrganizationDescription(organization, !thisOrganization)
    }
  }

  // If requested entities not found specified, redirect to current organization's dashboard
  if (!(entityType && (organization || place || device))) {
    return {
      redirectTo: {
        name: to.name,
        query: {
          entityType: EntityType.Organization,
          organization: currentOrganization.id,
          fast: true
        }
      }
    }
  }

  // CHECK IF ENTITY CAN BE SEEN BY THIS ORGANIZATION
  const entityId = deviceId || placeId || organizationId
  const knownEntity = hierarchy.find(entityId, entityType)
  if (!knownEntity) {
    Log.warn(`Unauthorized access to ${entityType} [${entityId}] dashboard`)
    return {
      redirectTo: {
        name: to.name,
        query: {
          entityType: EntityType.Organization,
          organization: currentOrganization.id,
          fast: true
        }
      }
    }
  }

  // TODO: TEMPORARY, REMOVE WHEN OLD DASHBOARD IS GONE!
  // If organization granted permission for the new Buildings dashboard,
  // redirect from here
  if ((entityType === EntityType.Organization || entityType === EntityType.Place) &&
    !currentOrganization.isSuperOrganization &&
    guardian.canUse('buildings')) {
    Log.warn('Redirecting to the new BUILDINGS page')
    return {
      redirectTo: { name: 'buildings' }
    }
  }

  // Determine security guardian for the viewed organization
  if (organization) {
    organizationGuardian = await dispatch('getGuardian', { principal: organization, force: true })
  }

  // Check whether to hide the assets tree
  const isTreeMinimized = to.query.tree === 'minimized'
  if (entityType) {
    await dispatch('setRouteData', { from, to, title })
    await dispatch('initializeDashboardView', {
      title,
      hierarchy,
      entityType,
      organization,
      organizationGuardian,
      place,
      device,
      devicePart,
      isTreeMinimized
    })
    return { title, entityType, hierarchy, organization, place, device }
  }
}
