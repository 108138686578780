import { ListViewMode } from '@stellacontrol/client-utilities'
import { Place, sortPlaces, PlaceSortOrder } from '@stellacontrol/model'
import { createState, getPlaceDevices } from './dashboard-view.organization.state'

export const mutations = {
  /**
   * Stores devices, places and organization for the ORGANIZATION DASHBOARD view
   * @param organization Organization to view
   * @param place Place within the organization to view
   * @param ownDevices Devices of the organization
   * @param sharedDevices Devices shared with organization
   * @param places Places of the organization
   * @param expandedPlaces List of identifiers of places which are initially expanded
   */
  populateOrganizationDashboard (
    state,
    {
      organization,
      place,
      ownDevices = [],
      sharedDevices = [],
      places = [],
      expandedPlaces = [],
      collapsedPlaceGroups = []
    } = {}) {

    state.ownDevices = ownDevices
    state.sharedDevices = sharedDevices
    state.devices = [
      ...ownDevices,
      // Prevent duplicates - device might be owned by organization or its child
      // and additionally (in error) shared with it again
      ...sharedDevices.filter(({ id }) => !ownDevices.find(d => d.id === id))
    ]
    state.places = sortPlaces(places, PlaceSortOrder.Custom)
    state.organization = organization
    state.place = place
    state.expandedPlaces = expandedPlaces
    state.collapsedPlaceGroups = collapsedPlaceGroups
    state.selectedDevices = []
    state.allDevicesSelected = false
  },

  /**
   * Stores an place in the state
   * @param place Place to store
   */
  storePlace (state, { place } = {}) {
    if (!place) return

    const { id } = place
    const index = state.places.findIndex(i => i.id === id)
    if (index === -1) {
      state.places.push(place)
    } else {
      state.places[index] = place
    }
  },

  /**
   * When place is removed, also uncouple any devices currently assigned to it
   * @param place Removed place
   */
  removePlace (state, { place } = {}) {
    if (!place) return

    const { id } = place
    const index = (state.places || []).findIndex(i => i.id === id)
    if (index !== -1) {
      state.places.splice(index, 1)
    }
    const devicesInPlace = state.devices.filter(d => d.placeId === id)
    for (const device of devicesInPlace) {
      device.placeId = undefined
    }
  },

  /**
   * Toggles place as expanded/collapsed
   */
  togglePlace (state, { place, isExpanded } = {}) {
    if (!place) return

    const { id } = place
    const isNowExpanded = state.expandedPlaces.includes(id)

    // Toggle
    if (isExpanded === undefined) {
      isExpanded = !isNowExpanded
    }

    if (isExpanded && !isNowExpanded) {
      // Expand
      state.expandedPlaces.push(id)
    } else if (isNowExpanded && !isExpanded) {
      // Collapse
      state.expandedPlaces = state.expandedPlaces.filter(i => i !== id)
    }

    // Filter out expanded places which are no longer there
    state.expandedPlaces = state
      .expandedPlaces
      .filter(id => id === Place.ID_NOPLACE || id === Place.ID_SHAREDPLACE || state.places.find(p => p.id === id))
  },

  /**
   * Toggles place group as expanded/collapsed
   * @param place Place
   * @param placeGroup Place group to toggle
   * @param isExpanded Optional status of the place group. If not specified, place group status is toggled.
   */
  togglePlaceGroup (state, { place, placeGroup, isExpanded } = {}) {
    if (!place) return

    const index = state.collapsedPlaceGroups.findIndex(item => item.placeId === place.id && item.group === placeGroup)
    const isNowExpanded = index === -1
    if (isExpanded === undefined) {
      isExpanded = !isNowExpanded
    }

    if (isExpanded && !isNowExpanded) {
      // Expand
      state.collapsedPlaceGroups.splice(index, 1)
    } else if (isNowExpanded && !isExpanded) {
      state.collapsedPlaceGroups.push({ placeId: place.id, group: placeGroup })
    }
  },

  /**
   * Changes sorting order of places by moving the specified place before another one
   * @param place Place to move
   * @param before Place before which the specified place is to be moved
   */
  movePlaceBefore (state, { place: { id } = {}, before }) {
    state.places = sortPlaces(state.places, PlaceSortOrder.Custom)
    const { places } = state

    const placeIndex = places.findIndex(p => p.id === id)
    if (placeIndex > -1) {
      const place = places.splice(placeIndex, 1)[0]
      const beforeIndex = places.findIndex(p => p.id === before.id)
      if (beforeIndex > -1) {
        places.splice(beforeIndex, 0, place)
        let sortOrder = 1
        for (const item of places) {
          item.sortOrder = sortOrder++
        }
      }
    }
  },

  /**
   * Changes sorting order of devices by moving the specified device before another one
   * @param device Device to move
   * @param atDevice Device at which the specified device is to be moved
   */
  moveDeviceAt (state, { device: { id, placeId }, atDevice: { id: atId } }) {
    const devices = getPlaceDevices(state.devices, { id: placeId })
    const deviceIndex = devices.findIndex(p => p.id === id)
    const atIndex = devices.findIndex(p => p.id === atId)

    if (deviceIndex > -1 && atIndex > -1) {
      // Remove the device from its current position
      const atDevice = devices[atIndex]
      const device = devices.splice(deviceIndex, 1)[0]
      const deviceGroup = device.placeGroup
      const atGroup = atDevice.placeGroup
      const targetIsBefore = atIndex < deviceIndex
      const moveBefore = atIndex < deviceIndex && inSameGroup(devices, device, atDevice)

      // Move device before the specified device?
      if (moveBefore) {
        devices.splice(targetIsBefore ? atIndex : atIndex - 1, 0, device)
      } else {
        // Move device after the specified device?
        if (atIndex < devices.length - 1) {
          devices.splice(targetIsBefore ? atIndex + 1 : atIndex, 0, device)
        } else {
          devices.push(device)
        }
      }

      // If target device was beginning a group in a place,
      // assign this group to the dropped device now
      if (atGroup) {
        if (moveBefore) {
          atDevice.placeGroup = null
          atDevice.placeSeparator = false
        } else {
          device.placeGroup = null
          device.placeSeparator = false
        }
      }

      // If dragged device was beginning a group, make its successor head of that group
      if (deviceGroup) {
        const successor = devices.find(d => d.sortOrder > device.sortOrder)
        if (successor && !successor.placeGroup) {
          successor.placeGroup = deviceGroup
          successor.placeSeparator = false
          if (!atGroup) {
            device.placeSeparator = false
            device.placeGroup = null
          }
        }
      }

      // Reorder the devices
      let sortOrder = 1
      for (const item of devices) {
        item.sortOrder = sortOrder++
      }
    }
  },

  /**
   * Sets place group at the specified device
   * @param device Device
   * @param placeGroup Name of a place group to start at the specified device
   */
  setPlaceGroup (state, { device: { id }, placeGroup }) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.placeGroup = placeGroup
      device.placeSeparator = false
    }
  },

  /**
   * Removes place group at the specified device
   * @param device Device
   */
  removePlaceGroup (state, { device: { id } }) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.placeGroup = null
      device.placeSeparator = false
    }
  },

  /**
   * Adds separator before the specified device
   * @param device Device
   */
  addPlaceSeparator (state, { device: { id } }) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.placeSeparator = true
    }
  },

  /**
   * Removes separator before the specified device
   * @param device Device
   */
  removePlaceSeparator (state, { device: { id } }) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.placeSeparator = false
    }
  },

  /**
   * Marks a device as assigned to the specified place
   * @param device Device to set the place
   * @param place Place to assign to. If none, device will be unassigned from its current place
   */
  setDevicePlace (state, { place, device: { id } } = {}) {
    const device = state.devices.find(d => d.id === id)

    if (device) {
      device.place = place ? place : undefined
      device.placeId = place ? place.id : undefined
      // Add to the beginning
      device.sortOrder = 0
    }
  },

  /**
   * Sets device location
   * @param device Device whose custom location is to be set
   * @param location Location details
   * @param customLocation Custom location details
   */
  setDeviceLocation (state, { device: { id }, location, customLocation } = {}) {
    const device = state.devices.find(d => d.id === id)

    if (device) {
      if (location !== undefined) {
        device.location = location
      }
      if (customLocation !== undefined) {
        device.customLocation = customLocation
      }
    }
  },

  /**
   * Sets device comments
   * @param device Device whose custom comments are to be set
   * @param comments Comments text
   */
  setDeviceComments (state, { device: { id }, comments } = {}) {
    const device = state.devices.find(d => d.id === id)

    if (device) {
      device.comments = comments
    }
  },

  /**
   * Sets device name
   * @param device Device whose custom name is to be set
   * @param name Device name
   */
  setDeviceName (state, { device: { id }, name } = {}) {
    const device = state.devices.find(d => d.id === id)
    if (device) {
      device.name = name
    }
  },

  /**
   * Adds a new (non-connected) device to the specified place
   * @param device Device to add to the place
   * @param place Place to add to
   */
  addNewDeviceToPlace (state, { place, device } = {}) {
    device.place = place ? place : undefined
    device.placeId = place ? place.id : undefined
    state.devices.push(device)
  },

  /**
   * A (non-connected) device has been deleted
  * @param device Deleted device
  */
  deviceDeleted (state, { device } = {}) {
    const index = state.devices.findIndex(d => d.id === device.id)
    if (index > -1) {
      state.devices.splice(index, 1)
    }
  },

  /**
   * Signals that device has been unlinked from an organization.
   * If device happens to be shared with the currently shown organization, remove it from view.
   * @param device Device to unlink
   * @param organization Organization from which the device has been unlinked
   */
  unlinkDevice (state, { device, organization } = {}) {
    if (device && organization && state.organization && organization.id === state.organization.id) {
      let index = state.devices.findIndex(d => d && d.id === device.id)
      if (index > -1) {
        state.devices.splice(index, 1)
      }

      index = state.sharedDevices.findIndex(d => d && d.id === device.id)
      if (index > -1) {
        state.sharedDevices.splice(index, 1)
      }
    }
  },

  /**
   * Triggered when devices are removed from a premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  unsubscribeDevices (state, { subscription = {}, devices = [] } = {}) {
    if (subscription && devices && devices.length > 0) {
      for (const device of state.devices) {
        if (devices.find(d => d.id === device.id)) {
          if (device.premiumSubscriptionId === subscription.id) {
            device.clearPremiumService()
          }
        }
      }
    }
  },

  /**
   * Clears free-of-charge premium services currently assigned to specified devices
   * @param {Array[Device]} devices Devices to clear the service from
   */
  clearPremiumServicesOnDevices (state, { devices } = {}) {
    if (devices && devices.length > 0) {
      for (const device of state.devices) {
        if (devices.find(d => d.id === device.id)) {
          device.clearPremiumService()
        }
      }
    }
  },

  /**
   * Triggered when upload job status is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJobStatus} status Upload job status
   */
  storeUploadStatus (state, { status = {} } = {}) {
    const device = state.devices.find(device => device.id === status.deviceId)
    if (device) {
      device.updateUploadStatus(status)
    }
  },

  /**
   * Triggered when upload job update is received.
   * Updates upload status on any corresponding devices.
   * @param {UploadJob} job Updated job
   */
  storeUploadJob (state, { job = {} } = {}) {
    const device = state.devices.find(device => device.id === job.deviceId)
    if (device) {
      device.updateUploadStatus(job)
    }
  },

  /**
   * Selects or deselect a device for actions, commands etc.
   * @param place Place where device is present
   * @param device Device to select or deselect
   * @param isSelected Selection status
   */
  selectDeviceInPlace (state, { place, device: { id }, isSelected } = {}) {
    const device = state.devices.find(d => d.id === id)
    if (place && device) {
      const index = state.selectedDevices.findIndex(d => d.id === id)
      if (isSelected && index === -1) {
        state.selectedDevices.push(device)
      }

      if (!isSelected && index > -1) {
        state.selectedDevices.splice(index, 1)
      }
    }
  },

  /**
   * Selects or deselect all devices in a specified place
   * @param {Place} place Place where device is present
   * @param {Boolean} isSelected Selection status
   * @param {Array[Device]} allowed List of devices which are actually allowed to be selected,
   * can be a subset of all devices in the place. Useful when action to be performed on a batch
   * of devices is not applicable to some of them
   */
  selectDevicesInPlace (state, { place, isSelected, allowed } = {}) {
    if (place) {
      const devices = getPlaceDevices(state.devices, place)
      for (const device of devices) {
        if (isSelected && allowed && !allowed.find(d => d.id === device.id)) {
          continue
        }

        const index = state.selectedDevices.findIndex(d => d.id === device.id)
        if (isSelected && index === -1) {
          state.selectedDevices.push(device)
        }

        if (!isSelected && index > -1) {
          state.selectedDevices.splice(index, 1)
        }
      }
    }
  },

  /**
   * Selects or deselect all devices in all places
   * @param place Place where device is present
   * @param isSelected Selection status
   */
  selectDevicesInAllPlaces (state, { isSelected } = {}) {
    if (isSelected) {
      state.selectedDevices = [...state.devices]
    } else {
      state.selectedDevices = []
    }
  },

  /**
   * Sets view mode for devices in places
   * @param viewMode Devices list view mode
   */
  setPlaceDevicesViewMode (state, { viewMode = ListViewMode.MiniCards } = {}) {
    state.devicesListViewMode = viewMode
  },

  /**
   * Filters places and devices by specified text
   */
  filterPlaces (state, { filter } = {}) {
    state.filter = filter
  },

  /**
   * Triggered when live status of a device has been received.
   * If it's the displayed device, update its metadata so it's reflected in the view.
   * @param {DeviceStatus} status Live status of a single device
   * @param {Device} device Device whose status has been received
   * @param {Device} masterDevice Master device, if {@link device} is a part of multi-board unit
   */
  deviceStatusReceived (state, { device: { id }, status } = {}) {
    if (status && id) {
      for (const device of state.devices) {
        if (device?.id === id) {
          status.updateDevice(device)
        }
      }
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}

// Returns a place group to which a device belongs
function getDeviceGroup (devices, device) {
  let group = device.placeGroup
  if (!group) {
    const predecessors = [...devices.filter(d => d.sortOrder < device.sortOrder)].reverse()
    group = (predecessors.find(p => p.placeGroup) || {}).placeGroup
  }
  return group
}

// Returns true if devices are in the same place group
function inSameGroup (devices, a, b) {
  return getDeviceGroup(devices, a) == getDeviceGroup(devices, b)
}
