import { Point, countString } from '@stellacontrol/utilities'

/**
 * Plan tooltip
 */
export class PlanTooltip {
  constructor (data = {}) {
    this.text = data.text
    this.position = data.position
  }

  /**
   * Tooltip text, can contain HTML markup
   * @type {String}
   */
  text

  /**
   * Tooltip coordinates
   * @type {Point}
   */
  position

  /**
   * Indicates that the tooltip is valid and ready to show
   * @type {Boolean}
   */
  get isValid () {
    return this.text?.trim() && this.position != null
  }

  /**
   * Hides the tooltip
   */
  hide () {
    this.text = null
    this.position = null
  }
}

/**
 * Returns a tooltip for the specified plan item
 * @param {PlanItem} item Item to show the tooltip for
 * @param {Point} position Position at which to show the tooltip
 * @param {PlanLayout} layout The entire plan layout
 * @param {PlanFloor} floor The currently displayed floor
 * @returns {PlanTooltip}
 */
export function getPlanItemTooltip ({ item, position, layout, floor }) {
  if (!(item && position && layout && floor)) return

  let text
  position = Point.from(position || item.coordinates).moveBy({ x: 10, y: 10 })

  // Cable plug
  if (item.isPlug) {
    const riser = layout.getRiser(item.riser)
    text = [
      `Cable Riser (${countString(riser.connectors, 'cable')})`
    ].join('<br>')
  }

  // Part of a cross-section cable
  if (item.isConnector && item.partOf) {
    const connector = layout.getItem(item.partOf)
    if (connector) {
      const endItem = connector.getOtherItem(item.start.item)
      const endFloor = layout.getFloor(endItem.floorId)
      text = `${item.toHumanString()} cable connected to ${endItem.toHumanString(true)} ${endItem.tag ? ` (${endItem.tag}${endItem.tagIndex})` : ''} on the ${endFloor.label}`
    }
  }

  if (text) {
    return new PlanTooltip({
      text,
      position
    })
  }
}
