<script>
import { reactive, watch } from 'vue'
import { Note } from '@stellacontrol/model'

export default {
  props: {
    /**
     * Notes to edit
     */
    notes: {
      type: Array,
      default () {
        return []
      }
    },

    /**
     * Note recipients
     */
    recipients: {
      type: Array,
      default () {
        return []
      }
    },

    /**
     * Entity to which the note is linked
     */
    entity: {
      type: Object,
      required: true
    },

    /**
     * Note category to use,
     * when creating new notes
     */
    category: {
      type: String,
      required: true
    },

    /**
     * Note creator user
     */
    creator: {
      type: Object,
      required: true
    },

    /**
     * Use this to specify which notes can be edited.
     * Possible values are:
     *    none: notes can only be read
     *    own: only the notes by the specified creator are editable
     *    all: all notes are editable
     */
    editMode: {
      type: String,
      value: 'none'
    },

    // If true, a new note will be created automatically
    // when notes editor is opened
    createNewNote: {
      type: Boolean
    }
  },

  setup (props, { emit }) {
    const cannotEdit = props.editMode === 'none'
    const canEditOwn = props.editMode === 'own'
    const canEditAll = props.editMode === 'all'

    // Prepare a list of edited notes,
    // Sort notes chronologically in reverse order
    const state = reactive({
      // List of notes
      items: [],
      // Note being edited
      editedNote: null,
      // Note being deleted
      deletedNote: null,
      // Text of the edited note
      text: null,
      // Recipient of the newly edited note
      recipientId: null
    })

    // Refreshes the list of notes
    function refresh () {
      state.items = [...props.notes.map(note => {
        note.newText = note.text
        return note
      })].filter(note => note.text)
      state.items.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    }

    // Returns true if can add new notes.
    // This happens if proper permissions are passed,
    // and no note is currently being edited or deleted.
    function canAddNote () {
      return !(cannotEdit || state.editedNote || state.deletedNote)
    }

    // Adds a new note
    function add () {
      unedit()
      if (canAddNote()) {
        const note = new Note({
          id: Note.ID_NEW,
          createdAt: new Date(),
          createdBy: props.creator.id,
          updatedBy: props.creator.id,
          organizationId: props.creator.organizationId,
          creator: props.creator,
          updater: props.creator,
          category: props.category,
          entityId: props.entity.id,
          text: '',
          newText: ''
        })
        edit(note)
      }
    }

    // Starts editing of the specified note.
    // If no note specified, ends editing of the currently edited note.
    function edit (note) {
      unedit()
      if (note && canEditNote(note)) {
        state.text = note.text
        state.recipientId = note.recipientId
        state.editedNote = note
      }
    }

    // Checks whether the note can be edited.
    // This can happen if we're not in read-only mode
    // and note has been created by the current creator
    // or creator is a super administrator.
    function canEditNote (note) {
      return note && (canEditAll || (canEditOwn && note.createdBy === props.creator.id))
    }

    // Returns a recipient of the specified note
    function noteRecipient (note) {
      return props.recipients.find(r => r.id === note.recipientId)
    }

    // Stops editing the currently edited note
    function unedit () {
      state.editedNote = null
      state.deletedNote = null
    }

    // Saves changes in the edited note
    async function save () {
      const note = state.editedNote
      if (note) {
        note.text = (state.text || '').trim()
        note.recipientId = state.recipientId
        if (!note.id) {
          state.items.unshift(note)
        }
        if (note.isEmpty) {
          state.items = state.items.filter(n => n.id !== note.id)
        }
        emit('update', { note })
        unedit()
      }
    }

    // Cancels changes and signals to close the note editor
    function cancel () {
      unedit()
      if (props.createNewNote && state.items.length < 2) {
        emit('cancel')
      }
    }

    // Displays confirmation panel before removing the specified note
    function confirmRemove (note) {
      unedit()
      if (note && canEditNote(note)) {
        state.deletedNote = note
      }
    }

    // Cancels removal of the specified note
    function cancelRemove () {
      unedit()
    }

    // Removes the note
    function remove () {
      const note = state.deletedNote
      if (note && canEditNote(note)) {
        emit('remove', { note })
        const index = state.items.indexOf(note)
        state.items.splice(index, 1)
        unedit()
      }
    }

    // Populate notes, automatically create a new note
    refresh()
    if (props.createNewNote) {
      add()
    }

    // When notes change outside, refresh here
    watch(() => props.notes, () => {
      refresh()
    })

    return {
      state,
      canAddNote,
      canEditNote,
      noteRecipient,
      add,
      edit,
      unedit,
      cancel,
      save,
      remove,
      confirmRemove,
      cancelRemove
    }
  }
}
</script>

<template>
  <div class="notes-container q-pb-sm">
    <div class="notes" v-if="!(state.editedNote || state.deletedNote)">
      <div v-for="(note, index) in state.items" :key="index" class="note edit q-mb-sm q-pa-sm"
        @click="edit(note)">
        <div class="title row items-center text-gray-8">
          <span>
            {{ dateTimeString(note.createdAt) }}
          </span>
          <span class="q-ml-sm" v-if="note.creator">
            {{ note.creator.fullName }}
          </span>
          <span class="q-ml-sm row items-center" v-if="noteRecipient(note)">
            <q-icon name="double_arrow" size="16px" color="grey-6" class="q-mr-sm"></q-icon>
            {{ noteRecipient(note).name }}
          </span>
          <q-space></q-space>
          <span v-if="canEditNote(note)">
            <q-btn class="q-pa-xs button-delete" size="sm" round flat dense icon="close" color="red-10"
              title="Delete note" @click.stop="() => confirmRemove(note)" />
          </span>
        </div>

        <div class="text">
          {{ note.text }}
        </div>
      </div>
    </div>

    <div v-if="state.editedNote" class="note-editor q-pa-sm">
      <div class="title row items-center q-mb-sm text-gray-8" v-if="state.editedNote.id">
        <span>
          {{ dateTimeString(state.editedNote.createdAt) }}
        </span>
        <span class="q-ml-sm" v-if="state.editedNote.creator">
          {{ state.editedNote.creator.fullName }}
        </span>
        <span class="q-ml-sm row items-center" v-if="noteRecipient(state.editedNote)">
          <q-icon name="double_arrow" size="16px" color="grey-6" class="q-mr-sm"></q-icon>
          {{ noteRecipient(state.editedNote).name }}
        </span>
      </div>

      <q-input class="note-editor" ref="noteEditor" v-model="state.text" type="textarea" outlined
        bg-color="white" v-focus>
      </q-input>

      <sc-organization-selector class="q-mt-sm q-mb-sm" v-if="recipients.length > 0"
        :items="recipients" :dense="true" v-model="state.recipientId" label="Select note recipient">
      </sc-organization-selector>

      <div class="row justify-end q-mt-md">
        <q-btn label="Save" class="primary q-mr-sm" unelevated dense @mousedown="save()"></q-btn>
        <q-btn label="Cancel" unelevated dense @mousedown="cancel()"></q-btn>
      </div>
    </div>

    <div v-if="state.deletedNote" class="note q-pa-sm">
      <div class="title row items-center">
        <span>
          {{ dateTimeString(state.deletedNote.createdAt) }}
        </span>
        <span class="q-ml-sm" v-if="state.deletedNote.creator">
          <a :href="`mailto:${state.deletedNote.creator.email}`" title="Send e-mail">{{
              state.deletedNote.creator.fullName
          }}</a>
        </span>
      </div>
      <div class="text">
        {{ state.deletedNote.text }}
      </div>
      <div class="row justify-center q-pt-sm">
        <q-btn label="Remove" class="danger q-mr-sm" unelevated dense @mousedown="remove()"></q-btn>
        <q-btn label="Cancel" unelevated dense class="q-mr-sm" @mousedown="cancelRemove()"></q-btn>
      </div>
    </div>

    <div class="row q-mt-sm q-ml-sm" :class="{ 'justify-end': state.items.length > 0 }" v-if="canAddNote()">
      <slot name="add-button" v-bind:addNote="add">
        <q-btn unelevated flat dense icon="add" label="Add note" class="q-mr-sm" @click.stop="add()">
        </q-btn>
      </slot>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.notes-container {
  flex: 1;

  a {
    color: #b71c1c;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .note {
    border: dotted transparent 1px;
    border-radius: 4px;
    position: relative;

    &:hover {
      border: dotted #a7a7a7 1px;
    }

    &.edit {
      cursor: pointer;

      :deep(textarea) {
        outline: none;
      }
      :deep(textarea:focus) {
        outline: none;
      }
    }

    .title {
      font-size: 11px;
      margin-bottom: 4px;
    }

    .text {
      font-size: 13px;
    }

    .button-delete {
      position: absolute;
      right: 0px;
      top: 4px;
    }
  }

  .note-editor {
    border-radius: 4px;
    font-size: 13px;
  }
}
</style>
