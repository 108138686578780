import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Resolves the data required by the `building-dashboard` route
 */
export async function resolve ({ from, to }) {
  await dispatch('requireOrganizations')
  await dispatch('requirePlaces')
  await dispatch('requireDevices')
  await dispatch('requireUsers')

  const { id, organizationId } = to.params
  const place = await dispatch('initializeBuildingDashboard', { id, organizationId })

  if (place) {
    const title = place.name
    await dispatch('setRouteData', { from, to, title })

  } else {
    return {
      redirectTo: {
        name: 'buildings'
      }
    }
  }
}
