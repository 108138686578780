import { parseEnum, safeParseInt, round } from '@stellacontrol/utilities'
import { PlanItemType } from './plan-item'
import { PlanConnector } from './plan-connector'
import { PlanLineStyle } from '../styles'
import { SplitterType } from './plan-splitter'
import { AntennaType } from './plan-antenna'

/**
 * Cable types
 */
export const CableType = {
  SD200: 'sd200',
  SD400: 'sd400',
  SD600: 'sd600',
  SD900: 'sd900',
  Custom: 'custom-cable'
}

/**
 * Cable names
 */
export const CableName = {
  [CableType.SD200]: 'SD200',
  [CableType.SD400]: 'SD400',
  [CableType.SD600]: 'SD600',
  [CableType.SD900]: 'SD900',
  [CableType.Custom]: 'Other'
}

/**
 * Cable parameters per type
 */
export const CableParameters = {
  [CableType.SD200]: { gain: -0.248, thickness: 2, color: 'blue' },
  [CableType.SD400]: { gain: -0.202, thickness: 3, color: 'blue' },
  [CableType.SD600]: { gain: -0.132, thickness: 5, color: 'maroon' },
  [CableType.SD900]: { gain: -0.088, thickness: 7, color: 'purple' },
  [CableType.Custom]: { gain: -0.128, thickness: 4, color: 'grey' }
}

/**
 * Default cable lengths
 */
export const CableLengths = {
  // Default cable length
  Default: 35,
  // Cable connecting external antenna to repeater
  AntennaToRepeater: {
    // Direct connection from antenna to repeater
    Direct: 30,
    // Indirect connections via splitter
    [SplitterType.SplitterTwoWay]: 10,
    [SplitterType.SplitterThreeWay]: 8,
    [SplitterType.SplitterFourWay]: 6,
    // Custom settings per antenna type
    [AntennaType.Laser]: {
      Direct: 90,
      [SplitterType.SplitterTwoWay]: 30,
      [SplitterType.SplitterThreeWay]: 24,
      [SplitterType.SplitterFourWay]: 18,
    }
  },
  // Cable connecting repeater to a lineamp
  RepeaterToLineAmp: {
    // Direct connection from repeater to lineamp
    Direct: 100,
    // Indirect connections via splitter
    [SplitterType.SplitterTwoWay]: 80,
    [SplitterType.SplitterThreeWay]: 70,
    [SplitterType.SplitterFourWay]: 60,
    [SplitterType.TapperTwoWay]: 80
  },
  // Cable connecting repeater or lineamp to antenna
  ToAntenna: {
    // Direct connections to antenna
    Direct: 35,
    // Indirect connections via splitter
    [SplitterType.SplitterTwoWay]: 20,
    [SplitterType.SplitterThreeWay]: 15,
    [SplitterType.SplitterFourWay]: 10,
    [SplitterType.TapperTwoWay]: 20
  },
  // Cable entering a splitter
  ToSplitter: 1
}

/**
 * Cable linking two devices
 */
export class PlanCable extends PlanConnector {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Cable
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      cableType: CableType.SD400,
      lineStyle: new PlanLineStyle()
    }
  }

  normalize () {
    const { defaults } = this
    super.normalize()
    this.cableType = parseEnum(CableType, this.cableType, defaults.cableType)

    this.defaultLength = safeParseInt(this.defaultLength)
    this.realLength = safeParseInt(this.realLength)

    // Determine line style for the cable
    if (!this.isCustom) {
      this.lineStyle = new PlanLineStyle(defaults.lineStyle)
    }
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Don't store default line styles
    if (!this.isCustom || this.goesIntoRiser) {
      delete result.lineStyle
    }

    return result
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
  */
  toHumanString (details) {
    let label = CableName[this.cableType]
    const { realLength, start, end } = this
    if (details) {
      label = `${label}${realLength ? `${realLength}m` : ''}${start.item ? ` ${start.item.toHumanString()}` : ''}${end.item ? ` => ${end.item.toHumanString()}` : ''}`
    }
    return label
  }

  /**
   * Default cable length
   * @type {Number}
   */
  defaultLength

  /**
   * Real cable length
   * @type {Number}
   */
  realLength

  /**
   * Cable type
   * @type {CableType}
   */
  cableType

  /**
   * Automatically generated label
   * @type {String}
   */
  get autoLabel () {
    return `${CableName[this.cableType]} Cable`
  }

  /**
   * Indicates whether the cable is of custom type
   * @type {Boolean}
   */
  get isCustom () {
    return this.cableType === CableType.Custom
  }

  /**
   * Cable parameters
   * @type {Object}
   */
  get parameters () {
    return CableParameters[this.cableType]
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    const { parameters, realLength, defaultLength } = this
    if (layout && parameters) {
      // With default cable lengths ignore lengths of 1m or less
      // With real cable lengths all lengths should count
      const length = layout.realCableLengths
        ? realLength
        : (defaultLength > 1 ? defaultLength : 0)
      return round(length * parameters.gain || 0, 2)

    } else {
      return 0
    }
  }
}
