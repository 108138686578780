import { PlanAction, PlanActions } from './plan-action'
import { ClearWallsAction } from './radiation-actions'

/**
 * Sets the background image
 */
export class SetBackgroundImageAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetBackgroundImage
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set the floor plan image'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Attachment} file Image to assign. If null, the current image is cleared.
   * @param {Point} imagePosition Image position
   * @param {Number} imageScale Image scale
   * @param {Number} imageRotation Image rotation
   * @param {Attachment} file File data, `null` if image has been cleared
   */
  async execute ({ renderer, file, imagePosition, imageScale, imageRotation } = {}) {
    if (renderer) {
      const { backgroundLayer, radiationLayer, floor: { background } } = renderer

      if (file === null) {
        // Remove any existing walls
        const clearWalls = new ClearWallsAction()
        clearWalls.execute({ renderer })
        radiationLayer.refresh()
        // Clear the current image
        background.clearImage()
        await backgroundLayer.refreshImage()
        // Save
        await renderer.saveFloorImage()
        // Reset image transparency
        background.clearTransparentColors()

      } else if (file != null) {
        // Reset zoom to 1
        renderer.setZoom(1)

        // Remove any existing walls
        const clearWalls = new ClearWallsAction()
        clearWalls.execute({ renderer })

        // Assign the new image
        background.setImage(file)
        await backgroundLayer.refreshImage()

        // Save
        await renderer.saveFloorImage({ file })
      }

      if (imagePosition != null) {
        background.imagePosition.moveTo(imagePosition)
        backgroundLayer.refreshImage()
        renderer.changed()
      }

      if (imageScale != null) {
        background.imageScale = imageScale
        backgroundLayer.refreshImage()
        renderer.changed()
      }

      if (imageRotation != null) {
        background.imageRotation = imageRotation
        backgroundLayer.refreshImage()
        renderer.changed()
      }
    }
  }
}

/**
 * Clears the background image
 */
export class ClearBackgroundImageAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearBackgroundImage
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Clear the floor plan image'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      const { backgroundLayer, radiationLayer, floor: { background } } = renderer
      background.image = null

      // Reset image transparency
      background.clearTransparentColors()

      // Remove any existing walls
      const clearWalls = new ClearWallsAction()
      clearWalls.execute({ renderer })
      radiationLayer.refresh()

      // Clear the image and save
      await renderer.clearFloorImage()
      await renderer.saveFloorImage()
      await backgroundLayer.refreshImage()
    }
  }
}

/**
 * Toggles visibility of the background image
 */
export class ToggleBackgroundImageAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ToggleBackgroundImage
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle plan image'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isVisible Indicates whether the background image should be visible
   */
  execute ({ renderer, isVisible } = {}) {
    if (renderer) {
      renderer.floor.background.showImage = isVisible
      renderer.backgroundLayer.refreshImage()
      renderer.changed()
    }
  }
}
