import { Size, Margin } from '@stellacontrol/utilities'
import { PlanScale } from './plan-scale'
import { PlanFloor, PlanFloors } from './plan-floor'
import { PlanRiser } from '../items/plan-riser'

/**
 * Cross-section view layout
 */
export class PlanCrossSection extends PlanFloor {
  constructor (data = {}) {
    super(data)
    this.assign(data, {
    })
  }

  /**
   * Object defaults
   * @return {Object}
   */
  get defaults () {
    return {
      ...super.defaults,
      margin: new Margin({ top: 100, right: 100, bottom: 100, left: 100 }),
    }
  }

  normalize () {
    // Don't call the ancestor's `normalize`,
    // we do things differently on the cross-view,
    // for example `items` are a calculated property.

    const { defaults } = this
    this.id = PlanFloors.CrossSection
    this.label = 'Cross-Section'
    this.dimensions = Size.from(defaults.size)
    this.zoom = this.cast(this.zoom, PlanScale, defaults.zoom)
    this.margin = this.cast(this.margin, Margin, defaults.margin)

    this.risers = this.castArray(this.risers, PlanRiser, [])

    // Clear unused properties inherited from `PlanFloor`
    delete this.background
    delete this.radiation
    delete this.mapScale
    delete this.layers
    delete this.itemIndex
    this.layers = []
  }

  /**
   * Prepares the object for JSON serialization
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }

    // Delete RUNTIME properties
    delete result.layout
    delete result.__items
    delete result.isDeleted
    delete result.isNew
    delete result.isLoadingImage

    return result
  }

  /**
   * Resets the cross-view layout to defaults
   */
  reset () {
    const { defaults } = this

    // Parse dimensions, items scale and plan zoom
    this.dimensions = Size.from(defaults.size)
    this.zoom = PlanScale.Normal
    this.margin = Margin.from(defaults.margin)
  }

  /**
   * Cross-section view dimensions
   * @type {Size}
   */
  dimensions

  /**
   * Zoom level
   * @type {PlanScale}
   */
  zoom

  /**
   * Entire plan layout
   * @type {PlanLayout}
   * @description RUNTIME
   */
  layout

  /**
   * Items visible on the cross-section view
   * @type {Array[PlanItem]}
   */
  get items () {
    return this.layout.items.filter(i => i.showOnCrossSection)
  }

  /**
   * We don't modify items from the cross-view, but rather from specific floors
   */
  // eslint-disable-next-line no-unused-vars
  set items (value) {
  }

  /**
   * All risers on the plan
   * @type {Array[PlanRiser]}
   */
  risers

  /**
   * Finds a specified riser
   * @param {String} id Riser identifier
   * @returns {PlanRiser}
   */
  getRiser (id) {
    return this.risers.find(r => r.id === id)
  }

  /**
   * Finds a riser through which the specified cross-floor connector passes
   * @param {PlanConnector} connector Plan connector
   * @returns {PlanRiser}
   */
  getRiserFor (connector) {
    return this.risers.find(r => r.contains(connector))
  }

  /**
   * Refreshes the cable risers:
   * - adds a new riser for newly added cross-floor connectors
   * - removes risers whose connectors no longer exist
   * @param {Array[PlanConnector]} connectors Plan connectors
   * @param {Boolean} reuse If `true` and connector is in a need of a riser, we will reuse any existing risers
   */
  refreshRisers (connectors, reuse = true) {
    // Purge removed connectors from risers, or connectors which are no longer cross-floor.
    // Purge empty risers.
    const crossFloorConnectors = connectors.filter(c => c.isCrossFloor)
    this.risers = this.risers.filter(riser => {
      riser.connectors = riser.connectors.filter(id => crossFloorConnectors.some(c => c.id === id))
      return riser.hasConnectors
    })

    // Add riser for newly added connectors
    const defaultRiser = this.risers[0]
    const needRiser = crossFloorConnectors.filter(c => !this.getRiserFor(c))
    if (needRiser.length > 0) {
      if (reuse && defaultRiser) {
        defaultRiser.add(...needRiser)
      } else {
        const riser = new PlanRiser()
        riser.add(...needRiser)
        this.risers.push(riser)
      }
    }

    // Unlink riser from connectors which no longer go through a riser
    for (const connector of connectors) {
      const riser = this.getRiserFor(connector)
      if (riser && !connector.isCrossFloor) {
        riser.remove(connector)
      } else {
        delete connector.riser
      }
    }

    // Remove empty risers
    this.risers = this.risers.filter(riser => riser.hasConnectors)
  }

  /**
   * Cross-section view cannot be deleted
   * @type {Boolean}
   */
  get canDelete () {
    return false
  }

  /**
   * There are no building walls on cross-section view
   * @type {Array[PlanItem]}
   */
  get walls () {
    return []
  }

  /**
   * There are no building yards on cross-section view
   * @type {Array[PlanItem]}
   */
  get yards () {
    return []
  }

  /**
   * There are no building walls on cross-section view
   * @type {Boolean}
   */
  get hasWalls () {
    return false
  }

  /**
   * We don't add items to the cross-view, but rather to specific floors
   * @param {PlanItem} item Item to add
   * @returns {PlanItem} Added item
   */
  addItem (item) {
    const { layout } = this
    const floor = layout.selectedFloor
    if (floor) {
      layout.addItem(item, floor)
    }
  }

  /**
   * We don't remove items from the cross-view, but rather from specific floors
   * @param {PlanItem} item Item to remove
  */
  removeItem (item) {
    const { layout } = this
    const floor = layout.getFloorOf(item)
    if (floor) {
      layout.removeItem(item, floor)
    }
  }

  /**
   * We don't remove items from the cross-view, but rather from specific floors
   */
  clear () {
    for (const floor of this.layout.floors) {
      floor.clear()
    }
  }

  /**
   * Moves the item on z axis to the top
   * @param {PlanItem} item Item to move to the top
   * @returns {Number} New index of the item
   */
  moveToTop (item) {
    const floor = this.layout.getFloorOf(item)
    return floor?.moveToTop(item)
  }

  /**
   * Moves the item on z axis to the bottom
   * @param {PlanItem} item Item to move to the bottom
   * @returns {Number} New index of the item
  */
  moveToBottom (item) {
    const floor = this.layout.getFloorOf(item)
    return floor?.moveToBottom(item)
  }

  /**
   * There are no building walls on cross-section view
   */
  clearWalls () {
  }

  /**
   * There are no building walls on cross-section view
   * @param {Boolean} isLocked Lock status
   */
  // eslint-disable-next-line no-unused-vars
  lockWalls (isLocked) {
  }

  /**
   * Cross-section view does not have a background image
   * @param {Boolean} status
   */
  // eslint-disable-next-line no-unused-vars
  loadImage (status) {
  }
}
