<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ListViewMode } from '@stellacontrol/client-utilities'
import { Place, getDeviceLabel, EntityType } from '@stellacontrol/model'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { Secure } from '@stellacontrol/security-ui'
import { DashboardWidgets } from '../../widgets'

const columns = [
  { name: 'type', label: 'Type', field: 'acronym', sortable: true },
  { name: 'serialNumber', label: 'Serial Number', field: 'serialNumber', sortable: true },
  { name: 'firmwareVersion', label: 'Firmware', field: 'firmwareVersion', sortable: true, format: value => value || '-' },
  { name: 'location', label: 'Location', field: 'location', sortable: true, format: (value, row) => row.customLocation || value || '-' },
  { name: 'status', label: 'Status', field: 'bands', sortable: true },
  { name: 'commands', label: '', sortable: false }
]

export default {
  mixins: [
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  props: {
    // Place whose devices are shown
    place: {
      type: Place,
      required: true
    },

    // Identifier of a place which is now a drop target
    targetPlaceId: {
      type: String
    },

    // Indicates that we're currently allowing the user
    // to rearrange devices and places
    isRearranging: {
      type: Boolean,
      value: false
    },

    // Indicates that we want to see individual boards of multi-devices as well
    showParts: {
      type: Boolean,
      value: false
    },

    // Callback for checking whether live status of devices is allowed
    isLiveStatusAllowed: {
      type: Function,
      value: () => true
    }
  },

  data () {
    return {
      // Device commands
      DeviceCommands,
      DefaultDeviceCommands,
      // Device grid columns
      columns,
      // Identifier of a device which is now a drop target
      targetDeviceId: null,
      // Placeholder for names of edited device groups
      placeGroup: ''
    }
  },

  computed: {
    ...mapState({
      organization: state => state.dashboardView.organization,
      allDevices: state => state.dashboardView.organizationDashboard.devices,
      allSelectedDevices: state => state.dashboardView.organizationDashboard.selectedDevices,
      viewMode: state => state.dashboardView.organizationDashboard.devicesListViewMode,
      collapsedPlaceGroups: state => state.dashboardView.organizationDashboard.collapsedPlaceGroups,
      placeFilter: state => state.dashboardView.organizationDashboard.filter,
      viewedOrganization: state => state.dashboardView.organization,
      viewedOrganizationGuardian: state => state.dashboardView.organizationGuardian,
      isShowingPlace: state => state.dashboardView.entityType === EntityType.Place
    }),

    ...mapGetters([
      'getPlaceDevices',
      'isPlaceExpanded',
      'availableDeviceCommands',
      'isMobilePhone'
    ]),

    // Device label to display
    deviceLabel () {
      return device => {
        return getDeviceLabel(device)
      }
    },

    placeId () {
      return this.place.id
    },

    noPlace () {
      return this.place.id === 'none'
    },

    sharedPlace () {
      return this.place.id === 'shared'
    },

    // All devices in the place.
    // Do not show device boards which are parts of multi-devices.
    placeDevices () {
      return this
        .getPlaceDevices(this.place)
        .filter(device => this.showParts || !device.partOf)
    },

    // Devices which match the entered text filter
    filteredPlaceDevices () {
      const { placeFilter, placeDevices, deviceMatchesFilter } = this
      if (placeFilter) {
        const devices = placeDevices.filter(device => deviceMatchesFilter(device))
        if (devices.length > 0) {
          return devices
        }
      }
    },

    // Devices to display - all or filtered
    visibleDevices () {
      return this.filteredPlaceDevices || this.placeDevices
    },

    // Indicates that place has no devices
    isEmpty () {
      return this.placeDevices.length === 0
    },

    // Checks whether place should be displayed at all
    isVisible () {
      // Do not show Shared Devices category, if no devices are shared with organization
      return this.sharedPlace ? !this.isEmpty : true
    },

    // Checks whether devices are editable, commands can be sent to them etc.
    isEditable () {
      const { isMobilePhone, isRearranging, sharedPlace } = this
      return !isMobilePhone && !sharedPlace && !isRearranging
    },

    // Checks whether the place is currently displayed as expanded
    isExpanded () {
      return this.isPlaceExpanded(this.place)
    },

    // Indicates whether place can be modified, eg. devices can be dragged into
    // place, the place can be deleted etc.
    // Place is locked if it's region-bound, except for the super-administrator
    isPlaceLocked () {
      const { isMobilePhone, place, isSuperAdministrator } = this
      return isMobilePhone || place.hasRegion ? !isSuperAdministrator : false
    },

    // Returns a group within the place, to which a device belongs
    getGroup () {
      return device => {
        let group = device.placeGroup
        if (!group) {
          const predecessors = [...this.placeDevices.filter(d => d.sortOrder < device.sortOrder)].reverse()
          group = (predecessors.find(p => p.placeGroup) || {}).placeGroup
        }
        return group
      }
    },

    // Checks whether a group of devices in a place is currently expanded.
    // Do not collapse, if filtering devices inside groups, otherwise they might be invisible
    isGroupExpanded () {
      return group => !group ||
        this.filteredPlaceDevices ||
        !this.collapsedPlaceGroups.find(item => item.placeId === this.place.id && item.group === group)
    },

    // Returns all devices belonging to a specified place group
    getDevicesInGroup () {
      return group => this.placeDevices.filter(d => this.getGroup(d) == group)
    },

    // Returns number of devices belonging to a specified place group
    getGroupSize () {
      return group => this.getDevicesInGroup(group).length
    },

    // Devices currently selected in the place
    selectedDevices () {
      return this.placeDevices.filter(d => this.allSelectedDevices.some(s => s.id === d.id))
    },

    // Indicates whether all devices are selected in the place
    allDevicesSelected () {
      return !this.isEmpty && this.placeDevices.every(d => this.isDeviceSelected(d))
    },

    // Indicates whether more than one device has been selected
    isBatchSelected () {
      return this.selectedDevices.length > 0
    },

    // Checks whether the specified device is currently selected
    isDeviceSelected () {
      return device => this.selectedDevices.some(d => d.id === device.id)
    },

    // Checks whether the specified device is matching the specified place/device filter
    deviceMatchesFilter () {
      return device => {
        const { placeFilter } = this
        const { name, serialNumber } = device
        return placeFilter &&
          ((serialNumber || '').toLowerCase().includes(placeFilter) ||
            (name || '').toLowerCase().includes(serialNumber))
      }
    },

    // Checks whether a new place group should begin before the specified device
    isPlaceGroup () {
      return device => Boolean(device.placeGroup)
    },

    // Checks whether there should be a separator before the specified device.
    isSeparator () {
      return device => Boolean(device.placeSeparator)
    },

    // List view mode
    showDeviceList () {
      return this.viewMode === ListViewMode.Normal
    },

    // Small cards view mode
    showDeviceCards () {
      return this.viewMode === ListViewMode.MiniCards
    },

    // CSS class for device card
    deviceCardClass () {
      return device => {
        const { sharedPlace, isRearranging, targetDeviceId } = this
        return {
          'q-mr-md': sharedPlace || !isRearranging,
          drop: isRearranging && (targetDeviceId === device.id),
          filtered: this.deviceMatchesFilter(device),
          collapsed: !this.isGroupExpanded(this.getGroup(device))
        }
      }
    },

    // Additional commands to show in device context menu
    getCustomCommands () {
      return device => {
        const commands = []
        if (device.isNonConnectedDevice || device.isSimulatedDevice) {
          commands.push({
            name: 'delete-device',
            label: 'Delete device',
            icon: 'close',
            color: 'red-8',
            separator: true,
            conditions: {
              confirmation: 'Are you sure to delete ${identification}?',
              permissions: [],
              isBatchCommand: false
            },
            handler: async ({ devices }) => {
              for (const device of devices) {
                await this.deleteDevice({ device })
              }
            }
          })
        } else {
          if (!this.isBatchSelected) {
            commands.push({
              name: 'configure-device',
              label: 'Configure device',
              icon: 'settings',
              color: 'indigo-5',
              separator: true,
              conditions: {
                // Not only device-configuration permission is required,
                // but at least one of its child permissions
                permissionsOf: 'device-configuration',
                isConnectedDevice: true,
                isBatchCommand: false
              },
              handler: async ({ devices }) => {
                await this.configureDevice(devices[0])
              }
            })
          }
        }

        return commands
      }
    },

    // Indicates whether live status can be monitored for the specified device
    canSeeLiveStatus () {
      return device => {
        const { currentOrganizationGuardian, viewedOrganizationGuardian } = this
        if (currentOrganizationGuardian?.requiresPremiumSubscription('live-status')) {
          return viewedOrganizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
        } else {
          return currentOrganizationGuardian?.canUse('live-status')
        }
      }
    },

    // Indicates whether there are any commands which can be sent to the currently selected devices
    canSendCommands () {
      return device => {
        const canSendCommands = this.canUse('device-management') &&
          this.availableDeviceCommands([device], DefaultDeviceCommands, this.getCustomCommands(device))
        return canSendCommands
      }
    },

    // Indicates whether any device can receive commands
    anyDeviceCanSendCommands () {
      return this.placeDevices.every(device => this.canSendCommands(device))
    },

    // Indicates whether the device should be selectable
    isSelectable () {
      return device => !this.isMobilePhone &&
        this.canSeeLiveStatus(device) &&
        this.canSendCommands(device)
    }
  },

  methods: {
    ...mapMutations([
      'selectDeviceInPlace',
      'selectDevicesInPlace'
    ]),
    ...mapActions([
      'moveDeviceAt',
      'setDeviceCustomLocation',
      'getLiveStatus',
      'deleteDevice',
      'showDialog',
      'togglePlaceGroup',
      'addPlaceSeparator',
      'removePlaceSeparator',
      'setPlaceGroup',
      'removePlaceGroup'
    ]),

    // Returns true if the specified device belongs to this place
    isMyDevice (deviceId) {
      return this.placeDevices.some(d => d.id === deviceId)
    },

    // Refreshes live status of the devices in the place.
    // Only performed when place is currently expanded
    // and devices are displayed
    async refreshDeviceStatus () {
      const { isExpanded, placeDevices: devices } = this
      if (isExpanded) {
        await this.getLiveStatus({ name: 'devices', devices })
      }
    },

    // DRAG-DROP HANDLING ------------------------------------------
    // Starts dragging a device
    startDraggingDevice (e) {
      if (this.sharedPlace) return
      if (this.isPlaceLocked) return

      if (e.target) {
        const deviceId = e.target.getAttribute('deviceid')
        if (this.isMyDevice(deviceId)) {
          e.dataTransfer.setData('deviceId', deviceId)
          e.dataTransfer.dropEffect = 'move'
          e.target.classList.add('dragging')
        }
      }
    },

    // Ends dragging a device
    endDraggingDevice (e) {
      e.preventDefault()
      if (this.isPlaceLocked) return

      if (e.target) {
        e.target.classList.remove('dragging')
        this.$emit('placeDragEnded')
      }
    },

    // If dragging a device from another place over this place, signal it
    placeDragEnter (e) {
      if (!e.dataTransfer) return
      if (this.sharedPlace) return
      if (this.isPlaceLocked) return

      const { placeId: targetPlaceId } = this
      const deviceId = e.dataTransfer.getData('deviceId')
      const placeId = e.dataTransfer.getData('placeId')

      // Dragging device over the place
      if (deviceId) {
        if (!this.isMyDevice(deviceId)) {
          this.$emit('deviceDraggedOverPlace', { targetPlaceId, deviceId })
        }
      }
      // Dragging another place over the place
      if (placeId) {
        if (placeId !== targetPlaceId) {
          this.$emit('placeDraggedOverPlace', { targetPlaceId, placeId })
        }
      }
    },

    dragOver (e) {
      e.preventDefault()
    },

    // Something was dropped on this place
    dropSomethingOnPlace (e) {
      if (this.isPlaceLocked) return
      if (!e.dataTransfer) return

      const { placeId: targetPlaceId, noPlace, sharedPlace } = this
      const deviceId = e.dataTransfer.getData('deviceId')
      const placeId = e.dataTransfer.getData('placeId')

      // Device from another place dropped here
      if (deviceId && !this.isMyDevice(deviceId) && !sharedPlace) {
        this.toggleDevices(false)
        this.$emit('dropDeviceOnPlace', { targetPlaceId, deviceId })
      }

      // Place reordered
      if (placeId && placeId !== this.placeId && !noPlace && !sharedPlace) {
        this.$emit('dropPlaceOnPlace', { targetPlaceId, placeId })
      }
    },

    // Device was dropped onto another device in this place - reorder
    async dropDeviceOnDevice (e, atDevice) {
      if (this.isPlaceLocked) return
      if (!e.dataTransfer) return

      const deviceId = e.dataTransfer.getData('deviceId')
      const device = this.allDevices.find(d => d.id === deviceId)
      if (device && this.isMyDevice(deviceId) && atDevice && atDevice.id !== deviceId) {
        await this.moveDeviceAt({ device, atDevice })
      }
      this.targetDeviceId = null
    },

    // If dragging a device over another device, signal it
    deviceDragEnter (e, targetDevice) {
      if (this.isPlaceLocked) return
      if (!e.dataTransfer) return
      if (this.sharedPlace) return

      const deviceId = e.dataTransfer.getData('deviceId')
      if (deviceId && targetDevice && targetDevice.id !== deviceId) {
        this.targetDeviceId = targetDevice.id
      }
    },

    deviceDragLeave (e, targetDevice) {
      if (this.isPlaceLocked) return
      if (targetDevice && targetDevice.id === this.targetDeviceId) {
        this.targetDeviceId = null
      }
    },

    // SELECTIONS -----------------------------------------------------------
    toggleDevice (device, isSelected) {
      if (this.isSelectable(device)) {
        const { place } = this
        this.selectDeviceInPlace({ place, device, isSelected })
      }
    },

    toggleDevices (isSelected) {
      const { place } = this
      const allowed = this.placeDevices.filter(device => this.isSelectable(device))
      this.selectDevicesInPlace({ place, isSelected, allowed })
    },

    toggleGroup (placeGroup) {
      const { organization, place, sharedPlace } = this
      if (!sharedPlace) {
        this.togglePlaceGroup({ organization, place, placeGroup })
      }
    },

    // PLACE GROUPS ---------------------------------------------------------
    async startPlaceGroup (device, placeGroup) {
      if (device) {
        await this.setPlaceGroup({ device, placeGroup })
        this.placeGroup = ''
      }
    },

    async renamePlaceGroup (device, placeGroup) {
      if (device) {
        await this.setPlaceGroup({ device, placeGroup })
        this.placeGroup = ''
      }
    },

    // CONFIGURATION
    // Launches dialog for configuring devices
    async configureDevice (device) {
      const { serialNumber } = device
      await this.showDialog({
        dialog: 'device-configuration',
        data: {
          serialNumber
        }
      })
    }
  },

  watch: {
    // Refresh device status when place becomes expanded
    async isExpanded () {
      await this.refreshDeviceStatus()
    }
  }
}
</script>

<template>
  <q-tr class="place-devices"
    :class="{ drop: targetPlaceId === placeId, noplace: noPlace, sharedplace: sharedPlace }"
    v-if="isVisible && isExpanded" @dragenter="placeDragEnter" @dragover="dragOver"
    @drop="dropSomethingOnPlace">
    <q-td class="devices" colspan="4">
      <!-- card view -->
      <div class="cards q-pt-sm" v-if="showDeviceCards">
        <template v-for="device in visibleDevices">
          <div v-if="isPlaceGroup(device)" class="place-group">
            <header class="row items-center">
              <q-btn v-if="!sharedPlace && isRearranging" unelevated dense round color="indigo-1"
                icon="create" class="text-indigo-8 q-mr-xs" size="md" @click.stop="() => { }">
                <sc-tooltip :text="`Rename ${device.placeGroup} group`" />
                <q-popup-edit buttons self="bottom left" label-set="Rename group" anchor="top left"
                  v-slot="scope" :model-value="device.placeGroup"
                  :title="`Group of devices starting at ${device.acronym} ${device.serialNumber}`"
                  @save="placeGroup => renamePlaceGroup(device, placeGroup)">
                  <q-input label="Group name" required autofocus max-length="255"
                    v-model="scope.value" @keyup.enter="scope.set"></q-input>
                </q-popup-edit>
              </q-btn>

              <q-btn v-if="!sharedPlace && isRearranging" icon="close" unelevated dense round
                color="indigo-1" class="text-indigo-8 q-mr-xs" size="md"
                @click.stop="removePlaceGroup({ device })">
                <sc-tooltip
                  :text="`Ungroup devices in ${device.placeGroup} group. All devices will remain intact.`" />
              </q-btn>

              <span class="title" @click="toggleGroup(device.placeGroup)">
                <q-icon :name="isGroupExpanded(device.placeGroup) ? 'expand_more' : 'chevron_right'"
                  size="sm" color="indigo-5">
                </q-icon>

                <span class="q-ml-sm">
                  {{ device.placeGroup }}
                </span>

                <q-badge color="indigo-6" size="md" outline class="q-ml-sm">
                  {{ getGroupSize(device.placeGroup) }}
                </q-badge>
              </span>
            </header>
          </div>

          <div v-if="isSeparator(device) && isGroupExpanded(getGroup(device))"
            class="device-separator q-mb-md column items-center justify-center"
            :class="{ rearranging: isRearranging && !sharedPlace }"
            @click.stop="() => isRearranging ? removePlaceSeparator({ device }) : undefined">
            <q-icon v-if="!sharedPlace && isRearranging" name="close" color="indigo-5">
              <sc-tooltip text="Remove space">
              </sc-tooltip>
            </q-icon>
          </div>

          <div
            v-if="isRearranging && !sharedPlace && !isSeparator(device) && !device.placeGroup && isGroupExpanded(getGroup(device))"
            class="device-separator-add q-mb-md column items-center justify-center"
            @click.stop="addPlaceSeparator({ device })">
            <q-icon name="code" color="indigo-5">
              <sc-tooltip text="Add space"></sc-tooltip>
            </q-icon>
          </div>

          <div :deviceid="device.id" class="card q-mb-md" :class="deviceCardClass(device)"
            :draggable="!sharedPlace && isRearranging" @dragenter="e => deviceDragEnter(e, device)"
            @dragstart="startDraggingDevice" @dragover="dragOver" @dragend="endDraggingDevice"
            @drop="e => dropDeviceOnDevice(e, device)">

            <sc-widget-device-card dense :device="device" :place="place" :organization="organization"
              :isEditable="isEditable" :isBusy="isRearranging" :isSelectable="isSelectable(device)"
              :isSelected="isDeviceSelected(device)"
              :isLiveStatusAllowed="isLiveStatusAllowed(device)"
              @select="({ device, isSelected }) => toggleDevice(device, isSelected)">
            </sc-widget-device-card>


            <q-btn v-if="isRearranging && !device.placeGroup && !device.isShared" flat dense round
              color="indigo-5" class="button-add-place-group" icon="create_new_folder">
              <sc-tooltip
                :text="`Create device group starting at ${device.acronym} ${device.serialNumber}`" />

              <q-popup-edit buttons self="bottom left" label-set="Group" v-slot="scope"
                :model-value="device.placeGroup"
                :title="`Group of devices starting at ${device.acronym} ${device.serialNumber}`"
                @save="placeGroup => startPlaceGroup(device, placeGroup)">
                <q-input label="Group name" required autofocus max-length="255" v-model="scope.value"
                  @keyup.enter="scope.set"></q-input>
              </q-popup-edit>
            </q-btn>
          </div>
        </template>
      </div>

      <!-- table view -->
      <q-table v-if="showDeviceList" class="table" flat row-key="id" :hide-bottom="true"
        :rows="visibleDevices" :columns="columns" :pagination="{ rowsPerPage: 0 }">
        <template v-slot:header>
          <q-tr class="header">
            <q-th class="select" v-if="anyDeviceCanSendCommands">
              <q-checkbox dense size="sm" color="indigo-5" :model-value="allDevicesSelected"
                @update:model-value="value => toggleDevices(value)">
                <sc-tooltip text="Select all devices" />
              </q-checkbox>
            </q-th>

            <q-th class="type">{{ columns[0].label }}</q-th>

            <q-th class="serial-number">{{ columns[1].label }}</q-th>

            <q-th class="firmware-version">{{ columns[2].label }}</q-th>

            <q-th class="location">{{ sharedPlace ? ('Owner, ' + columns[3].label) :
              columns[3].label
            }}</q-th>

            <q-th class="status" v-if="canUse('device-bands')">{{ columns[4].label }}</q-th>

            <th></th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr class="device"
            :class="{ drop: isRearranging && (targetDeviceId === props.row.id), filtered: deviceMatchesFilter(props.row), }"
            :props="props" :deviceid="props.row.id" :draggable="!sharedPlace && isRearranging"
            @dragenter="e => deviceDragEnter(e, props.row)"
            @dragleave="e => deviceDragLeave(e, props.row)" @dragstart="startDraggingDevice"
            @dragover="dragOver" @dragend="endDraggingDevice"
            @drop="e => dropDeviceOnDevice(e, props.row)">
            <q-td class="select">
              <q-checkbox dense size="sm" color="indigo-5"
                v-if="isLiveStatusAllowed(props.row) && canSendCommands(props.row)"
                :model-value="isDeviceSelected(props.row)"
                @update:model-value="value => toggleDevice(props.row, value)" />
            </q-td>

            <q-td class="type">{{ props.row.acronym }}</q-td>

            <q-td class="serial-number">
              <span v-if="isRearranging || !props.row.isConnectedDevice">{{
                deviceLabel(props.row)
              }}</span>
              <router-link class="item-link" v-else
                :to="{ name: 'device-dashboard', params: { serialNumber: props.row.serialNumber } }">
                {{ deviceLabel(props.row) }}
                <sc-tooltip :text="`Go to ${deviceLabel(props.row)} dashboard`" />
              </router-link>
            </q-td>

            <q-td class="firmware-version">
              {{ props.row.firmwareVersionLong || '-' }}
            </q-td>

            <q-td class="location">
              <span>
                {{ props.row.isShared && props.row.owner ? props.row.owner.name + ' ' : '' }}
                {{ props.row.customLocation || props.row.location || '' }}
                <sc-tooltip text="Click to enter a custom location"
                  v-if="!isRearranging && currentUser.isAdministrator" />
              </span>
              <q-popup-edit v-if="!isRearranging && currentUser.isAdministrator" buttons
                self="bottom left" label-set="Save" v-slot="scope"
                :model-value="props.row.customLocation"
                :title="`Enter custom location of ${props.row.acronym} ${props.row.serialNumber}`"
                @save="location => setDeviceCustomLocation({ device: props.row, location })">
                <q-input autofocus max-length="255" v-model="scope.value"
                  :label="props.row.location ? `Reported by device: ${props.row.location}` : 'Enter custom device location'"
                  @keyup.enter="scope.set"></q-input>
              </q-popup-edit>
            </q-td>

            <q-td class="status" v-if="canUse('device-bands')">
              <sc-widget-device-leds v-if="canSeeLiveStatus(props.row)" :device="props.row" no-chrome>
              </sc-widget-device-leds>
              <span v-else>
                Not Available
              </span>
            </q-td>

            <q-td class="commands">
              <q-btn
                v-if="!isRearranging && isLiveStatusAllowed(props.row) && canSendCommands(props.row)"
                class="device-dropdown" size="md" padding="4px" color="indigo-8" flat round
                icon="more_vert" @click.stop="() => { }">
                <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="props.row">
                  <sc-device-commands :devices="[props.row]" :commands="DefaultDeviceCommands"
                    :custom-commands="getCustomCommands(props.row)"></sc-device-commands>
                </q-popup-edit>
              </q-btn>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-td>
  </q-tr>
</template>

<style lang="scss" scoped>
tr.place-devices {

  /* removes hover background introduced by Quasar */
  >td::before {
    background: none;
  }

  /* Separate NO-PLACE with thicker line */
  >td.q-td {
    border-bottom-width: 3px;
  }

  .devices {
    padding: 4px 20px 30px 56px;

    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .card {
        position: relative;

        &.drop {
          .device-card {
            border-color: #3a72b14b;
            background: #3a72b14b;
          }
        }

        &.filtered {
          .device-card {
            border: solid #182042b4 3px;
          }
        }

        &.collapsed {
          display: none;
        }

        .button-add-place-group {
          position: absolute;
          right: 2px;
          top: 2px;
          z-index: 1;
        }
      }

      .place-group {
        flex-basis: 100%;
        width: 0px;
        overflow: hidden;
        display: inline-block;
        margin-top: 4px;

        &:first-child {
          margin-top: 0;
        }

        &>header {
          font-size: 16px;
          padding-bottom: 8px;

          .title {
            cursor: pointer;
          }
        }
      }

      .device-separator {
        width: 50px;

        &.rearranging {
          width: 66px;
          border: dashed silver 1px;
          margin-left: 2px;
          margin-right: 2px;
          cursor: pointer;

          &:hover {
            background-color: #efefef;
          }
        }
      }

      .device-separator-add {
        border: dashed silver 1px;
        padding-left: 2px;
        padding-right: 2px;
        margin-left: 2px;
        margin-right: 2px;
        cursor: pointer;

        &:hover {
          background-color: #efefef;
        }
      }
    }

    /* Table view */
    .table {
      border: solid silver 1px;
      border-radius: 2;
      background: #fafafa;
      max-width: 950px;

      tr {
        &.drop {
          background: #498edd1c;
        }

        &.filtered {
          background: #376daa1c;
        }

        th {
          text-align: left;

          &.select {
            padding: 4px 0 4px 12px;
          }

          &.type {
            padding: 4px 0 4px 8px;
            width: 40px;
          }

          &.commands {
            text-align: right;
          }
        }

        td {
          &.select {
            padding: 4px 0 4px 12px;
            width: 24px;
          }

          &.type {
            padding: 4px 0 4px 8px;
            width: 40px;
          }

          &.serial-number {
            width: 80px;
          }

          &.firmware-version {
            width: 80px;
          }

          &.location {
            width: 200px;
            cursor: pointer;
          }

          &.status {
            width: 250px;
          }

          &.commands {
            width: 30px;
            text-align: right;
          }
        }

        &.device {
          cursor: pointer;
          height: 52px;
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  /* Drag-n-drop */
  &.drop {
    background: #498edd1c;
  }
}

/* Layout adjustments for mobile phones */
@media screen and (max-width: 640px) {
  tr.place-devices {
    .devices {
      padding: 0 8px 8px 8px;

      .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;

        .card {
          position: relative;
          flex: 1;
          max-width: calc(50% - 8px);
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
