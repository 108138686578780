<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { PlaceType, getPlaceIcon, getPlaceLabel, EntityType } from '@stellacontrol/model'
import { ListAction } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    place: {
      required: true
    },

    // Identifier of a place which is now a drop target
    targetPlaceId: {
      type: String
    },

    // Identifier of a device which is now a drop target
    targetDeviceId: {
      type: String
    },

    // Indicates that we're currently allowing the user
    // to rearrange devices and places
    isRearranging: {
      type: Boolean,
      value: false
    },

    // Indicates that we want to see individual boards of multi-devices as well
    showParts: {
      type: Boolean,
      value: false
    }
  },

  computed: {
    ...mapState({
      organization: state => state.dashboardView.organization,
      devices: state => state.dashboardView.organizationDashboard.devices,
      allSelectedDevices: state => state.dashboardView.organizationDashboard.selectedDevices,
      viewedOrganization: state => state.dashboardView.organization,
      viewedOrganizationGuardian: state => state.dashboardView.organizationGuardian,
      isShowingPlace: state => state.dashboardView.entityType === EntityType.Place
    }),

    ...mapGetters([
      'isDevelopmentEnvironment',
      'getPlaceDevices',
      'isPlaceExpanded',
      'availableDeviceCommands',
      'isMobilePhone'
    ]),

    placeId () {
      return this.place.id
    },

    // Returns true if this is a virtual place, representing devices not belonging to any place
    noPlace () {
      return this.place.placeType === PlaceType.NoPlace
    },

    // Returns true if this is a virtual place, representing devices shared with the organization
    sharedPlace () {
      return this.place.placeType === PlaceType.SharedPlace
    },

    // Devices in the place
    placeDevices () {
      return this.getPlaceDevices(this.place, !this.showParts)
    },

    // Serial numbers of all devices in place
    placeSerialNumbers () {
      return this.placeDevices.map(d => d.serialNumber)
    },

    // Devices currently selected in the place
    selectedDevices () {
      return this.placeDevices.filter(d => this.allSelectedDevices.some(s => s.id === d.id))
    },

    // Serial numbers of all devices in place
    selectedSerialNumbers () {
      return this.selectedDevices.map(d => d.serialNumber)
    },

    // Indicates whether any devices are selected
    hasSelectedDevices () {
      return (this.selectedDevices || []).length > 0
    },

    // Checks whether the specified device is currently selected
    isDeviceSelected () {
      return device => this.selectedDevices.some(d => d.id === device.id)
    },

    // Indicates whether all devices are selected in the place
    allDevicesSelected () {
      return !this.isEmpty && this.placeDevices.every(d => this.isDeviceSelected(d))
    },

    // Indicates that there are no devices in this place
    isEmpty () {
      return this.placeDevices.length === 0
    },

    // Determines whether place should be visible on the dashboard
    isVisible () {
      // Do not show Shared Devices category, if no devices are shared with organization
      return this.sharedPlace ? !this.isEmpty : true
    },

    // Checks place and its devices are editable, commands can be sent to them etc.
    isEditable () {
      const { isMobilePhone, isRearranging, sharedPlace } = this
      return !sharedPlace && !isRearranging && !isMobilePhone
    },

    // Checks place is currently expanded
    isExpanded () {
      return this.isPlaceExpanded(this.place)
    },

    // Checks whether current user can edit the place
    canEditPlace () {
      return !this.isMobilePhone &&
        !this.noPlace &&
        !this.sharedPlace &&
        this.canUse('set-device-place') &&
        this.isAdministrator
    },

    // Indicates whether place can be modified, eg. devices can be dragged into
    // place, the place can be deleted etc.
    // Place is locked if it's region-bound, except for the super-administrator
    isPlaceLocked () {
      const { place, isSuperAdministrator } = this
      return place.hasRegion ? !isSuperAdministrator : false
    },

    // Indicates whether user is allowed to select all devices in the place
    // to perform actions on them
    canSelectAll () {
      const { isEmpty, canUseAny, isMobilePhone } = this
      return !isMobilePhone &&
        !isEmpty &&
        canUseAny(['device-management-rebalance', 'device-management-reboot'])
    },

    // Icon representing notes
    notesIcon () {
      const { place: { hasOwnAttachments, hasNotes } } = this
      return hasOwnAttachments
        ? 'attachment'
        : (hasNotes ? 'comment' : 'mode_comment')
    },

    // Color of the icon representing notes
    notesColor () {
      const { place: { hasAttachments, hasNotes } } = this
      return hasAttachments || hasNotes ? 'indigo-5' : 'indigo-2'
    },

    // Indicates whether live status can be monitored for the specified device
    canSeeLiveStatus () {
      return device => {
        const { currentOrganizationGuardian, viewedOrganizationGuardian } = this
        if (currentOrganizationGuardian.requiresPremiumSubscription('live-status')) {
          return viewedOrganizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
        } else {
          return currentOrganizationGuardian.canUse('live-status')
        }
      }
    },

    // Indicates whether there are any commands which can be sent to the currently selected devices
    canSendCommands () {
      return device => {
        const canSendCommands = device.isConnectedDevice && this.canUse('device-management')
        return canSendCommands
      }
    },

    // Indicates whether the device should be selectable
    isDeviceSelectable () {
      return device => this.canSeeLiveStatus(device) && this.canSendCommands(device)
    },

    // Indicates whether the user can execute actions on place
    canExecuteActions () {
      return !this.isMobilePhone && this.actions.length > 0
    },

    // Context menu actions available for this place
    actions () {
      const { canUse, canEditPlace, isPlaceLocked } = this
      const { name, placeType } = this.place
      const actions = [
        {
          ...ListAction.Edit,
          label: `Edit ${getPlaceLabel(placeType)} ${name}`,
          isVisible: canEditPlace
        },
        {
          name: 'place-notes',
          label: 'Edit place notes',
          icon: 'attachment',
          color: 'grey-5',
          isVisible: canEditPlace
        },
        {
          separator: true,
          name: 'add-non-connected-device',
          label: 'Add non-connected device',
          icon: 'router',
          color: 'grey-5',
          isVisible: canUse('add-non-connected-devices')
        },
        {
          name: 'add-simulated-device',
          label: 'Add simulated device',
          icon: 'router',
          color: 'orange-7',
          isVisible: canUse('add-simulated-devices')
        },
        {
          ...ListAction.Delete,
          separator: true,
          label: `Delete ${getPlaceLabel(placeType)} ${name}`,
          isVisible: canEditPlace && !isPlaceLocked
        }
      ]

      return actions

    }
  },

  methods: {
    ...mapMutations([
      'storePlace',
      'selectDevicesInPlace'
    ]),
    ...mapActions([
      'busy',
      'done',
      'showDialog',
      'editPlace',
      'removePlace',
      'togglePlace',
      'addNonConnectedDevice',
      'addSimulatedDevice',
      'savePlaceNotes',
      'saveAttachments',
      'deleteAttachments'
    ]),

    getPlaceIcon,
    countString,

    // Returns true if the specified device belongs to this place
    isMyDevice (deviceId) {
      return this.placeDevices.some(d => d.id === deviceId)
    },

    // DRAG-DROP HANDLING ------------------------------------------
    // Starts dragging the place
    startDraggingPlace (e) {
      if (this.sharedPlace) return

      const placeId = e.target.id
      if (this.placeId === placeId) {
        e.dataTransfer.setData('placeId', placeId)
        e.dataTransfer.dropEffect = 'move'
        e.target.classList.add('dragging')
      }
    },

    // Ends dragging the place
    endDraggingPlace (e) {
      e.preventDefault()

      e.target.classList.remove('dragging')
      this.$emit('placeDragEnded')
    },

    // If dragging an item from another place over this place, signal it
    dragEnter (e) {
      if (this.sharedPlace) return

      const { placeId: targetPlaceId } = this
      const deviceId = e.dataTransfer.getData('deviceId')
      const placeId = e.dataTransfer.getData('placeId')

      if (deviceId) {
        // Dragging device over the place
        if (this.isPlaceLocked) return
        if (!this.isMyDevice(deviceId)) {
          this.$emit('deviceDraggedOverPlace', { targetPlaceId, deviceId })
        }
      } else if (placeId) {
        // Dragging another place over the place
        if (placeId !== targetPlaceId) {
          this.$emit('placeDraggedOverPlace', { targetPlaceId, placeId })
        }
      }
    },

    dragOver (e) {
      e.preventDefault()
    },

    // Something was dropped on the place
    dropOnPlace (e) {
      e.preventDefault()
      if (this.sharedPlace) return

      const { placeId: targetPlaceId, noPlace } = this
      const deviceId = e.dataTransfer.getData('deviceId')
      const placeId = e.dataTransfer.getData('placeId')

      if (deviceId && !this.isMyDevice(deviceId) && !this.isPlaceLocked) {
        // Device from another place was dropped here - change its place
        this.$emit('dropDeviceOnPlace', { targetPlaceId, deviceId })

      } else if (placeId && placeId !== this.placeId && !noPlace) {
        // Another place was dropped here - reorder
        this.$emit('dropPlaceOnPlace', { targetPlaceId, placeId })
      }
    },

    // Toggles visibility of the place
    async toggle () {
      const { organization, place, placeDevices: devices } = this
      await this.togglePlace({ organization, place })
      const { isExpanded } = this
      this.$emit('toggle', { organization, place, devices, isExpanded })
    },

    // Toggles all devices in place as selected/deselected
    toggleDevices (isSelected) {
      const { place, placeDevices } = this
      const allowed = placeDevices.filter(device => this.isDeviceSelectable(device))
      this.selectDevicesInPlace({ place, isSelected, allowed })
    },

    // Shows dialog for adding notes and documents to place
    async showPlaceNotes () {
      const { place, viewedOrganization, showDialog } = this
      const { name, placeType } = place
      const title = `Documents associated with ${getPlaceLabel(placeType)} ${name}`

      const { isOk, data } = await showDialog({
        dialog: 'document-upload',
        data: {
          title,
          okLabel: 'Save',
          selectFilesLabel: 'Add documents ...',
          notes: place.note || '',
          documents: [...place.ownAttachments],
          allowMultiple: true,
          maxFiles: 100
        }
      })

      const { notes, newDocuments, removedDocuments } = data || {}
      const storingDocuments = newDocuments?.length > 0 || removedDocuments?.length > 0
      if (isOk) {
        await this.busy({ message: storingDocuments ? `Storing documents of ${place.name} ...` : `Saving notes of ${place.name} ...` })
        place.note = notes
        place.attachments = place.attachments || []
        await this.savePlaceNotes({ place })

        try {
          if (newDocuments?.length) {
            const saved = await this.saveAttachments({
              entity: place,
              owner: viewedOrganization,
              attachments: newDocuments,
              silent: true
            })
            place.attachments = [...place.attachments, ...saved]
          }

          if (removedDocuments?.length) {
            await this.deleteAttachments({ attachments: removedDocuments, silent: true, confirm: false })
            place.attachments = place.attachments.filter(a => !removedDocuments.find(r => r.id === a.id))
          }

          this.storePlace({ place })
          await this.done({ message: storingDocuments ? 'Documents stored' : 'Notes saved' })

        } catch (error) {
          await this.done({ error: error.message })
        }

      }
    },

    // Executes an action
    async executeAction (place, action) {
      const { organization } = this

      switch (action.name) {
        case 'edit':
          await this.editPlace({ place })
          break
        case 'delete':
          await this.removePlace({ place, confirm: true })
          break
        case 'place-notes':
          await this.showPlaceNotes()
          break
        case 'add-non-connected-device':
          await this.addNonConnectedDevice({ place, organization })
          break
        case 'add-simulated-device':
          await this.addSimulatedDevice({ place, organization })
          break
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}
</script>

<template>
  <q-tr class="place-item" v-if="isVisible" :class="{
    expanded: isExpanded,
    empty: isEmpty,
    drop: placeId === targetPlaceId,
    noplace: noPlace,
    sharedplace: sharedPlace
  }" :id="place.id" :key="`place-${place.id}`"
    :draggable="!(noPlace || sharedPlace) && isRearranging" @dragstart="startDraggingPlace"
    @dragend="endDraggingPlace" @dragenter="dragEnter" @dragover="dragOver" @drop="dropOnPlace">

    <q-td class="icon">
      <q-btn dense round flat :color="isEmpty ? 'transparent' : 'indigo-6'" :disabled="isEmpty"
        :icon="isExpanded ? 'expand_more' : 'chevron_right'" @click.stop="toggle()">
      </q-btn>
    </q-td>

    <q-td class="name">
      <div class="row items-center no-wrap">
        <q-icon class="q-mr-sm" size="sm" color="indigo-5"
          :name="noPlace ? 'storage' : (sharedPlace ? 'share' : getPlaceIcon(place.placeType))">
        </q-icon>

        <span class="text q-mr-sm">
          <router-link class="item-link" v-once :to="{
            name: 'place-dashboard',
            params: { id: place.id },
            query: { organization: organization.id }
          }">
            {{ place.fullText }}
            /
            {{ countString(placeDevices, 'device') }}
          </router-link>
        </span>

        <span class="text q-ml-md" v-if="canEditPlace">
          <q-btn dense unelevated no-caps class="clear"
            :label="`${place.hasOwnAttachments ? countString(place.ownAttachments, 'document') : ''}`"
            :icon="notesIcon" :textColor="notesColor" @click="showPlaceNotes()">
          </q-btn>
        </span>
      </div>
    </q-td>

    <q-td class="select">
      <q-checkbox v-if="canSelectAll && !isRearranging" :model-value="allDevicesSelected"
        @update:model-value="() => toggleDevices(!allDevicesSelected)">
        <sc-tooltip v-if="allDevicesSelected" text="Deselect devices">
        </sc-tooltip>
        <sc-tooltip v-else>
          {{ noPlace ? 'Select all stock devices' : `Select all devices in ${place.name}` }}
        </sc-tooltip>
      </q-checkbox>
      <span v-else>
        &nbsp;
      </span>
    </q-td>

    <q-td class="actions" v-if="canExecuteActions">
      <sc-action-dropdown dense v-if="isEditable && !isRearranging" :actions="actions"
        @action="action => executeAction(place, action)">
      </sc-action-dropdown>
      <span v-else>
        &nbsp;
      </span>
    </q-td>
  </q-tr>
</template>

<style lang="scss" scoped>
.place-dropdown {
  .q-btn-dropdown__arrow {
    margin-left: 0 !important;
  }
}

tr.place-item {

  /* removes hover background introduced by Quasar */
  >td::before {
    background: none;
  }

  &.empty {
    cursor: default;
  }

  &.expanded {
    background: linear-gradient(180deg, #4242421f 0%, transparent 10%, transparent 100%);

    &:first-child {
      background: transparent;
    }

    >td {
      border-bottom-color: transparent;
    }
  }

  >td::before {
    background: #8f8f8f1f;
  }

  >td {
    background: transparent;
    text-align: left;
    border-bottom: solid #0000001f 1px;
    padding-top: 8px;

    &.icon {
      width: 32px;
      padding-right: 0;
    }

    &.name {
      padding-left: 4px;

      .text {
        font-size: 14px;
        color: #202020;
        cursor: pointer;
      }
    }

    &.select {
      width: 40px;
      max-width: 40px;
    }

    &.actions {
      width: 50px;
      max-width: 50px;
      text-align: right;
      padding-right: 16px;
    }
  }

  /* Separate NO-PLACE with thicker line */
  &.noplace {
    >td {
      border-bottom-width: 3px;
    }
  }

  /* Separate SHARED-PLACE with thicker line */
  &.sharedplace {
    >td {
      border-bottom-width: 3px;
    }
  }

  /* Drag-n-drop */
  &.drop {
    background: #498edd1c;
  }

  .button-select {
    min-width: 145px;
  }

}
</style>