import { EntityType } from '@stellacontrol/model'
import './widgets'
import DashboardView from './dashboard.vue'
import { resolve } from './dashboard.resolve'

export const Routes = [
  {
    // Full dashboard URL
    name: 'dashboard',
    path: '/dashboard',
    component: DashboardView,

    async beforeEnter (to, from, next) {
      const result = await resolve({ to, from })
      if (result) {
        next(result.redirectTo)
      }
    }
  },
  {
    // A shortcut to device dashboards
    name: 'device-dashboard',
    path: '/dashboard/device/:serialNumber',

    async beforeEnter (to, from, next) {
      const { serialNumber } = to.params
      next({
        name: 'dashboard',
        query: {
          entityType: EntityType.Device,
          device: serialNumber,
          tree: 'minimized'
        }
      })
    }
  },
  {
    // A shortcut to organization dashboards
    name: 'organization-dashboard',
    path: '/dashboard/organization/:id',

    async beforeEnter (to, from, next) {
      const { id } = to.params
      next({
        name: 'dashboard',
        query: {
          entityType: EntityType.Organization,
          organization: id,
          tree: 'minimized'
        }
      })
    }
  },
  {
    // A shortcut to place dashboards
    name: 'place-dashboard',
    path: '/dashboard/place/:id',

    async beforeEnter (to, from, next) {
      const { id: place } = to.params
      const { organization } = to.query
      next({
        name: 'dashboard',
        query: {
          entityType: EntityType.Place,
          organization,
          place,
          tree: 'minimized'
        }
      })
    }
  }
]
