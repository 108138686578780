<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { sameArrays } from '@stellacontrol/utilities'
import { EntityType, getDeviceLabel, getPlaceDescription } from '@stellacontrol/model'
import { ViewMixin, Viewport } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { resolve } from './dashboard.resolve'
import DeviceTree from '../../components/device-tree/device-tree.vue'
import OrganizationDashboard from './organization-dashboard/organization-dashboard.vue'
import DeviceDashboard from './device-dashboard/device-dashboard.vue'

const name = 'dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-device-tree': DeviceTree,
    'sc-organization-dashboard': OrganizationDashboard,
    'sc-device-dashboard': DeviceDashboard
  },

  data () {
    return {
      name,
      // Place filter for the mobile view
      placeFilter: null
    }
  },

  computed: {
    ...mapState({
      // Hierarchy of organizations, places and devices
      hierarchy: state => state.dashboardView.hierarchy,
      // Dashboard title
      dashboardTitle: state => state.dashboardView.title,
      // Currently viewed entity
      entityType: state => state.dashboardView.entityType,
      entity: state => state.dashboardView.entity,
      // All available organizations
      organizations: state => state.dashboardView.organizations,
      // Currently viewed organization or organization of the viewed place/device
      organization: state => state.dashboardView.organization,
      // Currently viewed organization's guardian
      organizationGuardian: state => state.dashboardView.organizationGuardian,
      // Currently viewed place or place of the viewed device
      place: state => state.dashboardView.place,
      // Currently viewed device
      device: state => state.dashboardView.device,
      // Currently viewed devices
      devices: state => [state.dashboardView.device],
      // Asset tree state
      treeState: state => state.dashboardView.tree
    }),

    ...mapGetters([
      'isMobilePhone'
    ]),

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return this.organization.id !== this.currentOrganization.id
    },

    // Name of the component for rendering the dashboard for currently selected entity
    dashboardComponent () {
      switch (this.entityType) {
        case EntityType.Organization:
        case EntityType.Place:
          return 'sc-organization-dashboard'
        case EntityType.Device:
          return 'sc-device-dashboard'
        default:
          return 'sc-empty'
      }
    },

    // Indicates whether asset tree is currently visible
    isTreeVisible () {
      return !this.isMobilePhone
    },

    // Indicates whether the tree should be autocollapsed,
    // once a selection has been made.
    // This happens in mobile view.
    autoCollapseTree () {
      return Viewport.isSmallScreen
    },

    // Indicates whether asset tree is currently minimized
    isTreeMinimized () {
      return this.treeState.isMinimized
    },

    // View breadcrumbs
    breadcrumbs () {
      const { isMobilePhone, organization, organizations, place, device, getViewTitle } = this

      if (isMobilePhone) {
        return []
      }

      if (!organization) {
        return []
      }
      const hierarchy = organization.getMyAncestors(organizations)
      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        ...hierarchy
          .filter(o => o)
          .map(organization => ({
            name: 'dashboard',
            title: organization.name,
            route: 'organization-dashboard',
            params: { id: organization.id }
          })),
        place
          ? {
            name: 'dashboard',
            title: getPlaceDescription(place),
            route: 'place-dashboard',
            params: { id: place.id },
            query: { organization: place.organizationId }
          }
          : null,
        device
          ? {
            name: 'dashboard',
            title: getDeviceLabel(device),
            route: 'device-dashboard',
            params: { serialNumber: device.serialNumber }
          }
          : null
      ]

      return breadcrumbs.filter(b => b)
    },

    // Indicates whether to show the button allowing navigation back to places
    showBackToPlacesButton () {
      const { isMobilePhone, entityType } = this
      return isMobilePhone && (entityType === EntityType.Device || entityType === EntityType.Place)
    },

    // Indicates whether to show the logout button
    showFilterAndLogout () {
      const { isMobilePhone, entityType } = this
      return isMobilePhone && entityType === EntityType.Organization
    }
  },

  methods: {
    ...mapMutations([
      'filterPlaces'
    ]),
    ...mapActions([
      'unsubscribeDeviceStatus',
      'gotoRoute',
      'storeExpandedDashboardItems',
      'storeSelectedDashboardItem',
      'storeDashboardTreeState',
      'endSession'
    ]),

    // Logs out from the application
    async logout () {
      await this.endSession()
    },

    // Triggered when a node in the tree has been selected
    treeSelected (node) {
      // If selected the same entity as currently displayed, just leave
      if (!node) return
      if (!this.entity || node.id === this.entity.id) return

      if (this.selected !== node.id) {
        this.storeSelectedDashboardItem({ selected: node.id })
        let organization, place, device, title

        if (node.type === EntityType.Organization) {
          organization = node.organization.id
          title = node.organization.name

        } else if (node.type === EntityType.Place) {
          place = node.place.id
          organization = node.organization.id
          title = node.place.name

        } else if (node.type === EntityType.Device) {
          device = node.device.id
          place = node.device.placeId
          organization = node.organization.id
          title = node.device.acronym
        }

        const query = { organization, place, device, entityType: node.type }
        const params = { title }
        this.gotoRoute({ name: 'dashboard', query, params })
      }
    },

    // Triggered when some nodes in the tree have been expanded or collapsed
    treeExpanded (expanded = []) {
      if (!sameArrays(expanded, this.treeState.expanded)) {
        this.storeExpandedDashboardItems({ expanded })
      }
    },

    // Expands the asset tree
    showTree () {
      this.storeDashboardTreeState({ isMinimized: false })
    },

    // Collapses the asset tree
    hideTree () {
      this.storeDashboardTreeState({ isMinimized: true })
    },

    // Triggered when tree has been filtered
    treeFiltered (filter) {
      this.storeDashboardTreeState({ filter })
    },

    // Triggered when tree view mode has been filtered
    treeViewModeChanged (viewMode) {
      this.storeDashboardTreeState({ viewMode })
    },

    // Invalidates the dashboard
    async invalidateDashboard (message) {
      const { dashboard } = this.$refs
      if (dashboard) {
        await dashboard.invalidate(message)
      }
    },

    // Populates the dashboard after navigation to another entity
    async populateDashboard (message) {
      const { dashboard, tree } = this.$refs
      if (dashboard) {
        await dashboard.invalidate(message)
        await dashboard.populate()
        const { entity } = this
        if (entity) {
          await this.storeSelectedDashboardItem({ selected: entity.id })
          if (tree) {
            await tree.selectNode(entity.id)
          }
        }
      }
    },

    // Triggered when places have been changed, tree needs to be refreshed
    placesChanged () {
      const { tree } = this.$refs
      if (tree) {
        tree.refresh()
      }
    }
  },

  // On first enter, initialize the dashboard
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.populateDashboard()
    })
  },


  // Reload data on navigation to another entity
  async beforeRouteUpdate (to, from, next) {
    // Stop any running status subscriptions
    await this.unsubscribeDeviceStatus()
    // Load new data
    if (await resolve({ from, to })) {
      await next()
      await this.invalidateDashboard()
      await this.populateDashboard()
    }
  },

  // Stop all status subscriptions before leaving the route
  async beforeUnmount () {
    await this.unsubscribeDeviceStatus()
  }
}

</script>

<template>
  <sc-view :name="name" :title="dashboardTitle" :breadcrumbs="breadcrumbs">

    <!-- navigation back to PLACES on mobile phone -->
    <template #toolbar v-if="showBackToPlacesButton">
      <q-btn flat dense no-caps class="primary" label="Close"
        :to="{ name: 'organization-dashboard', params: { id: currentOrganization.id }, query: { tree: false } }">
      </q-btn>
    </template>

    <template #toolbar v-if="showFilterAndLogout">
      <div class="row items-center">
        <q-btn flat dense icon="search" no-caps :class="{ primary: !placeFilter, warning: placeFilter }"
          label="Find">
          <q-popup-proxy square>
            <div class="place-filter">
              <q-input class="q-mr-sm" dense borderless v-model="placeFilter" debounce="500" autofocus
                clearable label="Find places or devices ..."
                @update:model-value="filter => filterPlaces({ filter })">
              </q-input>
            </div>
          </q-popup-proxy>
        </q-btn>
        <q-btn flat dense no-caps class="primary" label="Log Out" @click="logout()">
        </q-btn>
      </div>
    </template>

    <main class="dashboard">
      <section class="tree-container" :class="{ collapsed: isTreeMinimized }" v-if="isTreeVisible">
        <div v-if="treeState.isMinimized" class="expander row items-center q-pa-xs q-pt-sm">
          <q-btn no-caps no-wrap dense round unelevated :ripple="false" class="text-white q-mt-xs"
            icon="chevron_right" @click.stop="showTree()">
            <sc-tooltip text="Show organizations, places and devices"></sc-tooltip>
          </q-btn>
        </div>

        <sc-device-tree ref="tree" v-else :hierarchy="hierarchy" :allow-checking="false"
          :initially-minimized="treeState.isMinimized" :initially-expanded="treeState.expanded"
          :initially-selected="treeState.selected" :initial-filter="treeState.filter"
          :initial-view-mode="treeState.viewMode" :auto-collapse="autoCollapseTree"
          @minimized="hideTree()" @expanded="expanded => treeExpanded(expanded)"
          @selected="node => treeSelected(node)"
          @viewModeChanged="viewMode => treeViewModeChanged(viewMode)"
          @filtered="filter => treeFiltered(filter)">
        </sc-device-tree>
      </section>

      <section class="dashboard-container">
        <component ref="dashboard" :is="dashboardComponent" @placesChanged="placesChanged">
        </component>
      </section>
    </main>
  </sc-view>
</template>

<style lang='scss' scoped>
.dashboard {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .tree-container {
    flex: 0;
    display: flex;
    flex-direction: column;
    border-right: solid #dddddd 1px;

    .expander {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #273163;

      button {
        background-color: transparent;
      }
    }
  }

  .dashboard-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.place-filter {
  background-color: white;
  padding: 5px 10px 5px 10px;
  position: absolute;
  top: 55px;
  border-bottom: solid #273163 1px;
  width: 100vw;
}
</style>
