<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { groupItems, sortItems } from '@stellacontrol/utilities'
import { Place } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { FilesMixin } from '@stellacontrol/common-ui'

export default {
  mixins: [
    Secure,
    FilesMixin
  ],

  components: {
  },

  props: {
    // Place whose devices are shown
    place: {
      type: Place,
      required: true
    },

    // Indicates that we're currently allowing the user
    // to rearrange devices and places
    isRearranging: {
      type: Boolean,
      value: false
    },

    // Indicates that we want to see place's own attachments
    showOwn: {
      type: Boolean,
      value: false
    },

    // Indicates that we want to see place's linked attachments
    showLinked: {
      type: Boolean,
      value: false
    }
  },

  data () {
    return {
      // Dictionary of folder expanded/collapsed state
      collapsedFolders: {
      }
    }
  },

  computed: {
    ...mapState({
    }),

    ...mapGetters([
      'isPlaceExpanded',
      'isMobilePhone'
    ]),

    placeId () {
      return this.place.id
    },

    attachments () {
      return this.place.attachments || []
    },

    // Attachments matching the selection
    visibleAttachments () {
      const { showOwn, showLinked } = this
      const attachments = this.attachments.filter(a => (showOwn && !a.isLinked) || (showLinked && a.isLinked))
      return sortItems(attachments, 'createdAt', true)
    },

    // Indicates that place has no attachments to show
    isEmpty () {
      return this.visibleAttachments.length === 0
    },

    // Attachments grouped by folder
    folders () {
      const groups = groupItems(this.visibleAttachments, 'folder')
      return Object.entries(groups).map(([name, files]) => ({ name, files }))
    },

    // Checks whether devices are editable, commands can be sent to them etc.
    isEditable () {
      const { isMobilePhone, isRearranging } = this
      return !isMobilePhone && !isRearranging
    },

    // Checks whether the place is currently displayed as expanded
    isExpanded () {
      return this.isPlaceExpanded(this.place)
    },
  },

  methods: {
    ...mapActions([
      'showDialog',
      'unlinkAttachment'
    ]),

    // Removes the specified files
    async remove (file) {
      if (file) {
        if (file.isLinked) {
          await this.unlinkAttachment({ attachment: file, principal: this.place })
          this.place.removeAttachment(file)
        } else {
          this.removeFiles({ files: [file] })
        }
      }
    },

    // Previews the specified file
    async preview (file) {
      if (file) {
        await this.previewFile({ file })
      }
    },

    // Prints the specified file
    async print (file) {
      if (file) {
        await this.printFiles({ files: [file] })
      }
    },

    // Downloads the specified file
    async download (file) {
      if (file) {
        await this.downloadFile({ file })
      }
    },

    // Expands/collapses a folder
    toggleFolder (folder) {
      this.collapsedFolders[folder.name] = !this.collapsedFolders[folder.name]
    }
  }
}
</script>

<template>
  <q-tr class="place-attachments" v-if="isExpanded && !isEmpty">
    <q-td colspan="4">
      <div class="folders">
        <template v-for="folder in folders">
          <header class="text-indigo-8 row items-center" :class="{ collapsed: collapsedFolders[folder.name] }" @click="toggleFolder(folder)">
            <q-icon name="folder" color="indigo-6" size="sm" class="q-mr-sm">
            </q-icon>
            <span class="q-mr-sm">
              {{ folder.name }} ({{ count(folder.files, 'file') }})
            </span>
            <q-icon color="indigo-6"
              :name="collapsedFolders[folder.name] ? 'chevron_right' : 'expand_more'" size="sm">
            </q-icon>
          </header>

          <main class="attachments" v-if="!collapsedFolders[folder.name]">
            <sc-file v-for="file of folder.files" :key="file.id" :file="file" bordered rounded
              can-annotate can-delete can-preview can-print can-download
              @select="select(!file.isSelected, file)" @remove="remove" @preview="preview"
              @download="download" @print="print">
            </sc-file>
          </main>
        </template>
      </div>
    </q-td>
  </q-tr>
</template>

<style lang="scss" scoped>
tr.place-attachments {

  /* removes hover background introduced by Quasar */
  >td::before {
    background: none;
  }

  .folders {
    padding: 0 0 0 56px;

    >header {
      margin-bottom: 8px;
      cursor: pointer;
    }

    >main {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 25px;
    }
  }
}

/* Layout adjustments for mobile phones */
@media screen and (max-width: 640px) {
  tr.place-attachments {
    .attachments {
      padding: 0 8px 8px 8px;
    }
  }
}
</style>
