import BuildingsView from './buildings.vue'
import { resolve } from './buildings.resolve'

export const Routes = [
  // Buildings view
  {
    name: 'buildings',
    path: '/buildings',
    component: BuildingsView,

    async beforeEnter (to, from, next) {
      const { redirectTo } = await resolve({ from, to }) || {}
      next(redirectTo)
    }
  }
]
