<script>
import { mapActions } from 'vuex'
import { getPlaceIcon } from '@stellacontrol/model'
import { ListAction, ActionItem, DefaultListActions } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Organization
    organization: {
      type: Object,
      required: true
    },
    // Reseller above the organization
    reseller: {
      type: Object
    },
    // Floor plans of the organization
    floorPlans: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      DefaultListActions
    }
  },

  computed: {
    // List actions
    actions () {
      return [
        ListAction.Edit,
        new ActionItem({
          name: 'clone',
          label: 'Clone',
          icon: 'content_copy'
        }),
        ListAction.Delete
      ]
    }
  },

  methods: {
    ...mapActions([
      'editFloorPlan',
      'removeFloorPlan',
      'createFloorPlan',
      'cloneFloorPlan'
    ]),

    getPlaceIcon,

    executeAction (floorPlan, action) {
      switch (action.name) {
        case 'edit':
          return this.editFloorPlan({ floorPlan })
        case 'delete':
          return this.removeFloorPlan({ floorPlan, confirm: true })
        case 'clone':
          return this.cloneFloorPlan({ floorPlan })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}
</script>

<template>
  <q-markup-table class="items" flat borderless>
    <thead>
      <th>Plan Name</th>
      <th>Place</th>
      <th>Reseller</th>
      <th>Updated On</th>
      <th>Updated By</th>
      <th class="actions">
        <q-btn unelevated dense icon="add" label="Add" @click="createFloorPlan({ organization })" v-if="canUse('edit-floor-plans')"/>
      </th>
    </thead>
    <tbody>
      <tr v-for="plan in floorPlans" :key="plan.id" @dblclick="executeAction(plan, DefaultListActions.find(a => a.isDefault))">
        <td class="name">
          <router-link class="item-link" :to="{ name: 'floor-plan', params: { id: plan.id } }">
            {{ plan.name }}
          </router-link>
        </td>
        <td class="place">
        <div class="row items-center no-wrap">
            <q-icon size="22px" color="indigo-5" v-if="plan.place" :name="getPlaceIcon(plan.place.placeType)" />
            <span class="q-ml-sm">
              <router-link v-if="plan.place" class="item-link" :to="{ name: 'dashboard', query: { entityType: 'place', organization: plan.organizationId, place: plan.placeId } }">
                {{ plan.place.name }}
                <sc-tooltip :text="`Go to the ${plan.place.name} dashboard`" />
              </router-link>
              <span v-else>-</span>
            </span>
          </div>
        </td>
        <td>{{ reseller ? reseller.name : '-' }}</td>
        <td>{{ date(plan.updatedAt) }}</td>
        <td>
          {{ plan.updater.fullName }}
        </td>
        <td class="actions">
          <sc-action-dropdown :actions="actions" :title="`Floor plan ${plan.name}`"
            @action="action => executeAction(plan, action)" v-if="canUse('edit-floor-plans')" />
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<style lang='scss' scoped>
.items {
  border: solid silver 1px;
  border-radius: 2;
  background: #fafafa;
  max-width: 1300px;

  th {
    text-align: left;
    height: 48px;

    &.actions {
      text-align: right;
    }
  }

  td {
    &.name {
      width: 203px;
    }
    &.place {
      width: 248px;
    }
    &.actions {
      text-align: right;
      padding-right: 16px;
    }
  }

  &:last-child {
    td {
      border-bottom: none;
    }
  }
}
</style>
