import { getPropertyValue } from '@stellacontrol/utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Sets properties of items
 */
export class SetItemPropertiesAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetItemProperties
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Object} properties Properties to set
   */
  async execute ({ renderer, items, properties } = {}) {
    if (renderer && items) {
      let refreshHierarchy = false

      for (const item of items) {
        item.setProperties(properties)

        // Update any linked items invalidated as a result of property change
        await renderer.validateLinkedItems(item)

        // Notify all layers about the modified item.
        // They might want to render something related to it, for example radiation pattern around antenna.
        renderer.notifyLayers(layer => layer.itemChanged(item))

        // Check if property change requires rebuilding of the equipment hierarchy
        if (item.isDevice && 'device.serialNumber' in properties) {
          refreshHierarchy = true
        }

        // Some changes requires redrawing of all related items, for example:
        // - when we change device port count, splitter type or antenna type, shape might change
        // - when we change properties of a cross-floor connector, all its parts need to be updated accordingly
        // - etc.
        await renderer.refreshItem(item)
        await renderer.updateLinkedItems(item, properties)
      }

      // Notify about changes
      if (refreshHierarchy) {
        renderer.refreshEquipmentHierarchy()
      }

      renderer.changed({ action: this })
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
   */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })

    // Restore the changed property to its previous value
    for (const { id } of data.items || []) {
      const item = renderer.layout.getItem(id)
      const previous = data.getItemSnapshot(id)

      if (item && previous) {
        const properties = data.parameters.properties
        for (const name of Object.keys(data.parameters.properties)) {
          properties[name] = getPropertyValue(previous, name, undefined, false)
        }
        await this.execute({ renderer, items: [item], properties })
      }
      await renderer.refresh()
    }
  }

}
