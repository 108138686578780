import { parseEnum } from '@stellacontrol/utilities'
import { PlanRectangle } from './plan-rectangle'
import { PlanItemType } from './plan-item'

/**
 * Legend types
 */
export const LegendType = {
  Power: 'power',
  Equipment: 'equipment'
}

/**
 * Legend names
 */
export const LegendName = {
  [LegendType.Power]: 'Power Legend (dBm)',
  [LegendType.Equipment]: 'Equipment Legend'
}

/**
 * Legend-specific defaults
 */
const LegendDefaults = {
  [LegendType.Power]: {
  },
  [LegendType.Equipment]: {
  }
}

/**
 * Legend
 */
export class PlanLegend extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  __legendType

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Legend
  }

  /**
   * Creates a power legend
   * @param {Object} data Item data
   * @returns {PlanLegend}
   */
  static createPowerLegend (data = {}) {
    return new PlanLegend({
      ...data,
      legendType: LegendType.Power
    })
  }

  /**
   * Creates an equipment legend
   * @param {Object} data Item data
   * @returns {PlanLegend}
   */
  static createEquipmentLegend (data = {}) {
    return new PlanLegend({
      ...data,
      legendType: LegendType.Equipment
    })
  }

  /**
   * Object defaults
   */
  get defaults () {
    const { legendType } = this
    const legendDefaults = LegendDefaults[legendType]


    const defaults = {
      ...super.defaults,
      ...legendDefaults
    }

    return defaults
  }

  normalize () {
    super.normalize()
    this.legendType = parseEnum(LegendType, this.legendType, LegendType.Power)
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime data
    result.legendType = result.__legendType
    delete result.__legendType

    // Remove fixed styles
    delete result.backgroundStyle
    delete result.lineStyle
    delete result.textStyle

    return result
  }

  /**
   * Legend type
   * @type {LegendType}
   */
  get legendType () {
    return this.__legendType
  }
  set legendType (value) {
    if (value != null && this.__legendType !== value) {
      if (this.__legendType != null) {
        // If legend type was changed, refresh the ports and reset sizes to defaults
        this.__legendType = value
        this.refresh()
      } else {
        this.__legendType = value
      }
    }
  }

  /**
   * Refreshes the legend after changing type -
   * creates legend ports, updates sizes etc.
   */
  refresh () {
    const { legendType } = this
    const legendDefaults = LegendDefaults[legendType]
    Object.assign(this, legendDefaults)
  }
}
