import Konva from 'konva'
import { PlanItemType, PlanPortType, PlanLocation } from '@stellacontrol/planner'
import { Shape } from './shape'
import { ShapeLayout } from './shape-layout'

/**
 * Cable Plug
 * @param {PlanPlug} item Plan item details
 */
export class PlugShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Plug
  }

  __layout

  /**
   * Shape layout
   * @type {ShapeLayout}
   */
  get layout () {
    const { item } = this
    const shape = { x: 0, y: 0, radius: 12 }
    const drawer = { x: -70, y: -40, width: 140, height: 110 }

    if (shape.radius !== item.radius) {
      throw new Error('Item dimensions here are different than in the item definition')
    }

    // Return the existing layout if unchanged
    if (this.__layout) {
      return this.__layout
    }

    // Prepare the shape layout
    const layout = new ShapeLayout({
      shape,
      drawer,
      ports: [
        {
          id: PlanPortType.Main,
          location: PlanLocation.Bottom,
          drawer: { x: 70, y: 90 },
        }
      ]
    })


    this.__layout = layout
    return layout
  }


  get defaults () {
    return {
      ...super.defaults,
      port: {
        width: 10,
        height: 10
      }
    }
  }

  /**
   * Shape representing the plug
   * @type {Konva.Shape}
   */
  plugShape

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.plugShape
  }

  /**
   * Shape representing the connection port on the plug
   * @type {Konva.Shape}
   */
  portShape

  /**
   * Creates all shapes making up the plug
   */
  createShapes () {
    super.createShapes()

    this.plugShape = new Konva.Circle()

    this.content.add(this.plugShape)
  }

  /**
   * Renders the plug shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  async render (renderer) {
    super.render(renderer)

    const { item, plugShape } = this

    plugShape.radius(item.radius)
    plugShape.fill(item.backgroundStyle.color)
    plugShape.stroke(item.lineStyle.color)
    plugShape.strokeWidth(item.lineStyle.width)
  }
}
