import { getPlaceLabel } from '@stellacontrol/model'

export const actions = {
  /**
   * Filters the places view by the specified conditions
   * @param {String} filter Free-text filter
   */
  applyPlacesFilter ({ commit }, { filter }) {
    commit('applyPlacesFilter', { filter })
  },

  /**
   * Pins or unpins the specified place
   * @param {String} placeId Place to pin/unpin
   * @param {Boolean} pin If specified, defines whether the place should be pinned.
   * If not specified, the current `pinned` state of the place is toggled
   */
  pinPlace ({ commit }, { placeId, pin }) {
    commit('pinPlace', { placeId, pin })
  },

  // Shows dialog for adding notes and documents to place
  async showPlaceNotes ({ dispatch, getters }, { place } = {}) {
    if (!place) throw new Error('Place is required')

    const { name, placeType } = place
    const title = `Documents associated with ${getPlaceLabel(placeType)} ${name}`

    const { isOk, data } = await dispatch('showDialog', {
      dialog: 'document-upload',
      data: {
        title,
        okLabel: 'Save',
        selectFilesLabel: 'Add documents ...',
        notes: place.note || '',
        documents: [...place.ownAttachments],
        allowMultiple: true,
        maxFiles: 100
      }
    })

    const { notes, newDocuments, removedDocuments } = data || {}
    const storingDocuments = newDocuments?.length > 0 || removedDocuments?.length > 0

    if (isOk) {
      await dispatch('busy', { message: storingDocuments ? `Storing documents of ${place.name} ...` : `Saving notes of ${place.name} ...` })
      place.note = notes
      place.attachments = place.attachments || []
      await dispatch('savePlaceNotes', { place })

      try {
        if (newDocuments?.length) {
          const saved = await dispatch('saveAttachments', {
            entity: place,
            owner: getters.organizations.find(o => o.id === place.organizationId),
            attachments: newDocuments,
            silent: true
          })
          place.attachments = [
            ...place.attachments,
            ...saved
          ]
        }

        if (removedDocuments?.length) {
          await dispatch('deleteAttachments', { attachments: removedDocuments, silent: true, confirm: false })
          place.attachments = place.attachments.filter(a => !removedDocuments.find(r => r.id === a.id))
        }

        await dispatch('storePlace', { place })
        await dispatch('done', { message: storingDocuments ? 'Documents stored' : 'Notes saved' })

      } catch (error) {
        await dispatch('done', { error: error.message })
      }

    }
  }
}
