<script>
import { mapState, mapMutations } from 'vuex'
import { Storage } from '@stellacontrol/client-utilities'

export default {
  data () {
    return {
      // Indicates that user has suppressed the AdBlock warning
      noWarning: false
    }
  },

  computed: {
    ...mapState({
      // Indicates that Ad Block is active for our website
      adBlockActive: state => state.client.adBlockActive
    })
  },

  methods: {
    ...mapMutations([
      'adBlock'
    ]),

    // Dismisses the warning
    dismiss () {
      this.noWarning = true
      Storage.put('adblock-warning-hide', 1)
    }
  },

  async mounted () {
    this.noWarning = Storage.get('adblock-warning-hide')
    if (this.noWarning) return

    setTimeout(async () => {
      // Wait for any ad blockers to kick in,
      // then probe a few known domains serving ads and tracking user behaviour.
      // Source: https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
      const urls = [
        'https://ad.doubleclick.net',
        'https://advertising.microsoft.com',
        'https://advertising.apple.com',
        'https://advertising.yahoo.com',
        'https://analytics.yahoo.com'
      ]

      let isActive = false

      for (const url of urls) {
        try {
          const response = await fetch(url, {
            method: 'GET',
            mode: 'no-cors',
            cache: 'no-store',
          })
          isActive = Boolean(response.redirected)
        }
        catch {
          isActive = true
        }
        if (isActive) break
      }

      this.adBlock({ isActive })
    }, 1000)
  }
}
</script>

<template>
  <div class="adblock-warning" v-if="adBlockActive && !noWarning">
    <q-banner class="bg-orange-8 q-pa-xs">
      <div class="row items-center q-pa-sm">
        <q-btn outline label="Dismiss" class="q-mr-md" text-color="orange-1"
          @click="dismiss()">
        </q-btn>
        <span class="text-white">
          Please disable Ad Blocker for our website.
          It can severely reduce the website performance.
          We don't show any ads and we don't track or sell your private data.
        </span>
      </div>
    </q-banner>
  </div>
</template>

<style scoped lang="scss">
.adblock-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}
</style>
