import { state, getters } from './dashboard-view.state'
import { mutations } from './dashboard-view.mutations'
import { actions } from './dashboard-view.actions'
import organizationDashboard from './organization'
import deviceDashboard from './device'

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    organizationDashboard,
    deviceDashboard
  }
}
