export function createState () {
  return {
    /**
     * Hierarchy of organizations, places and devices
     * available to the currently logged in organization and user
     */
    hierarchy: null,
    /**
     * Flat list of organizations in the hierarchy,
     * for easier lookups
     */
    organizations: [],
    /**
     * Dashboard title
     */
    title: null,
    /**
     * Type of the viewed entity: organization, place or device
     */
    entityType: null,
    /**
     * Viewed entity: organization, place or device
     */
    entity: null,
    /**
     * Organization being viewed
     */
    organization: null,
    isMyOrganization: false,
    /**
     * Organization's guardian
     */
    organizationGuardian: null,
    /**
     * Cache of previously retrieved organizations
     */
    organizationCache: {},
    /**
     * Place being viewed
     */
    place: null,
    /**
     * Device being viewed
     */
    device: null,
    /**
     * Device part being viewed
     */
    devicePart: null,
    /**
     * Pending premium service associated with device
     */
    pendingPremiumService: null,
    /**
     * Current state of the tree - minimized or visible, filter etc
     */
    tree: {
      /**
       * View mode: full tree / buildings list
       */
      viewMode: 'tree',
      /**
       * Tree is now minimized
       */
      isMinimized: true,
      /**
       * Tree filter
       */
      filter: null,
      /**
       * Currently expanded items in the tree
       */
      expanded: [],
      /**
       * Currently selected item in the tree
       */
      selected: null
    }
  }
}

export const state = createState()

export const getters = {
}
