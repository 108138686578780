<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { DeviceCommands, DeviceCommandDefinitions } from '@stellacontrol/devices'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  computed: {
    ...mapState({
      // Currently viewed organization
      organization: state => state.dashboardView.organization,
      // All devices available to the current organization
      allDevices: state => state.devices.devices || [],
      // All devices on the dashboard
      devices: state => state.dashboardView.organizationDashboard.devices || [],
      // All selected devices
      selectedDevices: state => state.dashboardView.organizationDashboard.selectedDevices || []
    }),

    ...mapGetters([
      'availableDeviceCommands'
    ]),

    // Devices to which commands can be sent.
    // Multi-devices are expanded into individual device boards
    targetDevices () {
      const { allDevices, selectedDevices } = this
      return selectedDevices.flatMap(device => device.isMultiDevice
        ? allDevices.filter(d => d.partOf === device.id)
        : [device]
      )
    },

    // Indicates that there are no devices
    hasTargetDevices () {
      return this.targetDevices.length > 0
    },

    // Standard device commands
    standardCommands() {
      return [
        DeviceCommands.StartFastSampling,
        null,
        DeviceCommands.Recalibrate,
        DeviceCommands.Reboot,
        DeviceCommands.WipeEEPROM,
        DeviceCommands.Blink,
        DeviceCommands.RequestCellScan,
        null,
        DeviceCommands.ShutdownBands,
        DeviceCommands.TurnOnBands,
      ].map(name => {
        const command = DeviceCommandDefinitions[name] || { separator: true }

        if (command.name === DeviceCommands.StartFastSampling) {
          command.notifications = {
            // Let the user know we're switching devices to fast mode
            executing: devices => `Switching ${countString(devices, 'device')} to fast mode ...`,
            // We suppress executed notification entirely,
            // as it's handled in organization dashboard.
            // There we let the user know, whether only some
            // of the selected devices have been switched to fast mode.
            executed: false
          }
        }
        return command
      })
    },

    // Custom commands available under the current selection
    customCommands () {
      const { targetDevices: devices } = this
      if (devices.length === 0) return []

      const commands = []
      const isBatch = devices.length > 1
      const nonConnected = devices.every(d => d.isNonConnectedDevice)
      const simulated = devices.every(d => d.isSimulatedDevice)
      if (nonConnected || simulated) {
        commands.push({
          name: 'delete-device',
          label: isBatch ? 'Delete devices' : 'Delete device',
          icon: 'close',
          color: 'red-8',
          separator: true,
          conditions: {
            confirmation: 'Are you sure to delete ${identification}?',
            permissions: [],
            isBatchCommand: false
          },
          handler: async ({ devices }) => {
            for (const device of devices) {
              await this.deleteDevice({ device })
            }
          }
        })
      } else {
        if (!isBatch) {
          commands.push({
            name: 'configure-device',
            label: 'Configure device',
            icon: 'settings',
            color: 'indigo-5',
            separator: true,
            conditions: {
              // Not only device-configuration permission is required,
              // but at least one of its child permissions
              permissionsOf: 'device-configuration',
              isConnectedDevice: true,
              isBatchCommand: false
            },
            handler: async ({ devices }) => {
              await this.configureDevice(devices[0])
            }
          })
        }
      }

      return commands
    },

    // Indicates whether there are any commands which can be sent to the currently selected devices
    canSendCommands () {
      const { hasTargetDevices, targetDevices, availableDeviceCommands, standardCommands, customCommands } = this
      return hasTargetDevices &&
        availableDeviceCommands(targetDevices, standardCommands, customCommands)
    }
  },

  methods: {
    ...mapActions([
      'deleteDevice',
      'showDialog'
    ]),

    countString,

    // CONFIGURATION
    // Launches dialog for configuring devices
    async configureDevice (device) {
      const { serialNumber } = device
      await this.showDialog({
        dialog: 'device-configuration',
        data: {
          serialNumber
        }
      })
    },

    // Triggered when command has been executed
    commandExecuted ({ command, devices }) {
      this.$refs.popupCommands.hide()
      this.$emit('command', { command, devices })
    }
  }
}
</script>

<template>
  <main>
    <q-btn
      unelevated dense class="q-ml-sm button-commands"
      label="Commands"
      icon="wifi_tethering"
      :disabled="!canSendCommands"
      @click.stop>
      <q-popup-edit ref="popupCommands" square :style="{ padding: 0 }" :cover="false" :model-value="selectedDevices.length">
        <sc-device-commands
          :devices="targetDevices"
          :commands="standardCommands"
          :custom-commands="customCommands"
          :header="`${countString(selectedDevices, 'device')} selected}`"
          @command="commandExecuted">
        </sc-device-commands>
      </q-popup-edit>
    </q-btn>

    <sc-tooltip v-if="hasTargetDevices">
      Send commands to selected {{ selectedDevices?.length === 1 ? 'device' : 'devices' }}
    </sc-tooltip>
    <sc-tooltip v-else>
      To send commands, select some devices first
    </sc-tooltip>
  </main>
</template>

<style scoped lang="scss">
main {
  display: inline-block;

  .button-commands {
    min-width: 140px;
  }
}
</style>