<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    // Displayed places
    places: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState({
      // Currently selected organization
      organization: state => state.dashboardView.organization,
      // List of currently expanded places
      expandedPlaces: state => state.dashboardView.organizationDashboard.expandedPlaces
    }),

    // Returns true if all places are currently collapsed
    allPlacesCollapsed () {
      return (this.expandedPlaces || []).length === 0
    }
  },

  methods: {
    ...mapActions([
      'togglePlaces',
      'togglePlaceGroups'
    ]),

    toggle (isExpanded) {
      const { organization, places } = this
      this.togglePlaces({ organization, isExpanded })

      if (isExpanded) {
        for (const place of places) {
          this.togglePlaceGroups({ organization, place, isExpanded })
        }
      }
    }
  }
}
</script>

<template>
  <div class="row items-center no-wrap">
    <q-btn no-caps no-wrap round dense unelevated :ripple="false"
      :icon="allPlacesCollapsed ? 'expand_more' : 'expand_less'"
      @click="toggle(allPlacesCollapsed)">
      <sc-tooltip :text="allPlacesCollapsed ? 'Expand all places' : 'Collapse all places'" />
    </q-btn>
  </div>
</template>
