import { reactive } from 'vue'

/**
 * Viewport service
 */
export const Viewport = reactive({
  /**
  * Typical screen sizes
  */
  VGA: {
    width: 640,
    height: 480
  },
  SVGA: {
    width: 800,
    height: 600
  },
  XGA: {
    width: 1024,
    height: 768
  },
  HD: {
    width: 1366,
    height: 768
  },
  FullHD: {
    width: 1920,
    height: 1080
  },

  /**
   * Indicates whether current window size qualifies as small screen - phone, tablet, old laptop
   */
  isSmallScreen: false,
  /**
   * Indicates whether current window size is within mobile phone view range
   */
  isMobilePhone: false,

  /**
   * Indicates whether current window size is within desktop view range
   */
  isNormalScreen () {
    return !(this.isSmallScreen || this.isMobilePhone)
  },

  /**
   * Returns document body element
   */
  body () {
    return (window.document || {}).body
  },

  /**
   * Returns true if current window size is equal or above the specified one
   */
  isWidthAtLeast (value) {
    return this.body()?.offsetWidth >= value
  },

  /**
   * Returns true if current window size is equal or below the specified one
   */
  isWidthAtMost (value) {
    return this.body()?.offsetWidth <= value
  },

  /**
   * Returns true if current window size is below the specified one
   */
  isWidthBelow (value) {
    return this.body()?.offsetWidth < value
  },

  /**
   * Returns true if current window size is above the specified one
   */
  isWidthAbove (value) {
    return this.body()?.offsetWidth > value
  }
})


window.addEventListener('load', () => {
  Viewport.isSmallScreen = Viewport.isWidthAtMost(Viewport.HD.width)
  Viewport.isMobilePhone = Viewport.isWidthAtMost(Viewport.XGA.width)
})

window.addEventListener('resize', () => {
  const isSmallScreen = Viewport.isWidthAtMost(Viewport.HD.width)
  const isMobilePhone = Viewport.isWidthAtMost(Viewport.XGA.width)

  if (Viewport.isSmallScreen !== isSmallScreen) {
    Viewport.isSmallScreen = isSmallScreen
  }

  if (Viewport.isMobilePhone !== isMobilePhone) {
    Viewport.isMobilePhone = isMobilePhone
  }
})
