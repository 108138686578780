<script>
import { mapState, mapActions } from 'vuex'
import { EntityType, DeviceConnectionStatus, DeviceConnectionStatusBackgroundColor, DeviceRegion, DeviceRegionDescription, getDeviceLabel } from '@stellacontrol/model'
import { sortItems } from '@stellacontrol/utilities'
import { Confirmation } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands } from '@stellacontrol/devices'
import DeviceLeds from '../widgets/device-leds.vue'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-widget-device-leds': DeviceLeds
  },

  props: {
    // Device displayed in the widget
    device: {
      required: true
    },

    // Master device, if device displayed in the widget is part of a multi-device
    masterDevice: {
    },

    // Place to which the device belongs
    place: {
    },

    // Organization in whose context the device is displayed
    organization: {
      required: true
    },

    // Compact view
    dense: {
      default: false
    },

    // Indicates that device can be selected
    isSelectable: {
      default: false
    },

    // Indicates that device is now selected
    isSelected: {
      default: false
    },

    // Indicates that we're able to perform edit actions
    // such as changing device location etc.
    isEditable: {
      default: false
    },

    // Indicates that we're able to add comment to device
    canComment: {
      default: true
    },

    // Indicates that device is now busy, so certain actions should
    // become unavailable for a while.
    isBusy: {
      default: false
    },

    // Indicates that device live status can be displayed
    isLiveStatusAllowed: {
      default: true
    },

    // Alternative link for the device serial number.
    // If not specified, the link will lead to device dashboard.
    cardLink: {
    }
  },

  data () {
    return {
      DeviceCommands,
      DeviceRegion,
      DeviceRegionDescription,
      // Custom location, for popup editor
      customLocation: '',
      // Required to show popups
      popup: true,
      // Comments, for popup editor
      comments: ''
    }
  },

  computed: {
    ...mapState({
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
      changingRegionOf: state => state.deviceSettings.changingRegionOfDevices,
      changingRegionTo: state => state.deviceSettings.changingRegionTo,
      // All devices available to the current organization
      devices: state => state.devices.devices || [],
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    // Status of the viewed device
    status () {
      const { device, deviceStatus } = this
      return deviceStatus[device?.serialNumber]
    },

    // Firmware update status of the viewed device
    updateStatus () {
      const device = this.devices.find(d => d.id === this.device.id)
      return device?.updateStatus
    },

    // Device label to display
    deviceLabel () {
      const { device, device: { isConnectedDevice }, canUse, place } = this
      const showRegion = isConnectedDevice && canUse('device-management-region-change') && place && place.hasRegion
      return `${getDeviceLabel(device)} ${showRegion ? '(' + device.modelRegion.toUpperCase() + ')' : ''}`
    },

    // Device band count
    bandCount () {
      const { device: { bands } } = this
      return bands ? bands.length : 0
    },

    // Boards making up a multi-device
    deviceParts () {
      const parts = this.device.isMultiDevice
        ? this.devices.filter(d => d.partOf === this.device.id)
        : undefined
      return parts
        ? sortItems(parts, part => part.model)
        : undefined
    },

    // The visuals
    deviceCardClass () {
      const { isSelectable, isSelected, bandCount, device, deviceParts } = this
      return {
        selectable: isSelectable,
        selected: isSelected,
        'multi-device': device.isMultiDevice,
        'no-parts': deviceParts?.length === 0,
        [`bands-${bandCount}`]: true
      }
    },

    backgroundColors () {
      return DeviceConnectionStatusBackgroundColor
    },

    backgroundColor () {
      const { status, backgroundColors } = this
      if (status?.connection) {
        return backgroundColors[status.connection.status]
      } else {
        return backgroundColors[DeviceConnectionStatus.Unknown]
      }
    },

    deviceCardStyle () {
      const { backgroundColor } = this
      return {
        'background-color': backgroundColor
      }
    },

    canManageFirmware () {
      return this.canUse('can-manage-firmware')
    },

    canSendCommands () {
      return this.canUse('device-management') && this.isSelectable
    },

    canUnshare () {
      const { device, isEditable, currentUser } = this
      return !isEditable && device.isShared && currentUser.isAdministrator
    },

    canEditComments () {
      const { isEditable, isBusy, device, canUse, canComment } = this
      return canComment && isEditable && !isBusy && !device.isShared && canUse('device-management')
    },

    // Indicates whether device region icon should be visible.
    // We show this, when displaying the insides of a multi-device,
    // or showing multi-device on a place dashboard
    showDeviceRegion () {
      const { device, masterDevice } = this
      return masterDevice || device.isMultiDevice
    },

    // Indicates whether region of the specified device is currently being changed
    isChangingRegionOf () {
      return device => this.changingRegionOf.find(d => d.serialNumber === device.serialNumber)
    },

    // Returns true if device model supports the specified region
    modelRegionIs () {
      return value => {
        const { device, deviceParts } = this
        if (device) {
          // For multi-device return the model of the currently active board
          if (device.isMultiDevice) {
            const activePart = deviceParts.find(part => part.region && part.region === part.modelRegion)
            return activePart?.modelRegion === value
          } else {
            // For simple device just deduct from model
            return device.modelRegion === value
          }
        }
      }
    },

    // Returns true if device region in settings matches the device region determined by model.
    // If device region is not known yet (status hasn't been fetched), we simply check the model region.
    deviceRegionIs () {
      return value => {
        const { device, deviceParts } = this
        if (device) {
          if (device.isMultiDevice) {
            // For multi-device check the model of the currently active board
            const activePart = deviceParts.find(part => part.region && part.region === part.modelRegion)
            return activePart?.region ? activePart?.region === value : activePart?.modelRegion === value
          } else {
            return device?.region ? device.region === value : device.modelRegion === value
          }
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'setDeviceCustomLocation',
      'setDeviceComments',
      'unlinkDevices'
    ]),

    // Breaks lines of text with HTML break
    breakLines (text) {
      return (text || '').replace(/\n/g, '<br>')
    },

    // Marks the specified device as selected
    select () {
      const { device, isSelected } = this
      this.$emit('select', { device, isSelected: !isSelected })
    },

    // Returns link to part dashboard
    getPartLink (part) {
      return {
        name: 'dashboard',
        query: {
          entityType: EntityType.Device,
          device: part.partOf,
          part: part.serialNumber
        }
      }
    },

    // Stops sharing the device with current organization
    async unshare () {
      const { device, organization, unlinkDevices } = this
      const message = `Are you sure you no longer need access to device ${device.acronym}?`
      const yes = await Confirmation.ask({ message })
      if (yes) {
        await unlinkDevices({
          devices: [device],
          organization
        })
      }
    }
  }
}
</script>

<template>
  <div class="device-card q-pa-sm q-pt-md" :class="deviceCardClass" :style="deviceCardStyle"
    @click="select()">

    <div class="label title q-pl-xs">
      <span v-if="isBusy || !device.isConnectedDevice || cannotUse('device-dashboard')">
        {{ device.model }} {{ device.serialNumber }}
      </span>
      <div v-else @click.stop>
        <router-link class="item-link"
          :to="cardLink || { name: 'device-dashboard', params: { serialNumber: device.serialNumber } }">
          <span>
            {{ device.model }} {{ device.serialNumber }}
          </span>
          <sc-tooltip :text="`Go to ${deviceLabel} dashboard`" />
        </router-link>
      </div>
    </div>

    <!-- LEDS of a simple device -->
    <sc-widget-device-leds
      v-if="canUse('device-bands') && device.isConnectedDevice && !device.isMultiDevice && isLiveStatusAllowed"
      :device="device" :dense="dense" bg-color="transparent">
    </sc-widget-device-leds>

    <!-- LEDS of boards of a multi-device -->
    <div v-if="canUse('device-bands') && device.isMultiDevice" class="device-parts">
      <div class="row items-start" v-for="part in deviceParts">
        <router-link class="part-label item-link q-mr-xs" :to="getPartLink(part)">
          {{ part.model }}
          <sc-tooltip :text="`Go to ${part.model} ${part.serialNumber} dashboard`" />
        </router-link>

        <sc-widget-device-leds :device="part" :dense="dense" bg-color="transparent"
          v-if="isLiveStatusAllowed">
        </sc-widget-device-leds>
      </div>
      <div v-if="deviceParts.length === 0" class="part-label text-orange-8">
        No boards have been assigned
      </div>
    </div>

    <div class="footer q-pl-xs">
      <div class="label q-mr-xs" v-if="device.isNonConnectedDevice && !device.isMultiDevice">
        Non-connected device
      </div>

      <div class="label q-mr-xs" v-if="device.isConnectedDevice && !device.isMultiDevice">
        FW {{ device.firmwareVersionLong }}
      </div>

      <div class="label q-mr-xs row items-center text-orange-7 firmware-update"
        v-if="updateStatus?.inProgress">
        Sending FW {{ updateStatus.firmwareVersion }}, {{ updateStatus.progress }}%
      </div>

      <div class="label q-mr-xs row items-center text-orange-7 firmware-update"
        v-if="updateStatus?.isScheduled">
        FW {{ updateStatus.firmwareVersion }} scheduled
      </div>

      <div class="label location clip" @click.stop>
        <span>
          {{ device.isShared && device.owner ? device.owner.name + ' ' : '' }}
          {{ device.customLocation || device.location || '' }}
          <sc-tooltip v-if="isEditable">
            Location: {{ device.customLocation }}<br>
            Click to edit ...
          </sc-tooltip>
          <sc-tooltip v-else-if="device.customLocation">
            {{ device.customLocation }}
          </sc-tooltip>
        </span>
        <q-popup-edit v-if="isEditable && !isBusy" buttons self="bottom left" label-set="Save"
          v-slot="scope" :model-value="device.customLocation"
          :title="`Enter custom location of ${device.acronym} ${device.serialNumber}`"
          @save="location => setDeviceCustomLocation({ device, location })">
          <q-input autofocus max-length="255" v-model="scope.value"
            :label="device.location ? `Reported by device: ${device.location}` : 'Enter custom device location'"
            @keyup.enter="scope.set">
          </q-input>
        </q-popup-edit>
      </div>
    </div>

    <div class="buttons column">
      <div class="device-region" v-if="showDeviceRegion">
        <div class="region-icons">
          <sc-icon-flag-eu v-if="modelRegionIs(DeviceRegion.EMEA) && !isChangingRegionOf(device)"
            :width="18" :height="18" :disabled="!deviceRegionIs(DeviceRegion.EMEA)">
          </sc-icon-flag-eu>
          <sc-icon-flag-usa v-if="modelRegionIs(DeviceRegion.USA) && !isChangingRegionOf(device)"
            :width="18" :height="18" :disabled="!deviceRegionIs(DeviceRegion.USA)">
          </sc-icon-flag-usa>
        </div>
        <q-icon v-if="isChangingRegionOf(device)" name="change_circle" class="rotate-reverse"
          color="orange-7" size="22px">
        </q-icon>
      </div>

      <q-icon v-if="!isBusy && canComment && (canEditComments || device.comments)"
        class="device-comments" size="22px" :name="device.comments ? 'comment' : 'mode_comment'"
        :color="device.comments ? 'indigo-6' : (isSelected ? 'grey-3' : 'grey-5')" @click.stop>


        <sc-tooltip :text="breakLines(device.comments) || 'Click to add notes ...'"></sc-tooltip>

        <q-popup-edit v-if="canEditComments" buttons self="bottom left" label-set="Save"
          :model-value="device.comments" v-slot="scope"
          :title="`Enter comments for ${device.acronym} ${device.serialNumber}`"
          @save="comments => setDeviceComments({ device, comments })">
          <q-input type="textarea" autofocus max-length="1000" v-model="scope.value"
            @keydown.enter.stop @keypress.enter.stop @keyup.enter.stop>
          </q-input>
        </q-popup-edit>
      </q-icon>

      <q-btn v-if="canUnshare" flat round dense unelevated class="device-unshare" icon="highlight_off"
        size="sm" color="indigo-5" @click.stop="unshare()">
        <sc-tooltip text="Don't share the device with my organization"></sc-tooltip>
      </q-btn>

    </div>

    <q-checkbox v-if="!isBusy && isEditable && canSendCommands" class="device-selector" size="xs"
      color="indigo-4" keep-color :model-value="isSelected" @update:model-value="() => select()"
      @click.stop>
    </q-checkbox>

  </div>
</template>

<style lang="scss" scoped>
.device-card {
  border: solid #e0e0e0 1px;
  border-radius: 4px;
  background-color: #f8f8f8;
  height: 118px;
  min-height: 118px;
  position: relative;
  transition: all 0.1s ease-out;
  min-width: 190px;

  &.testTool {
    height: 45px;
    min-height: 45px;
  }

  &.multi-device {
    min-width: 220px;
    height: auto;

    &.no-parts {
      min-width: 190px;
      height: 118px;
      min-height: 118px;
    }
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: #e8eaf6 !important;
      border-color: #e8eaf6 !important;
    }

    &.selected {
      border: solid #5f68a7 3px !important;
    }
  }

  .title {
    font-size: 12px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 140px;

    .label {
      font-size: 11px;

      &.location {
        cursor: pointer;
        max-width: 130px;
      }

      &.firmware-update {
        flex-basis: 100%;
      }
    }
  }

  .device-parts {
    margin: 4px 0 4px 6px;

    .part-label {
      font-size: 11px;
      margin-top: 5px;
    }
  }

  .device-commands {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .device-selector {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .buttons {
    position: absolute;
    bottom: 4px;
    right: 4px;

    .device-unshare {
      margin-bottom: 4px;
    }

    .device-region {
      margin-bottom: 4px;
      padding-right: 2px;
    }

    .device-comments {
      cursor: pointer;
    }
  }
}

/* Layout adjustments for mobile phones */
@media screen and (max-width: 640px) {
  .device-card {
    min-width: 150px;

    &.multi-device {
      min-width: 150px;
      height: auto;

      &.no-parts {
        min-width: 150px;
        height: auto;
      }
    }
  }
}
</style>
