<script>
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      isVisible: false
    }
  },

  computed: {
    device () {
      return this.item.device
    },

    place () {
      return this.device.place
    },

    myPlace () {
      return this.place && this.place.organizationId === this.currentOrganization.id
    },

    // Returns true if organization can access dashboards of child organizations
    canAccessDashboard () {
      return this.place && (this.myPlace || this.canUse('child-places'))
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clip">
    <!-- Link to place dashboard, only available if own place or allowed to access places of child organizations -->
    <router-link class="item-link"
      v-if="canAccessDashboard"
      :to="{ name: 'place-dashboard', params: { id: place.id } }">
      {{ item.place }}
      <sc-tooltip :text="`Go to ${item.place} dashboard`" />
    </router-link>
    <span v-else>
      {{ item.place }}
    </span>
  </div>
</template>

<style>
</style>