import { Routes as inventoryRoutes } from '../views/inventory'
import { Routes as dashboardsRoutes } from '../views/dashboard'
import { Routes as placesRoutes } from '../views/places'
import { Routes as buildingsRoutes } from '../views/buildings'
import { Routes as buildingDashboardRoutes } from '../views/building-dashboard'
import { Routes as deviceHistoryRoutes } from '../views/device-history'
import { Routes as floorPlanRoutes } from '../views/floor-plans'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...inventoryRoutes,
  ...dashboardsRoutes,
  ...buildingsRoutes,
  ...buildingDashboardRoutes,
  ...deviceHistoryRoutes,
  // TODO: OBSOLETE, REMOVE WHEN READY
  ...floorPlanRoutes,
  ...placesRoutes,
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  Inventory: 'inventory',
  AddToInventory: 'inventory-add',
  Dashboard: 'dashboard',
  Buildings: 'buildings',
  BuildingDashboard: 'building-dashboard',
  Building: 'building',
  DeviceHistory: 'device-history',
  // TODO: OBSOLETE, REMOVE WHEN READY
  FloorPlans: 'floor-plans',
  FloorPlan: 'floor-plan',
  Place: 'place',
  Places: 'places'
}
