<script>
import DeviceNotes from '../popups/device-notes.vue'
import { CellMixin } from './cell-mixin'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  components: {
    'sc-device-notes': DeviceNotes
  },

  computed: {
    // Returns true if editing notes is possible
    canEditNotes () {
      return this.canUse('edit-device-notes')
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text" :class="{ clickable: canEditNotes }">
    <span>
      {{ clip(item.recentNote, 50) }}
    </span>
    <q-popup-edit
      v-if="canEditNotes"
      v-model="item.device.notes"
      label-set="Save"
      cover
      auto-save
      anchor="bottom right"
      max-height="700px"
      :title="`${item.device.acronym} ${item.device.serialNumber}`">
      <template v-slot="{ cancel }">
        <div class="notes-popup">
          <sc-device-notes popup
            :devices="[item.device]"
            :create-new-note="!item.device.hasNotes"
            @cancel="cancel">
          </sc-device-notes>
        </div>
      </template>
    </q-popup-edit>
  </div>
</template>

<style scoped lang="scss">
.notes-popup {
  width: 600px;
  min-height: 320px;
  overflow: auto;
}
</style>