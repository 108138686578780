export function createState () {
  return {
    /**
     * Organization being viewed in the dashboard
     * @type {Organization}
     */
    organization: null,
    /**
     * Device being viewed in the dashboard
     * @type {Device}
     */
    device: null,
    /**
     * Device part being viewed in the dashboard.
     * For single-board device this is the same as the device.
     * For multi-board devices we can watch the dashboard of each board individually.
     * @type {Device}
     */
    devicePart: null,
    /**
     * Age of the recent alerts fetched for the viewed device, in seconds
     * @type {Number}
     */
    lastAlertsAge: null
  }
}

export const state = createState()

export const getters = {
}
