<script>
import { mapGetters, mapActions } from 'vuex'
import { Viewport } from '@stellacontrol/client-utilities'
import TermsAndConditions from './terms-and-conditions.vue'

export default {
  components: {
    'sc-terms-and-conditions': TermsAndConditions
  },

  computed: {
    ...mapGetters([
      'isLoggedIn',
      'loggedInAsMaster',
      'termsAndConditionsAccepted'
    ])
  },

  methods: {
    ...mapActions([
      'collapseSidebar'
    ]),

    viewContainerClicked () {
      if (Viewport.isSmallScreen && this.isLoggedIn) {
        this.collapseSidebar()
      }
    }
  }
}
</script>

<template>
  <main class="view-container" @click="viewContainerClicked()">
    <sc-terms-and-conditions v-if="isLoggedIn && !loggedInAsMaster && !termsAndConditionsAccepted">
    </sc-terms-and-conditions>
    <router-view v-else>
    </router-view>
  </main>
</template>

<style scoped lang="scss">
.view-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>