import { createState } from './buildings.state'

export const mutations = {
  /**
   * Filters the buildings view by the specified conditions
   * @param {String} filter Free-text filter
   */
  applyPlacesFilter (state, { filter }) {
    state.filter = filter
  },


  /**
   * Pins or unpins the specified place
   * @param {String} placeId Place to pin/unpin
   * @param {Boolean} pin If specified, defines whether the place should be pinned.
   * If not specified, the current `pinned` state of the place is toggled
   */
  pinPlace (state, { placeId, pin }) {
    const index = state.pinnedPlaces.indexOf(placeId)
    const wasPinned = index > -1
    pin = pin == null ? !wasPinned : Boolean(pin)

    if (wasPinned && !pin) {
      state.pinnedPlaces.splice(index, 1)
    } else if (!wasPinned && pin) {
      state.pinnedPlaces.push(placeId)
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
