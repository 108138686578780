import devices from './devices'
import places from './places'
import buildingsView from './buildings-view'
import buildingDashboard from './building-dashboard'
import floorPlans from './floor-plans'
import deviceStatus from './device-status'
import deviceSettings from './device-settings'
import deviceCommands from './device-commands'
import dashboardView from './dashboard-view'
import inventoryView from './inventory-view'
import devicePanel from './device-panel'
import deviceHistoryView from './device-history-view'

export const AppletStores = {
  devices,
  places,
  buildingsView,
  buildingDashboard,
  floorPlans,
  deviceStatus,
  deviceSettings,
  deviceCommands,
  inventoryView,
  devicePanel,
  dashboardView,
  deviceHistoryView
}
