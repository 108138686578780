<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { Log, plainText, getId } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin, Exporter } from '@stellacontrol/client-utilities'
import { Device, DeviceType, StandardDeviceBands } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceAPI } from '@stellacontrol/client-api'
import { PlanLayout, PlanFloors, PlannerMode } from '@stellacontrol/planner'
import sampleLayout from './layout.json'

const name = 'stellaplanner-playground'

/**
 * Playground for Planner
 */
export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      name,
      layout: null,
      plan: null,
      floorId: null,
      layoutChanged: null,
      isDebugging: false,
      places: [],
      state: null,
      stateLabel: null,
      pointerPosition: null
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode,
      // Normal/maximized status of the view
      isMaximized: state => state.planner.isMaximized,
    }),

    ...mapGetters([
      'currentRoute'
    ]),

    devices () {
      return [
        new Device({
          type: DeviceType.Repeater,
          serialNumber: 'rig101',
          bands: StandardDeviceBands.FiveBands,
          portCount: 6
        }),
        new Device({
          type: DeviceType.Repeater,
          serialNumber: 'rig102',
          bands: StandardDeviceBands.SixBands,
          portCount: 4
        }),
        new Device({
          type: DeviceType.LineAmp,
          serialNumber: 'rig103',
          bands: StandardDeviceBands.FiveBands,
          portCount: 5
        })
      ]
    },

    place () {
      return this.places[0]
    },

    // View title
    title () {
      return 'Playground'
    },

    // Indicates that the plan is initialized
    isInitialized () {
      return this.layout != null
    },

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },
  },

  methods: {
    ...mapActions([
      'gotoHome',
      'toggleSidebar',
      'setPlannerMode',
      'setPlannerView',
      'updateRoute'
    ]),

    async initialize () {
      this.places = await DeviceAPI.getPlaces()

      // Load the layout
      let data = this.load()
      this.layout = new PlanLayout(data, 'Plan')

      // Determine the floor to show
      this.floorId = this.currentRoute.query.floor
      if (!(this.floorId && this.layout.getFloor(this.floorId))) {
        this.floorId = PlanFloors.CrossSection
      }

      // Start in the advanced mode
      this.setPlannerMode({ mode: PlannerMode.Advanced })

      // Save the plan immediately if any changes,
      // upgrades etc. have been applied while loading the plan
      if (this.layout.isDirty) {
        this.save()
      }
    },

    // Triggered when plan has been loaded
    planInitialized ({ renderer, layout, hierarchy }) {
      // Show current position in debug mode
      renderer.events.addEventListener('position', ({ detail: { position } }) => {
        this.pointerPosition = position
      })

      // Save the plan immediately if any changes,
      // upgrades etc. have been applied while loading the plan
      this.save()
    },

    // Goes back to the previous view
    async cancel () {
      await this.gotoHome()
    },

    // Notifies about changes to the plan layout
    onChanged () {
      this.save()
    },

    // Resets the layout from JSON file
    resetLayout () {
      this.layout = new PlanLayout(sampleLayout, 'Plan')
    },

    // Toggles between regular and advanced mode
    toggleAdvancedMode () {
      const mode = this.isAdvancedMode ? PlannerMode.Regular : PlannerMode.Advanced
      this.setPlannerMode({ mode })
    },

    // Load the plan layout from session store if present,
    // otherwise from defaults
    load () {
      let data
      try {
        data = JSON.parse(window.localStorage.getItem('plan-layout')) || sampleLayout
      } catch {
        data = sampleLayout
      }
      return data
    },

    // Saves the plan layout as JSON file
    async save ({ floor, download } = {}) {
      const { layout } = this
      if (layout) {
        if (floor) {
          await this.selectFloor({ floor })
        }

        const { label, id } = layout
        const fileName = `${plainText(label || id)}.json`
        const data = JSON.stringify(layout.serialize(), null, 2)
        try {
          window.localStorage.setItem('plan-layout', data)
          Log.debug('Plan saved')
        } catch {
        }
        if (download) {
          await Exporter.toFile(data, fileName, 'application/json')
        }
      }
    },

    // Saves the floor image
    saveImage ({ floor, file } = {}) {
      if (floor) {
        if (file && !file.id) {
          file.id = getId('img')
        }
        Log.debug(floor.label, file ? 'Image saved' : 'Image removed')
        return this.save()
      }
    },

    // Triggered when user selects another floor
    async selectFloor ({ floor, save } = {}) {
      if (save) {
        await this.save()
      }
      const id = floor?.id || PlanFloors.Main
      this.updateRoute({ query: { floor: id } })
    },

    // Triggered when user selects cross-section
    async selectCrossSection ({ save } = {}) {
      if (save) {
        await this.save()
      }
      this.updateRoute({ query: { floor: PlanFloors.CrossSection } })
    },

    // Displays help
    showHelp () {
      this.$refs.plan.showHelp()
    },

    // Displays the current plan state
    showState ({ state, label }) {
      this.state = state
      this.stateLabel = label
    },

    // Toggles maximal/normal view
    async toggleMaximize () {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen()
        await this.setPlannerView({ isMaximized: false })
      } else if (!document.fullscreenElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
        await this.setPlannerView({ isMaximized: true })
      }
      await this.toggleSidebar({ isCollapsed: this.isMaximized })
    },


    // Toggles debugging
    toggleDebugging () {
      this.isDebugging = !this.isDebugging
      this.$refs.plan.debug(this.isDebugging)
    }
  },

  // Reload data on navigation to another plan or floor
  async beforeRouteUpdate (to, from, next) {
    this.initialize()
    return next()
  },

  async created () {
    await this.initialize()
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :noHeader="isMaximized">
    <template #toolbar>
      <div class="q-gutter-xs row items-center">
        <div class="plan-state q-mr-lg bg-green-6 text-white" v-if="stateLabel">
          {{ stateLabel }}
        </div>
        <q-btn v-if="isInitialized && isDebugging && pointerPosition" :label="pointerPosition.toString() || ''" unelevated style="width: 120px;">
        </q-btn>
        <q-btn v-if="isInitialized" label="Debug" :outline="!isDebugging" unelevated
          :class="{ warning: isDebugging }" @click="toggleDebugging()"></q-btn>
        <q-btn v-if="isInitialized" label="Revert" unelevated @click="resetLayout()"
          class="q-mr-lg"></q-btn>
        <q-btn v-if="isInitialized" label="Help" icon="help" unelevated @click="showHelp()"></q-btn>
        <q-btn v-if="isInitialized" :label="isAdvancedMode ? 'Advanced Mode' : 'Normal Mode'"
          icon="tune" :class="isAdvancedMode ? 'bg-orange-7 text-white' : undefined" unelevated
          @click="toggleAdvancedMode()"></q-btn>
        <q-btn v-if="isInitialized" label="Maximize" unelevated @click="toggleMaximize()"></q-btn>
        <q-btn v-if="isInitialized" label="Save" unelevated
          @click="save({ download: true })"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-plan ref="plan" :is-debugging="isDebugging" :devices="devices" :layout="layout"
      :place="place" :floorId="floorId" :changeInterval="1000" :isMaximized="isMaximized"
      :initialized="(args) => planInitialized(args)"
      :changed="(args) => onChanged(args)" :saved="(args) => save(args)"
      :image-saved="(args) => saveImage(args)"
      :floor-selected="(args) => selectFloor({ ...args, save: true })"
      :cross-section-selected="(args) => selectCrossSection({ ...args, save: true })"
      :state-changed="(args) => showState(args)">
    </sc-plan>

    <q-btn dense unelevated flat v-if="isMaximized" class="button-minimize" label="Normal View"
      @click="toggleMaximize()"></q-btn>
  </sc-view>
</template>

<style lang="scss" scoped>
.button-minimize {
  position: absolute;
  right: 350px;
  top: 8px;
}

:deep(.q-tab-panel) {
  padding: 0 !important;
}

.plan-state {
  padding: 4px 8px 5px 8px;
  border-radius: 4px;
  font-size: 12px;
}
</style>