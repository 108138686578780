<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getFullDurationString } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin } from '@stellacontrol/client-utilities'
import { LoginError } from '@stellacontrol/security'

const name = 'login'

export default {
  mixins: [
    ViewMixin,
    FormMixin
  ],

  data () {
    return {
      name,
      userName: '',
      password: '',
      // Flag indicating whether password should be displayed in clear text
      revealPassword: false,
      // Login error code
      loginError: null
    }
  },

  computed: {
    ...mapState({
      ticker: state => state.client.ticker
    }),

    ...mapGetters([
      'application',
      'branding',
      'company',
      'configuration',
      'environment',
      'isProductionEnvironment',
      'isLoggedIn',
      'isLoggingIn',
      'configuration',
      'isMobilePhone'
    ]),

    // Indicates that we're running standalone development applet
    isDevelopmentApplet () {
      return this.configuration.environment === 'development' && this.application.isApplet
    },

    // Google reCaptcha configuration
    reCaptcha () {
      return this.configuration.security.login.reCaptcha
    },

    // Page to redirect to after succesful login
    redirectTo () {
      return this.$route.query.redirectTo
    },

    // Indicates whether the window is ready for login.
    // Sometimes reCaptcha is slow to initialize...
    // Use client.ticker to force re-evaluation
    isReady () {
      const { isDevelopmentApplet, ticker, reCaptcha, getReCaptchaService } = this
      if (!isDevelopmentApplet && reCaptcha && reCaptcha.enabled) {
        return ticker > 0 && getReCaptchaService() != null
      } else {
        return true
      }
    },

    // Returns true if login failed due to locked account
    accountIsLocked () {
      return this.loginError === LoginError.UserDisabled
    },

    // Returns true if login failed due to too many failed login attempts
    tooManyFailedLogins () {
      return this.loginError === LoginError.TooManyFailedLogins
    },

    // Information when is the next login allowed,
    // after locking due to too many failed logins
    nextLoginAllowed () {
      return getFullDurationString(this.configuration.security.login.resetMaximalLoginAttemptsAfter)
    }
  },

  methods: {
    ...mapActions([
      'authenticate',
      'startSession',
      'clearSession',
      'anonymousResetPassword'
    ]),

    // Access to reCaptcha service
    getReCaptchaService () {
      return window.grecaptcha
    },

    // Executes login
    async login (reCaptchaToken) {
      const { userName, password, redirectTo } = this
      if (await this.validate()) {
        if (userName && password) {
          const { session, reason } = await this.authenticate({ userName, password, reCaptchaToken })
          if (session) {
            await this.startSession({ session, redirectTo })
          } else {
            this.loginError = reason
          }
        }
      }
    },

    // Safe login, protected with reCaptcha if enabled
    safeLogin () {
      this.loginError = null
      const reCaptchaService = this.getReCaptchaService()
      const { reCaptcha, login } = this
      if (reCaptcha && reCaptchaService && reCaptcha.enabled) {
        reCaptchaService.ready(async () => {
          const token = await reCaptchaService.execute(reCaptcha.key, { action: 'submit' })
          if (token) {
            login(token)
          }
        })

      } else {
        return login()
      }
    },

    // Requests password reset
    async reset (reCaptchaToken) {
      if (await this.validate()) {
        const { userName } = this
        await this.anonymousResetPassword({ name: userName, reCaptchaToken })
      }
    },

    // Safe password reset, protected with reCaptcha if enabled
    safeReset () {
      this.loginError = null
      const reCaptchaService = this.getReCaptchaService()
      const { reCaptcha, reset } = this
      if (reCaptcha && reCaptchaService && reCaptcha.enabled) {
        reCaptchaService.ready(async () => {
          const token = await reCaptchaService.execute(reCaptcha.key, { action: 'submit' })
          if (token) {
            reset(token)
          }
        })

      } else {
        return reset()
      }
    }
  },

  async beforeRouteEnter (to, from, next) {
    // Make sure there's no session on entering this view
    next(async instance => {
      await instance.clearSession()
    })
  },

  created () {
    this.clearSession()
  }
}

</script>

<template>
  <sc-view :name="name" no-header>
    <main class="login column items-center justify-center">
      <q-card class="form-container" v-if="!isLoggedIn" v-cloak square :flat="isMobilePhone" :bordered="!isMobilePhone">
        <q-form ref="form" class="q-gutter-sm">
          <div class="header row">
            <header class="text-h5">
              {{ application.name }}
            </header>
            <q-space></q-space>
            <template v-if="branding.logo">
              <a v-if="company.website" :href="company.website" class="link-company-website"
                :title="`Go to ${company.name} website`">
                <div class="image-logo" :style="{ 'background-image': `url('${branding.logo}')` }">
                </div>
              </a>
              <div v-else>
                <div class="image-logo" :style="{ 'background-image': `url('${branding.logo}')` }">
                </div>
              </div>
            </template>
          </div>

          <div class="fields">
            <q-input class="input-username" square outlined label="User e-mail" v-model="userName"
              autocomplete="username" maxlength="45" lazy-rules :rules="[
                rules.required('User is required'),
                rules.isEmail('Invalid user')
              ]" @keydown.enter="safeLogin()">
            </q-input>

            <q-input class="input-password" square outlined label="Password" v-model="password"
              autocomplete="current-password" maxlength="45"
              :type="revealPassword ? 'text' : 'password'" lazy-rules :rules="[
                rules.required('Password is required')
              ]" @keydown.enter="safeLogin()">
              <template v-slot:append>
                <q-icon name="visibility" class="button-reveal-password cursor-pointer"
                  @click="revealPassword = !revealPassword"></q-icon>
              </template>
            </q-input>
          </div>

          <div class="buttons row">
            <q-btn unelevated label="Forgot password" class="button-recover-password"
              @click="safeReset()" :disable="!isReady || isLoggingIn || !userName">
              <sc-tooltip v-if="!userName"
                text="Enter your user name to reset the password"></sc-tooltip>
            </q-btn>
            <q-space></q-space>
            <q-btn unelevated class="button-login primary" size="md" label="Log in"
              @click="safeLogin()" :disable="!userName || isLoggingIn"></q-btn>
          </div>
        </q-form>
      </q-card>

      <footer class="login-error-container column items-center justify-center">
        <q-card class="login-error q-pa-lg bg-orange-7 text-white" square :flat="!isMobilePhone"
          v-if="accountIsLocked || tooManyFailedLogins">
          <span v-if="accountIsLocked">
            Your account is not active.<br>Contact the administrator to receive activation e-mail.
          </span>
          <span v-if="tooManyFailedLogins">
            Too many failed login attempts.<br>You can try again in {{ nextLoginAllowed }}.
          </span>
        </q-card>
      </footer>
    </main>
  </sc-view>
</template>

<style scoped lang="scss">
main.login {
  flex: 1;

  .form-container {
    width: 50%;
    max-width: 500px;
    padding: 20px;

    .header {
      margin-bottom: 20px;
    }

    .buttons {
      margin-top: 50px;
    }

    .image-logo {
      width: 200px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
    }

    .button-login {
      min-width: 180px;
    }

    .button-recover-password {
      min-width: 180px;
    }
  }

  .login-error-container {
    width: 100%;
    height: 70px;
    margin-top: 40px;

    .login-error {
      width: 50%;
      max-width: 500px;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  main.login {
    .form-container {
      width: 60%;
      max-width: 60%;
    }
  }
}

/* Layout adjustments for screen below XGA resolution */
@media screen and (max-width: 1023px) {
  main.login {
    .form-container {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border: none !important;
      padding: 0;

      .header {
        background-color: #273163;
        padding: 15px;
        color: white;
      }

      .fields {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .buttons {
        padding: 20px;
        padding-top: 0;
        margin-top: 0;
      }

      .button-login,
      .button-recover-password {
        min-width: 150px;
      }
    }

    .login-error-container {
      position: fixed;
      z-index: 10;
      bottom: 0;
      width: 100%;
      padding: 0;
      height: auto;

      .login-error {
        width: 100%;
        max-width: 100%;
        padding: 10px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>