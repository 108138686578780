import NonConnectedDeviceDialog from './non-connected-device/non-connected-device.vue'
import SimulatedDeviceDialog from './simulated-device/simulated-device.vue'
import BandSelector from './band-selector/band-selector.vue'
import DeviceConfiguration from './device-configuration/device-configuration.vue'
import DeviceHistory from './device-history/device-history.vue'

/**
 * Installs Device UI dialogs into the application
 * @param application Vue application instance
 */
export function useDevicesDialogs (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-non-connected-device-dialog',
          component: NonConnectedDeviceDialog
        },
        {
          tag: 'sc-simulated-device-dialog',
          component: SimulatedDeviceDialog
        },
        {
          tag: 'sc-band-selector-dialog',
          component: BandSelector
        },
        {
          tag: 'sc-device-configuration-dialog',
          component: DeviceConfiguration
        },
        {
          tag: 'sc-device-history-dialog',
          component: DeviceHistory
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
