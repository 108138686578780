import { PlanItemType, CableLengthMode } from '@stellacontrol/planner'
import { ConnectorShape } from './connector'

/**
 * Cable
 */
export class CableShape extends ConnectorShape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
  }

  static get type () {
    return PlanItemType.Cable
  }

  /**
   * Returns shape line style, appropriate for the specified state.
   * @param {PlanItem} item Item for which to determine the line style
   * @param {PlanLineStyle} style Optional style to tap into. If not specified, we're using `lineStyle` of the item
   * @param {PlanItemState} state Optional shape state
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {PlanLineStyle}
   */
  getLineStyle (item, style, state, renderer) {
    if (!item.isCustom) {
      item.lineStyle.width = item.parameters.thickness || 4
      item.lineStyle.color = item.parameters.color || 'blue'
    }
    const result = super.getLineStyle(item, style, state, renderer)
    return result
  }


  /**
   * Calculates the total length of a cross-floor
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item Plan cable
   * @returns {Number} Cable length
   */
  getCrossFloorLength ({ renderer, item }) {
    if (item.isCrossFloor) {
      const { layout } = renderer
      const { floors, from: fromFloor, to: toFloor } = layout.getConnectorFloors(item)
      const ends = layout
        .getConnectorEnds(item)
        .map(end => end.realLength || 0)
      const passThrough = floors
        .slice(1, floors.length - 1)
        .map(floor => floor.roomHeight)
      const length = [
        fromFloor.index < toFloor.index ? fromFloor.roomHeight : toFloor.roomHeight,
        ...passThrough,
        ...ends
      ].reduce((sum, length) => sum + length, 0)
      return length
    }
  }

  /**
   * Returns real length of the cable.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} meters If true, cable length in meters is returned.
   * This requires that the floor on which the item is placed has a scale defined!
   * @returns {Number} Cable length
   */
  getLength ({ renderer, meters }) {
    // For cross-floor cable length is a sum of cables linking
    // equipment with risers on the respective floor,
    // plus sum of heights of the floors in between
    const { item: cable } = this
    if (cable.isCrossFloor) {
      const length = this.getCrossFloorLength({ renderer, item: this })
      return length
    }

    const floor = renderer.layout.getFloorOf(this.item)
    if (floor) {
      const points = this.getShapePoints({ renderer })
      let length = 0
      let point = points[0]
      for (let i = 1; i < points.length; i++) {
        length = Math.round(length + points[i].distance(point))
        point = points[i]
      }

      if (meters) {
        const { mapScale } = floor
        if (mapScale > 0) {
          return Math.ceil(length / mapScale)
        }
      } else {
        return Math.ceil(length)
      }
    }
  }


  /**
   * Determines the cable label.
   * We display calculated cable lengths, unless the user has specified a custom label for a cable.
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {String}
   */
  getLabelText ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    const { item } = this
    const { layout } = renderer
    const mode = layout.cableLengths

    if (mode && mode !== CableLengthMode.Off) {
      let label = super.getLabelText({ renderer })
      let length

      if (!label) {
        if (mode === CableLengthMode.Default) {
          length = layout.getDefaultCableLength(item)
          item.defaultLength = length

        } else if (mode === CableLengthMode.Actual) {
          length = this.getLength({ renderer, meters: true })
          item.realLength = length
        }

        if (length > 0) {
          label = `${length}m`

          // If cable is part of a cross-floor connector, update the cross-floor length accordingly
          if (item.partOf) {
            const connector = layout.getItem(item.partOf)
            if (layout.realCableLengths) {
              connector.realLength = this.getCrossFloorLength({ renderer, item: connector })
            } else if (layout.defaultCableLengths) {
              connector.defaultLength = this.getCrossFloorLength({ renderer, item: connector })
            }
          }
        }
      }

      return label
    }
  }

  /**
   * Renders the shape
   * @param {PlanRenderer} renderer
   */
  render (renderer) {
    super.render(renderer)
  }
}
