<script>
import { countString } from '@stellacontrol/utilities'
import { mapGetters, mapState, mapActions } from 'vuex'
import { ListAction, ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { getPlaceIcon } from '@stellacontrol/model'

const TOOLTIP_LOADING = 'Loading ...'
const Tooltips = {
  plan: {
    on: 'Edit building plan',
    off: 'Create building plan'
  },
  notes: {
    on: 'Edit notes',
    off: 'Add notes'
  },
  pinned: {
    on: 'Remove from favourite',
    off: 'Add to favourites'
  }
}

/*
 * TODOs:
 * - alerts
 * - link on row-click
 * - persist filter in preferences
 */
const name = 'buildings'
const stockName = 'Stock'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      placeActions: [
        ListAction.Edit,
        ListAction.Delete
      ],
      organizationTooltip: {}
    }
  },

  computed: {
    ...mapGetters([
      'organizations',
      'users',
      'organizationHierarchy',
      'devices',
      'configuration',
      'organizationProfiles',
      'isMobilePhone'
    ]),

    ...mapState({
      // Current filter for the buildings view
      filter: state => state.buildingsView.filter,
      places: state => state.places.items || [],
      pinnedPlaces: state => state.buildingsView.pinnedPlaces || [],
      gridColumns (state) {
        return this.isMobilePhone
          ? state.buildingsView.columns.filter(c => !c.desktopOnly)
          : state.buildingsView.columns
      },
      sortState: state => state.buildingsView.sortState,
    }),

    // List of places (and stocks) with relevant data
    detailedPlaces () {
      const rootOrgId = this.organizations.find(organization => !organization.parentOrganizationId)?.id
      const stocks = {} // organizationID -> devices[]
      const favorite = this.configuration.entities.tag.items.favorite

      for (const device of this.devices.filter(device => !device.placeId)) {
        const ownerId = device.ownerId || rootOrgId
        stocks[ownerId] = [...(stocks[ownerId] || []), device]
      }

      const stockPlaces = Object.entries(stocks).map(([ownerId, devices]) => ({
        id: ownerId,  // Fake "placeId" to interact w/ the `pinned` functionality
        organizationId: ownerId,
        isStock: true,
        name: stockName,
        icon: getPlaceIcon(),
        isPinned: this.pinnedPlaces.includes(ownerId),
        pinnedIcon: this.pinnedPlaces.includes(ownerId) ?
          favorite.iconOn : favorite.iconOff,
        notes: false,
        devices,
        filteredDevices: null,
        deviceCount: devices.length
      }))

      const regularPlaces = this.places.map(place => ({
        id: place.id,
        organizationId: place.organizationId,
        name: place.name,
        icon: getPlaceIcon(place.placeType),
        isStock: false,
        isPinned: this.pinnedPlaces.includes(place.id),
        pinnedIcon: this.pinnedPlaces.includes(place.id) ?
          favorite.iconOn : favorite.iconOff,
        notes: !!place.notes?.length,
        devices: place.devices,
        filteredDevices: null,
        deviceCount: place.deviceCount,
        updatedBy: this.users.find(user => user.id === place.updatedBy)?.name,
        updatedAt: place.updatedAt,
        notesIcon: place.attachmentCount ? 'attachment' : place.notes.length ? 'comment' : 'mode_comment',
        hasNotes: place.attachmentCount > 0 || place.notes.length > 0,
        notesColor: place.attachmentCount || place.notes.length ? 'indigo-5' : 'indigo-2',
        notesLabel: place.attachmentCount ? countString(place.attachmentCount, 'document') : '',
        placeId: place.id,  // Actual place-id (not fake "placeId") for plan access.
        hasPlan: place.planId != null,
        planColor: place.planId ? 'indigo-5' : 'indigo-2'
      }))

      return [
        ...stockPlaces,
        ...regularPlaces
      ]
    },

    // Returns the ownership organizations for the device
    ownership () {
      return organizationId => {
        const { currentOrganization, organizationHierarchy } = this
        return organizationId === currentOrganization.id
          ? [currentOrganization]
          : [
            ...organizationHierarchy.getParentsOf(organizationId).reverse().slice(1),
            this.organizations.find(o => o.id === organizationId)
          ]
      }
    },


    organizationName () {
      const { currentOrganization } = this
      return organizationId => organizationId === currentOrganization.id
        ? currentOrganization.name
        : this.organizations.find(o => o.id === organizationId).name
    },

    // Returns a string showing the organization hierarchy of the specified one
    organizationPath () {
      return organizationId => this.ownership(organizationId).map(o => o.name).join(' > ')
    },

    // List of places after filtering
    filteredPlaces () {
      const filters = this.filter?.toLowerCase().split(' ').filter(word => word) || []
      let rows

      if (filters.length) {
        rows = this.detailedPlaces.filter(place => {
          place.filteredDevices = place.devices.filter(
            device => filters.some(filter => device.serialNumber.toLowerCase().includes(filter))
          ).length
          const deviceSerials = place.devices.map(device => device.serialNumber).join(',')
          return place.isPinned ||
            filters.every(filter =>
              place.name.toLowerCase().includes(filter) ||
              this.organizationPath(place.organizationId).toLowerCase().includes(filter) ||
              deviceSerials.toLowerCase().includes(filter)
            )
        })
      } else {
        for (const place of this.detailedPlaces) {
          place.filteredDevices = null
        }
        rows = this.detailedPlaces
      }

      // When no sorting is applied, `sort-method` is used forcefully as there is unfortunately no way to modify Quasar table sort-order
      //return rows.sort((a, b) => a.isPinned === b.isPinned ? a.modifiedAt - b.modifiedAt : a.isPinned ? -1 : 1)
      return this.sortMethod(rows, 'updatedAt', 'desc')
    },

    // Organization tooltip
    tooltipOf () {
      return organization => organization ? (this.organizationTooltip[organization.id] || TOOLTIP_LOADING) : undefined
    },

    // View title
    title () {
      return `Buildings (${this.places.length})`
    },

  },

  methods: {
    ...mapActions([
      'showDialog',
      'createPlace',
      'applyPlacesFilter',
      'pinPlace',
      'showPlaceNotes',
      'editPlace',
      'removePlace'
    ]),

    // Handler for sorting table rows
    sortMethod (rows, sortBy, descending) {
      return rows.sort((a, b) => {
        if (a.isPinned !== b.isPinned) return a.isPinned ? -1 : 1
        // Specific case when values can be undefined (dates for example)
        if ((a[sortBy] !== undefined) !== (b[sortBy] !== undefined))
          return b[sortBy] !== undefined ? 1 : -1
        if (a[sortBy] < b[sortBy]) return descending ? 1 : -1
        if (a[sortBy] > b[sortBy]) return descending ? -1 : 1
        return 0
      })
    },

    // Toggles pin state of the specified place
    updatePinned (placeId) {
      this.pinPlace({ placeId })
    },

    popupPlaceNotes (placeId) {
      this.showPlaceNotes({ place: this.places.find(place => place.id === placeId) })
    },

    executePlaceAction (place, action) {
      switch (action.name) {
        case 'edit':
          return this.editPlace({ place })
        case 'delete':
          return this.removePlace({ place, confirm: true })
      }
    },

    // Handler for tooltips
    tooltips (type, value) {
      return Tooltips[type][value ? 'on' : 'off']
    },

    // Loads tooltip for the specified organization
    async loadTooltipOf (organization) {
      if (organization) {
        const profile = this.organizationProfiles.find(p => p.id === organization.profileId)
        const lines = []
        lines.push(`${organization.name}`)
        if (profile) lines.push(`${profile.fullName}`)
        lines.push('')
        lines.push('Click to go to organization settings')
        this.organizationTooltip[organization.id] = lines.join('<br>')
      }
    },

    onSortOrderChange (so) {
      debugger
    }
  }
}

</script>

<template>
  <sc-view :name="name" subheader-color="#fff" :title="title" toolbar-class="row items-center">
    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <div class="q-pa-md buildings-filter">
        <span>
          <q-input clearable label="Filter" :model-value="filter" class="input-filter" :outlined="true"
            @update:model-value="filter => applyPlacesFilter({ filter })" dense
            debounce="500"></q-input>
          <sc-hint class="hint" text="Enter filters separated by spaces" size="20px" />
        </span>
      </div>
      <div class="row items-center">
        <q-btn flat class="primary" icon="add" label="Add Building" color="indigo-6"
          @click="createPlace()"></q-btn>
      </div>
    </template>

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isMobilePhone" to="#topbar-items">
      <div class="buildings-filter">
        <span>
          <q-input clearable label="Filter" class="input-filter" bg-color="white" outlined dense
            :model-value="filter" @update:model-value="filter => applyPlacesFilter({ filter })"
            debounce="500">
            <template v-slot:append>
              <sc-hint class="hint" text="Enter filters separated by spaces" size="20px" />
            </template>
          </q-input>
        </span>
      </div>
    </teleport>

    <div class="buildings column items-stretch">
      <q-table class="scroll sticky-header places-list" :rows="filteredPlaces" virtual-scroll
        :virtual-scroll-item-size="48" :virtual-scroll-sticky-size-start="48"
        :row-key="row => row.id" :columns="gridColumns" :rows-per-page-options="[0]"
        :sort-method="sortMethod" hide-bottom separator="none" :sort-order="sortState"
        @update:sort-order="newSortOrder => onSortOrderChange(newSortOrder)">

        <template v-slot:header="props">
          <q-tr :props="props" v-if="!isMobilePhone">
            <q-th v-for="col in props.cols" :key="col.name" :props="props"
              :style="(isMobilePhone ? col.mobileStyle : col.desktopStyle) || {}">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body-cell-pinned="props">
          <q-td :props="props">
            <q-btn dense unelevated no-caps class="clear" :icon="props.row.pinnedIcon"
              :textColor="props.row.isPinned ? 'amber-9' : 'grey-6'"
              @click.stop="updatePinned(props.row.id)">
              <sc-tooltip :text="tooltips('pinned', props.row.isPinned)" />
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-name="props">
          <q-td :props="props">
            <router-link v-if="props.row.deviceCount > 0" class="item-link" :to="{
    name: 'building-dashboard',
    params: {
      id: props.row.placeId || 'none',
      organizationId: props.row.organizationId
    }
  }">
              {{ props.row.name }}
            </router-link>
            <template v-else>
              {{ props.row.name }}
            </template>
          </q-td>
        </template>

        <template v-slot:body-cell-organization="props">
          <q-td :props="props">

            <template v-if="isMobilePhone">

              <router-link class="item-link" v-if="props.row.deviceCount > 0" :to="{
    name: 'building-dashboard',
    params: {
      id: props.row.placeId || 'none',
      organizationId: props.row.organizationId
    }
  }">
                {{ organizationName(props.row.organizationId) }}
              </router-link>
              <span v-else>
                {{ organizationName(props.row.organizationId) }}
              </span>

            </template>
            <template v-else>
              <template v-for="item in ownership(props.row.organizationId)">
                <!-- No more links on organization
                <router-link class="item-link" v-if="isAdministrator && item.id != currentOrganization.id"
                  :to="{ name: 'organization', params: { id: item.id } }">
                  {{ item.name }}
                  <sc-tooltip @show="loadTooltipOf(item)" :text="tooltipOf(item)">
                  </sc-tooltip>
                </router-link>
                <span v-else> -->
                  {{ item.name }}
                <!--span-->

                <q-icon name="chevron_right" size="xs" color="grey-7"
                  v-if="item.id !== props.row.organizationId" :key="`i-${item.id}`">
                </q-icon>

              </template>
            </template>
          </q-td>
        </template>

        <template v-slot:body-cell-plan="props">
          <q-td :props="props">
            <q-btn v-if="props.row.placeId" dense unelevated no-caps class="clear" icon="category"
              :textColor="props.row.planColor"
              :to="{ name: 'building-plan', params: { id: props.row.placeId } }">
              <sc-tooltip :text="tooltips('plan', props.row.hasPlan)" />
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-notes="props">
          <q-td :props="props">
            <q-btn v-if="props.row.notesIcon" dense unelevated no-caps class="clear"
              :label="props.row.notesLabel" :icon="props.row.notesIcon"
              :textColor="props.row.notesColor" @click.stop="popupPlaceNotes(props.row.placeId)">
              <sc-tooltip :text="tooltips('notes', props.row.hasNotes)" />
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-commands="props">
          <q-td :props="props">
            <sc-action-dropdown v-if="props.row.placeId" :data="props.row" :actions="placeActions"
              @action="action => executePlaceAction(props.row, action)">
            </sc-action-dropdown>
          </q-td>
        </template>

      </q-table>
    </div>

    <sc-document-upload-dialog></sc-document-upload-dialog>
  </sc-view>
</template>

<style lang="scss">
/*
Style cannot be scoped as some styles are applied to quasar sub-elements who are from a sub-scope
 */
.buildings {
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: #F2F2F2;
  border-bottom: 1px solid #DFE3EA;
  /* margin: auto; */

  /*.places-list {
    max-width: 1280px;
  }*/

  .scroll {
    overflow: auto;
    position: relative;

    .q-table {
      table-layout: fixed;

      tr {
        border: 0;
      }

      thead tr {
        color: #7a7a7a;
        background-color: white;
      }
    }

    &.sticky-header {
      .q-table thead tr th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }

  td.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
  }
}

.mobile .buildings {
  .scroll .q-table {
    width: 100%;
  }
}

.buildings-filter {
  display: inline-block;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: auto;

    .q-input {
      flex-grow: 1;
    }

    .hint {
      margin-left: 5px;
    }
  }
}

.mobile .buildings-filter {
  width: 100%;

  span {
    width: 100%;
  }
}
</style>
