import { formatDate } from '@stellacontrol/utilities'

const columns = [
  {
    name: 'pinned',
    label: '',
    field: 'isPinned',
    align: 'center',
    headerStyle: { width: '48px' }
  },
  {
    name: 'name',
    label: 'Name',
    sortable: true,
    field: 'name',
    align: 'left',
    classes: 'ellipsis',
    desktopStyle: { width: '175px' }
  },
  {
    name: 'organization',
    label: 'Organization',
    sortable: true,
    field: 'organization',
    align: 'left',
    classes: 'ellipsis',
    desktopStyle: { width: '400px' }
  },
  {
    name: 'deviceCount',
    label: 'Devices',
    format: (value, row) => row.filteredDevices ? `${row.filteredDevices}/${row.deviceCount}` : row.deviceCount,
    sortable: true,
    field: 'deviceCount',
    align: 'left',
    desktopStyle: { width: '64px' }
  },
  {
    name: 'plan',
    label: 'Plan',
    align: 'center',
    desktopStyle: { width: '48px' },
    desktopOnly: true
  },
  {
    name: 'notes',
    label: 'Notes',
    field: 'notes',
    align: 'center',
    desktopStyle: { width: '48px' },
    desktopOnly: true
  },
  {
    name: 'updatedAt',
    label: 'Last modified',
    format: value => value ? formatDate(value) : '',
    sortable: true,
    field: 'updatedAt',
    align: 'left',
    desktopOnly: true,
    desktopStyle: { width: '100px' }
  },
  {
    name: 'updatedBy',
    label: 'Updated by',
    field: 'updatedBy',
    align: 'left',
    desktopOnly: true
  },
  {
    name: 'commands',
    label: '',
    align: 'left',
    desktopOnly: true
  },
  {
    name: 'empty',
    label: '',
    format: () => '',
    style: 'width: 100%',
    desktopOnly: true
  }
]
export function createState () {
  return {
    /**
     * Places filter
     */
    filter: '',  // TODO -> preferences
    /**
     * List of pinned places IDs
     */
    pinnedPlaces: [],
    /**
     * Grid column definitions
     */
    columns
  }
}

export const state = createState()

export const getters = {
}
