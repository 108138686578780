import { Log, plainText } from '@stellacontrol/utilities'
import { Confirmation, Download } from '@stellacontrol/client-utilities'
import { PlanFloors, CableLengthMode } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Adds new floor to the plan
 */
export class AddFloorAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.AddFloor
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Adds new floor to the plan'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {String} label Floor label
   */
  async execute ({ renderer, label } = {}) {
    if (renderer) {
      const floor = renderer.layout.addFloor({ label })
      // Save changes and navigate to the new floor
      await renderer.save({ floor })
      await renderer.selectFloor({ floor })
      return floor
    }
  }
}

/**
 * Removes the floor from the plan
 */
export class RemoveFloorAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.RemoveFloor
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Removes the floor from the plan'
  }

  /**
   * Action label
   * @type {String}
   */
  get confirmation () {
    return 'Remove the entire floor from the plan?'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanFloor} floor Floor to remove
   */
  async execute ({ renderer, floor } = {}) {
    if (renderer) {
      // Remove the floor image before removing the floor
      floor.isDeleted = true
      await renderer.clearFloorImage()
      await renderer.saveFloorImage()
      await renderer.layout.removeFloor(floor)

      // Save and navigate back to the main floor unless on a cross-section view
      // in which case we just stay where we are
      if (renderer.isFloor) {
        await renderer.save()
        const main = renderer.layout.getFloor(PlanFloors.Main)
        renderer.selectFloor({ floor: main })
      } else {
        await renderer.save()
      }

      // Redraw cables and labels, as lengths might have changed
      renderer.reset()
      await renderer.refreshCables()
      await renderer.refreshLegends()
    }
  }
}

/**
 * Sets the floor label
 */
export class SetFloorLabelAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetFloorLabel
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Sets the floor label'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanFloor} floor Floor whose label to set
   * @param {String} label Floor label
   * @param {String} description Floor description
   */
  execute ({ renderer, floor, label, description } = {}) {
    if (renderer) {
      floor.label = label === undefined ? floor.label : label || ''
      floor.description = description === undefined ? floor.description : description || ''
      renderer.changed()
    }
  }
}

/**
 * Sets the scale of the floor map
 */
export class SetMapScaleAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetMapScale
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Sets the scale of the map'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanFloor} floor Floor whose label to set
   * @param {Number} scale Map scale to set
   */
  async execute ({ renderer, floor, scale } = {}) {
    if (renderer) {
      // Assign the new scale to the floor
      floor.mapScale = scale

      // Ask to assign the same scale to all other floors
      if (renderer.layout.floorCount > 1) {
        const yes = await Confirmation.ask(({ title: 'Confirmation', message: 'Apply the new scale to the other floors as well?' }))
        if (yes) {
          for (const floor of renderer.layout.floors) {
            floor.mapScale = scale
          }
        }
      }

      // If currently showing default cable lengths, switch to real cable lengths
      if (renderer.layout.cableLengths === CableLengthMode.Default) {
        renderer.layout.cableLengths = CableLengthMode.Actual
      }

      // Redraw cables and labels, as lengths might have changed
      renderer.reset()
      await renderer.refreshCables()
      await renderer.refreshLegends()
      await renderer.changed()
    }
  }
}

/**
 * Starts/ends setting the scale of the floor map by pointing it on the map
 */
export class DrawMapScaleAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DrawMapScale
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Draws the scale of the map'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  execute ({ renderer } = {}) {
    if (renderer) {
      if (!renderer.isDrawingMapScale) {
        renderer.cancelEditing()
      }
      renderer.reset()
      renderer.drawMapScale(!renderer.isDrawingMapScale)
    }
  }
}

/**
 * Downloads the floor plan image
 */
export class DownloadFloorImageAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DownloadFloorImage
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Downloads a screenshot of the plan'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer, floor } = {}) {
    if (renderer && floor) {
      let objectURL

      try {
        // Get the stage image, convert to data blob
        const dataUrl = await renderer.getStageImageData()
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        // Convert the blob to an object URL — this is basically an temporary internal URL
        // that points to an object stored inside the browser
        objectURL = URL.createObjectURL(blob)
        const fileName = `${plainText(renderer.layout.name)} - ${plainText(floor.label)}.png`

        // Download the data from that URL into a PNG file
        Download.saveUrlToFile(objectURL, fileName)

      } catch (error) {
        Log.error('Error downloading the plan image')
        Log.exception(error)

      } finally {
        // Release the blob after a while, as browser will only release it on page reload
        if (objectURL) {
          setTimeout(() => URL.revokeObjectURL(objectURL), 5000)
        }
      }
    }
  }
}

/**
 * Sets floor height on the cross-section view
 */
export class SetFloorHeightAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetFloorHeight
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Sets floor height'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer, floor, height } = {}) {
    if (renderer && floor && height > 0) {
      // Assign the new height
      renderer.layout.setFloorHeight(floor, height)
      // Recalculate floor bounds for all floors, from top to bottom
      renderer.layout.setCrossSectionFloorBounds({ adjustItems: true })

      renderer.changed()
    }
  }
}

/**
 * Marks the floor as selected on the cross-section view
 */
export class SelectFloorAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SelectFloor
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Selects the floor'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer, floor } = {}) {
    if (renderer && floor) {
      renderer.layout.selectFloor(floor)
      renderer.changed()
    }
  }
}

/**
 * Sets the margin around the floor
 */
export class SetFloorMarginAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetFloorMargin
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set margin'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} margin Plan margin
   * @param {PlanFloor} floor Plan floor to set the margin, optional.
   * If not specified, the currently displayed floor will be used.
   */
  execute ({ renderer, margin, floor } = {}) {
    if (renderer) {
      if (margin != null) {
        renderer.setMargin(margin, floor)
      }
    }
  }
}

/**
 * Sets the zoom level of the floor
 */
export class SetFloorZoomAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetFloorZoom
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set zoom level'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} zoom Zoom factor
   * @param {Number} delta Zoom delta, alternative to zoom factor
   * @param {Boolean} fit Set the zoom scale to fit the visible image into available screen size, alternative to above
   * @param {PlanFloor} floor Plan floor to set the zoom, optional.
   * If not specified, the currently displayed floor will be used.
   */
  execute ({ renderer, zoom, delta, fit, floor } = {}) {
    if (renderer) {
      if (zoom != null) {
        renderer.setZoom(zoom, floor)
      } else if (delta != null) {
        renderer.zoomBy(delta, true, floor)
      } else if (fit) {
        renderer.zoomToFit(floor)
      }
    }
  }
}

/**
 * Sets the floor canvas dimensions
 */
export class SetFloorSizeAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetFloorSize
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Set floor size'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Size} size Canvas size
   * @param {PlanFloor} floor Plan floor to set the size, optional.
   * If not specified, the currently displayed floor will be used.
   */
  execute ({ renderer, size, floor } = {}) {
    if (renderer) {
      renderer.setSize(size, floor)
    }
  }
}

